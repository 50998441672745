import { Component, Input, OnChanges } from '@angular/core';
import { Provider } from '../../../../@core/data/model/models';
import { ContentService } from '../../../../@core/data/api/content.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import _ from 'lodash-es';

@Component({
  selector: 'ngx-counselor-image',
  templateUrl: './counselor-image.component.html',
  styleUrls: ['./counselor-image.component.scss'],
})
export class CounselorImageComponent implements OnChanges {
  @Input() counselor: Provider;
  @Input() isFacetedSearch: boolean;
  imageUrl: Observable<any>;
  initials: String = '';
  placeholderImageUrl: String = '';
  constructor(private contentService: ContentService) { }
  lName: any;
  ngOnChanges() {
    if (this.isEligibleForPlaceholderImage()) {
      this.imageUrl = this.getPlaceholderImage();
    } else {
      this.imageUrl = this.counselor.internalId && this.counselor.imageModifiedOn ? this.getProviderImage() : null;
      this.initials = this.getInitials();
    }
  }

  isEligibleForPlaceholderImage() {
    const gender = this.counselor.gender && this.counselor.gender.toLowerCase();
    if (_.get(environment, 'placeholderProviderImages', false) !== true || (gender !== 'male' && gender !== 'female')) {
      return false;
    }
    return true;
  }

  getPlaceholderImage() {
    const endBytes = this.counselor.internalId.slice(-5);
    const imageId = parseInt(endBytes, 16) % 94;
    const gender = this.counselor.gender.toLowerCase();
    const src = `/assets/images/providers/${gender}/${imageId}.jpg`;
    return of(src);
  }

  getInitials(): string {
    if (this.counselor && this.counselor.firstName) {
      return this.counselor.firstName[0] + this.counselor.lastName[0];
    }
    const lName = this.counselor.lastName.split(' ');
    return lName[0][0] + lName[1][0];

  }

  getProviderImage(): Observable<any> {
    return this.contentService.getProviderImage(this.counselor.internalId);
  }
}
