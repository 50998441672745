import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../../@core/data';
import { CaseManagementService } from '../../../../@core/data/caseManagement.service';
import { filter } from 'rxjs/operators';

export const FIELDS_TO_MATCH = {
  'email': 'email',
  'firstName': 'firstName',
  'preferredCommunicationMethod': 'preferredCommunicationMethod',
  'lastName': 'lastName',
  'dateOfBirth': 'dateOfBirth',
  'gender': 'gender',
  'ethnicity': 'ethnicity',
  'preferredSpokenLangauge': 'preferredSpokenLangauge',
  'preferredWrittenLangauge': 'preferredWrittenLangauge',
  'phone': ['workPhone', 'homePhone', 'cellPhone'],
  'address': ['state', 'city', 'street', 'country', 'postal'],
  'emergencyContact': ['name', 'phoneNumber', 'relationship'],
};

@Component({
  selector: 'ngx-profile-progress',
  templateUrl: './profile-progress.component.html',
  styleUrls: ['./profile-progress.component.scss'],
})
export class ProfileProgressComponent implements OnInit {

  @Input() public profileProgress: number = 0;
  @Input() public showComplete: boolean = true;
  @Input() public user: User;

  private total: number = 0;
  public subTitles: string[] = ['COMPLETE YOUR PROFILE', 'PROFILE COMPLETED'];
  public btnText: string[] = ['COMPLETE PROFILE', 'COMPLETED'];

  constructor(private router: Router, private caseMngt: CaseManagementService) { }

  ngOnInit() {
    this.caseMngt.getUser().pipe(filter(patient => !!patient)).subscribe((patient => { this.initProfile(patient); }));
  }

  private initProfile(patient: User) {
    this.user = patient;

    this.extractAndSum();
    this.calcPercentage();

    if (this.profileProgress >= 100) {
      this.showComplete = false;
    }
  }

  public goToProfile() {
    this.router.navigate(['/pages/profile']);
  }

  extractAndSum(): void {
    this.profileProgress = 0;
    this.total = 0;

    const fields = Object.getOwnPropertyNames(FIELDS_TO_MATCH);

    fields.forEach(element => {
      if (element === 'address' && this.user && this.user.addresses && this.user.addresses[0]) {
        const address = this.user.addresses[0];
        FIELDS_TO_MATCH[element].forEach(addr => {
          if (address.hasOwnProperty(addr) && !this.isNil(address[addr])) {
            this.total++;
          }
        });

      } else if (element === 'phone') {

        if (!this.isNil(this.user.workPhone)) {
          this.total++;
        } else if (!this.isNil(this.user.cellPhone)) {
          this.total++;
        } else if (!this.isNil(this.user.homePhone)) {
          this.total++;
        }

      } else if (this.user.emergencyContact && element === 'emergencyContact') {

        if (!!this.user.emergencyContact.name) {
          this.total++;
        } else if (!!this.user.emergencyContact.phoneNumber) {
          this.total++;
        } else if (!!this.user.emergencyContact.relationship) {
          this.total++;
        }

      } else {
        this.calcTotal(element);
      }
    });

  }

  private isNil(field: any): boolean {
    return field === null || (typeof field === typeof undefined) || field === '' || !field;
  }

  private calcPercentage(): void {
    const length = Object.keys(FIELDS_TO_MATCH).filter(key => key !== 'address').length;
    const addressLength = FIELDS_TO_MATCH.address.length;
    const totalFields = length + addressLength;
    this.profileProgress = Math.round(this.total / totalFields * 100);
  }

  private calcTotal(element) {
    if (!this.isNil(this.user[element])) {
      this.total++;
    }
  }

  public isProfileCompletedBtn(): string {
    return this.profileProgress >= 100 ? this.btnText[1] : this.btnText[0];
  }

  public isProfileCompletedSubtitle(): string {
    return this.profileProgress >= 100 ? this.subTitles[1] : this.subTitles[0];
  }
}

