/**
 *
 * @param items items to replace or append on the value array of the behavior subject
 * @param theSubject the cache/observable of the underlying list of data
 */
export function upsertItem(items, theSubject) {
    var updated = theSubject.getValue().slice(0);
    var _loop_1 = function (one) {
        var indexAt = updated.findIndex(function (entry) { return entry.id === one.id; });
        if (indexAt === -1) {
            updated.unshift(one);
        }
        else {
            updated[indexAt] = one;
        }
    };
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var one = items_1[_i];
        _loop_1(one);
    }
    theSubject.next(updated);
}
