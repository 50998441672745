var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var ProgressBarService = /** @class */ (function () {
    function ProgressBarService() {
        this.progressChange = new BehaviorSubject(null);
    }
    Object.defineProperty(ProgressBarService.prototype, "progress", {
        get: function () { return this.progressChange.getValue(); },
        set: function (value) { this.progressChange.next(__assign({}, value)); },
        enumerable: true,
        configurable: true
    });
    ;
    ProgressBarService.ngInjectableDef = i0.defineInjectable({ factory: function ProgressBarService_Factory() { return new ProgressBarService(); }, token: ProgressBarService, providedIn: "root" });
    return ProgressBarService;
}());
export { ProgressBarService };
