import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions, ConfirmationModal } from '../../../../../@theme/components/reusables';

@Component({
  selector: 'ngx-work-life-request-confirmation',
  templateUrl: './work-life-confirmation-modal.component.html',
  styleUrls: ['./work-life-confirmation-modal.component.scss'],
})
export class WLConfirmationModalComponent implements OnInit {
  heading: string = '';

  set modal(value: ModalOptions) {

    if (Object.keys(value).length > 0) {

      const { modalHeader } = value;

      if (modalHeader) {
        this.heading = modalHeader;
      }
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
  ) { }


  ngOnInit() {}

  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }

  accept(): void {
    this.activeModal.close({ answer: ConfirmationModal.Yes});
  }
}
