import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ModelCase } from '../../../../@core/data';
import { Router } from '@angular/router';
import { CaseManagementService } from '../../../../@core/data/caseManagement.service';
import { ModalService } from '../../../../@core/utils/modal.service';
import { SizeModal, SERVICE_TYPE, ModalOptions } from '../../../../@theme/components/reusables';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'ngx-more-suggestions',
  templateUrl: './more-suggestions.component.html',
  styleUrls: ['./more-suggestions.component.scss'],
})
export class MoreSuggestionsComponent implements OnInit {
  @Input() caseId: string;
  @Input() isCoachCase: boolean;
  @Input() caseData: ModelCase;
  @Input() user: any;
  @Output() viewMoreSuggestionsEvent = new EventEmitter();
  @Input() modality: string;
  selectState: string[] = [];
  submitted: boolean = false;
  footerText: string = '';
  additionalText: string = '';
  searchText: string = '';
  enableOnlineTherapy: boolean = environment.enableOnlneTherapyAccess;

  constructor(private router: Router, private caseMngt: CaseManagementService, private modalService: ModalService) { }

  ngOnInit() {
    this.getAdditionalText();
    this.footerText = (this.isCoachCase) ? 'Find other coaches' : 'Find other counselors';
    this.searchText = (this.isCoachCase) ? 'Search again for additional coaches' : 'Search again for counselors';
    this.fallBack();
  }

  getAdditionalText() {
    if (this.caseData.counselorPreference.modality === 'coaching') {
      this.additionalText = 'that are available.';
    } else if (this.caseData.counselorPreference.modality === 'onsiteCounseling') {
      this.additionalText = 'available in-office or on site.';
    } else {
      this.additionalText = 'available in-office.';
    }
  }

  showProviderSelectionModal() {
    this.modalService.showProviderSelectionModal(
      SizeModal.LARGE,
      {
        context: {
          provider: null, // this.counselor,
          user: this.user,
          isCoach: this.isCoachCase,
          modality: this.caseData.counselorPreference.modality,
        },
      },
      this.continueProviderSelectionSubmission.bind(this), true);
  }

  continueProviderSelectionSubmission(data) {
    if (data && data.answer === 'Yes') {
      this.modalService.showAddressFormModal(SizeModal.LARGE,
        {
          context: {
            user: this.user,
            serviceType: SERVICE_TYPE.PROFILE,
            isShipping: false,
          },
        }, this.continueSubmission.bind(this), true);
    }
  }

  continueSubmission(data) {
    if (data && data.user && data.answer === 'Yes') {
      this.caseMngt.selectedCase = this.caseData;
      this.caseMngt.assignToProvider(null, true);
    }
  }

  scheduleAppointment() {
    if (this.submitted) return;
    this.submitted = true;
    this.caseMngt.selectedCase = this.caseData;
    this.router.navigate([
      `/pages/dashboard/schedule-appointment/${this.caseData.counselorPreference.modality}`,
    ]);
  }

  fallBack() {
    if (this.modality === '') {
      this.modality = this.caseData.counselingLocation.selection;
    }
  }

  selectOnlineTherapy() {
    const modal: ModalOptions = {
      options: false,
    };
    this.modalService.showSwitchModal(SizeModal.LARGE, modal, this.convertCase.bind(this), true);
  }

  convertCase(data) {
    if (data && data.user && data.answer === 'Yes') {
      this.selectState = data.selectState;
      this.updateCase();
    }
  }

  updateCase(reason = '') {
    this.caseMngt.selectedCase = this.caseData;
    this.caseMngt.selectedCase.selectState = this.selectState;
    this.caseMngt.changeVideoToOnlineTherapy(reason, this.selectState);
  }
}
