import { Injectable } from '@angular/core';
import { NbMenuService } from '@nebular/theme';
import { Observable, Subject } from 'rxjs';
import { NbMenuBag } from '@nebular/theme/components/menu/menu.service';

@Injectable({ providedIn: 'root' })
export class LumaMenuService {
  private _onItemClick = new Subject<NbMenuBag>();
  private _onItemSelect = new Subject<NbMenuBag>();
  constructor(replayMenuService: NbMenuService) {
    replayMenuService.onItemClick().subscribe(item => this._onItemClick.next(item));
    replayMenuService.onItemSelect().subscribe(item => this._onItemSelect.next(item));
  }
  onItemClick(): Observable<NbMenuBag> { return this._onItemClick; }
  onItemSelect(): Observable<NbMenuBag> { return this._onItemSelect; }
}
