import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-age-of-majority',
  templateUrl: './age-of-majority.component.html',
  styleUrls: ['./age-of-majority.component.scss'],
})
export class AgeOfMajorityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
