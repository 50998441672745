import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[ngxAnalyticsContext]',
})
export class AnalyticsContextDirective implements OnChanges {
    @Input('ngxAnalyticsContext') analytics: string = '';
    public category: string = '';
    public action: string = '';

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        const [ category, action ] = this.analytics.toLowerCase().split(':').map(x => x.trim());
        this.category = category;
        this.action = action;
    }
}
