import {
  Component,
  OnInit,
  AfterViewChecked,
  HostListener,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal, ModalOptions } from '../modal.component';
import { UserService } from '../../../../../@core/data/users.service';
import { GeocodeingService } from '../../../../../pages/dashboard/display-appointment/geocodingService';
import { GeocodedAddress } from '../../../../../pages/dashboard/display-appointment/models/GeocodedAddress';
import { BabyKitService } from '../../../../../@core/data/babykit.service';
import { SERVICE_TYPE } from '../../../../../@theme/components/reusables';
import { CaseManagementService } from '../../../../../@core/data/caseManagement.service';
import { NgForm } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { BH_MODE_OF_HELP } from '../../../../../@core/utils/constants';
import { STATES } from '../../../../../@core/utils/intake-flow.service';
import { ConfigService } from '../../../../../@core/data/config.service';

const POSTAL_CODE = 'postal_code',
  ROUTE = 'route',
  STREET_ADDRESS = 'street_address',
  PREMISE = 'premise';
const DEFAULT_COUNTRY = 'USA';
const DEFAULT_STATE_CITIES = {
  DC: ['Washington'],
};

@Component({
  selector: 'ngx-address-form-modal',
  templateUrl: './address-form-modal.component.html',
  styleUrls: ['./address-form-modal.component.scss'],
})
export class AddressFormModalComponent implements OnInit, AfterViewChecked {
  _modal: ModalOptions = { context: {}, modalHeader: '', options: false };

  headerTitle: string;
  isShipping: boolean = false;
  submitted: boolean = false;
  isValidating: boolean = false;
  isHomeAddressSame: boolean = true;
  primaryAddressExist: boolean = false;
  formattedOriginalAddress: string = '';
  chosenAdress: string = '';
  suggestedAddresses: GeocodedAddress[] = [];
  serviceType: SERVICE_TYPE;
  user: any = {};
  header: string;
  showEmergencyContact: boolean = true;
  showPCPInfo: boolean = true;
  pcpAgreed: boolean = true;
  pcpInfoExist: boolean = true;
  cities: any;
  filteredCities: string[] = [];
  pcpcities: any;
  alwaysPromptForAnAddress: boolean = false;
  inputStreet;
  dialogModal;
  isModalLoaded = false;
  loadingCities: boolean = false;
  showCityDropdown: boolean = false;
  showPcpCityDropdown: boolean = false;
  filteredPcpCities: string[] = [];
  loadingPcpCities: boolean = false;
  selected: any;

  contract: any;
  org: any;

  set modal(value: ModalOptions) {
    if (Object.keys(value).length > 0) {
      const {
        user,
        contract,
        org,
        serviceType,
        isShipping,
        title,
        showEmergencyContact,
        alwaysPromptForAnAddress,
        selected,
      } = value.context;
      this.user = user;
      if (!this.user.emergencyContact) {
        this.user.emergencyContact = {
          name: '',
          phoneNumber: '',
          relationship: '',
        };
      }
      this.selected = selected;
      this.contract = contract;
      this.org = org;
      this.user.fullName = `${this.user.firstName} ${this.user.lastName}`;
      this.serviceType = serviceType;
      this.showEmergencyContact = showEmergencyContact;
      this.alwaysPromptForAnAddress = alwaysPromptForAnAddress;
      const [userAddress] = this.user.addresses;
      this.primaryAddressExist =
        userAddress.state &&
        userAddress.street &&
        userAddress.postal &&
        userAddress.city
          ? true
          : false;
      this.pcpInfoExist =
        this.user.UserDefinedTextField1 &&
        this.user.addresses[1].state &&
        this.user.addresses[1].street &&
        this.user.addresses[1].postal &&
        this.user.addresses[1].city &&
        this.user.TTYPhone
          ? true
          : false;
      if (isShipping) {
        this.isShipping = isShipping;
      }
      if (title) {
        this.headerTitle = title;
      }
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    private geoService: GeocodeingService,
    private babyKitService: BabyKitService,
    private caseMgtSrvc: CaseManagementService,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService
  ) {}

  tempUser: any = {
    addresses: [{}, {}],
    emergencyContact: {},
  };

  cityValid: boolean = true;
  pcpCityValid: boolean = true;

  ngOnInit() {
    this.setHeader();
    this.tempUser = JSON.parse(JSON.stringify(this.user));
    const selectedState =
      this.user.addresses[0].state || this.selected.selectState[0];
    if (selectedState) {
      this.getcities(selectedState, false).then(() => {
        if (this.filteredCities.length > 0) {
          this.tempUser.addresses[0].city = this.filteredCities[0];
        }
      });
      const possiblePCPAddress =
        this.user.addresses[1] &&
        (this.user.addresses[1].state || selectedState);
      this.tempUser.addresses[1].state = possiblePCPAddress;
      this.tempUser.addresses[0].state = possiblePCPAddress;
      this.getcities(possiblePCPAddress, true).then(() => {
        if (this.filteredPcpCities.length > 0) {
          this.tempUser.addresses[1].city = this.filteredPcpCities[0];
        }
      });
    } else {
      const babyKitLocationZip =
        this.selected && this.selected.counselingLocationZip;
      const counselingLocationZip =
        this.selected &&
        this.selected.counselingLocation &&
        this.selected.counselingLocation.zip;
      const zip = babyKitLocationZip || counselingLocationZip;
      if (zip) {
        this.configService.getStateFromZip(zip).subscribe((data) => {
          this.tempUser.addresses[0].state = data.state;
          this.tempUser.addresses[0].postal = zip;
          this.getcities(data.state, false).then(() => {
            if (this.filteredCities.length > 0) {
              this.tempUser.addresses[0].city = this.filteredCities[0];
            }
          });
          this.getcities(data.state, true).then(() => {
            if (this.filteredPcpCities.length > 0) {
              this.tempUser.addresses[1].city = this.filteredPcpCities[0];
            }
          });
        });
      }
    }
  }

  getStates() {
    return Object.keys(STATES);
  }

  getIndexState(state) {
    const stateKeys = Object.keys(STATES);
    const stateKey = stateKeys.find((key) => STATES[key] === state);
    return stateKeys.indexOf(stateKey);
  }

  handleStateChange(state: string, pcp: boolean) {
    if (!state) {
      if (pcp) {
        this.tempUser.addresses[1].city = '';
        this.filteredPcpCities = [];
      } else {
        this.tempUser.addresses[0].city = '';
        this.filteredCities = [];
      }
      this.updateCityValidity(pcp);
      return;
    }

    this.getcities(state, pcp).then(() => {
      const pcpCurrentCity = this.tempUser.addresses[1].city;
      const userCurrentCity = this.tempUser.addresses[0].city;
      if (!pcp && !this.filteredCities.includes(userCurrentCity)) {
        this.tempUser.addresses[0].city = this.filteredCities[0];
      } else if (pcp && !this.filteredPcpCities.includes(pcpCurrentCity)) {
        this.tempUser.addresses[1].city = this.filteredPcpCities[0];
      }
      this.updateCityValidity(pcp);
    });
  }

  getmycities() {
    return this.cities;
  }

  getpcpcities() {
    return this.pcpcities;
  }

  sortResults(arr) {
    return arr.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    });
  }

  getcities(state: string, pcp: boolean): Promise<void> {
    return new Promise((resolve) => {
      if (!state) {
        if (pcp) {
          this.tempUser.addresses[1].city = '';
          this.filteredPcpCities = [];
        } else {
          this.tempUser.addresses[0].city = '';
          this.filteredCities = [];
        }
        resolve();
        return;
      }
      const defaultCities = DEFAULT_STATE_CITIES[state];
      if (defaultCities) {
        if (pcp) {
          this.pcpcities = defaultCities;
          this.filteredCities = defaultCities;
          this.filteredPcpCities = defaultCities;
          if (this.user.addresses[1].state) {
            this.user.addresses[1].city = defaultCities[0];
          }
        } else {
          this.cities = defaultCities;
          this.filteredCities = defaultCities;
          if (this.user.addresses[0].state) {
            this.user.addresses[0].city = defaultCities[0];
          }
        }
        resolve();
        return;
      }
      if (pcp) {
        this.loadingPcpCities = true;
      } else {
        this.loadingCities = true;
      }
      this.configService.getCitiesFromState(state).subscribe((data) => {
        const sortCitiesResults = this.sortCitiesResults(
          data.results.map(({ name }) => name)
        );
        const uniqueCities = sortCitiesResults.filter(
          (value, index, array) => array.indexOf(value) === index
        );
        if (pcp) {
          this.pcpcities = uniqueCities;
          this.filteredPcpCities = uniqueCities;
          this.loadingPcpCities = false;
        } else {
          this.cities = uniqueCities;
          this.filteredCities = this.cities;
          this.loadingCities = false;
        }
        resolve();
      });
    });
  }

  updateCityValidity(isPcp: boolean) {
    if (isPcp) {
      this.pcpCityValid =
        !this.pcpAgreed ||
        this.isCityValid(
          this.tempUser.addresses[1].city,
          this.filteredPcpCities,
          true
        );
    } else {
      this.cityValid = this.isCityValid(
        this.tempUser.addresses[0].city,
        this.filteredCities,
        false
      );
    }
  }

  isCityValid(
    city: string,
    filteredCities: string[],
    isPcp: boolean = false
  ): boolean {
    if (!this.pcpAgreed && isPcp) {
      return true;
    }
    return city && filteredCities.includes(city);
  }

  onCityInput(input: string) {
    if (!this.tempUser.addresses[0].state) {
      this.filteredCities = [];
      return;
    }
    this.filteredCities = this.cities.filter((city) =>
      city.toLowerCase().includes(input.toLowerCase())
    );
    this.showCityDropdown = true;
    this.updateCityValidity(false);
  }

  onPcpCityInput(input: string) {
    if (!this.tempUser.addresses[1].state) {
      this.filteredPcpCities = [];
      return;
    }
    this.filteredPcpCities = this.pcpcities.filter((city) =>
      city.toLowerCase().includes(input.toLowerCase())
    );
    this.showPcpCityDropdown = true;
    this.updateCityValidity(true);
  }

  hideCityDropdownIfValid() {
    if (this.cityValid) {
      this.showCityDropdown = false;
    }
  }

  hidePcpCityDropdownIfValid() {
    if (this.pcpCityValid) {
      this.showPcpCityDropdown = false;
    }
  }

  selectCity(city: string) {
    this.tempUser.addresses[0].city = city;
    this.showCityDropdown = false;
    this.updateCityValidity(false);
  }

  selectPcpCity(city: string) {
    this.tempUser.addresses[1].city = city;
    this.cdr.detectChanges();
    this.showPcpCityDropdown = false;
    this.updateCityValidity(true);
  }

  validateCity() {
    if (
      this.user.addresses[0].city &&
      this.filteredCities.includes(this.user.addresses[0].city)
    ) {
      this.user.addresses[0].city = this.user.addresses[0].city;
    } else {
      this.user.addresses[0].city = '';
    }
    this.cdr.detectChanges();
  }

  validatePcpCity() {
    if (
      this.user.addresses[1].city &&
      this.filteredPcpCities.includes(this.user.addresses[1].city)
    ) {
      this.user.addresses[1].city = this.user.addresses[1].city;
    } else {
      this.user.addresses[1].city = '';
    }
    this.cdr.detectChanges();
  }

  toggleCityDropdown() {
    this.showCityDropdown = !this.showCityDropdown;
  }

  togglePcpCityDropdown() {
    this.showPcpCityDropdown = !this.showPcpCityDropdown;
  }

  hideCityDropdown() {
    setTimeout(() => {
      this.showCityDropdown = false;
    }, 200);
  }

  hidePcpCityDropdown() {
    setTimeout(() => {
      this.showPcpCityDropdown = false;
    }, 200);
  }

  sortCitiesResults(results: string[]): string[] {
    return results.sort();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const insideForm =
      target.closest('.form-group') || target.closest('.form-control');
    const insideCityDropdown = target.closest('.dropdown-wrapper');

    if (!insideCityDropdown) {
      this.showCityDropdown = false;
      this.showPcpCityDropdown = false;
    }

    if (insideForm && !insideCityDropdown) {
      this.hideDropdowns();
    }
  }

  hideDropdowns() {
    this.showCityDropdown = false;
    this.showPcpCityDropdown = false;
  }

  ngAfterViewChecked() {
    if (
      document.getElementsByClassName('modal-dialog')[0] &&
      !this.isModalLoaded
    ) {
      this.inputStreet = document.getElementById('input-addressLineOne');
      this.inputStreet.focus();

      this.inputStreet.blur();
      this.dialogModal = document.getElementsByClassName('modal-dialog')[0];
      this.dialogModal.scrollIntoView(true);
      this.isModalLoaded = true;
      this.cdr.detectChanges();
    }
  }

  closeModal() {
    this.activeModal.close({
      answer: ConfirmationModal.No,
      user: this.user,
      selected: this.selected,
    });
  }

  accept(): void {
    this.activeModal.close({
      answer: ConfirmationModal.Yes,
      user: this.user,
      selected: this.selected,
    });
  }

  validateUserAddress(form: NgForm) {
    if (this.submitted) return;
    this.submitted = true;
    const [userAddress] = this.user.addresses;
    const { street, city, state, postal } = userAddress;

    this.geoService.getGeocodeFromAddress(userAddress).subscribe(
      (data) => {
        const filtered = data.results.filter((address) =>
          this.isGeoAddressValid(address)
        );

        this.formattedOriginalAddress = `${street}, ${city}, ${state} ${postal}, ${DEFAULT_COUNTRY}`;
        this.submitted = false;
        if (
          filtered.length > 0 &&
          // do a case insensitive comparison here
          filtered[0].formatted_address.toUpperCase() !==
            this.formattedOriginalAddress
              .replace(/\n/, ' ')
              .replace(/\s\s+/, ' ')
              .toUpperCase()
        ) {
          this.suggestedAddresses = filtered;
          this.chosenAdress = filtered[0].formatted_address;
          this.isValidating = true;
        } else {
          this.chosenAdress = this.formattedOriginalAddress;
          this.submitForm(form);
        }
      },
      (err) => {
        this.submitted = false;
        console.error(err);
      }
    );
  }

  isGeoAddressValid(address: any): boolean {
    const validcomponent = address.address_components.filter((a) =>
      a.types.includes(POSTAL_CODE)
    );
    if (
      validcomponent.length > 0 &&
      (address.types.includes(ROUTE) ||
        address.types.includes(STREET_ADDRESS) ||
        address.types.includes(PREMISE))
    ) {
      return true;
    }
    return false;
  }

  parseAddress() {
    if (!this.chosenAdress) {
      console.error('chosenAdress is undefined');
      return;
    }

    const parts = this.chosenAdress.split(',');

    if (parts.length < 3) {
      console.error('chosenAdress format is incorrect');
      return;
    }

    const street = parts[0] ? parts[0].trim() : '';
    const city = parts[1] ? parts[1].trim() : '';
    const postState = parts[2] ? parts[2].trim() : '';

    if (!postState) {
      console.error('postState is undefined');
      return;
    }

    const [state, postal] = postState.split(' ');

    if (!state || !postal) {
      console.error('state or postal is undefined');
      return;
    }

    Object.assign(this.user.addresses[0], {
      street: street,
      city: city,
      state: state,
      postal: postal,
      country: DEFAULT_COUNTRY,
    });
  }

  onSubmitClicked(form: NgForm) {
    if (this.submitted) return;
    this.submitted = true;
    this.submitForm(form);
  }

  async submitForm(form: NgForm) {
    if (!this.submitted) return;
    this.user = JSON.parse(JSON.stringify(this.tempUser));

    if (this.selected) {
      if (this.selected.modeOfHelp === BH_MODE_OF_HELP) {
        this.user.UserDefinedCheckbox1 = this.pcpAgreed;
        if (!this.pcpAgreed) {
          delete this.user.UserDefinedTextField1;
          delete this.user.TTYPhone;
          this.user.addresses[1].street = null;
          this.user.addresses[1].postal = null;
          this.user.addresses[1].suite = null;
          this.user.addresses[1].city = null;
          this.user.addresses[1].state = null;
        }
      }
    }

    if (!this.pcpAgreed) {
      this.filteredPcpCities = [];
      this.tempUser.addresses[1].city = '';
    }

    this.parseAddress();
    await this.updateUserData();
    this.submitted = false;
    form.reset();
    if (
      !this.isHomeAddressSame &&
      !this.primaryAddressExist &&
      this.isShipping
    ) {
      this.serviceType = SERVICE_TYPE.PROFILE;
      this.isShipping = false;
      this.isValidating = false;
      this.headerTitle = '';
      return;
    }

    this.accept();
  }

  async updateUserData() {
    switch (this.serviceType) {
      case SERVICE_TYPE.BABYKIT:
        this.user = await this.babyKitService.updateUserBabyKit(
          this.user,
          this.contract,
          this.org,
          this.isHomeAddressSame
        );
        this.closeModal();
        break;
      case SERVICE_TYPE.PROFILE:
        const [userPrimaryAddress] = this.user.addresses;
        if (userPrimaryAddress) {
          this.user.addresses[0].country = DEFAULT_COUNTRY;
        }
        await this.userService.updateUser(this.user).toPromise();
        this.primaryAddressExist = true;
        break;
    }
    this.caseMgtSrvc
      .refreshUser()
      .then()
      .catch((err) => {
        throw err;
      });
  }

  setHeader() {
    if (this.serviceType === SERVICE_TYPE.BABYKIT) {
      this.header =
        'Please provide your shipping information and your new baby kit will be on its way';
    } else {
      this.header = this.primaryAddressExist
        ? 'Please review and update the following contact information'
        : 'We need the following contact information from you';
    }
  }
}
