/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-block.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./auth-block.component";
var styles_DxAuthBlockComponent = [i0.styles];
var RenderType_DxAuthBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DxAuthBlockComponent, data: {} });
export { RenderType_DxAuthBlockComponent as RenderType_DxAuthBlockComponent };
export function View_DxAuthBlockComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_DxAuthBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nb-auth-block", [], null, null, null, View_DxAuthBlockComponent_0, RenderType_DxAuthBlockComponent)), i1.ɵdid(1, 49152, null, 0, i2.DxAuthBlockComponent, [], null, null)], null, null); }
var DxAuthBlockComponentNgFactory = i1.ɵccf("nb-auth-block", i2.DxAuthBlockComponent, View_DxAuthBlockComponent_Host_0, {}, {}, ["*"]);
export { DxAuthBlockComponentNgFactory as DxAuthBlockComponentNgFactory };
