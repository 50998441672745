import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CaseManagementService } from '../../../@core/data/caseManagement.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public showBanner: boolean;
  public bannerMessage: string;
  public user: any;
  public loginStatus: boolean = false;
  public env: any;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private caseMngt: CaseManagementService,
  ) {
    this.router.events.subscribe((routeLocation) => {
      if (routeLocation instanceof NavigationEnd) {
        if ((routeLocation.url === '/') || (routeLocation.url === '/auth/logout') || (routeLocation.url === '/auth/login')) {
          // do nothing
        } else {
          this.getUserInfo();
        }
      }
  });
  }

  ngOnInit() {
    this.getNavbarConfig();
    this.getUserInfo();
    this.env = environment;
  }

  getNavbarConfig(): any {
    return this.httpClient.get<any>(`${environment.basePath}/configuration/navbar`)
      .subscribe((config) => {
        this.showBanner = config.showBanner;
        this.bannerMessage = config.bannerMessage;
      });
  }

  getUserInfo(): void {
    this.caseMngt.getUser().subscribe(
      (user) => {
        // If the user is done being fetched and is still invalid...
        if (!this.caseMngt.fetchingUser && (!user || !user.internalId)) {
          this.loginStatus = false;
        } else {
          this.loginStatus = true;
          this.user = user;

          // Full screen
          const logoutArea = document.getElementById('context-logout');
          logoutArea.style.display = 'none';
          // eslint-disable-next-line
          const loginArea = document.getElementById('context-login-updated');
          loginArea.style.display = 'block';

          // mobile screen
          const mobileLogoutArea = document.getElementById('mobile-context-logout');
          mobileLogoutArea.style.display = 'none';
          const mobileLoginArea = document.getElementById('mobile-context-login_updated');
          mobileLoginArea.style.display = 'block';
        }
      });
  }

  clearUserInfo(): void {
    // Full screen
    const logoutArea = document.getElementById('context-logout');
    logoutArea.style.display = 'block';
    const loginArea = document.getElementById('context-login-updated');
    loginArea.style.display = 'none';

    // mobile screen
    const mobileLogoutArea = document.getElementById('mobile-context-logout');
    mobileLogoutArea.style.display = 'block';
    const mobileLoginArea = document.getElementById('mobile-context-login_updated');
    mobileLoginArea.style.display = 'none';

    this.loginStatus = false;
    this.user = null;
  }
}
