/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./detail.component";
import * as i5 from "../../@core/utils/intake-flow.service";
var styles_DetailComponent = [i0.styles];
var RenderType_DetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DetailComponent, data: {} });
export { RenderType_DetailComponent as RenderType_DetailComponent };
function View_DetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_DetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["nav", 1]], null, 11, "div", [["class", "row main my-3 mx-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "col-sm-12 col-xs-12 col-lg-12 col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "mb-3 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["style", "text-align: center; margin-top: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry, we\u2019re down for maintenance."])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "h3", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We\u2019ll be back up shortly. If you require further assistance, please call "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "tel:8003444222"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(800) 344-4222"]))], null, null); }
function View_DetailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["nav", 1]], null, 25, "div", [["class", "row main my-3 mx-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "col-sm-12 col-xs-12 col-lg-6 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "mb-3 content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "mb-3 content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "mb-3 content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 9, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["class", "link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Sign Up"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "mx-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["or"])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "a", [["class", "link"], ["routerLink", "../auth/login"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Sign in"])), (_l()(), i1.ɵted(-1, null, [" now to get started. A happier you awaits. "])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "div", [["class", "col-sm-12 col-xs-12 col-md-6 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "img", [["alt", "detail"], ["class", "img-fluid detail-img"], ["src", "../../../assets/images/landing/detail.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.getHelpLink, ""); _ck(_v, 16, 0, currVal_6); var currVal_9 = "../auth/login"; _ck(_v, 21, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.contentTop; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.contentBottom; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.contentShort; _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 16).target; var currVal_5 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 21).target; var currVal_8 = i1.ɵnov(_v, 21).href; _ck(_v, 20, 0, currVal_7, currVal_8); }); }
export function View_DetailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { nav: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DetailComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["maintenace", 2]], null, 0, null, View_DetailComponent_2)), (_l()(), i1.ɵand(0, [["normal", 2]], null, 0, null, View_DetailComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enableMaintenanceMode; var currVal_1 = i1.ɵnov(_v, 3); var currVal_2 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_DetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-landing-detail", [], null, null, null, View_DetailComponent_0, RenderType_DetailComponent)), i1.ɵdid(1, 638976, null, 0, i4.DetailComponent, [i5.IntakeFlowService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DetailComponentNgFactory = i1.ɵccf("ngx-landing-detail", i4.DetailComponent, View_DetailComponent_Host_0, { learnMoreNavEvent: "learnMoreNavEvent" }, {}, []);
export { DetailComponentNgFactory as DetailComponentNgFactory };
