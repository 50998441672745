import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Provider, ModelCase } from '../../@core/data';
import { BH_SERVICE } from '../../@core/utils/constants';
import * as M from '../../../assets/js/roundy-carousel.js';

@Component({
  selector: 'ngx-counselor-recommendation',
  templateUrl: './counselor-recommendation.component.html',
  styleUrls: ['./counselor-recommendation.component.scss'],
})
export class CounselorRecommendationComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() counselors: Observable<Provider[]>;
  @Input() caseData: ModelCase;
  @Input() modality: string;
  @Input() title: string;
  @Input() subText: string;
  isCoaching: boolean = false;
  widgetTitle: string = '';
  findMoreLinkTitle: string = '';
  carousel: any;
  providers: Provider[] = [];
  dataInterval: any;
  providerSubscription: Subscription;

  options = {
    shift: 100,
    numVisible: 3,
    padding: 0,
    indicators: true,
  };

  hrefs = ['one', 'two', 'three'];
  isFirstResponder: boolean;

  ngOnInit() {
    this.coachingCheck();
    this.getWidgetTitle();
    this.getFindMoreLinkTitle();
    this.firstResponderCheck();
  }

  // used timer in afterviewinit to wait on data to load carousel.
  ngAfterViewInit() {
    this.dataInterval = setInterval(() => {
      // only when there are providers, we init carousel.
      if (this.providers.length) {
        clearInterval(this.dataInterval);
        this.initCarousel();
      }
    }, 1000)
  }

  ngOnChanges(changes: SimpleChanges) {
    const counselorChange: SimpleChange = changes.counselors;
    if (counselorChange.currentValue) {
      this.providerSubscription = counselorChange.currentValue.subscribe((providers) => {
        // assign & unsubscribe when we have some providers.
        // if values continue to be emitted after caousel is init, it causes it to disappear
        if (this.providers.length) {
          if (this.providerSubscription) this.providerSubscription.unsubscribe();
        } else if (providers && providers.length && !this.providers.length) {
          this.providers = providers;
        }
      })
    }
  }

  initCarousel() {
    const elems = document.querySelectorAll('.roundy-carousel');
    this.carousel = M.Carousel.init(elems, this.options);
  }

  coachingCheck() {
    if (this.caseData.counselorPreference && this.caseData.counselorPreference.modality
      && this.caseData.counselorPreference.modality === 'coaching') {
      this.isCoaching = true;
    }
  }

  getWidgetTitle() {
    this.widgetTitle = this.isCoaching ? 'These are your top 3 matching coaches' :
      `These are your top 3 matching counselors plus ${BH_SERVICE} (online) counseling`;
    if (this.caseData.modeOfHelp === 'online-therapy') {
      this.widgetTitle = `These are your top 3 matching ${BH_SERVICE} (online) counselors`;
    }
    if (this.caseData.modeOfHelp === 'first-responder') {
      this.widgetTitle = 'These are your top 3 matching first responder counselors';
    }
  }

  getFindMoreLinkTitle() {
    this.findMoreLinkTitle = this.isCoaching ? 'Find More Coaches' : 'Find More Counselors';
  }
  firstResponderCheck() {
    if (this.caseData.modeOfHelp === 'first-responder') {
      this.isFirstResponder = true;
    }
  }

  navigateCarousel(direction: string) {
    if (this.carousel) {
      if (direction === 'next')
        this.carousel[0].next();
      else
        this.carousel[0].prev();
    }
  }

  ngOnDestroy() {
    if (this.dataInterval) clearInterval(this.dataInterval);
  }
}
