<div class="animate__animated animate__slideInRight animate__faster">
  <div class="row justify-content-center text-center">
    <h4 class="col-12 top header-text font-size-36 font-500 font-montserrat color-black">
      What is the primary reason for your score? Is there anything else we should know?
    </h4>
    <h5 class="small_title mt32 mb45">(Optional)</h5>

  </div>
  <textarea class="form-control mb48 suggestion_text" [(ngModel)]="suggestionText" id="suggestion_box"
    rows="3"></textarea>
  <div class="row justify-content-center no-gutters mb24">
    <button class="btn btn-primary col-12 col-md-5" (click)="goNext()">CONTINUE
      <i class="fas fa-arrow-circle-right"></i></button>
  </div>
