import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../../../../../@theme/components/reusables';
var WLConfirmationModalComponent = /** @class */ (function () {
    function WLConfirmationModalComponent(activeModal) {
        this.activeModal = activeModal;
        this.heading = '';
    }
    Object.defineProperty(WLConfirmationModalComponent.prototype, "modal", {
        set: function (value) {
            if (Object.keys(value).length > 0) {
                var modalHeader = value.modalHeader;
                if (modalHeader) {
                    this.heading = modalHeader;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    WLConfirmationModalComponent.prototype.ngOnInit = function () { };
    WLConfirmationModalComponent.prototype.closeModal = function () {
        this.activeModal.close(ConfirmationModal.No);
    };
    WLConfirmationModalComponent.prototype.accept = function () {
        this.activeModal.close({ answer: ConfirmationModal.Yes });
    };
    return WLConfirmationModalComponent;
}());
export { WLConfirmationModalComponent };
