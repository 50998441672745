import { LumaMenuService } from './LumaMenuService';
import { OnInit, OnDestroy } from '@angular/core';
import { NbTokenStorage, NbTokenService } from '@nebular/auth';
import { NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { LUMA_THEME } from '../../styles/theme.luma';
import { SizeModal } from '../reusables';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalService } from '../../../@core/utils/modal.service';
import { IntakeFlowService } from '../../../@core/utils/intake-flow.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { CookieService } from 'ngx-cookie-service';
window.lumaAuth = {
    acronym: '',
    name: '',
    loggedIn: false,
    ssoUser: false,
    profileComplete: false,
};
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(menuService, userService, tokenStorage, tokenService, modalService, analytics, cookieService, router, sidebarService, intakeFlowService) {
        this.menuService = menuService;
        this.userService = userService;
        this.tokenStorage = tokenStorage;
        this.tokenService = tokenService;
        this.modalService = modalService;
        this.analytics = analytics;
        this.cookieService = cookieService;
        this.router = router;
        this.sidebarService = sidebarService;
        this.intakeFlowService = intakeFlowService;
        this.siteName = LUMA_THEME.variables.siteName;
        this.supportNumber = LUMA_THEME.variables.supportNumber;
        this.supportText = LUMA_THEME.variables.supportText;
        this.supportSubText = LUMA_THEME.variables.supportSubText;
        this.getHelpText = LUMA_THEME.variables.getHelpText;
        this.position = 'normal';
        this.alive = true;
        this.userMenu = [
            { title: 'My Profile', link: '/pages/profile' },
            { title: 'Terms & Conditions', link: '/auth/privacy' },
            { title: 'Change Password', link: '/auth/change-password' },
            { title: 'Request Additional Services', link: '/pages/intake/employer-information-2' },
            { title: 'Logout' /*, link : '/auth/logout'*/ },
        ];
        this.canShowSidebar = false;
        this.hideContextArea();
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscribeOnItemClick();
        this.tokenService.tokenChange().subscribe(function (token) {
            if (token && token.isValid()) {
                if (!_this.userServiceSubscription) {
                    _this.userServiceSubscription = _this.userService
                        .getUser()
                        .subscribe(function (user) {
                        _this.user = user;
                        _this.updateUniBar();
                        _this.updateMobileUniBar();
                    });
                }
            }
            else {
                _this.user = null;
                _this.updateUniBar();
                _this.updateMobileUniBar();
            }
        });
        // Check if company context is set
        if (this.intakeFlowService.getFormData().currentContract) {
            this.getHelpLink = '/pages/intake/care-path';
        }
        else {
            this.getHelpLink = '/pages/intake/care-path';
        }
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.alive = false;
        if (this.userServiceSubscription) {
            this.userServiceSubscription.unsubscribe();
        }
    };
    HeaderComponent.prototype.subscribeOnItemClick = function () {
        var _this = this;
        this.filterForUserMenu(this.menuService.onItemSelect()).subscribe(function (menuItem) {
            // hide anynchronously
            _this.hideMenu();
        });
        this.filterForUserMenu(this.menuService.onItemClick()).subscribe(function (menuItem) {
            if (menuItem.item.title === 'Logout') {
                _this.showStaticModal();
            }
            // hide anynchronously
            _this.hideMenu();
        });
    };
    Object.defineProperty(HeaderComponent.prototype, "checkUser", {
        get: function () {
            if (this.tokenStorage.get().getValue())
                return true;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.hideMenu = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.menuInstance)
                _this.menuInstance.hide();
            if (_this.sidebarService)
                _this.sidebarService.toggle(false, 'left');
        });
    };
    HeaderComponent.prototype.filterForUserMenu = function (observable) {
        var _this = this;
        return observable.pipe(takeWhile(function () { return _this.alive; }));
    };
    HeaderComponent.prototype.showMenu = function () {
        this.canShowSidebar = true;
        this.sidebarService.toggle(false, 'left');
    };
    HeaderComponent.prototype.showStaticModal = function () {
        var modal = {
            modalHeader: 'Are you sure you want to Logout?',
            options: true,
        };
        this.modalService.showModal(SizeModal.MEDIUM, modal, this.navigateLogout.bind(this));
    };
    HeaderComponent.prototype.navigateLogout = function () {
        this.analytics.setUserID(null);
        this.cookieService.delete('userId');
        this.cookieService.delete('ssoUser');
        this.router.navigate(['/auth/logout']);
    };
    HeaderComponent.prototype.hideContextArea = function () {
        var contextAuth = document.getElementById('inner-context');
        if (contextAuth) {
            contextAuth.style.display = 'none';
        }
    };
    // updates UI in Unified Nav Bar
    HeaderComponent.prototype.updateUniBar = function () {
        var contextAuth = document.getElementById('inner-context');
        var contextLoggedIn = document.getElementById('context-login');
        var contextLoggedout = document.getElementById('context-logout');
        var userPicture = document.getElementById('user-picture');
        var userName = document.getElementById('user-name');
        var changePasswordLink = document.getElementById('change-password');
        var elemsReady = contextAuth && contextLoggedIn && contextLoggedout;
        if (this.user && this.user.name) {
            var matches = this.user.name.match(/\b(\w)/g);
            var acronym = matches[0] + matches[1];
            if ((this.user.profileComplete !== undefined && this.user.profileComplete === false)
                && this.user.ssoUser) {
                contextAuth.style.display = 'none';
            }
            if (elemsReady) {
                contextLoggedout.style.display = 'none';
                contextLoggedIn.style.display = 'block';
                userPicture.innerHTML = '<i class="fa fa-user-circle" aria-hidden="true"></i>';
                userName.innerText = this.user.name;
                contextAuth.style.display = 'block';
                if (this.user.ssoUser !== undefined && this.user.ssoUser === true) {
                    changePasswordLink.style.display = 'none';
                }
                else {
                    changePasswordLink.style.display = 'block';
                }
            }
            window.lumaAuth = {
                acronym: acronym,
                name: this.user.name,
                loggedIn: true,
                ssoUser: this.user.ssoUser,
                profileComplete: this.user.profileComplete,
            };
        }
        else {
            if (elemsReady) {
                contextLoggedIn.style.display = 'none';
                contextLoggedout.style.display = 'block';
                userPicture.innerText = '';
                userName.innerText = '';
            }
            window.lumaAuth = {
                acronym: '',
                name: '',
                loggedIn: false,
            };
        }
        if (contextAuth) {
            contextAuth.style.display = 'block';
        }
    };
    HeaderComponent.prototype.updateMobileUniBar = function () {
        var mobileContextAuth = document.getElementById('mobile-inner-context');
        var mobileContextLoggedIn = document.getElementById('mobile-context-login');
        var mobileContextLoggedout = document.getElementById('mobile-context-logout');
        var mobileUserPicture = document.getElementById('mobile-user-picture');
        var mobileUserName = document.getElementById('mobile-user-name');
        var mobileChangePasswordLink = document.getElementById('mobile-change-password');
        var elemsReady = mobileContextAuth && mobileContextLoggedIn && mobileContextLoggedout;
        if (this.user && this.user.name) {
            var matches = this.user.name.match(/\b(\w)/g);
            var acronym = matches[0] + matches[1];
            if ((this.user.profileComplete !== undefined && this.user.profileComplete === false)
                && this.user.ssoUser) {
                mobileContextAuth.style.display = 'none';
            }
            if (elemsReady) {
                mobileContextLoggedout.style.display = 'none';
                mobileContextLoggedIn.style.display = 'block';
                mobileUserPicture.innerHTML = '<i class="fa fa-user-circle" aria-hidden="true"></i>';
                mobileUserName.innerText = this.user.name;
                mobileContextAuth.style.display = 'block';
                if (this.user.ssoUser !== undefined && this.user.ssoUser === true) {
                    mobileChangePasswordLink.style.display = 'none';
                }
                else {
                    mobileChangePasswordLink.style.display = 'block';
                }
            }
            window.lumaAuth = {
                acronym: acronym,
                name: this.user.name,
                loggedIn: true,
                ssoUser: this.user.ssoUser,
                profileComplete: this.user.profileComplete,
            };
        }
        else {
            if (elemsReady) {
                mobileContextLoggedIn.style.display = 'none';
                mobileContextLoggedout.style.display = 'block';
                mobileUserPicture.innerText = '';
                mobileUserName.innerText = '';
            }
            window.lumaAuth = {
                acronym: '',
                name: '',
                loggedIn: false,
            };
        }
        if (mobileContextAuth) {
            mobileContextAuth.style.display = 'block';
        }
    };
    return HeaderComponent;
}());
export { HeaderComponent };
