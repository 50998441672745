import { Component, Input, OnChanges, SimpleChanges, SimpleChange, ViewChild, ElementRef, OnInit } from '@angular/core';
import { IntakeFlowService } from '../../@core/utils/intake-flow.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-landing-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnChanges, OnInit {

  @ViewChild('nav')
  nav: ElementRef;
  getHelpLink: String;
  title: String;
  contentTop: String;
  contentBottom: String;
  contentShort: String;
  enableMaintenanceMode: Boolean = environment.enableMaintenanceMode;

  @Input() learnMoreNavEvent: Event;

  ngOnChanges(changes: SimpleChanges): void {
    const toggler: SimpleChange = changes.learnMoreNavEvent;
    if (toggler.currentValue !== toggler.previousValue) {
      this.nav.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  constructor(private intakeFlowService: IntakeFlowService) {
    this.title = 'Wellbeing, Done Well.';
    this.contentTop = `In today's fast-paced world, juggling work and personal
    life has never been more challenging. Busy schedules and mounting responsibilities often
    leave us feeling worn down and stressed out.`;

    this.contentBottom = `Concern's digital experience can customize a suite of wellbeing services to
     assist you in managing your everyday life. Struggling to find childcare?
     Want to speak with a counselor? Need legal advice? We can help.` ;

    this.contentShort = 'That\'s what we call \"Wellbeing, done well.\"';
  }

  ngOnInit(): void {
    // Check if company context is set
    if (this.intakeFlowService.getFormData().currentContract) {
      this.getHelpLink = '/pages/intake/care-path';
    } else {
      this.getHelpLink = '/pages/intake/care-path';
    }
  }

}

