/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./intake-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../instructions-tooltip/instructions-tooltip.component.ngfactory";
import * as i3 from "../instructions-tooltip/instructions-tooltip.component";
import * as i4 from "@angular/common";
import * as i5 from "./intake-header.component";
var styles_IntakeHeaderComponent = [i0.styles];
var RenderType_IntakeHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntakeHeaderComponent, data: {} });
export { RenderType_IntakeHeaderComponent as RenderType_IntakeHeaderComponent };
function View_IntakeHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngx-instructions-tooltip", [], null, null, null, i2.View_InstructionsTooltipComponent_0, i2.RenderType_InstructionsTooltipComponent)), i1.ɵdid(2, 114688, null, 0, i3.InstructionsTooltipComponent, [], { popOverMessage: [0, "popOverMessage"], attachToCorner: [1, "attachToCorner"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_IntakeHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h4", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "urgent-red": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntakeHeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(2, "col-12 top header-text ", _co.fontSize, " ", _co.fontWeight, ""); var currVal_2 = _ck(_v, 3, 0, (_co.showSpecialMsg === true)); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _co.tooltip; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "row justify-content-center ", _co.styleClasses, " text-center"); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.title; _ck(_v, 4, 0, currVal_3); }); }
export function View_IntakeHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-intake-header", [], null, null, null, View_IntakeHeaderComponent_0, RenderType_IntakeHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.IntakeHeaderComponent, [], null, null)], null, null); }
var IntakeHeaderComponentNgFactory = i1.ɵccf("ngx-intake-header", i5.IntakeHeaderComponent, View_IntakeHeaderComponent_Host_0, { title: "title", styleClasses: "styleClasses", tooltip: "tooltip", showSpecialMsg: "showSpecialMsg", fontSize: "fontSize", fontWeight: "fontWeight" }, {}, []);
export { IntakeHeaderComponentNgFactory as IntakeHeaderComponentNgFactory };
