import { Component, Input } from '@angular/core';

@Component({
    selector: 'ngx-progress-indicator',
    templateUrl: './progress-indicator.component.html',
    styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent {
    @Input() progress: number = 0;

    constructor() { }

}
