
  <div>
    <div class="flex-centered">
      <nb-auth-block>
        <div class="row logos" *ngIf="isEmindful">
          <div class="col-sm-12 d-flex justify-content-center">
              <img src="./../../../../assets/icons/concern-one-color-logo.png" alt="Concern logo" class="concern-logo">
              <i class="fa fa-plus seperator" aria-hidden="true"></i>
              <img src="../../../../../assets/icons/emlife-logo.png" alt="eMindful logo" class="emindful-logo">
          </div>
        </div>
        <ngx-intake-header *ngIf="!isEmindful" title="You're all set. Let's Login and see your personal dashboard."
        styleClasses="mt-0"></ngx-intake-header>
        <ngx-intake-header *ngIf="isEmindful" title="You're all set. Let's Login and see the available eMLife programs."
        styleClasses="mt-0"></ngx-intake-header>

        <div class="row justify-content-center no-gutters">
          <button class="btn btn-primary col-12 col-md-3" (click)="goToLogin()">
            Login
          </button>
        </div>
      </nb-auth-block>
    </div>
  </div>
  