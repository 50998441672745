import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

const PASSWORD_PATTERN = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

@Directive({
    selector: '[ngxValidatePassword]',
    providers: [{provide: NG_VALIDATORS, useExisting: ValidatePasswordDirective, multi: true}],
  })
  export class ValidatePasswordDirective implements Validator {

    validate(control: AbstractControl): ValidationErrors | null {
        return PASSWORD_PATTERN.test(control.value) ? null : { passwordInvalid: true };
    }
  }
