/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counselor-details-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./counselor-details-modal.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_CounselorDetailsModalComponent = [i0.styles];
var RenderType_CounselorDetailsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounselorDetailsModalComponent, data: {} });
export { RenderType_CounselorDetailsModalComponent as RenderType_CounselorDetailsModalComponent };
export function View_CounselorDetailsModalComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_CounselorDetailsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-counselor-details-modal", [], null, null, null, View_CounselorDetailsModalComponent_0, RenderType_CounselorDetailsModalComponent)), i1.ɵdid(1, 114688, null, 0, i2.CounselorDetailsModalComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CounselorDetailsModalComponentNgFactory = i1.ɵccf("ngx-counselor-details-modal", i2.CounselorDetailsModalComponent, View_CounselorDetailsModalComponent_Host_0, {}, {}, []);
export { CounselorDetailsModalComponentNgFactory as CounselorDetailsModalComponentNgFactory };
