import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import { AnalyticsService } from '../@core/utils/analytics.service';
import { EmployerInformationService } from '../pages/intake/employer-information/employer-information.service';
import { CaseManagementService } from '../@core/data/caseManagement.service';
import { ContractService } from '../@core/data';

@Component({
    selector: 'ngx-magic-link',
    styleUrls: ['./magic-link.component.scss'],
    templateUrl: './magic-link.component.html',
})
export class MagicLinkComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute,
        private cookieService: CookieService,
        private router: Router,
        private employerInformationService: EmployerInformationService,
        private caseManagementService: CaseManagementService,
        private contractService: ContractService,
        private analyticsService: AnalyticsService ) { }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if (!params['orgId']) {
                window.location.href = 'https://www.concernhealth.com/';
                return;
            }
            this.employerInformationService.verifyOrg(params['orgId']).pipe(take(1)).subscribe(
                company => {
                    if (company.error) {
                        // an error occured from the concern sso
                        this.cookieService.delete('companyName');
                        this.analyticsService.removeCompany();
                        window.location.href = 'http://www.concernresiliencehub.com/'; // I dont like this
                    } else {

                        // set currentContract
                        this.contractService.getOrgContractByOrgId(company.id.toString()).pipe(take(1)).subscribe(contracts => {
                            const contract = contracts.contract;
                            if (contract && contract.digitalPlatformEnabled) {
                                this.cookieService.set('companyName', company.company);
                                this.cookieService.set('companyId', `${company.id}`);
                                this.caseManagementService.currentContract = this.caseManagementService.currentContract ?
                                    this.caseManagementService.currentContract : contract;
                                this.analyticsService.setCompany(company.company);
                                this.analyticsService.setCompanyId(`${company.id}`);
                                if (params['ssoFlag'] && params['ssoFlag'] === 'true') {
                                    this.router.navigate(['/pages/intake/employer-information-2'])
                                } else {
                                    this.router.navigate(['/landing']);
                                }
                            } else {
                                window.location.href = 'https://www.concernhealth.com/';
                            }
                        });

                    }
                },
            );
        });
    }
}
