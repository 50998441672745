
<div class="modal-content" style="margin-top:0px;">

  <span class="close-icon" (click)="closeModal()">&#10005;</span>
  <div class="text-center message-container">
    <div class="row justify-content-center align-content-center align-items-center no-gutters mb-5">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <div class="counselor-text justify-content-center align-content-center align-items-center">
          <div class="media mx-auto provider-media">
            <img class="modal-icon" src="../../../../../../assets/icons/telephone-coaching.svg" alt="Coaching Icon">
          </div>
            <p class="modal-heading">Personal Coaching</p>
            <p class="modal-sub-heading">With certified coaches | 30 minute telephone sessions</p>
            <p>
              <img class="concern-logo" src="../../../../../../assets/images/logo/concern-one-color-logo.png" alt="Concern Logo">
              <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
              <img class="cc360-logo" src="../../../../../../assets/icons/CatalystCoaching.png" alt="CC360 Logo">
            </p>
        </div>
      </div>
    </div>
  </div>

  <div class="first-line">
    <div class="row first-left-side">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <p class="section-heading">ABOUT: CATALYST COACHING 360 (CC360)</p>
        <p>CC360 is a national network of board certified coaches.</p>
        <p class="about-bh">
          Concern in partnership with CC360 allows you to conveniently schedule a Personal Coaching session.
        </p>
      </div>
    </div>

    <div class="row first-right-side">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <p class="section-heading">ONCE THERE, YOU WILL:</p>
        <ul class="bh-steps">
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Create your FREE Personal Coaching account</p>
          </div>
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Confirm your time zone</p>
          </div>
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Schedule your session with a Personal Coach</p>
          </div>
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Personal Coach calls you at your appointment time</p>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center align-content-center align-items-center">
      <div class="accept-btn" (click)="accept()">
        OKAY, LETS’S GO! <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center align-content-center align-items-center">
      <p class="cancel-link" (click)="closeModal()">
        I’M NOT READY TO LEAVE YET
      </p>
    </div>
  </div>

</div>
