<ngx-intake-header [title]="title" styleClasses="mt-0 mb-2"></ngx-intake-header>
<nb-card>
  <nb-card-body class="flex-centered">
    <nb-auth-block>
      <p class="form-text sub-title">Enter your email adress and we’ll send a link to reset your password</p>
      <form (ngSubmit)="requestPass()" #requestPassForm="ngForm">

        <ngx-request-errors *ngIf="showMessages.error && !submitted" [errors]="errors"></ngx-request-errors>


        <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted" class="alert alert-success"
          role="alert">
          <div *ngFor="let message of messages">{{ message }}</div>
        </div>

        <div class="form-group">
          <label for="input-email" class="sr-only">Enter your email address</label>
          <input name="email" [(ngModel)]="user.email" id="input-email" #email="ngModel" class="form-control"
            placeholder="Email address" pattern=".+@.+\..+" [class.form-control-danger]="email.invalid && email.touched"
            [required]="getConfigValue('forms.validation.email.required')" autofocus>
          <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
            Email is required!
          </small>
          <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.pattern">
            Email should be the real one!
          </small>
        </div>

        <button [disabled]="submitted || !requestPassForm.form.valid" class="btn btn-block btn-primary top-separation"
          [class.btn-pulse]="submitted">
          Request password
        </button>
      </form>
    </nb-auth-block>
  </nb-card-body>
</nb-card>