import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NpsSurveyService, STEPS } from '../../../../@core/utils/nps-survey.service';

@Component({
  selector: 'ngx-nps-navigation-buttons',
  templateUrl: './nps-navigation-buttons.component.html',
  styleUrls: ['./nps-navigation-buttons.component.scss'],
})
export class NpsNavigationButtonsComponent implements OnInit {
  @Input() backOnly: boolean;


  constructor(private router: Router, private npsSurveyService: NpsSurveyService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() { }

  goBack(): void {
    try {
      const currentUrl = this.router.url;
      const arrPath = currentUrl.split('/');
      const currentStep = arrPath[arrPath.length - 1];
      const previousStep = this.npsSurveyService.getPreviousStep(currentStep);
      this.router.navigate([`${previousStep}`], { relativeTo: this.activatedRoute });
    } catch (exception) {
      console.error(exception);
    }
  }

  startOver(): void {
    try {
      this.npsSurveyService.resetFormData();
      const firstStep = STEPS.recommendPath;
      this.router.navigate([`${firstStep}`], { relativeTo: this.activatedRoute });
    } catch (exception) {
      console.error(exception);
    }

  }
}
