import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxMaskModule } from 'ngx-mask'

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbSidebarModule,
  NbSpinnerService,
  NbMenuService,
  NbSidebarService,
  NbListModule,
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SwitcherComponent,
  FloatingButtonComponent,
} from './components';

import { InTheMomentComponent } from './components/in-the-moment/in-the-moment.component'

import { CapitalizePipe, RoundPipe, CalendarDatePipe } from './pipes';

import {
  OneColumnLayoutComponent,
} from './layouts';

import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { LUMA_THEME } from './styles/theme.luma';

import {
  DxAuthComponent,
  DxLoginComponent,
  DxSsoLoginComponent,
  DxLogoutComponent,
  DxRegisterComponent,
  DxRequestPasswordComponent,
  DxResetPasswordComponent,
  DxAuthBlockComponent,
  DxEmailVerifiedComponent,
  DxEmailVerificationErrorComponent,
  DxVerifyEmailComponent,
  DxChangePasswordComponent,
  TermsComponent,
  PrivacyComponent,
  SouComponent,
  AccountDeactivatedComponent,
} from './components/auth';

import {
  CloudButtonComponent,
  IconListComponent,
  PillSelectorComponent,
  ScreenIntroComponent,
  SubHeaderComponent,
  ContentRadioComponent,
  SelectorComponent,
  SelectorAltComponent,
  SelectorXlComponent,
  ProgressBarComponent,
  MultiSelectorComponent,
  IntakeHeaderComponent,
  ModalComponent,
  ProfileProgressComponent,
  InstructionsTooltipComponent,
  IntakeNavigationButtonsComponent,

} from './components/reusables';

import { RouterModule } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProgressIndicatorComponent } from './components/reusables/profile/progress-indicator/progress-indicator.component';
import { AgeOfMajorityComponent } from './components/auth/register/age-of-majority/age-of-majority.component';
import { CapitalizeWordsPipe } from './pipes/capitalize-words.pipe';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { StripProtocol } from './pipes/stripProtocol.pipe';
import { SanitizeRtfPipe } from './pipes/sanitize-rtf.pipe';
import { OnlineTherapyCardComponent } from '../pages/counselor-recommendation/counseling/online-therapy-card.component';

import {
  CounselorRecommendationComponent, CounselorImageComponent,
  CounselorComponent, CounselorNameLicencesComponent,
} from '../pages/counselor-recommendation';
import { FilterSelectorComponent } from './components/reusables/filter-selector/filter-selector.component';
import { MoreSuggestionsComponent } from './components/reusables/more-suggestions/more-suggestions.component';

import { AddressSouModalComponent } from './components/reusables/modal/address-sou-modal/address-sou-modal.component';
import { RequestErrorsComponent } from './components/reusables/request-errors/request-errors.component'
import { CustomMinDirective } from './components/reusables/custom-validators/custom-min-validator.directive';
import { ZipCodeValidatorDirective } from './components/reusables/custom-validators/zipCode-validator.directive';
import { WidgetContentComponent } from './components/reusables/widget-content/widget-content.component';
import { TextComponent } from './components/reusables/text/text.component';
import { AnalyticsDirective } from './directives/analytics.directive';
import { LabelledComponent } from './components/reusables/labelled/labelled.component';
import { ValidatePasswordDirective } from './components/reusables/custom-validators/password-validator.directive';
import { AnalyticsContextDirective } from './directives/analytics-context.directive';
import { AddressComponent } from './components/reusables/address/address.component';
import { AddressValidationComponent } from './components/reusables/address-validation/address-validation.component';
import { AddressFormModalComponent } from './components/reusables/modal/address-form-modal/address-form-modal.component';
import { ProviderSelectionModalComponent } from './components/reusables/modal/provider-selection-modal/provider-selection-modal.component';
import { SwitchModalComponent } from './components/reusables/modal/switch-modal/switch-modal.component';
import { BetterHelpTransitionModalComponent } from './components/reusables/modal/betterhelp-transition-modal/betterhelp-transition-modal.component';
import { SwitchToOnlineModalComponent } from './components/reusables/modal/switch-to-online-modal/switch-to-online-modal.component';
import { WorkLifeRequestFormModalComponent } from './components/reusables/modal/work-life-request-form-modal/work-life-request-form-modal.component';
import { WLConfirmationModalComponent } from './components/reusables/modal/work-life-request-confirmation/work-life-confirmation-modal.component';
import { MomentSupportComponent } from './components/reusables/moment-support/moment-support.component';
import { ValidateDobDirective } from './components/reusables/custom-validators/dob-validator.directive';
import { OnlineSwitchComponent } from './components/reusables/modal/online-switch/online-switch.component';
import { CounselorDetailsModalComponent } from './components/reusables/modal/CounelorDetailsModalComponent/counselor-details-modal.component';
import { EmindfulModalComponent } from './components/reusables/modal/edmindful/emindful-modal.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ConfirmBabyKitModalComponent } from './components/reusables/modal/confirm-babykit-modal/confirm-babykit-modal.component';
import { NoProvidersFoundComponent } from '../pages/no-providers-found/no-providers-found.component';
import { CoachingTransitionModalComponent } from './components/reusables/modal/coaching-transition-modal/coaching-transition-modal.component';
import { NpsNavigationButtonsComponent } from './components/reusables/nps-navigation-buttons/nps-navigation-buttons.component';
import { NpsProgressBarComponent } from './components/reusables/progress-bar/progress-bar.component-nps';
import { EmotionalNavigationButtonsComponent } from './components/reusables/emotional-navigation-buttons/emotional-navigation-buttons.component';
import { EmotionalPillSelectorComponent } from './components/reusables/emotional-pill-selector/emotional-pill-selector.component';
import { WorklifeNpsNavigationButtonsComponent } from './components/reusables/worklife-nps-navigation-buttons/worklife-nps-navigation-buttons.component';

const BASE_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule, // *nbIsGranted directive
  NbSidebarModule,
  NbListModule,
];

const COMPONENTS = [
  SwitcherComponent,
  HeaderComponent,
  FooterComponent,
  InTheMomentComponent,
  OneColumnLayoutComponent,
  DxAuthBlockComponent,
  DxAuthComponent,
  DxLoginComponent,
  DxSsoLoginComponent,
  DxLogoutComponent,
  DxRegisterComponent,
  DxRequestPasswordComponent,
  DxResetPasswordComponent,
  CloudButtonComponent,
  IconListComponent,
  PillSelectorComponent,
  EmotionalPillSelectorComponent,
  ScreenIntroComponent,
  SubHeaderComponent,
  DxEmailVerifiedComponent,
  DxEmailVerificationErrorComponent,
  DxVerifyEmailComponent,
  FloatingButtonComponent,
  ContentRadioComponent,
  SelectorComponent,
  SelectorAltComponent,
  SelectorXlComponent,
  FilterSelectorComponent,
  ProgressBarComponent,
  NpsProgressBarComponent,
  IntakeNavigationButtonsComponent,
  NpsNavigationButtonsComponent,
  WorklifeNpsNavigationButtonsComponent,
  EmotionalNavigationButtonsComponent,
  MultiSelectorComponent,
  IntakeHeaderComponent,
  LabelledComponent,
  DxChangePasswordComponent,
  TermsComponent,
  PrivacyComponent,
  SouComponent,
  AgeOfMajorityComponent,
  ModalComponent,
  ProfileProgressComponent,
  ProgressIndicatorComponent,
  RequestErrorsComponent,
  CounselorRecommendationComponent,
  CounselorComponent,
  OnlineTherapyCardComponent,
  CounselorImageComponent,
  CounselorNameLicencesComponent,
  MoreSuggestionsComponent,
  AddressComponent,
  AddressSouModalComponent,
  CustomMinDirective,
  ZipCodeValidatorDirective,
  ValidatePasswordDirective,
  WidgetContentComponent,
  TextComponent,
  AnalyticsDirective,
  AnalyticsContextDirective,
  AddressValidationComponent,
  AddressFormModalComponent,
  ProviderSelectionModalComponent,
  SwitchModalComponent,
  BetterHelpTransitionModalComponent,
  InstructionsTooltipComponent,
  MomentSupportComponent,
  ValidateDobDirective,
  AccountDeactivatedComponent,
  OnlineSwitchComponent,
  CounselorDetailsModalComponent,
  SwitchToOnlineModalComponent,
  WorkLifeRequestFormModalComponent,
  WLConfirmationModalComponent,
  EmindfulModalComponent,
  ConfirmBabyKitModalComponent,
  NoProvidersFoundComponent,
  CoachingTransitionModalComponent,
];
const PIPES = [CapitalizePipe, RoundPipe, CalendarDatePipe, CapitalizeWordsPipe, SanitizePipe, StripProtocol, SanitizeRtfPipe];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'luma',
    },
    [DEFAULT_THEME, COSMIC_THEME, LUMA_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  NbSpinnerService,
  NbMenuService,
  NbSidebarService,
];

@NgModule({
  imports: [...BASE_MODULES,
  DeviceDetectorModule.forRoot(),
  NgxMaskModule.forRoot(),
    CarouselModule,
  ...NB_MODULES, NgCircleProgressModule.forRoot({
    radius: 45,
    outerStrokeWidth: 5.5,
    innerStrokeWidth: 0,
    outerStrokeColor: '#7ecdd6',
    animation: false,
    renderOnClick: false,
    subtitle: 'COMPLETED',
    backgroundColor: '#FAFAFA',
  })],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES, MomentSupportComponent],
  entryComponents: [ModalComponent, AddressSouModalComponent, AddressFormModalComponent, ProviderSelectionModalComponent,
    SwitchModalComponent, BetterHelpTransitionModalComponent, OnlineSwitchComponent, CounselorDetailsModalComponent,
    SwitchToOnlineModalComponent, WorkLifeRequestFormModalComponent, WLConfirmationModalComponent,
    ConfirmBabyKitModalComponent, EmindfulModalComponent, CoachingTransitionModalComponent],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
