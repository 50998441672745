/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./moment-support.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./moment-support.component";
var styles_MomentSupportComponent = [i0.styles];
var RenderType_MomentSupportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MomentSupportComponent, data: {} });
export { RenderType_MomentSupportComponent as RenderType_MomentSupportComponent };
export function View_MomentSupportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "widget-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "clear-float"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "red widget d-none"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.helpOptionClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "open": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-phone"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "help-tab"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["HELP"])), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "help-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-user red-user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Need immediate assistance?"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please call:"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "p", [["class", "contact-number"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "a", [["href", "tel:800-344-4222"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["800-344-4222"])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "clear-float"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.chatOptionClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(21, { "open": 0 }), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "img", [["src", "/assets/images/icons/chat-purple.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "div", [["class", "chat-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "iframe", [["allow", "microphone;"], ["class", "chat-frame"], ["src", "https://home-c62.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=d22041e1-7f46-49f2-9aee-37aa5db75304&bu=4605795"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "div", [["class", "clear-float"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "red widget d-none"; var currVal_1 = _ck(_v, 4, 0, _co.helpOpen); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = ""; var currVal_3 = _ck(_v, 21, 0, _co.chatOpen); _ck(_v, 20, 0, currVal_2, currVal_3); }, null); }
export function View_MomentSupportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-moment-support", [], null, null, null, View_MomentSupportComponent_0, RenderType_MomentSupportComponent)), i1.ɵdid(1, 114688, null, 0, i3.MomentSupportComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MomentSupportComponentNgFactory = i1.ɵccf("ngx-moment-support", i3.MomentSupportComponent, View_MomentSupportComponent_Host_0, {}, {}, []);
export { MomentSupportComponentNgFactory as MomentSupportComponentNgFactory };
