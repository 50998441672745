
  <nb-card style="box-shadow:none;">
  <nb-card-body class="flex-centered">
    <nb-auth-block>
      <ngx-intake-header title="Email Verification Error" styleClasses="mt-0"></ngx-intake-header>
      <small class="form-text sub-title">Something went wrong</small>

      <button class="btn btn-primary btn-block" (click)="router.navigateByUrl('/auth/login');" >Login</button>

    </nb-auth-block>
    </nb-card-body>
    </nb-card>

  