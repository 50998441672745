import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngxSanitizeRtf',
})
export class SanitizeRtfPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return;
    value = value.replace(/\\par[d]?/g, '');
    return value.replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, '').trim();
  }

}
