export { DxVerifyEmailComponent } from './verify-email/verify-email.component';
export { DxEmailVerificationErrorComponent } from './email-verification-error/email-verification-error.component';
export {DxAuthComponent} from './auth.component';
export {DxAuthBlockComponent} from './auth-block/auth-block.component';
export {DxLoginComponent} from './login/login.component';
export {DxSsoLoginComponent} from './sso-login/sso-login.component';
export {DxLogoutComponent} from './logout/logout.component';
export {DxRegisterComponent} from './register/register.component';
export {DxRequestPasswordComponent} from './request-password/request-password.component';
export {DxResetPasswordComponent} from './reset-password/reset-password.component';
export {DxEmailVerifiedComponent} from './email-verified/email-verified.component';
export {DxChangePasswordComponent} from './change-password/change-password.component';
export { TermsComponent } from './terms/terms.component';
export { PrivacyComponent } from './privacy/privacy.component';
export { SouComponent } from './sou/sou.component';
export { AccountDeactivatedComponent } from './account-deactivated/account-deactivated.component';
