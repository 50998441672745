import { Component, OnInit } from '@angular/core';
import { PillSelectorItem } from '../../../@theme/components/reusables';
import { Router, ActivatedRoute } from '@angular/router';
import { EmotionalWellbeingSurveyService } from '../../../@core/utils/emotional-wellbeing-survey.service';
import { PHQ_RESPONSES } from '../../../@core/utils/enums';

const RESPONSES = [
  PHQ_RESPONSES.NOT_AT_ALL,
  PHQ_RESPONSES.SEVERAL_DAYS,
  PHQ_RESPONSES.MORE_THAN_HALF,
  PHQ_RESPONSES.ALMOST_EVERY_DAY,
];
const ROUTE_NAME = 'feelings';


@Component({
  selector: 'ngx-feelings-survey',
  templateUrl: './feelings-survey.component.html',
  styleUrls: ['./feelings-survey.component.scss'],
})
export class FeelingsSurveyComponent implements OnInit {
  title: string;
  subHeader: string;
  isShowValid: boolean;
  pillItems: PillSelectorItem[];
  submitted: boolean = false;
  selectedFrequencyIndices: [number, number, number, number] = [-1, -1, -1, -1];
  tooltip =
    `These questions help us understand the degree of depression or anxiety you may be experiencing so that we can guide you to the right level of support.`;
  feelingsDataValue: any[];

  constructor(private router: Router,
    private emotionalWellBeingService: EmotionalWellbeingSurveyService,
    private activatedRoute: ActivatedRoute) {
    this.emotionalWellBeingService.setCarePathProgress(ROUTE_NAME);

    this.pillItems = [
      {
        caption: '1. Feeling nervous, anxious or on edge',
        items: RESPONSES,
      },
      {
        caption: '2. Not being able to stop or control worrying',
        items: RESPONSES,
      },
      {
        caption: '3. Feeling down, depressed or hopeless',
        items: RESPONSES,
      },
      {
        caption: '4. Little interest or pleasure in doing things',
        items: RESPONSES,
      },
    ];
  }


  ngOnInit() {

    this.emotionalWellBeingService.setCarePathProgress(ROUTE_NAME);
    this.feelingsDataValue = Object.values(this.emotionalWellBeingService.feelingsData);
    if (this.emotionalWellBeingService.feelingsData) {
      this.selectedFrequencyIndices[0] = this.pillItems[0].items.findIndex(e => e === this.feelingsDataValue[0]);
      this.selectedFrequencyIndices[1] = this.pillItems[1].items.findIndex(e => e === this.feelingsDataValue[1]);
      this.selectedFrequencyIndices[2] = this.pillItems[2].items.findIndex(e => e === this.feelingsDataValue[2]);
      this.selectedFrequencyIndices[3] = this.pillItems[3].items.findIndex(e => e === this.feelingsDataValue[3]);
      this.isValidState();
    }

  }


  isValidState() {
    this.isShowValid = this.selectedFrequencyIndices[0] < 0 || this.selectedFrequencyIndices[1] < 0 ||
      this.selectedFrequencyIndices[2] < 0 || this.selectedFrequencyIndices[3] < 0;
    //   return this.isShowValid;
  }


  goNext(): void {
    this.submitted = true;
    try {
      const feelingsArr = [
        this.pillItems[0].items[this.selectedFrequencyIndices[0]],
        this.pillItems[1].items[this.selectedFrequencyIndices[1]],
        this.pillItems[2].items[this.selectedFrequencyIndices[2]],
        this.pillItems[3].items[this.selectedFrequencyIndices[3]],
      ];
      const feelingsData = this.convertSelectionsToAnswer(feelingsArr);
      this.emotionalWellBeingService.feelingsData = feelingsData;
      const nextStep = this.emotionalWellBeingService.getNextStep(ROUTE_NAME);
      this.router.navigate([`../${nextStep}`], { relativeTo: this.activatedRoute });
    } catch (err) { this.submitted = false }
  }

  private convertSelectionsToAnswer([feelingsNervous, notControlWorrying, feelingDown, littleInterest]: any) {

    return { feelingsNervous, notControlWorrying, feelingDown, littleInterest };
  }

}
