import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StateService } from './state.service';
import { InsurerProviderService } from './provider.service';
import { ProviderService } from '.';
import { ApiModule } from './api.module';
import { OrgService } from '.';
import { ConfigService } from './config.service';
import { ContractService, CaseService } from './api/api';
import { CounselorService } from './counselor.service';
import { HistoryService } from './api/history.service';
import { LoggingService } from './logging.service';

const SERVICES = [
  StateService,
  CaseService,
  ProviderService,
  OrgService,
  ConfigService,
  InsurerProviderService,
  ContractService,
  CounselorService,
  HistoryService,
  LoggingService,
];

@NgModule({
  imports: [
    CommonModule,
    ApiModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
