<div class="bgColor">
    <div class="collapse-x" (click)="closeModal()">
        <img src="/assets/icons/close-deep-purple.svg" />
    </div>
    <div class="modal-header">
        <ngx-intake-header [title]="heading" fontSize="font-size-22" fontWeight="font-weight-600" class="mx-auto">
        </ngx-intake-header>
    </div>

    <div class="modal-content mx-auto form" >
        <div class="row justify-content-center no-gutters">
            <div class="col-12" style="margin: 31px 0 14px">
                <label for="preferredCommunication">HOW DO YOU PREFER TO COMMUNICATE?</label>
                <select name="preferredComunicationMethod" class="form-control"
                    [(ngModel)]="communicationType">
                    <option>Email</option>
                    <option>Phone</option>
                    <option>Text Message</option>
                </select>
            </div>
            <div class="col-12">
                <label for="description">PLEASE DESCRIBE YOUR SITUATION:</label><br>
                <span class="small-text">Try to be as brief as possible</span>
                <textarea name="description" class="textarea" [(ngModel)]="description"></textarea>
            </div>
        </div>
        <div class="row justify-content-center no-gutters">
            <button class="btn btn-primary col-12 col-md-8" (click)="accept()" 
             [disabled]="!description || !communicationType">Submit
                Request</button>
        </div>

        <div class="row justify-content-center no-gutters" (click)="closeModal()">
            <a class="col-12 col-md-8 cancel">CANCEL</a>
        </div>
    </div>
</div>