var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { isEmpty } from 'lodash-es';
import { Configuration } from './configuration';
import { environment } from '../../../environments/environment';
import { caseRoutes, loginHistoryRoutes, riskHistoryRoutes, userRoutes } from './api/constants';
import { mapAllowedMessageProps } from '../utils/user-service-utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./variables";
import * as i3 from "./configuration";
var GET_LOGIN_HISTORY_URL = '/run/get-login-history';
// const LOCAL_USER_URL = '/v1/run/local-user';
var UserService = /** @class */ (function () {
    function UserService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.reloadDashboard = false;
        this.basePath = environment.basePath;
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    UserService.prototype.getUser = function () {
        return this.httpClient.get("" + this.basePath + userRoutes.user)
            .pipe(map(function (user) {
            if (isEmpty(user))
                return null;
            return {
                name: user.firstName + " " + user.lastName, picture: 'assets/images/kate.png', ssoUser: user.ssoUser || false,
                profileComplete: user.profileComplete || false
            };
        }));
    };
    UserService.prototype.getSsoUser = function () {
        return this.httpClient.get("" + this.basePath + userRoutes.user)
            .pipe(map(function (user) { return ({ data: user }); }));
    };
    UserService.prototype.getAllUserData = function () {
        return this.httpClient.get("" + this.basePath + userRoutes.user)
            .pipe(tap(function (user) {
            if (!user.internalId) {
                throw new Error('Missing InternalId');
            }
        }), map(function (user) { return ({ data: user }); }));
    };
    UserService.prototype.updateUser = function (userData) {
        var userToUpdate = __assign({}, userData);
        userToUpdate.allowedCellPhoneMessage = mapAllowedMessageProps(userToUpdate.allowedCellPhoneMessage);
        userToUpdate.allowedHomePhoneMessage = mapAllowedMessageProps(userToUpdate.allowedCellPhoneMessage);
        userToUpdate.allowedWorkPhoneMessage = mapAllowedMessageProps(userToUpdate.allowedCellPhoneMessage);
        return this.httpClient.put("" + this.basePath + userRoutes.user, userToUpdate).pipe(map(function (user) { return ({ data: user }); }));
    };
    UserService.prototype.saveLoginHistory = function (data) {
        return this.httpClient.post("" + this.basePath + loginHistoryRoutes.postLoginHistory, data).pipe(map(function (response) { return ({ data: response }); }));
    };
    UserService.prototype.getLoginHistory = function (data) {
        return this.httpClient.get("" + this.basePath + GET_LOGIN_HISTORY_URL, data).pipe(map(function (response) { return ({ data: response }); }));
    };
    UserService.prototype.requestWorkLifeServices = function (data) {
        return this.httpClient.post("" + this.basePath + caseRoutes.postRequestWorkLifeServices, data).pipe(map(function (response) { return ({ data: response }); }));
    };
    UserService.prototype.getpreferredPhoneNumber = function (user) {
        var preferredPhone = user.preferredPhone;
        if (preferredPhone === 'Home') {
            return user.homePhone;
        }
        else if (preferredPhone === 'Work') {
            return user.workPhone;
        }
        else if (preferredPhone === 'Cell') {
            return user.cellPhone;
        }
        else {
            return user.cellPhone;
        }
    };
    UserService.prototype.saveRiskHistory = function (data) {
        return this.httpClient.post("" + this.basePath + riskHistoryRoutes.postRiskHistory, data).pipe(map(function (response) { return ({ data: response }); }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
