import { Component, OnInit, Input } from '@angular/core';
import { ConfirmationModal, ModalOptions } from '../modal.component';
import { SERVICE_TYPE } from '../..';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';
import { ONLINE_SWITCH_MODAL_HEADING, FIRST_RESP_MODE_OF_HELP, COUNSELING_FOR_INDIVIDUAL, FR_MILES, IN_PERSON_MILES, LOCAL_SERVICE, PHYSICIAN_MODE_OF_HELP } from '../../../../../@core/utils/constants';
import { STATES } from '../../../../../@core/utils/intake-flow.service';
@Component({
  selector: 'ngx-online-switch',
  templateUrl: './online-switch.component.html',
  styleUrls: ['./online-switch.component.scss'],
})
export class OnlineSwitchComponent implements OnInit {
  @Input() modeOfHelp: string;

  _modal: ModalOptions = { context: {}, modalHeader: '', options: false };

  LOCAL_STRING: string;
  headerTitle: string;
  serviceType: SERVICE_TYPE;
  user: any = {};
  heading: string;
  souAgreed: boolean = false;
  modality: string = '';
  message = '';
  contract: any;
  org: any;
  submitted: boolean = false;
  zipradii: any[] = [];
  zipCode: string = '';
  radius: string = '';
  counselingFor: string = '';
  reasonForSwitch: string = '';
  reasons: any;
  public states = STATES;
  stateList: string[];
  selectedState: string = '';
  switchFrom: string = '';

  set modal(value: ModalOptions) {
    if (Object.keys(value).length > 0) {
      this.message = ``;
      this.heading = ONLINE_SWITCH_MODAL_HEADING;
      this.switchFrom = value.context.switchFrom;
    }
  }

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getLocalString();
    this.reasons = environment.reasons;
    if (this.modeOfHelp === FIRST_RESP_MODE_OF_HELP) {
      this.counselingFor = COUNSELING_FOR_INDIVIDUAL;
      this.zipradii = FR_MILES;
    } else {
      this.zipradii = IN_PERSON_MILES;
    }
    this.stateList = Object.values(this.states)
    this.stateList.shift();
  }

  getLocalString() {
    this.LOCAL_STRING = LOCAL_SERVICE;
  }

  isValidZipCode() {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.zipCode)
  }

  closeModal() {
    this.activeModal.close({ answer: ConfirmationModal.No, user: this.user });
  }

  getUSAStateCode(stateName): string {
    return Object.keys(this.states).find(key => this.states[key] === stateName);
  }

  getStates(): any {
    return this.stateList
  }

  accept(): void {
    const modalForm =  {}

    switch (this.modeOfHelp) {
      case PHYSICIAN_MODE_OF_HELP:
        modalForm['selectState'] = [this.getUSAStateCode(this.selectedState), this.selectedState];
        break;
      default:
        modalForm['zipCode'] = this.zipCode;
        modalForm['radius'] = this.radius;
        modalForm['counselingFor'] = this.counselingFor;
        break;
    }
    this.activeModal.close({
      answer: ConfirmationModal.Yes,
      user: this.user,
      reasonForSwitch: this.reasonForSwitch,
      ...modalForm,
    });
  }

  completeRequest () {
    if (this.submitted) return;
    this.submitted = true;
    this.accept();
  }
}
