/**
 * Luma API
 * Luma API
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ConcernResp } from '../model/concernResp';
import { Org } from '../model/org';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { ConcernOrg } from '..';

import { environment } from '../../../../environments/environment';
import { orgRoutes } from './constants';

@Injectable()
export class OrgService {
    protected basePath = environment.basePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get Org
     * Get Org by filtering on name, email and/or phone number
     * @param name 
     * @param phone 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrg(name?: string, id?: string, phone?: string, email?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Org>>;
    public getOrg(name?: string, id?: string, phone?: string, email?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Org>>>;
    public getOrg(name?: string, id?: string, phone?: string, email?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Org>>>;
    public getOrg(name?: string, id?: string, phone?: string, email?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        var query = orgRoutes.getOrg;

        if(environment.isDemoEnvironment){
            query = 'getMockData';
            queryParameters = queryParameters.set('model', 'org');
        } else {
            if (name !== undefined) {
                queryParameters = queryParameters.set('name', <any>name);
            }
            if (phone !== undefined) {
                queryParameters = queryParameters.set('phone', <any>phone);
            }
            if (email !== undefined) {
                queryParameters = queryParameters.set('email', <any>email);
            }
        }
        if (id !== undefined) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Org>>(`${this.basePath}${query}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Org By Name
     * Get Org by filtering on name
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrgByName(name: string, observe?: 'body', reportProgress?: boolean): Observable<Org>;
    public getOrgByName(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Org>>;
    public getOrgByName(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Org>>;
    public getOrgByName(name: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling getOrgByName.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        
        return this.httpClient.get<Org>(`${this.basePath}${orgRoutes.getOrg}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Org
     * Get Org
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrgId(id: string, observe?: 'body', reportProgress?: boolean): Observable<Org>;
    public getOrgId(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Org>>;
    public getOrgId(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Org>>;
    public getOrgId(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrgId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Org>(`${this.basePath}${orgRoutes.getOrg}/${id}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lookup Concern Company Code
     * LookupCode
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lookupCode(code: string, observe?: 'body', reportProgress?: boolean): Observable<ConcernOrg>;
    public lookupCode(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConcernOrg>>;
    public lookupCode(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConcernOrg>>;
    public lookupCode(code: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling lookupCode.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (code !== undefined) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ConcernOrg>(`${this.basePath}${orgRoutes.getOrgLookup}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
