import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NpsSurveyService } from '../../../@core/utils/nps-survey.service';
import { SurveyService } from '../../../@core/data/api/survey.service';
import { take } from 'rxjs/operators';
const ROUTE_NAME = 'suggestion-box';
@Component({
  selector: 'ngx-feedback-textbox',
  templateUrl: './feedback-textbox.component.html',
  styleUrls: ['./feedback-textbox.component.scss'],
})
export class FeedbackTextboxComponent implements OnInit {

  constructor(private npsSurveyService: NpsSurveyService,
    private router: Router, private activatedRoute: ActivatedRoute, private surveyService: SurveyService) {
    this.npsSurveyService.setCarePathProgress(ROUTE_NAME);
  }
  recommendData: number;
  myCounseling: string;
  scheduledData: string;
  concernedData: string;
  suggestionData: string;
  submitted: boolean = false;

  ngOnInit() {
    this.suggestionText = this.npsSurveyService.suggestionData;

  }
  suggestionText: string;

  getData() {
    this.recommendData = this.npsSurveyService.recommendData;
    this.myCounseling = this.npsSurveyService.mycounselorData;
    this.scheduledData = this.npsSurveyService.scheduledData;
    this.concernedData = this.npsSurveyService.concernedData;
    this.suggestionData = this.npsSurveyService.suggestionData;
  }

  goNext(): void {
    this.submitted = true;
    this.suggestionText = this.suggestionText;
    this.npsSurveyService.suggestionData = this.suggestionText;
    this.getData();
    const selectedData = [this.recommendData, this.myCounseling, this.scheduledData, this.concernedData, this.suggestionData]
    const data = this.convertSelectionsToAnswer(selectedData);
    const model = { data, config: { name: 'nps', lastTaken: new Date().toISOString(), showSurvey: false } };

    this.surveyService.updateNpsSurvey(model).subscribe((response: any) => {
    }, err => {
      // error saving data
    });
    this.surveyService.createNpsSurvey(data).pipe(take(1)).subscribe((response: any) => {
    }, err => {

    });

    const nextStep = this.npsSurveyService.getNextStep(ROUTE_NAME);
    this.router.navigate([`../${nextStep}`], { relativeTo: this.activatedRoute });

  }



  private convertSelectionsToAnswer([RecommendServiceSlider, myCounselorSlider, scheduleSlider, concernedSlider, feedbackAnswer]: any) {

    return { RecommendServiceSlider, myCounselorSlider, scheduleSlider, concernedSlider, feedbackAnswer };
  }
}
