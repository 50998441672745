import { OnInit } from '@angular/core';
import { ProgressBarService } from '../../@core/utils/progress-bar.service';
import { Router } from '@angular/router';
var NpsSurveyComponent = /** @class */ (function () {
    function NpsSurveyComponent(progressBarService, router) {
        this.progressBarService = progressBarService;
        this.router = router;
        this.previousValue = -1;
        this.exception = ['recommend-service'];
        this.goBackOnly = ['nps-thankyou'];
        this.noBar = [];
        this.showIntakeNavigationButtons = false;
    }
    NpsSurveyComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(NpsSurveyComponent.prototype, "isVisibleOnStep", {
        get: function () {
            var currentUrl = this.router.url;
            var arrPath = currentUrl.split('/');
            var currentStep = arrPath[arrPath.length - 1];
            return !this.exception.includes(currentStep);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NpsSurveyComponent.prototype, "backOnly", {
        get: function () {
            var currentUrl = this.router.url;
            var arrPath = currentUrl.split('/');
            var currentStep = arrPath[arrPath.length - 1];
            return this.goBackOnly.includes(currentStep);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NpsSurveyComponent.prototype, "showBar", {
        get: function () {
            var currentUrl = this.router.url;
            var arrPath = currentUrl.split('/');
            var currentStep = arrPath[arrPath.length - 1];
            return !this.noBar.includes(currentStep);
        },
        enumerable: true,
        configurable: true
    });
    return NpsSurveyComponent;
}());
export { NpsSurveyComponent };
