
<div class="modal-content">
  <span class="close-icon" (click)="closeModal()">&#10005;</span>
  <div class="row d-flex justify-content-center align-content-center">
    <div class="col-12 d-flex justify-content-center">
      <img class="check-mark" src="../../../../../../assets/icons/finished-checkmark-purple.png" alt="Purple checkmark">
    </div>
  </div>

  <div class="row d-flex justify-content-center align-content-center">
    <div class="col-12">
      <p class="confirmation">Your New Baby Kit request has been placed!</p>
    </div>
  </div>

  <div class="row d-flex justify-content-center align-content-center">
    <div class="col-12 ">
      <p class="label-text">Shipped to:</p>
      <p class="main-text">{{ user.babyKitRequest.shipTo }}</p>
      <p class="main-text">1{{ user.babyKitRequest.shippingAddress.street }}</p>
      <p class="main-text">{{ user.babyKitRequest.shippingAddress.city }}, {{ user.babyKitRequest.shippingAddress.state }} {{ user.babyKitRequest.shippingAddress.postal }}</p>
    </div>
  </div>

  <div class="row d-flex justify-content-center align-content-center">
    <div class="col-12">
      <p class="label-text">Date Requested:</p>
      <p class="main-text">{{submittedDate | date:'mediumDate'}}</p>
    </div>
  </div>

  <div class="row d-flex justify-content-center align-content-center">
    <div class="col-12">
      <p class="label-text">Ships in 7-14 days</p>
    </div>
  </div>
  
  <div class="row"> 
    <div class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center align-content-center align-items-center">
      <div class="confirm-btn" (click)="closeModal()">
        BACK TO MY BENEFITS <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
      </div>
    </div>
  </div>

</div>