<div class="bgColor">
    <div class="holder">
        <div class="collapse-x" (click)="closeModal()">
            <img src="/assets/icons/close-deep-purple.svg" />
        </div>
        <div class="w-100 text-center">
            <img src="/assets/icons/finished-checkmark-purple.png" class="img-fluid big-check">
        </div>
        <div class="modal-header">
            <ngx-intake-header [title]="heading" fontSize="font-size-22" fontWeight="font-weight-600"
                class="mx-auto">
            </ngx-intake-header>
        </div>

        <div class="mx-auto" style="margin-top: 36px;">
            <p>A Concern representative will be contacting you within one business day.</p>

            <p>If you don't hear back within this timeframe, please feel free to email us at <a
                    href="mailto:info@concernhealth.com" style="color: #742886;">info@concernhealth.com.</a>
            </p>
        </div>

        <div class="row justify-content-center no-gutters">
            <button class="btn btn-primary col-12 col-md-8" (click)="accept()" >Finished
                <i class="fas fa-arrow-circle-right"></i>
            </button>
        </div>
    </div>
</div>