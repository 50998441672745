<div class="emindful-modal">
    <div class="modal-header">
        <button class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-content">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 mx-auto">
                    <div class="row">
                        <div class="col-5 mx-auto logo-container">
                            <img class="logos" src="../.../../../../../../../assets/images/logo/concern-one-color-logo.png" alt="Concern">
                        </div>
                        <div class="col-2 mx-auto plus logo-container">
                            <i class="fas fa-plus"></i>
                        </div>
                        <div class="col-5 mx-auto logo-container">
                            <img class="logos" src="../.../../../../../../../assets/icons/emlife-logo.png" alt="eMLife">
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <div class="col-10 mx-auto title text-center">
                    We're taking you to our partner, eM Life Mindfulness App
                </div>
            </div>
    
            <div class="row">
                <div class="col-12 details">
                    Concern has partnered with <span class="emLife">eM Life Mindfulness App</span>
                    to bring you guided mindfulness solutions to help improve your emotional and physical well being.
                </div>
            </div>
            <div class="row">
                <div class="col-12 details">
                    Once at <span class="emLife">eM Life Mindfulness App</span>,
                    you’ll be able to customize the programs to match your needs.
                    <a class="learn" [href]="linkDomain + '/articles/em-life'" target="_blank">Learn more about eM Life Mindfulness App.</a>
                </div>
            </div>
    
            <div class="row">
                <div class="col-12">
                    <button type="button" class="go btn btn-primary btn-block" (click)="accept()">
                        OKAY, LET'S GO!
                        <i class="fas fa-arrow-circle-right"></i>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="button" class="back btn btn-link btn-block"  (click)="closeModal()">
                        I’M NOT READY TO LEAVE YET
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer text-center">
        <div class="container">
            <div class="row">
                <div class="col-12 mx-auto show-container">
                    <div class="show">
                        <input type="checkbox" class="check-show form-check-input" (change)="changeModalFlag($event)">
                        <span class="dont-show">Don't show this again</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>