<div class="sub-header mt-1 mb-3" *ngIf="visible">
    <div class="headline row justify-content-center" *ngIf="headline">
        <div class="col-12 col-md-9">
            {{ headline }}
        </div>
    </div>
    <div class="row justify-content-center icon-position" *ngIf="caption">
        <div class="caption col-12">
           <ng-content></ng-content>
        </div>
    </div>
</div>
