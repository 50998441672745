<nb-card class="login-card">
  <nb-card-body>

    <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
        <ngx-intake-header [title]="'Please hold while we authenticate you...'" styleClasses="mt-0" ></ngx-intake-header>
      <form #form="ngForm" autocomplete="nope">

        <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted" class="alert alert-success"
          role="alert">
          <div>
            <strong>Hooray!</strong>
          </div>
          <div *ngFor="let message of messages">{{ message }}</div>
        </div>

        <ngx-request-errors *ngIf="showMessages.error && !submitted" [errors]="errors" [showTitle]="false">
        </ngx-request-errors>

      </form>

    </div>

  </nb-card-body>

</nb-card>