export const orgRoutes = {
  getOrg: '/organization',
  getOrgContractById: '/contract/organization/:organizationId/contract',
  getContract: '/contract/organization',
  getOrgLookup: '/organization/lookup',
  getLeafContracts: '/contract/siteLocations'
}

export const caseRoutes = {
  getOpenCase: '/case/open',
  case: '/case',
  getCaseData: '/case/data',
  postShouldLimitCase: '/case/should-limit',
  postRequestWorkLifeServices: '/case/workLifeServices',
}

export const authorizationRoutes = {
  postPfStatus: '/authorization/pfstatus',
};

export const betterHelpRoutes = {
  getBetterHelp: '/betterHelp',
};

export const configConstants = {
  onlyActive: 'true',
};

export const configRoutes = {
  getEAPXParams: '/parameters',
  getCoachingConfig: '/configuration/coaching',
  getCitiesFromState: '/geolocation/cities',
  getStateFromZip: '/geolocation/state',
};

export const userHistoryRoutes = {
  userHistory: '/userHistory',
}

export const loginHistoryRoutes = {
  postLoginHistory: '/login/history',
};

export const emotionalSurveyRoutes = {
  emotionalSurvey: '/emotional-survey',
  eapxEmotionalSurvey: '/emotional-survey/eapx',
};

export const providerRoutes = {
  getCounselorProvidersByIds: '/provider',
  getProviders: '/provider/case',
  getProvidersByOrg: '/provider/organization/:orgId',
  getProviderBySpecialization: '/provider/specialization',
  getProviderImage: '/provider/providerImage'
};

export const contentRoutes = {
  getServiceContent: '/content/service',
  getRecommendedContents: '/content/recommended',
  getContentById: '/content',
  getGeneralContent: '/content/general',
  postContentRating: '/content/rating',
  getFAQ: '/content/getFaq',
};

export const userRoutes = {
  user: '/user',
  postUserChangePassword: '/user/changePassword',
  postUserForgotPassword: '/user/forgotPassword',
  getUserLogout: '/user/logout',
  postLogin: '/user/login',
  postResendVerification: '/user/resendVerification',
  postUserResetPassword: '/user/resetPassword',
  postRefreshToken: '/user/refresh'
};

export const riskHistoryRoutes = {
  postRiskHistory: '/risk-history'
};

export const emindfulConstants = {
  live: 'live',
  recorded: 'recorded',
  programLimit: 10,
};

export const emindfulRoutes = {
  getPrograms: '/program'
};

export const removeFalsyProps = <T extends object>(obj: T) => {
  const result: Partial<T> = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      result[key] = obj[key];
    }
  });
  return result;
};
