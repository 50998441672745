import { Injectable } from '@angular/core';
import { filter, pairwise } from 'rxjs/operators';
import { Router, RoutesRecognized} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreviousUrlService {

  navigationUrls: Observable<any[]>;

  constructor(private router: Router) {
     this.navigationUrls = this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise());
  }
}
