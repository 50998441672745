import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GeocodedAddress } from '../../../../pages/dashboard/display-appointment/models/GeocodedAddress';

@Component({
  selector: 'ngx-address-validation',
  templateUrl: './address-validation.component.html',
  styleUrls: ['./address-validation.component.scss'],
})
export class AddressValidationComponent implements OnInit {
  @Output() addressChange = new EventEmitter<string>();
  @Input() suggested: GeocodedAddress[] = [];
  @Input() original: string = '';

  chosenAddress: string = '';

  constructor() { }

  ngOnInit() {
    this.chosenAddress = this.suggested[0].formatted_address;
  }

}
