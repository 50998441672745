import { EmotionalWellbeingSurveyService } from './emotional-wellbeing-survey.service';
import { RISK } from './enums';
import * as i0 from "@angular/core";
import * as i1 from "./emotional-wellbeing-survey.service";
var NO_SCORE = 0;
var LOW_SCORE = 1;
var MEDIUM_SCORE = 2;
var HIGH_SCORE = 3;
var HIGHEST_SCORE = 4;
var PHQ_SCORE_MAP = {
    'Not at All': NO_SCORE,
    'Several Days': LOW_SCORE,
    'More Than Half the Days': MEDIUM_SCORE,
    'Almost Everyday': HIGH_SCORE,
};
var SAT_SCORE_MAP = {
    'Very Satisfied': LOW_SCORE,
    'Slightly Satisfied': MEDIUM_SCORE,
    'Slightly Dissatisfied': HIGH_SCORE,
    'Very Dissatisfied': HIGHEST_SCORE,
};
var STRESS_SCORE_MAP = {
    'Never': NO_SCORE,
    'Only a Little': LOW_SCORE,
    'Sometimes': MEDIUM_SCORE,
    'Fairly Often': HIGH_SCORE,
    'Very Often': HIGHEST_SCORE,
};
var SurveyCalcService = /** @class */ (function () {
    function SurveyCalcService(emotionalSurvey) {
        this.emotionalSurvey = emotionalSurvey;
        this.feelingsData = []; // Capture Feelings data
        this.feelings = [];
        this.stressData = []; // Capture Stress data
        this.satisfiedData = []; // Capture satisfied data
    }
    // gather data from emotional service
    SurveyCalcService.prototype.gatherData = function () {
        this.feelings = this.emotionalSurvey.feelingsData;
        this.feelingsData = Object.values(this.feelings);
        this.stressData = this.emotionalSurvey.stressLifeData;
        this.satisfiedData = this.emotionalSurvey.satisfiedLifeData;
        this.PHQ2_1_Score = this.getEmotionIntensityScore(0);
        this.PHQ2_2_Score = this.getEmotionIntensityScore(1);
        this.PHQ2_3_Score = this.getEmotionIntensityScore(2);
        this.PHQ2_4_Score = this.getEmotionIntensityScore(3);
        this.SAT_1_SCORE = this.getSatisfactionIntensityScore(0);
        this.STRESS_1_SCORE = this.getStressIntensityScore(0);
    };
    SurveyCalcService.prototype.scoreMap = function () {
    };
    // Calculate Risk from responses
    SurveyCalcService.prototype.calculateRisk = function () {
        this.gatherData();
        var PHQ_Total = this.PHQ2_1_Score + this.PHQ2_2_Score + this.PHQ2_3_Score + this.PHQ2_4_Score;
        var EMOTIONAL_TOTAL = PHQ_Total + this.SAT_1_SCORE + this.STRESS_1_SCORE;
        if (EMOTIONAL_TOTAL <= 8) {
            return RISK.LOW;
        }
        else if (EMOTIONAL_TOTAL > 8 && EMOTIONAL_TOTAL < 15) {
            return RISK.MEDIUM;
        }
        else {
            return RISK.HIGH;
        }
    };
    SurveyCalcService.prototype.getEmotionIntensityScore = function (index) {
        return PHQ_SCORE_MAP[this.feelingsData[index]];
    };
    SurveyCalcService.prototype.getSatisfactionIntensityScore = function (index) {
        return SAT_SCORE_MAP[this.satisfiedData[index]];
    };
    SurveyCalcService.prototype.getStressIntensityScore = function (index) {
        return STRESS_SCORE_MAP[this.stressData[index]];
    };
    SurveyCalcService.ngInjectableDef = i0.defineInjectable({ factory: function SurveyCalcService_Factory() { return new SurveyCalcService(i0.inject(i1.EmotionalWellbeingSurveyService)); }, token: SurveyCalcService, providedIn: "root" });
    return SurveyCalcService;
}());
export { SurveyCalcService };
