<div class="modal-header text-center">
    <span class="lead">{{ modal?.modalHeader }}</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="modal?.modalContent" class="modal-content text-center">
    <p class="m-0 p-3" [innerHTML]="modal?.modalContent"></p>
</div>
<div class="modal-footer text-center" *ngIf="modal?.options;else message">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-6">
                <button class="btn btn-lg btn-primary" (click)="closeModal()">No</button>
            </div>
            <div class="col-6">
                <button class="btn btn-lg btn-primary" (click)="accept()">Yes</button>
            </div>
        </div>
    </div>
</div>


<ng-template #message>
    <div class="text-center message-container">
        <p>{{ modal?.firstMessage }}</p>
        <p>{{ modal?.secondMessage }}</p>
    </div>
</ng-template>