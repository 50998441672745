
  <div>
  <div class="flex-centered">
    <nb-auth-block class="col-12">
    <ngx-intake-header title="Hi, thank you for registering!" styleClasses="mt-0"></ngx-intake-header>
      <div class="row justify-content-center no-gutters">
        <div class="form-text sub-title col-10">
        To access your personal dashboard, you’ll have to verify your email address.
        An email has been sent to <strong>{{email ? email : 'your email'}}</strong> with instructions to verify that you are the owner.
        </div>
        <button class="btn btn-outline-primary col-12 col-md-7" (click)="resendVerificationEmail();">
          Resend Verification
        </button>
        <div class="form-text sub-title col-10">
        Please verify, then log in. <ngx-instructions-tooltip
        [attachToCorner]="false"
        [popOverMessage]="tooltipMessage"></ngx-instructions-tooltip>
        </div>
      </div>

      <div class="row justify-content-center no-gutters">
        <button class="btn btn-primary col-12 col-md-6" (click)="router.navigateByUrl('/auth/login');">
          Login
        </button>
      </div>
      <div class="row justify-content-center no-gutters">
        <a class="link" [routerLink]="'/auth/login'">
          ALREADY REGISTERED? CLICK HERE
        </a>
      </div>
      <br/>
    </nb-auth-block>
    </div>
    </div>  