<nb-card>
  <nb-card-body class="flex-column">
    <nb-auth-block class="flex-centered">
      <ngx-intake-header title="Terms & Conditions" styleClasses="mt-0"></ngx-intake-header>
      <div class="terms">
        <h6 class="text-center">Concern Digital Platform Terms of Use</h6>
        <div class="text-center"><b>Effective September 24, 2020</b></div> <br />
        <p>
          CONCERN: EAP (hereinafter “Concern”) runs the internet domain www.concernhealth.com
          and any associated mobile application sites (the “Mobile Web App”) including
          app.concernhealth.com Digital Platform designed to identify, track and communicate clear
          and accurate information about solutions for wellbeing, where the Mobile Web App and the
          services related thereto (the “Services”) and internet domain is branded and referred to
          herein as “ Concern Digital Platform” (collectively the “Site”).
        </p>
        <p>
          These Terms of Use constitute a binding agreement between you and Concern. BY USING THE
          SITE YOU SIGNIFY THAT YOU AGREE TO THESE TERMS OF USE AND TO
          INDEMNIFY CONCERN AGAINST ANY CLAIMS BY THIRD PARTIES ARISING FROM
          YOUR VIOLATIONS OF THESE TERMS OF USE. YOU CONSENT TO THE USE OF
          YOUR PERSONAL INFORMATION AS SET FORTH IN THE PRIVACY STATEMENT. IF
          YOU DO NOT WISH TO BE BOUND BY THESE TERMS, DO NOT ACCESS,
          DOWNLOAD OR USE THE SITE. This Site is not intended or designed to be used by anyone
          under the age of 18. You may not use the Site if you are under the age of 18.
        </p>
        <p>
          Access to the Site is provided via a username and a password, which can be freely chosen by you
          at the time of registration. Passwords can be changed at any time and must be stored safely. You
          are responsible for taking reasonable steps to ensure that no unauthorized persons have access to
          your username or password. If your password or username are compromised, it is your sole
          responsibility to immediately inform Concern of any need to deactivate your password or
          username.
        </p>
        <p>
          You may use this Site for lawful purposes only. You may not attempt to gain unauthorized
          access to any services, user accounts, computer systems or networks, through hacking,
          password mining or any other means. You may not use the Site in any way that may
          interfere with any other party's use and enjoyment of the Site, including but not limited to
          using the Site in any manner that could overburden our servers or impair, damage, or
          disable, our networks or servers. You hereby grant Concern and persons or entities
          involved in the operation of this Site, the right to transmit, monitor, store, retrieve and use
          your information in connection with operating the Site. You agree not to, and represent and
          warrant that you will not, reproduce, duplicate, copy, sell, resell or exploit any portion of
          the Site including the content therein, use of the Site or access to the Site for any purposes
          other than for which the Site is being provided to you. We may take any legal and technical
          remedies to prevent the violation of this provision and to enforce these Terms of Use.
          When you use Concern Digital Platform, we gather certain information about you. Our
          Privacy Statement <a href="https://app.concernhealth.com/#/auth/privacy">https://app.concernhealth.com/#/auth/privacy</a> and Terms & Conditions at
          <a href="https://app.concernhealth.com/#/auth/terms">https://app.concernhealth.com/#/auth/privacy</a> explain how we use, disclose and maintain
          your personal information and how you may control, correct and update this information.
          By using the Site, you expressly consent to the use of your personal information as set forth 
          in the Privacy Statement. While there is no such thing as "perfect security" on the Internet,
          we will take reasonable steps to help ensure the safety of your personal information.
          However, you understand and agree that such steps do not guarantee that the Site is
          invulnerable to all security breaches or immune from viruses, security threats or other
          vulnerabilities. We reserve the right to cooperate with local, state, provincial and national
          authorities in investigations of improper or unlawful activities and this may require the
          disclosure of your personal information. We may also report to other organizations about
          improper or unlawful user activities and this reporting may include disclosure of the
          personal information relating to those individuals conducting such improper or unlawful
          activities. You agree that we may use your username to authenticate you on any portion of
          this Site and with vendors acting on our behalf who may be located outside the United
          States. You consent to the transfer of your personal information outside the United States
          for processing and storage by us.
        </p>
        <p>
          You agree not to take any of the following actions, any or all of which taken separately or
          together, constitutes a material breach of these Terms of Use:
        </p>
        <p>
          • Impersonating another user or allowing another user to use your personal identification;
        </p>
        <p>
          • Posting harassing, threatening, harmful, obscene, defamatory, abusive, fraudulent, illegal,
          inflammatory, pornographic, offensive, hateful, libelous or slanderous materials, or any material
          that could constitute or encourage conduct that would be considered a criminal offence, give rise
          to civil liability, or otherwise violate any law, as determined by Concern in its sole discretion;
        </p>
        <p>
          • Using Concern’s Digital Platform for any purpose that violates any laws and/or regulations,
          including, without limitation, the laws and regulations governing unfair competition, false
          advertising, consumer protection, obscenity, spamming and privacy;
        </p>
        <p>
          • Using Concern’s Digital Platform in a manner or posting any materials that violates or infringes
          on another person’s or entity’s intellectual property rights or posting advertising (e.g. copyrights,
          trademarks or any other intellectual property rights, or rights of publicity);
        </p>
        <p>
          • Posting any materials that violates any other person's right to privacy or publicity;
        </p>
        <p>
          • Engaging in viral messaging, distributing harmful computer code or chain mail;
        </p>
        <p>
          • SPAMMING or SPIMMING: Collecting data about others, including harvesting, without their
          knowledge;
        </p>
        <p>
          • Uploading viruses or malicious code or do anything that could disable, overburden or impair
          the Site;
        </p>
        <p>
          • Advocating or encouraging illegal activity; or
        </p>
        <p>
          • Prohibiting or preventing others from their enjoyment and use of the Site that, in Concern’s
          sole discretion, exposes or attempts to expose Concern or our licensors, users, customers or
          suppliers to any liability whatsoever.
        </p>
        <p>
          CONCERN AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
          SUBCONTRACTORS AND LICENSORS HAVE NO RESPONSIBILITY OR LIABILITY TO
          ANY USER OR THIRD-PARTY FOR ITS PERFORMANCE OR NON- PERFORMANCE OF
          ANY ACTIVITIES DESCRIBED IN THIS SECTION.
        </p>
        <p>
          In the event that you provide us any ideas, thoughts, criticisms, suggested improvements or other
          feedback related to the Site (collectively “Feedback”), you agree we may use the Feedback in our
          Services or to modify our Site or create a new Site and that you will not be due any
          compensation, including any royalty related to the product or service that incorporates your
          Feedback. You grant to us a worldwide, royalty-free, fully paid, perpetual, irrevocable license to
          use, reproduce, modify, translate, distribute, perform, display, import, sell, offer for sale, make,
          have made and otherwise exploit the Feedback in any form, media, or technology, whether now
          known or hereafter developed, and to allow others to do the same. This is true whether you
          provide the Feedback on the Site or through any other method of communication with us, unless
          we have entered into a separate agreement with you that provides otherwise.
        </p>
        <p>
          The trademarks, logos, characters and service marks (collectively "Trademarks") displayed on
          this Site belong to Concern or are used with permission. Unauthorized sale, reproduction,
          republishing, uploading, downloading, distribution, posting, displaying, transferring, alteration,
          preparing derivative works of this Site or Trademarks is prohibited, including disassemble,
          decompile, exchange, translation of the Site, reverse engineering or attempting to discover
          source code of the Site. You may download the Site for the specified use only, provided that you
          maintain all notices, information or restrictions contained in any material accessed.
        </p>
        <p>
          Subject to these Terms of Use and your status as a registered user, you are hereby granted a
          limited, worldwide, non-exclusive, royalty-free, non-sub licensable, revocable license to use the
          Site for your personal, non-commercial use. Concern reserves the right to amend or withdraw the
          Site or charge for the Site at any time and for any reason.
        </p>
        <p>
          You understand and acknowledge that the software, code, proprietary methods and systems used
          to provide the Site (“Our Technology”) are: (i) copyrighted by Concern and/or their respective
          licensors under the United States copyright laws; (ii) subject to other intellectual property and
          proprietary rights and laws; and (iii) owned by us or our licensors. Our Technology may not be
          copied, modified, reproduced, republished, posted, transmitted, sold, offered for sale, or
          redistributed in any way without our prior written permission and the prior written permission of
          our Applicable licensors. You must abide by all copyright notices, information, or restrictions
          contained in or attached to any of Our Technology. Nothing in these Terms of Use grants you
          any right to receive delivery of a copy of Our Technology or to obtain access to Our Technology
          except as generally and ordinarily permitted through the Site according to these Terms of Use.
          Furthermore, nothing in these Terms of Use will be deemed to grant, by implication, estoppel or
          otherwise, a license to Our Technology.
        </p>
        <p>
          We provide the Site and the content, related surveys, emails or other content therein (“Content”)
          is provided "as is" "with all faults" and "as available." Concern and its suppliers and licensors
          make no express or implied warranties or guarantees about the Site including the Content
          thereon. Any claims arising in connection with the Site or Content must be brought within one
          (1) year of the date of the event giving rise to such action occurred. Remedies under these Terms
          of Use are exclusive and are limited to those expressly provided for in these Terms of Use.
        </p>
        <p>
          TO THE EXTENT PERMITTED BY LAW, CONCERN AND ITS RESPECTIVE
          LICENSORS AND SUPPLIERS DISCLAIM IMPLIED WARRANTIES THAT THE SITE IS
          MERCHANTABLE, OF SATISFACTORY QUALITY, ACCURATE, FIT FOR A
          PARTICULAR PURPOSE OR NEED, OR NON-INFRINGING. WE DO NOT GUARANTEE
          THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE WILL
          BE EFFECTIVE, RELIABLE, AND ACCURATE OR MEET YOUR REQUIREMENTS. WE
          DO NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE THE SITE
          (EITHER DIRECTLY OR THROUGH THIRD-PARTY NETWORKS) AT ALL TIMES OR
          LOCATIONS OF YOUR CHOOSING. WE MAKE NO WARRANTY THAT THE SITE OR
          CONTENT THEREON WILL MEET YOUR REQUIREMENTS, OR THAT THE SITE WILL
          BE UNINTERRUPTED, TIMELY, SECURE, ERROR FREE, AND FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS, OR THAT DEFECTS WILL BE CORRECTED. YOU
          UNDERSTAND AGREE THAT THIS SITE AND ALL INFORMATION, CONTENT AND
          MATERIALS CONTAINED THEREIN IS FOR INFORMATIONAL PURPOSES ONLY,
          AND NOT INTENDED TO SERVE AS A SUBSTITUTE FOR PROPER DIAGNOSIS
          AND/OR MEDICAL TREATMENT BY A QUALIFIED PHYSICIAN OR HEALTHCARE
          PROVIDER.
        </p>
        <p>
          YOU UNDERSTAND AND AGREE THAT ANY MATERIAL, CONTENT OR
          INFORMATION DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
          THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE
          SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SERVICES OR
          LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL
          AND/OR INFORMATION. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN
          BY US SHALL CREATE A WARRANTY.
        </p>
        <p>
          YOU MAY HAVE ADDITIONAL CONSUMER RIGHTS UNDER YOUR LOCAL LAWS
          THAT THIS CONTRACT CANNOT CHANGE. YOUR SOLE AND EXCLUSIVE REMEDY
          FOR ANY DISPUTE WITH CONCERN IS THE CANCELLATION OF YOUR
          REGISTRATION. IN NO EVENT SHALL CONCERN’S OR ITS AFFILIATES’
          CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO THE
          SITE AND ITS CONTENT EXCEED $100 USD.
        </p>
        <p>
          NEITHER CONCERN NOR ITS AFFILIATES SHALL BE LIABLE FOR ANY INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING
          FROM YOUR USE OR RELIANCE ON THIS SITE, MATERIALS CONTAINED IN THE
          SITE OR INABILITY TO USE THE SITE. THESE EXCLUSIONS APPLY TO ANY CLAIMS
          FOR LOST PROFITS, LOST DATA, LOSS OF GOODWILL, WORK STOPPAGE,
          COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER COMMERCIAL OR
          OTHER DAMAGES OR LOSSES, EVEN IF CONCERN WAS ADVISED OF OR SHOULD
          HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES
          OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF
          LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
          JURISDICTIONS, CONCERN’S LIABILITY, AND THE LIABILITY OF ITS PARENT
          COMPANY OR SUPPLIERS SHALL BE LIMITED TO THE MAXIMUM EXTENT
          PERMITTED BY LAW.
        </p>
        <p>
          You agree to defend, indemnify, and hold harmless Concern and its parent or other affiliated
          companies, and their employees, contractors, officers, and directors from all liabilities, claims,
          and expenses, including but not limited to, attorneys’ fees that arise from your use or misuse of
          the Site, or your violation of these Terms of Use. We reserve the right, at our own expense, to
          assume the exclusive defense and control of any matter otherwise subject to indemnification by
          you, in which event you will cooperate with us in asserting any available defenses.
        </p>
        <p>
          From time to time, Concern may, in our sole discretion, change these Terms of Use or upgrade,
          enhance, change and modify the Site, including by discontinuing a service or any aspect or
          feature thereto (collectively, “Modifications”). Any Modifications will be subject to these Terms
          of Use and we will use reasonable efforts to post such Modifications on the Site, noting any
          updates that would be material to our relationship. If you disagree with the Modifications, simply
          discontinue your use of the Applicable Site. Your subsequent use of the Site after the changes
          take effect signifies your agreement to the Modifications.
        </p>
        <p>
          We may cancel, suspend or block your use of the Site and/or registration at any time, without
          cause and/or without notice. You may terminate your registration at any time.
        </p>
        <p>
          We may terminate and/or suspend your registration immediately, without notice, if there has
          been a violation of these Terms of Use and such other policies and terms posted on the Site.
          Your right to use the Site will end once your registration is terminated, and any data you have
          stored on the Site may be unavailable later, unless we are required to retain it by law. We are not
          responsible or liable for any records or information that is made unavailable to you as a result of
          termination of registration, except where we are required by law to make those records available
          to you. We may assign this contract, including your personal information, at any time without
          notice to you. You may not assign this contract to anyone else. Any attempted assignment or
          delegation by you is null and void.
        </p>
        <p>
          YOU AGREE THAT CONCERN WILL NOT BE LIABLE TO YOU OR ANY OTHER
          PARTY FOR ANY TERMINATION OF YOUR ACCESS TO THE SITE.
        </p>
        <p>
          Any limitations on liability are explicitly set forth herein. Concern’s proprietary rights in and to
          the Site, Our Technology, the Content and the Services will survive the expiration or termination
          of these Terms of Use for any reason.
        </p>
        <p>
          Your affirmative act of using this Site constitutes your electronic signature to these Terms of Use
          and your consent to enter into agreements with us electronically. You also agree that we may
          send to you in electronic form any privacy or other notices, disclosures, reports, documents,
          communications or other records regarding the Services (collectively, "Notices"). We can send
          you electronic Notices (1) to the e-mail address that you provided to us during registration, or (2)
          by posting the Notice on the Site. The delivery of any Notice from us is effective when sent by
          Concern, regardless of whether you read the Notice when you receive it or whether you actually
          receive the delivery. You can withdraw your consent to receive Notices electronically within the
          Site settings or by canceling or discontinuing your use of the Applicable Service. You must give
          notice to us in writing via email to app@concernhealth.com or as otherwise expressly provided.
        </p>
        <p>All contracts completed electronically will be deemed for all legal purposes to be in writing and
          legally enforceable as a signed writing.</p>
        <p>
          You are responsible for compliance with all applicable laws. You agree that the law of the
          California, United States of America, shall govern this contract and any claim or dispute that you
          may have against us, without regard to the state’s conflict of laws rules. You further agree
          consent to the personal jurisdiction of the courts of competent jurisdiction in the federal or state
          courts of Santa Clara County, Northern District of California.
        </p>
        <p>
          PLEASE NOTE THAT BY AGREEING TO THESE TERMS OF USE, EXCEPT AS
          EXPRESSLY SET FORTH HEREIN, YOU ARE: (1) WAIVING CLAIMS THAT YOU
          MIGHT OTHERWISE HAVE AGAINST US BASED ON THE LAWS OF OTHER
          JURISDICTIONS, INCLUDING YOUR OWN; (2) IRREVOCABLY CONSENTING TO THE
          EXCLUSIVE JURISDICTION OF THE COURTS OF SANTA CLARA COUNTY, CA OVER
          ANY DISPUTES OR CLAIMS YOU HAVE WITH US RELATING TO OR ARISING OUT
          OF THE SITE OR THE TERMS OF USE; AND (3) SUBMITTING YOURSELF TO THE
          PERSONAL JURISDICTION OF THE COURTS OF SANTA CLARA COUNTY, CA FOR
          THE PURPOSE OF RESOLVING ANY SUCH DISPUTES OR CLAIMS.
        </p>
        <p>
          This contract and any supplemental terms, policies, rules and guidelines posted on the Site
          constitute the entire agreement between you and us and supersede all previous written or oral
          agreements. If any part of these Terms of Use is held invalid or unenforceable, that portion shall
          be construed in a manner consistent with Applicable law to reflect, as nearly as possible, the
          original intentions of the parties, and the remaining portions shall remain in full force and
          effect.
          You acknowledge that we have the right hereunder to seek an injunction, if necessary, to stop or
          prevent a breach of your obligations hereunder. The paragraph headings in these Terms of Use,
          shown in boldface type, are included only to help make these Terms of Use easier to read and
          have no binding effect. Any delay or failure by us to exercise or enforce any right or provision of
          these Terms of Use will not constitute a waiver of such right or provision. No waiver by us will
          have effect unless such waiver is set forth in writing, signed by us; nor will any such waiver of
          any breach or default constitute a waiver of any subsequent breach or default.
        </p>
        <p>
          <b>Links to other Applications, Websites or Providers</b>
        </p>
        <p>
          For your convenience and enjoyment, our Site may provide links to other websites or
          applications that are not operated by Concern or its affiliates. These links do not mean that
          Concern, or its affiliates endorses, approves, or sponsors the linked website, application or any
          information, products, or services contained therein, nor is Concern liable for any damage that
          might result from your use of such information, products, or services. You agree that you might
          be bound to terms of use of other providers.
        </p>
        <p>
          <b>User Comments, Feedback, and other Submissions</b>
        </p>
        <p>
          All comments, feedback, suggestions, ideas, and other submissions disclosed, submitted, or
          offered to Concern on or by this Site or otherwise disclosed, submitted, or offered in connection
          with your use of this Site (collectively "Feedback") remains your property, but you grant
          Concern and its Affiliates a non-exclusive, transferable, sub-licensable, royalty- free, worldwide
          license to use any Feedback that you post on or in connection with the Site for any purpose,
          including but not limited to, reproduction, disclosure, transmission, publication, broadcast and
          posting without any compensation. By submitting Feedback, you are also warranting that you
          own the material/content submitted, that it is not defamatory, and that its use by Concern or its
          Affiliates will not violate any third party's rights or otherwise place us in breach of any
          applicable laws.
        </p>
        <p>
          <b>License</b>
        </p>
        <p>
          These Terms of Use form part of the limited license for your use of this Site and Content therein.
          You may ask any questions before you consent – simply email your question
          to <a href="mailto:info@concernhealth.com">info@concernhealth.com</a>.
        </p>
      </div>
    </nb-auth-block>
    <nb-card-body>
      <nb-card>