<div>
  <div class="login-card animate__animated animate__fadeInUp">
    <div>

      <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
        <ngx-intake-header [title]="title" styleClasses="mt-0"></ngx-intake-header>
        <form (ngSubmit)="login()" #form="ngForm" autocomplete="nope">

          <!-- user failed login attempts 3+ times -->
          <!-- <div class="alert alert-danger" *ngIf="redirectOnMaxfailedLoginCount" role="alert">
          <small class="lead">
            You have exceeded the maximum authentication attempts.
          </small>
        </div> -->

          <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted" class="alert alert-success"
            role="alert">
            <div>
              <strong>Hooray!</strong>
            </div>
            <div *ngFor="let message of messages">{{ message }}</div>
          </div>
          <br>
          <div class="form-group">
            <input name="email" [(ngModel)]="user.email" placeholder="Email" id="input-email" pattern=".+@.+\..+" class="form-control"
              #email="ngModel" [class.form-control-danger]="email.invalid && email.touched" autofocus
              [required]="getConfigValue('forms.validation.email.required')">
            <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
              Email is required!
            </small>
            <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.pattern">
              Email should be the real one!
            </small>
          </div>
          <br>
          <div class="form-group">
            <input name="password" [(ngModel)]="user.password" placeholder="Password" type="password" id="input-password" class="form-control"
              #password="ngModel" [class.form-control-danger]="password.invalid && password.touched"
              [required]="getConfigValue('forms.validation.password.required')"
              [minlength]="getConfigValue('forms.validation.password.minLength')"
              [maxlength]="getConfigValue('forms.validation.password.maxLength')">
            <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
              Password is required!
            </small>
            <small class="form-text error"
              *ngIf="password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)">
              Password must contain at lease one uppercase letter, one lowercase letter, one number and be at least
              {{getConfigValue('forms.validation.password.minLength')}}
              characters long
            </small>
          </div>

          <ngx-request-errors *ngIf="showMessages.error && !submitted" [errors]="errors" [showTitle]="false">
          </ngx-request-errors>

          <button [disabled]="submitted || !form.valid" class="btn btn-block btn-primary top-separation"
            [class.btn-pulse]="submitted">
            LOGIN
          </button>

        </form>

        <div class="d-flex flex-column align-items-center">
          <a class="btn btn-outline-primary register-btn top-separation"
            routerLink="/pages/intake/employer-information">Register</a>
          <a class="link top-separation" routerLink="../request-password">FORGOT PASSWORD?</a>
        </div>

      </div>

    </div>

  </div>
</div>