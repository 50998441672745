import { Component, OnInit } from '@angular/core';
import { IntakeFlowService } from '../../@core/utils/intake-flow.service';

@Component({
  selector: 'ngx-landing-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

  title: String;
  content: String;
  selfHelpOptions;
  btnText: String;
  getHelpLink: String;
  constructor(private intakeFlowService: IntakeFlowService) {
    this.btnText = 'ACCESS SERVICES';
    this.title = 'Concern has you covered';
    this.content = 'Let us provide the help you need on your terms. It\'s free and confidential.'
    this.selfHelpOptions = [
      {
        icon: '/assets/images/landing/phonesupport-icon.svg',
        title: '24/7 Phone Support',
        description: `We can connect you with live support, 24/7, when you
         need help from a real person, real fast.`,
      },
      {
        icon: '/assets/images/landing/selfhelp-icon.svg',
        title: 'Self-help Resources',
        description: `Our digital experience makes personalized recommendations for
        videos, articles, apps and more to meet your individual needs.`,
      },
      {
        icon: '/assets/images/landing/counseling-icon.svg',
        title: 'Counseling Services',
        description: `Concern offers confidential counseling via in-person, video,
        telephone, live chat, and text therapy to ensure fast and convenient access`,
      },
      {
        icon: '/assets/images/landing/lifebalance-icon.svg',
        title: 'Life-balance Resources',
        description: `We provide services to help you manage
        everyday life. Access legal or financial advice or with findi elder and childcare
        resources.`,
      },
    ];
  }

  ngOnInit() {
    // Check if company context is set
    if (this.intakeFlowService.getFormData().currentContract) {
      this.getHelpLink = '/pages/intake/care-path';
    } else {
      this.getHelpLink = '/pages/intake/care-path';
    }
  }

}
