import { Observable, BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { ModelCase, Provider, Org, Contract, User } from './model/models';
import { Appointment } from '../../pages/dashboard/display-appointment/models/Appointment';
import { CaseService, OrgService, ContractService } from './api/api';
import { Router } from '@angular/router';
import { NbTokenService, NbTokenStorage } from '@nebular/auth';
import { UserService } from './users.service';
import { IntakeFlowService } from '../utils/intake-flow.service';
import { take, map, retry, retryWhen, tap } from 'rxjs/operators';
import { CounselorService } from './counselor.service';
import { ContentManagementService } from './contentManagement.service';
import { upsertItem } from '../utils/upsertItem';
import { AnalyticsService } from '../utils/analytics.service';
import { NbSpinnerService } from '@nebular/theme';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import {
  IN_PERSON_UPDATE_MODE,
  SWITCH_COUNSELORS_UPDATE_MODE,
  ONLINE_UPDATE_MODE,
  AUTHORIZATION_UPDATE_MODE,
  SWITCH_PROVIDER,
  SWITCH_TO_IN_PERSON,
  SWITCH_TO_ONLINE,
  COUNSELING_LOCATION_IN_OFFICE,
  SWITCH_VID_TO_ONLINE,
  BH_MODE_OF_HELP,
  ASSIGN_TO_PROVIDER,
  ASSIGN_TO_COACH,
  ASSIGN_TO_CALL_CENTER,
  SELECT_COUNSELOR_MODES_OF_HELP,
  SWITCH_TO_PHYSICIAN_UPDATE_MODE,
  SWITCH_TO_PHISICIAN,
} from '../utils/constants';

const REGISTRATION_ROUTE = ['/auth/register'];
const DASHBOARD_ROUTE = ['/pages/dashboard'];
const ERROR_ROUTE = ['/pages/error'];
const MAX_ATTEMPTS = 3;

let retryAttempts = 0;

const EMPLOYEE_NUMBER_COMPANY_CODE = environment.employeeNumberCompanyCode;

const hasNoSuggestionsForCounseling = ({
  modeOfHelp,
  providers,
  internalCase,
}: ModelCase) =>
  modeOfHelp === 'counselor' &&
  providers.length === 0 &&
  !internalCase.providerId;

const externalIdMissing: (errors: Observable<any>) => Observable<any> = (
  errors
) =>
  errors.pipe(
    tap((error) => {
      // This is caused when there is a network latency while calling external APIs.
      // User may be in an inconsistent / incomplete state.
      // retry only 3 times to prevent continuous hits to API if its down
      retryAttempts++;
      if (
        (error && error.message !== 'Missing InternalId') ||
        retryAttempts === MAX_ATTEMPTS
      ) {
        retryAttempts = 0;
        throw error;
      }
    })
  );
@Injectable({ providedIn: 'root' })
export class CaseManagementService implements OnInit {
  upsertProviders(result: [Provider]): any {
    throw new Error('Method not implemented.');
  }

  fetching: boolean = false;
  fetchingUser: boolean = false;
  fetchingAppointment: boolean = false;
  fetchingOrg: boolean = false;
  fetchingParentOrg: boolean = false;
  fetchingContract: boolean = false;
  fetchingCounselors: boolean = false;
  fetchingOpenCases: boolean;
  fetchingAppointments: boolean;
  validWorkLifeBenefits: Promise<string[]>;
  specialBenefits: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    []
  );

  creatingCase: boolean = false;
  ssoCodeBlock = false;

  public selectedCase: ModelCase;
  public currentContract: Contract;
  public orgStructure: any;

  protected openCasesSubject = new BehaviorSubject<ModelCase[]>([]);
  protected userSubject = new BehaviorSubject<any>(undefined);
  protected appointmentSubject = new BehaviorSubject<Appointment>(undefined);
  protected appointmentsSubject = new BehaviorSubject<Appointment[]>([]);
  protected counselorsSubject = new BehaviorSubject<Provider[]>([]);
  protected onSiteCounselorsSubject = new BehaviorSubject<Provider[]>([]);
  protected orgSubject = new BehaviorSubject<Org>(undefined);
  protected parentOrgSubject = new BehaviorSubject<Org>(undefined);
  protected contractSubject = new BehaviorSubject<Contract>(undefined);
  protected allProvidersSubject = new BehaviorSubject<Provider[]>([]);
  protected loginStatusChangeSubject = new ReplaySubject<boolean>(1);

  constructor(
    private router: Router,
    private tokenStorage: NbTokenStorage,
    private tokenService: NbTokenService,
    private userService: UserService,
    private caseService: CaseService,
    private contractService: ContractService,
    private orgService: OrgService,
    private intakeFlowService: IntakeFlowService,
    private providerService: CounselorService,
    private contentMgmtService: ContentManagementService,
    private analyticsService: AnalyticsService,
    private spinner: NbSpinnerService,
    private cookieService: CookieService
  ) {
    this.ngOnInit();
  }

  public getLoginStatus(): Observable<boolean> {
    return this.loginStatusChangeSubject;
  }

  public getOpenCases(): Observable<ModelCase[]> {
    return this.openCasesSubject.asObservable();
  }

  public getUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  public getCounselors(): Observable<Provider[]> {
    return this.counselorsSubject.asObservable();
  }

  public getOrg(): Observable<Org> {
    return this.orgSubject.asObservable();
  }

  public getParentOrg(): Observable<Org> {
    return this.parentOrgSubject.asObservable();
  }

  public getContract(): Observable<Contract> {
    return this.contractSubject.asObservable();
  }

  getProviders(): Observable<Provider[]> {
    return this.allProvidersSubject.asObservable();
  }

  /**
   * gets the last created case
   */
  public getLastCase(): Observable<ModelCase> {
    // sort the list and return the first one... 0 dereferencing will give undefined when the list is empty
    return this.openCasesSubject.pipe(map((list) => list[0]));
  }

  public getProvider(id: string): Observable<Provider> {
    return this.counselorsSubject.pipe(
      map((list) => list.find((x) => x.id === id))
    );
  }

  public getSessionById(id: string): Observable<Appointment> {
    return this.appointmentsSubject.pipe(
      map((appointments) => appointments.find((a) => a.id === id))
    );
  }
  public getProviderOn(model: ModelCase): Observable<Provider> {
    if (model.internalCase) {
      return this.counselorsSubject.pipe(
        map((list) =>
          list.find(
            (x) =>
              x.internalId === model.internalCase.providerId ||
              x.internalId === model.providerId
          )
        )
      );
    }
    return of(null);
  }

  public getSuggestions(model: ModelCase): Observable<Provider[]> {
    return this.counselorsSubject.pipe(
      map((list) => list.filter((x) => model.providers.includes(x.internalId)))
    );
  }
  spinUp(method: Promise<any>) {
    this.spinner.registerLoader(method.catch((err) => null));
  }
  ngOnInit() {
    this.openCasesSubject.subscribe((models) => {
      if (models) {
        this.refreshCounselors();
      } else {
        this.openCasesSubject.next([]);
      }
    });

    this.validWorkLifeBenefits = this.getContract()
      .pipe(
        filter((m) => !!m),
        tap((m) =>
          this.specialBenefits.next([
            `${
              m.allowedSessions === 99 ? 'Unlimited' : m.allowedSessions
            } Counseling sessions`,
          ])
        ),
        map((m) => m.validWorkLifeBenefits),
        take(1)
      )
      .toPromise();

    this.getLastCase().subscribe((model) => {
      if (model) {
        // when no counselor suggestions are made on the last counseling case, then remove all the others
        const cases = this.openCasesSubject.getValue();
        const onlyOneWithoutProviders = cases.filter(
          (obj, index) => index === 0 || !hasNoSuggestionsForCounseling(obj)
        );

        // when we actually removed items then update the open cases list
        if (onlyOneWithoutProviders.length < cases.length) {
          // we return since there is no need to refresh until this triggers all the observers again (including last case)
          this.openCasesSubject.next(onlyOneWithoutProviders);
          return;
        }
      }
    });

    this.userSubject.subscribe((user) => {
      if (user) {
        // Get cases and set Org, Contract info
        this.refreshOrg(user.orgId); // The orgId of the contract associated with the chosenSiteLocation
        this.refreshParentOrg(user.orgId); // The orgId of the contract associated with the chosenSiteLocation
        this.refreshContract(user);

        if (user.ssoUser && !user.profileComplete) {
          this.appointmentSubject.next(null);
          this.openCasesSubject.next([]);
        }
      } else {
        this.appointmentSubject.next(null);
        this.openCasesSubject.next([]);
      }
    });

    this.tokenService.tokenChange().subscribe((token) => {
      if (token.isValid()) {
        this.refreshUser()
          .then(() => {
            this.refreshOpenCases();
          })
          .catch((err) => {
            throw err;
          });
      } else {
        this.userSubject.next(null);
      }
    });
  }
  /**
   * Navigates the user to registration screen when unauthenticated
   * Creates the case from the intake flow data and moves to the dashboard when authenticated
   */
  async registerOrCreateCase(
    itmsCaseData = null,
    redirect = true
  ): Promise<any> {
    let caseData;

    this.ssoCodeBlock = false;
    if (this.creatingCase) {
      return;
    }
    // get the intake data that we will try to create the case from
    if (itmsCaseData) {
      caseData = itmsCaseData;
    } else {
      caseData = <any>this.intakeFlowService.getFormData();

      if (caseData.riskAtIntake) {
        const user = await this.getUser().pipe(take(1)).toPromise();
        if (user) {
          this.userService
            .saveRiskHistory({
              userId: user.id,
              type: 'risk',
              value: caseData.riskAtIntake,
              ownerId: user.id,
              updatedBy: user.id,
            })
            .subscribe();
        }
      }
    }

    // when the user is authenticated (having a valid token) we try to create the case, then move to dashboard
    if (this.tokenStorage.get().isValid()) {
      // check to see if user profile completed
      return new Promise((resolveIt, rejectIt) => {
        try {
          this.userService
            .getSsoUser()
            .pipe(take(1))
            .subscribe((user) => {
              this.userSubject.next(user.data);
              if (
                !!user.data.profileComplete === false &&
                !!user.data.internalId === false
              ) {
                this.router.navigate(REGISTRATION_ROUTE); // user can register or login at the end
              } else {
                this.creatingCase = true;

                this.caseService
                  .createCase(<ModelCase>caseData)
                  .pipe(take(1))
                  .subscribe(
                    (newCase) => {
                      // Clean cases
                      upsertItem([newCase], this.openCasesSubject); // upserting item
                      this.validWorkLifeBenefits.then((value) => {
                        this.contentMgmtService.getAllRecommendedContent(
                          [newCase.id],
                          value
                        );
                      });
                      // 'Counseling Recommendation' GA
                      if (newCase.providers) {
                        this.analyticsService.trackRecommendations(
                          'Counseling Recommendation',
                          newCase.providers.length
                        );
                      }
                      const modeOfHelp = this.intakeFlowService.modeOfHelp;
                      this.intakeFlowService.resetFormData();

                      if (redirect) {
                        this.refreshUser()
                          .then(() => {
                            this.creatingCase = false;
                            // Navigate to dashboard
                            if (SELECT_COUNSELOR_MODES_OF_HELP.includes(modeOfHelp)) {
                              this.router.navigate([
                                `/pages/counselor-faceted-search/${newCase.id}`,
                              ]);
                            } else {
                              // Navigate to dashboard
                              this.router.navigate(DASHBOARD_ROUTE);
                            }
                          })
                          .catch((err) => {
                            this.creatingCase = false;
                            throw err;
                          });
                      }
                      this.creatingCase = false;
                      resolveIt(newCase);
                    },
                    (err) => {
                      this.creatingCase = false;
                      this.router.navigate(ERROR_ROUTE); // failed... maybe token expired, user can register or login
                    }
                  );
              }
            });
        } catch (error) {
          rejectIt(`Error during case creation: ${error}`);
        }
      });
    } else {
      this.router.navigate(REGISTRATION_ROUTE); // user can register or login at the end
      return;
    }
  }

  completeCreateCase() {
    if (this.creatingCase) return;
    // get the intake data that we will try to create the case from
    const caseData = <any>this.intakeFlowService.getFormData();

    // when the user is authenticated (having a valid token) we try to create the case, then move to dashboard
    if (
      this.tokenStorage.get().isValid() &&
      this.intakeFlowService.isFormValid()
    ) {
      this.creatingCase = true;

      this.caseService
        .createCase(<ModelCase>caseData)
        .pipe(take(1))
        .subscribe(
          (obj) => {
            upsertItem([obj], this.openCasesSubject); // upserting item
            this.validWorkLifeBenefits.then((value) => {
              this.contentMgmtService.getAllRecommendedContent([obj.id], value);
            });
            // 'Counseling Recommendation' GA
            if (obj.providers) {
              this.analyticsService.trackRecommendations(
                'Counseling Recommendation',
                obj.providers.length
              );
            }
            this.router.navigate(DASHBOARD_ROUTE);
            this.intakeFlowService.resetFormData();
            this.creatingCase = false;
          },
          (err) => {
            this.creatingCase = false;
            return err;
          }
        );
    }
  }

  refreshOpenCases() {
    const user = this.userSubject.getValue();
    if (!user || this.fetchingOpenCases) return;
    this.validWorkLifeBenefits.then((value) => {
      this.contentMgmtService.getAllRecommendedContent([], value);
    });
    this.fetchingOpenCases = true;

    const sub = this.caseService
      .findOpenCases()
      .pipe(retry(3))
      .subscribe(
        (data: ModelCase[]) => {
          this.fetchingOpenCases = false;
          this.openCasesSubject.next(
            data.sort(
              (a: any, b: any) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
          );
        },
        (err) => {
          this.fetchingOpenCases = false;
          this.openCasesSubject.error(err);
        }
      );
    this.getLoginStatus().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.fetchingOpenCases = false;
        sub.unsubscribe();
      }
    });
  }
  refreshOpenCasesOnly() {
    const user = this.userSubject.getValue();
    if (!user || this.fetchingOpenCases) return;
    this.fetchingOpenCases = true;

    const sub = this.caseService
      .findOpenCases()
      .pipe(retry(3))
      .subscribe(
        (data: ModelCase[]) => {
          this.fetchingOpenCases = false;
          this.openCasesSubject.next(
            data.sort(
              (a: any, b: any) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
          );
        },
        (err) => {
          this.fetchingOpenCases = false;
          this.openCasesSubject.error(err);
        }
      );
    this.getLoginStatus().subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.fetchingOpenCases = false;
        sub.unsubscribe();
      }
    });
  }

  getRemoteCases() {
    return this.caseService.findOpenCases();
  }

  refreshUser() {
    if (this.fetchingUser) return;
    this.fetchingUser = true;

    return this.userService
      .getAllUserData()
      .pipe(
        retryWhen(externalIdMissing),
        tap(
          (data) => {
            this.fetchingUser = false;
            this.userSubject.next(data.data);
          },
          (err) => {
            this.fetchingUser = false;
            this.userSubject.next(null);
            this.router.navigate(ERROR_ROUTE);
          }
        )
      )
      .toPromise();
  }

  refreshCounselors(): any {
    const model = this.openCasesSubject.getValue().filter((m) => m.modeOfHelp && m.modeOfHelp !== 'coaching');
    const exist = this.counselorsSubject
      .getValue()
      .map((provider) => provider.id);

    if (!model || !model.length) return;

    // collect all the ids of the providers we have not yet fetched
    const unseen = (id) => id && !exist.includes(id);
    // look for either the assigned provider or the list of suggestions
    const union = (data: ModelCase) => {
      let providers;
      if (data.internalCase && data.internalCase.authorizationId) {
        providers = [data.internalCase.providerId];
      } else {
        providers = data.providers;
      }
      return providers;
    };

    const ids = []
      .concat(...model.map((caseData) => union(caseData) || []))
      .filter(unseen);

    // when there is no counselor to fetch or we are already fetching... then just stop
    if (!ids.length || this.fetchingCounselors) return;

    this.fetchingCounselors = true;
    this.providerService
      .getCounselorsAPI(ids)
      .subscribe(
        (data: Provider[]) => {
          this.fetchingCounselors = false;
          upsertItem(data, this.counselorsSubject); // just insert the ones we got
        },
        (err) => {
          this.fetchingCounselors = false;
          this.counselorsSubject.error(err);
        }
      );
  }

  refreshOrg(orgId?: string): any {
    if (!orgId || this.fetchingOrg) return;

    this.fetchingOrg = true;
    this.orgService.getOrgId(orgId).subscribe(
      (data: Org) => {
        if ( data.userDefinedTextField10 ) {
          this.cookieService.set('BhCode', data.userDefinedTextField10);
        }
        this.fetchingOrg = false;
        this.orgSubject.next(data);
      },
      (err) => {
        this.fetchingOrg = false;
        this.orgSubject.next(null);
      }
    );
  }
  refreshParentOrg(orgId?: string): any {
    if (!orgId || this.fetchingParentOrg) return;

    this.fetchingParentOrg = true;
    this.orgService.getOrgId(orgId).subscribe(
      (data: Org) => {
        this.fetchingParentOrg = false;
        if ( !this.cookieService.get('BhCode') && data.userDefinedTextField10 ) {
          this.cookieService.set('BhCode', data.userDefinedTextField10);
        }
        if (data.parentOrg === '00000000-0000-0000-0000-000000000000') {
          this.cookieService.set('parentCompany', data.name);
          this.parentOrgSubject.next(data);
        } else {
          this.refreshParentOrg(data.parentOrg);
        }
      },
      (err) => {
        this.fetchingParentOrg = false;
        this.parentOrgSubject.next(null);
      }
    );
  }

  refreshContract(model: User): any {
    if (!model || !model.orgId || this.fetchingContract) return;

    // set employer information when login
    if (model.employerInformation) {
      const employerInfo = model.employerInformation;
      this.analyticsService.setAffiliation(employerInfo.companyAffiliation);
      this.analyticsService.setCompany(employerInfo.companyName);
      this.analyticsService.setLocation(employerInfo.companySiteLocation);
    }

    this.fetchingContract = true;
    this.contractService.getOrgContractById(model.orgId).subscribe(
      (data: Contract) => {
        this.fetchingContract = false;
        if (data) {
          data.validWorkLifeBenefits = data.validWorkLifeBenefits.filter(
            (benefits) => benefits.split(':')[0] !== 'Emergency Services'
          );
          this.contractSubject.next(data);
        } else {
          this.contractSubject.next(null);
        }
      },
      (err) => {
        this.fetchingContract = false;
        this.contractSubject.error(err);
      }
    );
  }

  getContractOfNearestParent(orgList, org) {
    const parent = org.parentOrg;
    if (parent === '00000000-0000-0000-0000-000000000000') {
      return orgList[org.id].contract;
    } else if (orgList[parent].contract) {
      return orgList[parent].contract;
    } else {
      const grandParentOrgId = orgList[parent].org.parentOrg;
      const grandParent = orgList[grandParentOrgId].org; // : orgList['parentOrg'].org;
      return this.getContractOfNearestParent(orgList, grandParent);
    }
  }

  assignToProvider(provider: Provider, fromModal = false): any {
    // this.selectedCase.internalCase.providerId = provider.internalId;
    this.caseService.getCaseById(this.selectedCase.id).subscribe((cases: any) => {
      if (!cases) {
        return;
      }
      this.selectedCase = cases;
      this.selectedCase.updateMode = AUTHORIZATION_UPDATE_MODE;
      // !!provider ? this.selectedCase.internalCase.providerId = provider.internalId : null;
      if (provider) {
        this.selectedCase.internalCase.providerId = provider.internalId;
      }
      this.updateCase(this.selectedCase, ASSIGN_TO_PROVIDER, fromModal);
    });
  }

  assignToCoach(provider: Provider, fromModal = false): any {
    this.selectedCase.internalCase.providerId = provider.internalId;
    this.selectedCase.updateMode = AUTHORIZATION_UPDATE_MODE;
    this.updateCase(this.selectedCase, ASSIGN_TO_COACH, fromModal);
  }

  assignToCallCenter(
    provider: Provider,
    callCenterScheduled: boolean,
    sessionNotes: string
  ): any {
    this.caseService.getCaseById(this.selectedCase.id).subscribe((cases: any) => {
      if (!cases) {
        return;
      }
      this.selectedCase = cases;
      this.selectedCase.internalCase.providerId = provider.internalId;
      this.selectedCase.isCallCenter = callCenterScheduled;
      this.selectedCase.updateMode = AUTHORIZATION_UPDATE_MODE;
      if (sessionNotes) {
        this.selectedCase.sessionNotes = sessionNotes;
      }
      this.updateCase(this.selectedCase, ASSIGN_TO_CALL_CENTER);
    });
  }

  switchProvider(
    reason,
    options: { zipCode?; radius?; counselingFor?; selectState? }
  ): any {
    this.caseService.getCaseById(this.selectedCase.id).subscribe((cases: any) => {
      if (!cases) {
        return;
      }
      this.selectedCase = cases;
      this.selectedCase.updateMode = SWITCH_COUNSELORS_UPDATE_MODE;
      if (
        this.selectedCase.modeOfHelp !== BH_MODE_OF_HELP &&
        this.selectedCase.providerId
      ) {
        this.selectedCase.providerId = '';
      }
      this.selectedCase.reasonForSwitch = reason;
      if (options.selectState) {
        this.selectedCase.selectState = options.selectState;
      } else {
        this.selectedCase.counselingFor.participants = options.counselingFor;
        this.selectedCase.counselingLocation = {
          selection: COUNSELING_LOCATION_IN_OFFICE,
          value: options.radius,
          zip: options.zipCode,
        };
      }
      this.updateCase(this.selectedCase, SWITCH_PROVIDER);
    });
  }

  switchToInPerson(reason, zipCode, radius, counselingFor): any {
    this.caseService.getCaseById(this.selectedCase.id).subscribe((cases: any) => {
      if (!cases) {
        return;
      }
      this.selectedCase = cases;
      this.selectedCase.updateMode = IN_PERSON_UPDATE_MODE;
      this.selectedCase.reasonForSwitch = reason;
      this.selectedCase.counselingFor.participants = counselingFor;
      this.selectedCase.counselingLocation = {
        selection: COUNSELING_LOCATION_IN_OFFICE,
        value: radius,
        zip: zipCode,
      };
      this.updateCase(this.selectedCase, SWITCH_TO_IN_PERSON);
    });
  }

  switchToPhysician(reason, zipCode, radius, selectedState): any {
    this.caseService.getCaseById(this.selectedCase.id).subscribe((cases: any) => {
      if (!cases) {
        return;
      }
      this.selectedCase = cases;
      this.selectedCase.updateMode = SWITCH_TO_PHYSICIAN_UPDATE_MODE;
      this.selectedCase.reasonForSwitch = reason;
      this.selectedCase.counselingLocation = {
        selection: COUNSELING_LOCATION_IN_OFFICE,
        value: radius || String(this.selectedCase.counselorPreference.radius) || '50',
        zip: zipCode || this.selectedCase.counselorPreference.zipCode,
      };
      this.selectedCase.selectState = selectedState;
      this.updateCase(this.selectedCase, SWITCH_TO_PHISICIAN);
    });
  }



  changeToOnlineTherapy(reason, selectState, counselingFor): any {
    this.caseService.getCaseById(this.selectedCase.id).subscribe((cases: any) => {
      if (!cases) {
        return;
      }
      this.selectedCase = cases;
      this.selectedCase.updateMode = ONLINE_UPDATE_MODE;
      this.selectedCase.selectState = selectState;
      this.selectedCase.counselingFor.participants = counselingFor;
      this.selectedCase.counselingLocation.selection =
        COUNSELING_LOCATION_IN_OFFICE;
      this.selectedCase.reasonForSwitch = reason;
      this.updateCase(this.selectedCase, SWITCH_TO_ONLINE);
    });
  }

  changeVideoToOnlineTherapy(reason, selectState) {
    this.caseService.getCaseById(this.selectedCase.id).subscribe((cases: any) => {
      if (!cases) {
        return;
      }
      this.selectedCase = cases;
      this.selectedCase.updateMode = ONLINE_UPDATE_MODE;
      this.selectedCase.reasonForSwitch = reason;
      this.selectedCase.selectState = selectState;
      this.updateCase(this.selectedCase, SWITCH_VID_TO_ONLINE);
    });
  }

  async shouldShowEmployeeNumberStep(companyCode: string) {
    const companyCodeRequiresEmployeeNumber =
      await this.companyCodeRequiresEmployeeNumber(companyCode);
    const user = await this.getUser().pipe(take(1)).toPromise();
    return !user.employeeNumber && companyCodeRequiresEmployeeNumber;
  }

  async companyCodeRequiresEmployeeNumber(companyCode: string) {
    return (
      companyCode.toLowerCase() === EMPLOYEE_NUMBER_COMPANY_CODE.toLowerCase()
    );
  }

  setLoginStatus(loginStatus: boolean) {
    this.loginStatusChangeSubject.next(loginStatus);
  }

  getPfStatus() {
    const authId = this.selectedCase.internalCase.authorizationId;
    return this.caseService.getPfStatus(authId);
  }

  shouldLimitCases(caseData = null): Observable<any> {
    let passedCaseData: any;
    if (!caseData) {
      passedCaseData = <any>this.intakeFlowService.getFormData();
    } else {
      passedCaseData = caseData;
    }

    const { currentContract: { id: contractId }, organization: { id: organizationId } } = passedCaseData;

    return this.caseService.getShouldLimit(contractId, organizationId);
  }

  updateCase(caseData, event, fromModal?) {
    this.spinUp(
      this.caseService
        .updateCaseById(caseData.id, caseData)
        .toPromise()
        .then((updated) => {
          this.analyticsService.trackEvent(event);
          upsertItem([updated], this.openCasesSubject); // upserting item
          this.refreshOpenCasesOnly();
          if (event === ASSIGN_TO_PROVIDER) {
            if (fromModal) {
              this.router.navigate(['/pages/dashboard']);
            } else {
              if (
                this.selectedCase.counselorPreference.modality !==
                'onsiteCounseling'
              ) {
                this.router.navigate(['/pages/dashboard/counselor-contact']);
              }
            }
          } else if (event === ASSIGN_TO_COACH) {
            fromModal
              ? this.router.navigate(['/pages/dashboard'])
              : this.router.navigate(['/pages/dashboard/counselor-contact']);
          } else if ([SWITCH_PROVIDER, SWITCH_TO_IN_PERSON, SWITCH_TO_PHISICIAN, SWITCH_TO_ONLINE].includes(event)) {
            this.router.navigate([`/pages/counselor-faceted-search/${caseData.id}`]);
          } else {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/pages/intake/counselor-selection/${this.selectedCase.id}']);
          }
      })
    );
    this.spinner.load();
  }
}
