/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./footer.component";
import * as i5 from "../../../@core/utils/analytics.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 42, "div", [["class", "w-100 footer p-0 py-lg-5 m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 41, "div", [["class", "row text-center w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-12 col-lg-3 mb-3 mb-lg-0 logo-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", ""], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "footer-img-responsive"], ["src", "/assets/images/logo/concern-one-color-logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 14, "div", [["class", "col-6 offset-1 col-md-3 offset-md-3 col-lg-3 offset-lg-0 links-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 13, "ul", [["class", "p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "footer-link"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.GaEvent("About Counseling") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["About Counseling"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "footer-link"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.GaEvent("Browse Library") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Browse Library"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["class", "footer-link"], ["href", "/?tab=my-care"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.GaEvent("My Care") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["My Care"])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "a", [["class", "footer-link"], ["routerLink", "/pages/contact-us"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.GaEvent("Contact Us") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵeld(21, 0, null, null, 15, "div", [["class", "col-4 offset-1 col-lg-3 offset-lg-0 links-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 14, "ul", [["class", "p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "a", [["class", "footer-link"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.GaEvent("HIPAA Privacy") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["HIPAA Privacy"])), (_l()(), i1.ɵeld(26, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "a", [["class", "footer-link"], ["routerLink", "/auth/privacy"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.GaEvent("Online Privacy") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Online Privacy"])), (_l()(), i1.ɵeld(30, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 2, "a", [["class", "footer-link"], ["routerLink", "/auth/terms"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 32).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.GaEvent("Terms of Use") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(32, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Terms of Use"])), (_l()(), i1.ɵeld(34, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 1, "a", [["class", "footer-link"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.GaEvent("Language Services") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Language Services"])), (_l()(), i1.ɵeld(37, 0, null, null, 5, "div", [["class", "col-12 col-lg-3 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 1, "span", [["class", "For-immediate-suppor"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" FOR IMMEDIATE SUPPORT CALL "])), (_l()(), i1.ɵeld(40, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 1, "a", [["class", "contact-number"], ["href", "tel:800-344-4222"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 800.344.4222 "]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 4, 0, currVal_2); var currVal_7 = "/pages/contact-us"; _ck(_v, 19, 0, currVal_7); var currVal_11 = "/auth/privacy"; _ck(_v, 28, 0, currVal_11); var currVal_14 = "/auth/terms"; _ck(_v, 32, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.env.concernHealth, "/about-counseling"); _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.env.concernHealth, "/resources"); _ck(_v, 12, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 19).target; var currVal_6 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵinlineInterpolate(1, "", _co.env.concernHealth, "/hipaa-privacy"); _ck(_v, 24, 0, currVal_8); var currVal_9 = i1.ɵnov(_v, 28).target; var currVal_10 = i1.ɵnov(_v, 28).href; _ck(_v, 27, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵnov(_v, 32).target; var currVal_13 = i1.ɵnov(_v, 32).href; _ck(_v, 31, 0, currVal_12, currVal_13); var currVal_15 = i1.ɵinlineInterpolate(1, "", _co.env.concernHealth, "/language-assistance-program"); _ck(_v, 35, 0, currVal_15); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.FooterComponent, [i5.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("ngx-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
