import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { userRoutes } from '../../../../@core/data/api/constants';

@Injectable()
export class VaraAuthService {
    route: string;
    constructor(private _http: HttpClient) {
        this.route = `${environment.basePath}${userRoutes.postUserChangePassword}`; // refactor this, modularize v1 route , consider v2, v3 etc
     }
     public changeUserPassword(oldPass: string, newPass: string): Observable<any> {
        return (this._http.post(this.route, {
            oldPassword: oldPass,
            newPassword: newPass,
        }));
     }

}
