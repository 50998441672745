/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verify-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../auth-block/auth-block.component.ngfactory";
import * as i3 from "../auth-block/auth-block.component";
import * as i4 from "../../reusables/intake-header/intake-header.component.ngfactory";
import * as i5 from "../../reusables/intake-header/intake-header.component";
import * as i6 from "../../reusables/instructions-tooltip/instructions-tooltip.component.ngfactory";
import * as i7 from "../../reusables/instructions-tooltip/instructions-tooltip.component";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "./verify-email.component";
import * as i11 from "../../../../@core/data/shared-state.service";
import * as i12 from "ngx-toastr";
import * as i13 from "../../../../@core/data/api/send-email.service";
var styles_DxVerifyEmailComponent = [i0.styles];
var RenderType_DxVerifyEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DxVerifyEmailComponent, data: {} });
export { RenderType_DxVerifyEmailComponent as RenderType_DxVerifyEmailComponent };
export function View_DxVerifyEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 24, "div", [["class", "flex-centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 23, "nb-auth-block", [["class", "col-12"]], null, null, null, i2.View_DxAuthBlockComponent_0, i2.RenderType_DxAuthBlockComponent)), i1.ɵdid(3, 49152, null, 0, i3.DxAuthBlockComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "ngx-intake-header", [["styleClasses", "mt-0"], ["title", "Hi, thank you for registering!"]], null, null, null, i4.View_IntakeHeaderComponent_0, i4.RenderType_IntakeHeaderComponent)), i1.ɵdid(5, 49152, null, 0, i5.IntakeHeaderComponent, [], { title: [0, "title"], styleClasses: [1, "styleClasses"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 11, "div", [["class", "row justify-content-center no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "form-text sub-title col-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" To access your personal dashboard, you\u2019ll have to verify your email address. An email has been sent to "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" with instructions to verify that you are the owner. "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-outline-primary col-12 col-md-7"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resendVerificationEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Resend Verification "])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "form-text sub-title col-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please verify, then log in. "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "ngx-instructions-tooltip", [], null, null, null, i6.View_InstructionsTooltipComponent_0, i6.RenderType_InstructionsTooltipComponent)), i1.ɵdid(17, 114688, null, 0, i7.InstructionsTooltipComponent, [], { popOverMessage: [0, "popOverMessage"], attachToCorner: [1, "attachToCorner"] }, null), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "div", [["class", "row justify-content-center no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-primary col-12 col-md-6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.router.navigateByUrl("/auth/login") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Login "])), (_l()(), i1.ɵeld(21, 0, null, 0, 3, "div", [["class", "row justify-content-center no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "a", [["class", "link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" ALREADY REGISTERED? CLICK HERE "])), (_l()(), i1.ɵeld(25, 0, null, 0, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Hi, thank you for registering!"; var currVal_1 = "mt-0"; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = _co.tooltipMessage; var currVal_4 = false; _ck(_v, 17, 0, currVal_3, currVal_4); var currVal_7 = "/auth/login"; _ck(_v, 23, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.email ? _co.email : "your email"); _ck(_v, 10, 0, currVal_2); var currVal_5 = i1.ɵnov(_v, 23).target; var currVal_6 = i1.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_5, currVal_6); }); }
export function View_DxVerifyEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nb-verify-email-error-page", [], null, null, null, View_DxVerifyEmailComponent_0, RenderType_DxVerifyEmailComponent)), i1.ɵdid(1, 114688, null, 0, i10.DxVerifyEmailComponent, [i8.Router, i11.SharedStateService, i12.ToastrService, i13.SendEmailService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DxVerifyEmailComponentNgFactory = i1.ɵccf("nb-verify-email-error-page", i10.DxVerifyEmailComponent, View_DxVerifyEmailComponent_Host_0, {}, {}, []);
export { DxVerifyEmailComponentNgFactory as DxVerifyEmailComponentNgFactory };
