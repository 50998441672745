import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardService } from '../../@core/utils/auth-guard.service';
import { NpsSurveyComponent } from './nps-survey.component';
import { RecommendServicesComponent } from './recommend-services/recommend-services.component';
import { MyCounselorComponent } from './my-counselor/my-counselor.component';
import { ScheduledAppointmentsComponent } from './scheduled-appointments/scheduled-appointments.component';
import { ConcernedWelfareComponent } from './concerned-welfare/concerned-welfare.component';
import { FeedbackTextboxComponent } from './feedback-textbox/feedback-textbox.component';
import { ThankyouSurveyComponent } from './thankyou-survey/thankyou-survey.component';
import { NpsFlowGuardService } from '../../@core/utils/nps-survey-guard.service';


const routes: Routes = [{
  path: '',
  component: NpsSurveyComponent,
  children: [
    {
      path: 'recommend-service',
      component: RecommendServicesComponent,
      canActivate: [NpsFlowGuardService],
    },
    {
      path: 'my-counselor',
      component: MyCounselorComponent,
      canActivate: [NpsFlowGuardService],
    },
    {
      path: 'scheduled-appointments',
      component: ScheduledAppointmentsComponent,
      canActivate: [NpsFlowGuardService],
    },
    {
      path: 'concerned-welfare',
      component: ConcernedWelfareComponent,
      canActivate: [NpsFlowGuardService],
    },
    {
      path: 'suggestion-box',
      component: FeedbackTextboxComponent,
      canActivate: [NpsFlowGuardService],
    },
    {
      path: 'nps-thankyou',
      component: ThankyouSurveyComponent,
      canActivate: [NpsFlowGuardService],
    },
  ],
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService, NpsFlowGuardService],
})
export class NpsSurveyRoutingModule { }
