import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ContractService, OrgService, ConcernOrg } from '../../../@core/data';
import { CookieService } from 'ngx-cookie-service';
import { CaseManagementService } from '../../../@core/data/caseManagement.service';
import { environment } from '../../../../environments/environment';

const GET_ORG_AFFILIATION_ENDPOINT = '/run/listSingleConfig?id=clientTypes';

@Injectable()
export class EmployerInformationService {
  constructor(
    private http: HttpClient,
    private contractService: ContractService,
    private caseMngt: CaseManagementService,
    private cookieService: CookieService,
    private orgService: OrgService) { }

  verifyOrg(code: string): Observable<ConcernOrg> {
    return this.orgService.lookupCode(code).pipe(catchError(this.handleVerifyError));
  }

  verifyOrgByName(name: string): Observable<any> {
    return this.orgService.getOrgByName(name);
  }

  getAffiliation(): Observable<any> {
    return this.http.post(`${environment.basePath}${GET_ORG_AFFILIATION_ENDPOINT}`, '').pipe(catchError(this.handleError));
  }

  getContract(companyName: string): Observable<any> {
    return this.contractService.getOrgContractByName(companyName)
      .pipe(tap(contracts => {
        const currentContract = contracts.contract;
        if (currentContract) {
          if (!currentContract.digitalPlatformEnabled) {
            this.cookieService.delete('companyName');
            this.cookieService.delete('parentCompany');
            this.caseMngt.currentContract = null;
          } else {
            this.caseMngt.currentContract = currentContract;
            this.cookieService.set('companyName', companyName);
            this.cookieService.set('parentCompany', contracts.org.name);
          }
          return currentContract;
        }
      }), catchError(this.handleError));
  }

  getContractByOrgId(orgId: string): Observable<any> {
    return this.contractService.getOrgContractByOrgId(orgId)
      .pipe(tap(contracts => {
        const currentContract = contracts.contract;
        if (currentContract) {
          if (!currentContract.digitalPlatformEnabled) {
            this.cookieService.delete('companyName');
            this.cookieService.delete('parentCompany');
            this.cookieService.delete('companyId');
            this.caseMngt.currentContract = null;
          } else {
            this.caseMngt.currentContract = currentContract;
            this.cookieService.set('companyId', orgId);
            this.cookieService.set('parentCompany', contracts.org.name);
            this.cookieService.set('companyName', contracts.org.name);
          }
          return currentContract;
        }
      }), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  private handleVerifyError(error: HttpErrorResponse) {
    switch (error.status) {
      case 404:
        return throwError('Unable to find company');
      default:
        return throwError('Something bad happened; please try again later.');
    }
  };
}
