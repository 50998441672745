import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngxSanitize',
})
export class SanitizePipe implements PipeTransform {

  transform(value: string) {
    if (!value) return;
    const tempElement = document.createElement('div')
    tempElement.innerHTML = value
    return tempElement.innerText
}

}
