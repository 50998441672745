<script type = "text/javascript" src = 'https://home-c62.nice-incontact.com/inContact/ChatClient/js/embed.min.js'></script>

<script type ="text/javascript">

icPatronChat.init({serverHost:'https://home-c62.nice-incontact.com',bus_no:4605795,poc:'d22041e1-7f46-49f2-9aee-37aa5db75304',params:['FirstName','Last Name','first.last@company.com',555-555-5555]});

</script>
<div class="widget-container">
    <div class="clear-float"></div>
  <div class="red widget d-none" (click)="helpOptionClicked()" [ngClass]="{'open': helpOpen}">
    <div class="button">
      <i class="fa fa-phone"></i>
      <p class="help-tab">HELP</p>
    </div>
    <div class="help-content">
      <i class="fa fa-user red-user"></i>
      <p>Need immediate assistance?</p>
      <p>Please call:</p>
      <p class="contact-number"><a href="tel:800-344-4222">800-344-4222</a></p>
    </div>
  </div>
  <div class="clear-float"></div>
  <div class="" (click)="chatOptionClicked()" [ngClass]="{'open': chatOpen}">
    <div class="">
      <img src="/assets/images/icons/chat-purple.svg" />
    </div>
    <div class="chat-content">
        <iframe class="chat-frame" allow="microphone;"
        src="https://home-c62.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=d22041e1-7f46-49f2-9aee-37aa5db75304&bu=4605795"></iframe>
    </div>
  </div>
  <div class="clear-float"></div>
</div>
