var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { ConfirmationModal } from '../modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';
import { ONLINE_SWITCH_MODAL_HEADING, FIRST_RESP_MODE_OF_HELP, COUNSELING_FOR_INDIVIDUAL, FR_MILES, IN_PERSON_MILES, LOCAL_SERVICE, PHYSICIAN_MODE_OF_HELP } from '../../../../../@core/utils/constants';
import { STATES } from '../../../../../@core/utils/intake-flow.service';
var OnlineSwitchComponent = /** @class */ (function () {
    function OnlineSwitchComponent(activeModal) {
        this.activeModal = activeModal;
        this._modal = { context: {}, modalHeader: '', options: false };
        this.user = {};
        this.souAgreed = false;
        this.modality = '';
        this.message = '';
        this.submitted = false;
        this.zipradii = [];
        this.zipCode = '';
        this.radius = '';
        this.counselingFor = '';
        this.reasonForSwitch = '';
        this.states = STATES;
        this.selectedState = '';
        this.switchFrom = '';
    }
    Object.defineProperty(OnlineSwitchComponent.prototype, "modal", {
        set: function (value) {
            if (Object.keys(value).length > 0) {
                this.message = "";
                this.heading = ONLINE_SWITCH_MODAL_HEADING;
                this.switchFrom = value.context.switchFrom;
            }
        },
        enumerable: true,
        configurable: true
    });
    OnlineSwitchComponent.prototype.ngOnInit = function () {
        this.getLocalString();
        this.reasons = environment.reasons;
        if (this.modeOfHelp === FIRST_RESP_MODE_OF_HELP) {
            this.counselingFor = COUNSELING_FOR_INDIVIDUAL;
            this.zipradii = FR_MILES;
        }
        else {
            this.zipradii = IN_PERSON_MILES;
        }
        this.stateList = Object.values(this.states);
        this.stateList.shift();
    };
    OnlineSwitchComponent.prototype.getLocalString = function () {
        this.LOCAL_STRING = LOCAL_SERVICE;
    };
    OnlineSwitchComponent.prototype.isValidZipCode = function () {
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.zipCode);
    };
    OnlineSwitchComponent.prototype.closeModal = function () {
        this.activeModal.close({ answer: ConfirmationModal.No, user: this.user });
    };
    OnlineSwitchComponent.prototype.getUSAStateCode = function (stateName) {
        var _this = this;
        return Object.keys(this.states).find(function (key) { return _this.states[key] === stateName; });
    };
    OnlineSwitchComponent.prototype.getStates = function () {
        return this.stateList;
    };
    OnlineSwitchComponent.prototype.accept = function () {
        var modalForm = {};
        switch (this.modeOfHelp) {
            case PHYSICIAN_MODE_OF_HELP:
                modalForm['selectState'] = [this.getUSAStateCode(this.selectedState), this.selectedState];
                break;
            default:
                modalForm['zipCode'] = this.zipCode;
                modalForm['radius'] = this.radius;
                modalForm['counselingFor'] = this.counselingFor;
                break;
        }
        this.activeModal.close(__assign({ answer: ConfirmationModal.Yes, user: this.user, reasonForSwitch: this.reasonForSwitch }, modalForm));
    };
    OnlineSwitchComponent.prototype.completeRequest = function () {
        if (this.submitted)
            return;
        this.submitted = true;
        this.accept();
    };
    return OnlineSwitchComponent;
}());
export { OnlineSwitchComponent };
