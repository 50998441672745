// Survey Monkey

export function show(popUpName) {

  // var UnregisteredPopUp = 'tRaiETqnLgj758hTBazgd6nPm34zncY2WrvOcAPc_2B_2BtaEcNBWiAcS7v1347sz9Hq';
  // var UnregisteredPopUp = 'tRaiETqnLgj758hTBazgdxH9NlI1a7W911VCIbYgCRV3ZmKmgip9FpwjsV1AqXcs';

  var RegisteredPopUpUniversal = 'tRaiETqnLgj758hTBazgdxPVylRSsvhAlIroXN1gOnpmjw5PtgWGaaPR839C7B6X';

  var ElCaminoHospital = 'tRaiETqnLgj758hTBazgd2u7ZIslNig9prSIcbqxZLdCNOP0HOQ1mGhWhDxpoHc_2F';

  var PixarAnimationStudios = 'tRaiETqnLgj758hTBazgd6nPm34zncY2WrvOcAPc_2B_2BsuXmuz9HdxjbDiTGoy9CY3';

  var NetflixCorporate = 'tRaiETqnLgj758hTBazgd6nPm34zncY2WrvOcAPc_2B_2Bv3IHiX_2BzrkJZAtZAylZxhU';

  var popUps = {
    // unregisteredUser: UnregisteredPopUp,
    registeredUser: RegisteredPopUpUniversal,
    ElCaminoHospital: ElCaminoHospital,
    PixarAnimationStudios: PixarAnimationStudios,
    NetflixCorporate: NetflixCorporate
  };
  (function(t,e,s,o){var n,a,c;t.SMCX=t.SMCX||[],e.getElementById(o)||(n=e.getElementsByTagName(s),a=n[n.length-1],c=e.createElement(s),c.type="text/javascript",c.async=!0,c.id=o,c.src=["https:"===location.protocol?"https://":"http://",'widget.surveymonkey.com/collect/website/js/'+popUps[popUpName]+'.js'].join(""),a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk")

}
