import { throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { isEmpty, get } from 'lodash-es';
import { SurveyDataService, PRE, POST } from '../../utils/survey-data.service';
import { CaseManagementService } from '../caseManagement.service';
import { Configuration } from '../configuration';
import { emotionalSurveyRoutes } from './constants';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../utils/survey-data.service";
import * as i3 from "../caseManagement.service";
import * as i4 from "../variables";
import * as i5 from "../configuration";
var MOOD_SURVEY_URL = '/data/class/moodSurvey';
var ANXIETY_SURVEY_URL = '/data/class/anxietySurvey';
var STRESS_SURVEY_URL = '/data/class/stressSurvey';
var QOL_SURVEY_URL = '/data/class/qolSurvey';
var WOS_SURVEY_URL = '/data/class/wosSurvey';
var UPDATE_USER_SURVEY_URL = '/run/updateUserSurvey';
// const LIMIT_LAST_THREE = '?filter[limit]=3&filter[order]=createdAt DESC';
var LIMIT_LAST_THREE = '?limit=3&order=-1';
var NPS_SURVEY_URL = '/data/class/npsSurvey';
var WORKLIFE_NPS_SURVEY = '/data/class/worklifeNpsSurvey';
var DAY = 1000 * 60 * 60 * 24;
var MIN_DAYS_TAG = 30; // follow up surveys during first 16 days cannot be logged as post
export function mapDaysToSurveyPeriod(actualDays) {
    // slide the scale down by 16 days so that day 16 to 46 are in period 30, 47 to 76 are in period 60 etc
    var days = actualDays - MIN_DAYS_TAG;
    var rangePeriod = Math.floor(days / 30) + 1; // period number starting at 1
    return Math.min(30 * rangePeriod, 120); // 30 day periods up to 120 days...
}
var SurveyService = /** @class */ (function () {
    function SurveyService(httpClient, activeData, userService, basePath, configuration) {
        var _this = this;
        this.httpClient = httpClient;
        this.activeData = activeData;
        this.userService = userService;
        this.basePath = environment.basePath;
        this.configuration = new Configuration();
        this.hasMoodResult = false;
        this.hasEmotionalData = false;
        this.hasAnxietyResult = false;
        this.hasStressResult = false;
        this.hasQolResult = false;
        // get the survey configs so we can calculate 30, 60, 90 day offsets on survey  results
        userService.getUser().subscribe(function (user) { return _this.surveyConfigs = get(user || {}, 'surveys', {}); });
        userService.getUser().subscribe(function (user) {
            _this.user = user;
        });
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    SurveyService.prototype.setStageTypeBasedOnExistingConfiguration = function (surveyData, lastSurveyData, anchorDateField) {
        var prePostType = get(lastSurveyData, 'config.lastTaken', null) ? POST : PRE;
        var anchorDate = get(this.surveyConfigs, anchorDateField, null);
        if (prePostType === PRE || !anchorDate) {
            surveyData.config.period = 0;
        }
        else {
            var diff = new Date(surveyData.config.lastTaken).getTime() - new Date(anchorDate).getTime();
            var actualDays = Math.floor(diff / DAY);
            // do not tag record with 'type' PRE/POST or 'period' 30/60/90/90 when less than 16 days after anchor date
            if (actualDays < MIN_DAYS_TAG) {
                prePostType = PRE;
            }
            surveyData.config.period = mapDaysToSurveyPeriod(actualDays);
        }
        surveyData.config.type = prePostType;
    };
    SurveyService.prototype.updateNpsSurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeNpsSurveyData, 'mood.date');
        return this.httpClient.post("" + this.basePath + NPS_SURVEY_URL, surveyData, this.user)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.updateEmotionalSurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeEmotionalSurveyData, 'mood.date');
        return this.httpClient.post("" + this.basePath + emotionalSurveyRoutes.emotionalSurvey, surveyData, this.user)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.createEmotionalSurvey = function (body) {
        return this.httpClient.post("" + this.basePath + emotionalSurveyRoutes.eapxEmotionalSurvey, body);
    };
    SurveyService.prototype.createNpsSurvey = function (body) {
        return this.httpClient.post(this.basePath + "/run/nps-survey", body);
    };
    SurveyService.prototype.createWorklifeNpsSurvey = function (body) {
        return this.httpClient.post(this.basePath + "/run/worklifeNpsSurvey", body);
    };
    SurveyService.prototype.updateWorklifeNpsSurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeWorklifeNpsSurveyData, 'mood.date');
        return this.httpClient.post("" + this.basePath + WORKLIFE_NPS_SURVEY, surveyData, this.user)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.updateIntakeSurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeIntakeSurveyData, 'mood.date');
        return this.httpClient.post("" + this.basePath + emotionalSurveyRoutes.emotionalSurvey, surveyData, this.user)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.updateMoodSurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeMoodSurveyData, 'mood.date');
        this.surveyTrendData = this.createSurveyTrendData(surveyData, this.activeData.activeMoodSurveyData, this.user);
        return this.httpClient.post("" + this.basePath + MOOD_SURVEY_URL, surveyData)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.getMoodSurvey = function () {
        var _this = this;
        var track = function (result) {
            return _this.hasMoodResult = result && result.result.length > 0 && !isEmpty(result.result[0].data);
        };
        return this.httpClient.get("" + this.basePath + MOOD_SURVEY_URL + LIMIT_LAST_THREE, {})
            .pipe(map(function (data) {
            // rewversed for the chart purposes
            data.result.reverse();
            return data;
        }), catchError(this.handleError), tap(track.bind(this)));
    };
    SurveyService.prototype.getEmotionalSurvey = function () {
        var _this = this;
        var track = function (result) {
            return _this.hasEmotionalData = result && result.result.length > 0 && !isEmpty(result.result[0].data);
        };
        return this.httpClient.get("" + this.basePath + emotionalSurveyRoutes.emotionalSurvey + LIMIT_LAST_THREE, {})
            .pipe(map(function (data) {
            // rewversed for the chart purposes
            data.result.reverse();
            return data;
        }), catchError(this.handleError), tap(track.bind(this)));
    };
    SurveyService.prototype.getAnxietySurvey = function () {
        var _this = this;
        var track = function (result) {
            return _this.hasAnxietyResult = result && result.result.length > 0 && !isEmpty(result.result[0].data);
        };
        return this.httpClient.get("" + this.basePath + ANXIETY_SURVEY_URL + LIMIT_LAST_THREE, {})
            .pipe(map(function (data) {
            // rewversed for the chart purposes
            data.result.reverse();
            return data;
        }), catchError(this.handleError), tap(track.bind(this)));
    };
    SurveyService.prototype.updateAnxietySurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeAnxietySurveyData, 'anxiety.date');
        this.surveyTrendData = this.createSurveyTrendData(surveyData, this.activeData.activeAnxietySurveyData, this.user);
        return this.httpClient.post("" + this.basePath + ANXIETY_SURVEY_URL, surveyData)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.getStressSurvey = function () {
        var _this = this;
        var track = function (result) {
            return _this.hasStressResult = result && result.result.length > 0 && !isEmpty(result.result[0].data);
        };
        return this.httpClient.get("" + this.basePath + STRESS_SURVEY_URL + LIMIT_LAST_THREE, {})
            .pipe(map(function (data) {
            // rewversed for the chart purposes
            data.result.reverse();
            return data;
        }), catchError(this.handleError), tap(track.bind(this)));
    };
    SurveyService.prototype.updateStressSurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeStressSurveyData, 'stress.date');
        this.surveyTrendData = this.createSurveyTrendData(surveyData, this.activeData.activeStressSurveyData, this.user);
        return this.httpClient.post("" + this.basePath + STRESS_SURVEY_URL, surveyData)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.getQualitySurvey = function () {
        var _this = this;
        var track = function (result) {
            return _this.hasQolResult = result && result.result.length > 0 && !isEmpty(result.result[0].data);
        };
        return this.httpClient.get("" + this.basePath + QOL_SURVEY_URL + LIMIT_LAST_THREE, {})
            .pipe(map(function (data) {
            // reversed for the chart purposes
            data.result.reverse();
            return data;
        }), catchError(this.handleError), tap(track.bind(this)));
    };
    SurveyService.prototype.updateQualitySurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeQualitySurveyData, 'qol.date');
        this.surveyTrendData = this.createQolSurveyTrendData(surveyData, this.activeData.activeQualitySurveyData, this.user);
        return this.httpClient.post("" + this.basePath + QOL_SURVEY_URL, surveyData)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.getWosSurvey = function () {
        return this.httpClient.get("" + this.basePath + WOS_SURVEY_URL, {})
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.updateWosSurvey = function (surveyData) {
        this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeWosSurveyData, 'wos.date');
        return this.httpClient.post("" + this.basePath + WOS_SURVEY_URL, surveyData)
            .pipe(catchError(this.handleError));
    };
    SurveyService.prototype.updateUserSurvey = function (riskLevel, type) {
        var _this = this;
        var trendData = this.surveyTrendData;
        return this.httpClient.post("" + this.basePath + UPDATE_USER_SURVEY_URL, { riskLevel: riskLevel, type: type, trendData: trendData })
            .pipe(tap(function (x) { return _this.userService.refreshUser().then(); }), catchError(this.handleError));
    };
    SurveyService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    ;
    SurveyService.prototype.createSurveyTrendData = function (surveyData, lastSurveyData, user) {
        var lastScore = 0;
        var createdAt = null;
        if (lastSurveyData.stage && lastSurveyData.stages.length !== 0) {
            lastScore = lastSurveyData.stages[lastSurveyData.stages.length - 1].score;
            createdAt = lastSurveyData.createdAt;
        }
        else {
            createdAt = surveyData.config.lastTaken;
        }
        var surveyTrendData = {
            'surveyName': surveyData.config.name,
            'client': user.internalId,
            'organization': user.orgId,
            'firstTaken': createdAt,
            'lastTaken': surveyData.config.lastTaken,
            'surveyType': surveyData.config.type,
            'periodLength': surveyData.config.period,
            'surveyScore': surveyData.data.user_score,
            'changeFromLastPeriod': surveyData.data.user_score - lastScore,
            'changeFromInitial': 0 + surveyData.data.user_score,
        };
        return surveyTrendData;
    };
    SurveyService.prototype.createQolSurveyTrendData = function (surveyData, lastSurveyData, user) {
        var surveyTrendData = [];
        var createdAt = null;
        var lastScore = 0;
        if (lastSurveyData.stage && lastSurveyData.stages.length !== 0) {
            lastScore = lastSurveyData.stages[lastSurveyData.stages.length - 1].score;
            createdAt = lastSurveyData.createdAt;
        }
        else {
            createdAt = surveyData.config.lastTaken;
        }
        for (var property in surveyData.data) {
            if (property) {
                var previousScore = 0;
                if (lastScore === 0) {
                    previousScore = 0;
                }
                else {
                    previousScore = lastScore[property];
                }
                var data = {
                    'surveyName': surveyData.config.name + "-" + property,
                    'client': user.internalId,
                    'organization': user.orgId,
                    'firstTaken': createdAt,
                    'lastTaken': surveyData.config.lastTaken,
                    'surveyType': surveyData.config.type,
                    'periodLength': surveyData.config.period,
                    'surveyScore': surveyData.data[property],
                    'changeFromLastPeriod': surveyData.data[property] - previousScore,
                    'changeFromInitial': 0 + surveyData.data[property],
                };
                surveyTrendData.push(data);
            }
        }
        return surveyTrendData;
    };
    SurveyService.ngInjectableDef = i0.defineInjectable({ factory: function SurveyService_Factory() { return new SurveyService(i0.inject(i1.HttpClient), i0.inject(i2.SurveyDataService), i0.inject(i3.CaseManagementService), i0.inject(i4.BASE_PATH, 8), i0.inject(i5.Configuration, 8)); }, token: SurveyService, providedIn: "root" });
    return SurveyService;
}());
export { SurveyService };
