<ng-container *ngIf="addressRequired; then addressInput; else sou">
  </ng-container>
 
 <ng-template #addressInput>
     <ng-container *ngIf="suggestedAddresses.length === 0; then addressForm; else chooseAddress"></ng-container>
 </ng-template>
 
 <ng-template #addressForm>
   <div class="modal-header text-center">
     <span class="lead text-center">
       <p>The requested service requires a primary address.</p>
     </span>
     <button class="close" aria-label="Close" (click)="closeModal()">
       <span aria-hidden="true">&times;</span>
     </button>
   </div>
 
   <form class="col-md-8 col-lg-8 col-sm-12 mx-auto" #form="ngForm">
     <div class="modal-content">
       <div class="text-center message-container">
 
         <ngx-request-errors [errors]="errors"></ngx-request-errors>
         <div *ngIf="messages && messages.length > 0" class="alert alert-success" role="alert">
           <div><strong>Hooray!</strong></div>
           <div *ngFor="let message of messages">{{ message }}</div>
         </div>
 
         <ngx-address [address]="address"></ngx-address>
 
       </div>
     </div>
 
     <div class="modal-footer text-center">
       <div class="container">
         <div class="row justify-content-center">
           <div class="col-6">
             <button class="btn btn-lg btn-primary" (click)="closeModal()">Cancel</button>
           </div>
           <div class="col-6">
             <button [disabled]="form.invalid" class="btn btn-lg btn-primary" (click)="validateUserAddress()">
               {{ souRequired ? 'Next': 'Continue'}}
             </button>
           </div>
         </div>
       </div>
     </div>
   </form>
 
 </ng-template>
 
 <ng-template #sou>
   <div class="modal-header text-center mx-auto">
     <div class="container">
 
       <div class="row">
         <div class="col-12">
           <button class="close" aria-label="Close" (click)="closeModal()">
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
       </div>
 
       <div class="row col-12">
         <span class="lead title text-center">
           <h3 style="color: #4dacbf; font-weight: bold;">You're Almost There</h3>
         </span>
       </div>
 
       <div class="row">
         <div class="col-12">
           <p class="sou-subtitle">Before you book your appointment, please agree to the following Statement of
             Understanding</p>
         </div>
       </div>
 
     </div>
   </div>
 
   <div class="modal-content">
     <div class="m-0 p-3" style="overflow-y: scroll; height:400px;">
       <ngx-sou [(agreeToSou)]="agreeToSou"></ngx-sou>
     </div>
   </div>
 
   <div class="modal-footer text-center">
     <div class="container">
       <div class="row justify-content-center">
         <div class="col-6">
           <button class="btn btn-lg btn-primary text-center" (click)="closeModal()">Cancel</button>
         </div>
         <div class="col-6">
           <button [disabled]="!agreeToSou" class="btn btn-lg btn-primary" (click)="accept()">Continue</button>
         </div>
       </div>
     </div>
   </div>
 </ng-template>
 
 <ng-template #chooseAddress>
   <div class="modal-header text-center">
     <span class="lead text-center">
       <p>Review your Address</p>
     </span>
     <button class="close" aria-label="Close" (click)="closeModal()">
       <span aria-hidden="true">&times;</span>
     </button>
   </div>
 
   <form class="col-md-8 col-lg-8 col-sm-12 mx-auto" #form="ngForm">
 
     <div class="modal-content">
       <div class="text-center message-container">
           <ngx-address-validation (addressChange)="userAddress = $event" [original] = "formattedOriginalAddress" [suggested] = "suggestedAddresses"></ngx-address-validation>
       </div>
     </div>
 
     <div class="modal-footer text-center">
       <div class="container">
         <div class="row justify-content-center">
           <div class="col-6">
             <button class="btn btn-lg btn-primary" (click)="closeModal()">Cancel</button>
           </div>
           <div class="col-6">
             <button [disabled]="form.invalid" class="btn btn-lg btn-primary" (click)="validateSavedAddress()">
               {{ souRequired ? 'Next': 'Continue'}}
             </button>
           </div>
         </div>
       </div>
     </div>
 
   </form>
 </ng-template>
 