/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./emotional-navigation-buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./emotional-navigation-buttons.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../../@core/utils/emotional-wellbeing-survey.service";
var styles_EmotionalNavigationButtonsComponent = [i0.styles];
var RenderType_EmotionalNavigationButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmotionalNavigationButtonsComponent, data: {} });
export { RenderType_EmotionalNavigationButtonsComponent as RenderType_EmotionalNavigationButtonsComponent };
function View_EmotionalNavigationButtonsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "nav-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "Go-Back"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["GO BACK"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startOver() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "Start-Over"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["START OVER"]))], null, null); }
export function View_EmotionalNavigationButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmotionalNavigationButtonsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.backOnly; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EmotionalNavigationButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-emotional-navigation-buttons", [], null, null, null, View_EmotionalNavigationButtonsComponent_0, RenderType_EmotionalNavigationButtonsComponent)), i1.ɵdid(1, 114688, null, 0, i3.EmotionalNavigationButtonsComponent, [i4.Router, i5.EmotionalWellbeingSurveyService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmotionalNavigationButtonsComponentNgFactory = i1.ɵccf("ngx-emotional-navigation-buttons", i3.EmotionalNavigationButtonsComponent, View_EmotionalNavigationButtonsComponent_Host_0, { backOnly: "backOnly" }, {}, []);
export { EmotionalNavigationButtonsComponentNgFactory as EmotionalNavigationButtonsComponentNgFactory };
