import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-screen-intro',
  templateUrl: './screen-intro.component.html',
  styleUrls: ['./screen-intro.component.scss'],
})
export class ScreenIntroComponent implements OnInit {
  @Input() text: string;
  constructor() { }

  ngOnInit() {
  }

}
