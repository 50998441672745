import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NbTokenStorage } from '@nebular/auth';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class SsoAuthGuardService implements CanActivate {
  constructor(public router: Router, public tokenStorage: NbTokenStorage, private cookieService: CookieService) { }
  canActivate(): boolean {
    return this.cookieService.get('ssoUser') !== 'true';
  }
}
@Injectable()
export class OnlySsoAuthGuardService implements CanActivate {
  constructor(public router: Router, public tokenStorage: NbTokenStorage, private cookieService: CookieService) { }
  canActivate(): boolean {
    return this.cookieService.get('ssoUser') === 'true';
  }
}
