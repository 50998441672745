<div id="cxHeader" class="ncr ncr-luma-local">
  <div id="cxMask" style="position: fixed; top: 0; left: 0; background-color: white; z-index: 2147483647;">
  </div>
  <div class="ncr-navocat-web-wrapper">
    <div class="ncr-bootstrap-styles ncr-top-navs ncr-luma ncr-eluma"><!-- banner -->
      <div class="ncr-banner" style1="display: none" *ngIf="showBanner">
        {{bannerMessage}}
        <!--div>The BetterHelp website is going through a maintenance and may not be accessible. If so, please choose from our other recommendations or call our Access Center&nbsp;<a style='color:white;font-size: 15px;' href='tel:(800) 344-4222'>(800) 344-4222</a>.</div-->
      </div>
      <!-- banner /--><!-- COVID-19 banner --><!-- COVID-19 banner /--><!-- TOP BAR START-->
      <div class="ncr-top-bar">
        <div class="ncr-top-bar-right">
          <div class="ncr-language-services"><a href="{{ env.concernHealth }}/language-assistance-program">Language
              Services</a></div>
          <div class="ncr-support"><span>For Immediate Support call: <a class="ncr-concern-number"
                href="tel:800-344-4222">800.344.4222</a></span></div>
          <div class="ncr-user">
            <div id="inner-context" style="display: block;"><!-- LOGGED OUT -->
              <ul id="context-logout" style="display: block;">
                <li class="ncr-context-auth"><a href="/#/auth/login">
                    <i class="ncr-fa ncr-fa-user-circle" aria-hidden="true"></i> Login</a>
                </li>
              </ul><!-- LOGGED OUT --><!-- LOGGED IN -->
              <div id="context-login-updated" style="display:block" class="ncr-main-login">
                <ul>
                  <li id="context-dropdown" class="ncr-dropdown ncr-login-container"><a href="" id="context-toggle"
                      class="ncr-dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                      aria-expanded="false">
                      <div class="ncr-toggle-text" style="vertical-align:middle; padding-top: 15px;">
                        <div id="user-picture" class="ncr-user-picture" style="display: inline-block; ">
                          <i class="ncr-fa ncr-fa-user-circle" aria-hidden="true"></i>
                        </div>
                        <div class="ncr-user-name"
                          style="display: inline-block; padding-left: 5px; padding-right: 10px;"><span
                            id="user-name">{{user != null ? user.firstName+" "+user.lastName : ""}} &nbsp;</span> <i
                            class="ncr-fa ncr-fa-chevron-down" aria-hidden="true"></i></div>
                      </div>
                    </a>
                    <ul class="ncr-dropdown-menu">
                      <li><a href="/#/pages/profile">
                          <div class="ncr-toggle-text">My Profile</div>
                        </a></li>
                      <li><a href="/#/auth/terms">
                          <div class="ncr-toggle-text">Terms &amp; Conditions</div>
                        </a></li>
                      <li><a href="/#/auth/privacy">
                          <div class="ncr-toggle-text">Privacy Policy</div>
                        </a></li>
                      <li id="change-password"><a href="/#/auth/change-password">
                          <div class="ncr-toggle-text">Change Password</div>
                        </a></li>
                      <li><a (click)="clearUserInfo()" href="/#/auth/logout">
                          <div class="ncr-toggle-text">Logout</div>
                        </a></li>
                    </ul>
                  </li>
                </ul>
              </div><!-- LOGGED IN -->
            </div>
          </div>
        </div>
      </div><!-- TOP BAR END--><!-- UNIFIED NAV START-->
      <nav class="ncr-navbar ncr-navbar-default ncr-unified-nav ncr-luma-uni">
        <div class="ncr-container-fluid">
          <div class="ncr-navbar-header"><a class="ncr-navbar-brand" href="/#/"><img class="ncr-brand-logo"
                src="assets/images/concern-one-color-logo.png"></a>
          </div>
          <div class="ncr-collapse ncr-navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="ncr-nav ncr-navbar-nav ncr-navigation">
              <li class="menu-has-child">
                <a class="ncr-top-link" href="javascript:void(0);">Counseling &amp; Care <i
                    class="ncr-fa ncr-fa-chevron-down" aria-hidden="true"></i></a>
                <ul class="child-wrapper ncr-menu-item">
                  <li><a href="{{ env.concernHealth }}/about-counseling">About Counseling </a>
                    <p class="ncr-description">Confidential in-person, video, phone, live chat, text and app-based
                      options.</p>
                  </li>
                  <li><a href="{{ env.concernHealth }}/more-care-options">More Care Options </a>
                    <p class="ncr-description">Coaching, guided mindfulness, &amp; integrated apps</p>
                  </li>
                </ul>
              </li>
              <li class="menu-has-child">
                <a class="ncr-top-link" href="javascript:void(0);">Other Services <i class="ncr-fa ncr-fa-chevron-down"
                    aria-hidden="true"></i></a>
                <ul class="child-wrapper ncr-menu-item">
                  <li><a href="{{ env.concernHealth }}/work-life">Work-Life </a>
                    <p class="ncr-description">Financial, legal, childcare, &amp; adult care consultations and referrals
                    </p>
                  </li>
                  <li><a href="{{ env.concernHealth }}/digital-access">Digital Access </a>
                    <p class="ncr-description">Convenient one-stop-shop for personalized care &amp; support</p>
                  </li>
                </ul>
              </li>
              <li id="mega-nav-toggle" class="ncr-mega-nav-toggle"><a class="ncr-top-link">Resources by
                  Topic <i class="ncr-fa ncr-fa-chevron-down" aria-hidden="true"></i></a></li>
              <li><a class="ncr-top-link" href="{{ env.concernHealth }}/resources">Library</a></li>
              <li><a id="my-care-link" class="ncr-top-link" href="{{ env.concernHealth }}/topics/employer-toolkit">For
                  Employers</a></li>
              <li><a class="ncr-top-link" href="#" id="open-search"><i class="ncr-fa ncr-fa-search"
                    aria-hidden="true"></i></a></li>
              <li><a class="ncr-access-service" href="/#/pages/intake/care-path">Get Services</a>
              </li>
            </ul><!-- https://app.concernhealth.com/#/pages/dashboard" -->
          </div><!--ONLY MOBILE VISIBLE-->
          <div class="ncr-mobile-icons">
            <div class="ncr-search-icon ncr-right-icons"><i class="ncr-fa ncr-fa-search ncr-top-link"
                id="mobile-search-open" aria-hidden="true"></i></div>
            <div class="ncr-menu-bars ncr-right-icons"><span id="mobile-hide"
                class="ncr-mobile-hide ncr-top-link">&times;</span> <i
                class="ncr-fa ncr-fa-chevron-down ncr-mobile-show ncr-top-link" id="mobile-show" aria-hidden="true"></i>
            </div>
          </div><!--ONLY MOBILE VISIBLE-->
        </div>
      </nav><!-- UNIFIED NAV START--><!--ONLY MOBILE VISIBLE SEARCH-->
      <div class="ncr-mobile-search" id="mobile-search"><span class="ncr-mobile-close-search"
          id="mobile-close-search">&times;</span>
        <div class="ncr-row">
          <div
            class="ncr-col-sm-12 ncr-d-flex ncr-justify-content-center ncr-align-content-center ncr-align-items-center">
            <form class="ncr-form-inline" id="mobile-search-form" onsubmit="submitSearch()">
              <div class="ncr-form-group"><input type="text" class="ncr-form-control ncr-mobile-search-box"
                  id="mobile-search-box" placeholder="I need help with"></div><i
                class="ncr-fa ncr-fa-search ncr-mobile-search-submit" id="mobile-search-submit" aria-hidden="true"></i>
            </form>
          </div>
        </div>
      </div><!--ONLY MOBILE VISIBLE SEARCH--><!--ONLY MOBILE VISIBLE - FULL MOBILE MENU VIEW-->
      <div class="ncr-mobile-view" id="mobile-view"><!--ONLY MOBILE VISIBLE - BLUE PART-->
        <div class="ncr-mobile-blue">
          <div class="ncr-row ncr-blue-row">
            <div class="ncr-col-sm-12 ncr-mobile-support"><span>For Immediate Support call: <a
                  class="ncr-concern-number" href="tel:800-344-4222">800.344.4222</a></span></div>
          </div>
          <div class="ncr-row ncr-blue-row">
            <div class="ncr-col-sm-12 ncr-mobile-user">
              <div class="ncr-user-flex">
                <div class="ncr-user-detail">
                  <div class="ncr-user">
                    <div id="mobile-inner-context" style="display: block;"><!-- LOGGED OUT -->
                      <ul id="mobile-context-logout" class="ncr-context-logout" style="display:block">
                        <li class="ncr-mobile-context-auth"><a href="/#/auth/login" class="ncr-close-link"><i
                              class="ncr-fa ncr-fa-user-circle" aria-hidden="true"></i>
                            Login</a></li>
                      </ul><!-- LOGGED OUT --><!-- LOGGED IN -->
                      <div id="mobile-context-login_updated" style="display: block;">
                        <ul class="ncr-loggedin-container">
                          <li id="mobile-context-dropdown" class="ncr-dropdown ncr-login-container"><a href=""
                              id="mobile-context-toggle" class="ncr-dropdown-toggle" data-toggle="dropdown"
                              role="button" aria-haspopup="true" aria-expanded="false">
                              <div class="ncr-toggle-text">
                                <div id="mobile-user-picture" class="ncr-user-picture">
                                  <i class="ncr-fa ncr-fa-user-circle" aria-hidden="true"></i>
                                </div>
                                <div class="ncr-user-name"><span id="mobile-user-name"> {{user != null ?
                                    user.firstName+" "+user.lastName : ""}}</span></div>
                              </div>
                            </a>
                            <ul class="ncr-dropdown-menu">
                              <li><a href="/#/pages/profile" class="ncr-close-link">
                                  <div class="ncr-toggle-text ncr-user-link">My Profile</div>
                                </a></li>
                              <li><a href="/#/auth/terms" class="ncr-close-link">
                                  <div class="ncr-toggle-text ncr-user-link">Terms &amp; Conditions</div>
                                </a></li>
                              <li><a href="/#/auth/privacy" class="ncr-close-link">
                                  <div class="ncr-toggle-text ncr-user-link">Privacy Policy</div>
                                </a></li>
                              <li id="mobile-change-password"><a href="/#/auth/change-password" class="ncr-close-link">
                                  <div class="ncr-toggle-text ncr-user-link">Change Password</div>
                                </a></li>
                              <li><a href="/#/auth/logout" class="ncr-close-link">
                                  <div class="ncr-toggle-text ncr-user-link">Logout</div>
                                </a></li>
                            </ul>
                          </li>
                        </ul>
                      </div><!-- LOGGED IN -->
                    </div>
                  </div>
                </div>
                <div class="ncr-user-arrow"><i class="ncr-fa ncr-fa-chevron-right ncr-right-arrow"
                    aria-hidden="true"></i></div>
              </div>
            </div>
          </div>
          <div class="ncr-row ncr-blue-row">
            <div class="ncr-col-sm-12 ncr-mobile-language">
              <div class="ncr-language-flex">
                <div class="ncr-language-link"><a href="{{ env.concernHealth }}/language-assistance-program">Language
                    Services</a></div>
              </div>
            </div>
          </div>
        </div><!--ONLY MOBILE VISIBLE - BLUE PART--><!--ACCESS SERVICES-->
        <div class="ncr-as-container">
          <div class="ncr-row">
            <div class="ncr-col-sm-12"><a class="ncr-access-service ncr-close-link" href="/#/pages/intake/care-path">Get
                Services</a></div>
          </div>
        </div><!--ACCESS SERVICES--><!--MOBILE MEGA MENU-->
        <div class="ncr-links-container mobmenu-has-child">
          <div class="ncr-row">
            <div class="ncr-col-sm-12 ncr-link-flex ncr-top-link"><a>Counseling &amp; Care</a><i
                class="ncr-fa ncr-fa-chevron-down ncr-link-arrow ncr-down-arrow" aria-hidden="true"></i>
              <i class="ncr-fa ncr-fa-chevron-up ncr-link-arrow ncr-up-arrow" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="ncr-mobile-mega-menu mobchild-wrapper">
          <div class="ncr-sub-links-container">
            <div class="ncr-row">
              <div class="ncr-hidden-text">
                <div class="ncr-col-sm-12"><a href="/about-counseling">About Counseling</a>
                  <p class="ncr-mobile-description">Confidential in-person, video, phone, live chat, text and app-based
                    options.</p>
                </div>
                <div class="ncr-col-sm-12"><a href="/more-care-options">More Care Options</a>
                  <p class="ncr-mobile-description">Coaching, guided mindfulness, &amp; integrated apps</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ncr-links-container mobmenu-has-child">
          <div class="ncr-row">
            <div class="ncr-col-sm-12 ncr-link-flex ncr-top-link"><a>Other Services </a><i
                class="ncr-fa ncr-fa-chevron-down ncr-link-arrow ncr-down-arrow" aria-hidden="true"></i>
              <i class="ncr-fa ncr-fa-chevron-up ncr-link-arrow ncr-up-arrow" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="ncr-mobile-mega-menu mobchild-wrapper">
          <div class="ncr-sub-links-container">
            <div class="ncr-row">
              <div class="ncr-hidden-text">
                <div class="ncr-col-sm-12"><a href="/work-life">Work-Life</a>
                  <p class="ncr-mobile-description">Financial, legal, childcare, &amp; adult care consultations and
                    referrals</p>
                </div>
                <div class="ncr-col-sm-12"><a href="/digital-access">Digital Access</a>
                  <p class="ncr-mobile-description">Convenient one-stop-shop for personalized care &amp; support</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ncr-links-container" id="mobile-menu-toggle">
          <div class="ncr-row">
            <div class="ncr-col-sm-12 ncr-link-flex"><a>Resources by Topic</a> <i
                class="ncr-fa ncr-fa-chevron-down ncr-link-arrow ncr-down-arrow" id="down-arrow" aria-hidden="true"></i>
              <i class="ncr-fa ncr-fa-chevron-up ncr-link-arrow ncr-up-arrow" id="up-arrow" aria-hidden="true"></i>
            </div>
          </div>
        </div><!---MOBILE SUB MENU-->
        <div class="ncr-mobile-mega-menu" id="mobile-mega-menu">
          <div class="ncr-sub-links-container" id="relationship-toggle">
            <div class="ncr-row">
              <div class="ncr-col-sm-12 ncr-sub-link-flex"><a>Relationships</a>
                <i class="ncr-fa ncr-fa-chevron-down ncr-sub-link-arrow" id="relationship-down-arrow"
                  aria-hidden="true"></i> <i class="ncr-fa ncr-fa-chevron-up ncr-sub-link-arrow ncr-sub-up-arrow"
                  id="relationship-up-arrow" aria-hidden="true"></i>
              </div>
              <div class="ncr-hidden-text" id="relationship-hidden">
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/enhance-relationships">Enhance Relationships</a>
                  <p class="ncr-mobile-description">Communication / Managing Conflict / Personal Relationships</p>
                </div>
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/diversity">Diversity</a>
                  <p class="ncr-mobile-description">Cultural Diversity / Gender Identity / LGBTQ+</p>
                </div>
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/divorce-separation-divorce">Separation &amp;
                    Divorce</a>
                  <p class="ncr-mobile-description">Breakups / Separation / Abuse</p>
                </div>
              </div>
            </div>
          </div>
          <div class="ncr-sub-links-container" id="legal-toggle">
            <div class="ncr-row">
              <div class="ncr-col-sm-12 ncr-sub-link-flex"><a>Legal
                  &amp; Financial</a> <i class="ncr-fa ncr-fa-chevron-down ncr-sub-link-arrow" id="legal-down-arrow"
                  aria-hidden="true"></i> <i class="ncr-fa ncr-fa-chevron-up ncr-sub-link-arrow ncr-sub-up-arrow"
                  id="legal-up-arrow" aria-hidden="true"></i></div>
              <div class="ncr-hidden-text" id="legal-hidden">
                <div class="ncr-col-sm-12"><a href="/topics/legal-resources">Legal
                    Resources</a></div>
                <div class="ncr-col-sm-12"><a href="/topics/financial-resources">Financial Resources</a></div>
              </div>
            </div>
          </div>
          <div class="ncr-sub-links-container" id="emotional-toggle">
            <div class="ncr-row">
              <div class="ncr-col-sm-12 ncr-sub-link-flex"><a>Emotional
                  Wellbeing</a> <i class="ncr-fa ncr-fa-chevron-down ncr-sub-link-arrow" id="emotional-down-arrow"
                  aria-hidden="true"></i> <i class="ncr-fa ncr-fa-chevron-up ncr-sub-link-arrow ncr-sub-up-arrow"
                  id="emotional-up-arrow" aria-hidden="true"></i></div>
              <div class="ncr-hidden-text" id="emotional-hidden">
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/managing-emotions">Managing Emotions</a>
                  <p class="ncr-mobile-description">Train Your Anger / Lift Your Mood / Loneliness &amp; Sadness</p>
                </div>
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/stress-anxiety-depression-substance-use">Stress,
                    Anxiety, Depression, &amp; Substance Use</a></div>
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/loss-grief-change">Loss,
                    Grief, &amp; Change</a></div>
              </div>
            </div>
          </div>
          <div class="ncr-sub-links-container" id="caring-toggle">
            <div class="ncr-row">
              <div class="ncr-col-sm-12 ncr-sub-link-flex"><a>Caring
                  for Others</a> <i class="ncr-fa ncr-fa-chevron-down ncr-sub-link-arrow" id="caring-down-arrow"
                  aria-hidden="true"></i> <i class="ncr-fa ncr-fa-chevron-up ncr-sub-link-arrow ncr-sub-up-arrow"
                  id="caring-up-arrow" aria-hidden="true"></i></div>
              <div class="ncr-hidden-text" id="caring-hidden">
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/parenting-childcare">Parenting &amp;
                    Childcare</a>
                  <p class="ncr-mobile-description">Growing A Family / Parenting / Childcare / Life Milestones</p>
                </div>
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/adult-care">Adult Care</a>
                  <p class="ncr-mobile-description">Planning / Housing / Long Term Care / Insurance Advocacy</p>
                </div>
              </div>
            </div>
          </div>
          <div class="ncr-sub-links-container" id="healthy-toggle">
            <div class="ncr-row">
              <div class="ncr-col-sm-12 ncr-sub-link-flex"><a>Healthy
                  Habits</a> <i class="ncr-fa ncr-fa-chevron-down ncr-sub-link-arrow" id="healthy-down-arrow"
                  aria-hidden="true"></i> <i class="ncr-fa ncr-fa-chevron-up ncr-sub-link-arrow ncr-sub-up-arrow"
                  id="healthy-up-arrow" aria-hidden="true"></i></div>
              <div class="ncr-hidden-text" id="healthy-hidden">
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/resilience-mindfulness">Resilience &amp;
                    Mindfulness</a>
                  <p class="ncr-mobile-description">Tiny Habits / Mindfulness / Gratitude / Emotional Intelligence</p>
                </div>
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/sleep-nutrition">Sleep, Nutrition, &amp; Exercise</a>
                </div>
              </div>
            </div>
          </div>
          <div class="ncr-sub-links-container" id="success-toggle">
            <div class="ncr-row">
              <div class="ncr-col-sm-12 ncr-sub-link-flex"><a>Success
                  at Work</a> <i class="ncr-fa ncr-fa-chevron-down ncr-sub-link-arrow" id="success-down-arrow"
                  aria-hidden="true"></i> <i class="ncr-fa ncr-fa-chevron-up ncr-sub-link-arrow ncr-sub-up-arrow"
                  id="success-up-arrow" aria-hidden="true"></i></div>
              <div class="ncr-hidden-text" id="success-hidden">
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/work-stress">Work
                    Stress</a>
                  <p class="ncr-mobile-description">Workplace Stress &amp; Change / Burnout</p>
                </div>
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/work-communication">Work
                    Communication</a>
                  <p class="ncr-mobile-description">Workplace Communication &amp; Relationships / Remote Work /
                    Harassment</p>
                </div>
              </div>
            </div>
          </div>
          <div class="ncr-sub-links-container" id="events-toggle">
            <div class="ncr-row">
              <div class="ncr-col-sm-12 ncr-sub-link-flex"><a>Current
                  Events</a> <i class="ncr-fa ncr-fa-chevron-down ncr-sub-link-arrow" id="events-down-arrow"
                  aria-hidden="true"></i> <i class="ncr-fa ncr-fa-chevron-up ncr-sub-link-arrow ncr-sub-up-arrow"
                  id="events-up-arrow" aria-hidden="true"></i></div>
              <div class="ncr-hidden-text" id="events-hidden">
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/social-injustice">Social
                    Injustice</a>
                  <p class="ncr-mobile-description">Unrest &amp; Protests / Mass Violence &amp; Shootings</p>
                </div>
                <div class="ncr-col-sm-12"><a href="{{ env.concernHealth }}/topics/natural-disasters">Natural Disasters</a>
                  <p class="ncr-mobile-description">Wildfire / Hurricane / Flood / Earthquake / Tornado</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="sub-links-container" id="employer-toggle">        <div class="row">          <div class="col-sm-12 sub-link-flex">            <span>              <object class="mobile-menu-logo" type="image/svg+xml" data="https://login.concernhealth.com/html-resources/assets/images/icon-nav-employer-toolkit.svg" width="30">                <img src="https://login.concernhealth.com/html-resources/assets/images/icon-nav-employer-toolkit.svg" />              </object>            </span>            <a>For Employers</a>            <i class="fa fa-chevron-down sub-link-arrow"  id="employer-down-arrow" aria-hidden="true"></i>            <i class="fa fa-chevron-up sub-link-arrow sub-up-arrow" id="employer-up-arrow" aria-hidden="true"></i>          </div>          <div class="hidden-text" id="employer-hidden">            <div class="col-sm-12">              <a href="/topics/employer-toolkit">Employer Toolkit <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></a>              <p class="mobile-description">Promotional Materials / Critical Incident Response / Management Consults / Training</p>            </div>          </div>        </div>      </div> -->
        </div><!---MOBILE SUB MENU-->
        <div class="ncr-links-container">
          <div class="ncr-row">
            <div class="ncr-col-sm-12 ncr-link-flex"><a href="{{ env.concernHealth }}/resources">Library</a>
            </div>
          </div>
        </div>
        <div class="ncr-links-container">
          <div class="ncr-row">
            <div class="ncr-col-sm-12 ncr-link-flex">
              <!-- <a class="close-link" href="https://app.concernhealth.com/#/pages/dashboard">My Care</a> --> <a
                class="ncr-close-link" href="https://employees.concernhealth.com/topics/employer-toolkit">For
                Employers</a>
            </div>
          </div>
        </div><!----MOBILE MEGA MENU-->
      </div><!--ONLY MOBILE VISIBLE - FULL MOBILE MENU VIEW--><!-- MEGA MENU START-->
      <div class="ncr-mega-menu" id="mega-menu" style="display:none">
        <!-- <i class="fa fa-times close-icon" id="close-icon" aria-hidden="true"></i> --> <span class="ncr-close-icon"
          id="close-icon">&times;</span>
        <div class="ncr-container ncr-menu-container">
          <div class="ncr-row">
            <div class="ncr-col-sm-3"><span class="ncr-menu-title">Relationships</span>
              <ul class="ncr-menu-item">
                <li><a href="{{ env.concernHealth }}/topics/enhance-relationships">Enhance Relationship </a>
                  <p class="ncr-description">Communication / Managing Conflict / Personal Relationships</p>
                </li>
                <li><a href="{{ env.concernHealth }}/topics/diversity">Diversity</a>
                  <p class="ncr-description">Cultural Diversity / Gender Identity / LGBTQ+</p>
                </li>
                <li><a href="{{ env.concernHealth }}/topics/separation-divorce">Separation &amp; Divorce</a>
                  <p class="ncr-description">Breakups / Separation / Abuse</p>
                </li>
              </ul>
            </div>

            <div class="ncr-col-sm-3 ncr-emotional-pull-up"><span class="ncr-menu-title">Emotional
                Wellbeing</span>
              <ul class="ncr-menu-item">
                <li><a href="{{ env.concernHealth }}/topics/managing-emotions">Managing Emotions</a>
                  <p class="ncr-description">Train Your Anger / Lift Your Mood / Loneliness &amp; Sadness</p>
                </li>
                <li><a class="ncr-small-line-height"
                    href="{{ env.concernHealth }}/topics/stress-anxiety-depression-substance-use">Stress,
                    Anxiety, Depression, &amp; Substance Use</a></li>
                <li><a href="{{ env.concernHealth }}/topics/loss-grief-change">Loss, Grief, &amp; Change</a></li>
              </ul>
            </div>

            <div class="ncr-col-sm-3 ncr-healthy-pull-up"><span class="ncr-menu-title">Healthy
                Habits</span>
              <ul class="ncr-menu-item">
                <li><a href="{{ env.concernHealth }}/topics/resilience-mindfulness">Resilience &amp;
                    Mindfulness</a>
                  <p class="ncr-description">Tiny Habits / Mindfulness / Gratitude / Emotional Intelligence</p>
                </li>
                <li><a href="{{ env.concernHealth }}/topics/sleep-nutrition">Sleep, Nutrition, &amp; Exercise</a></li>
              </ul>
            </div>

            <div class="ncr-col-sm-3"><span class="ncr-menu-title">Current
                Events</span>
              <ul class="ncr-menu-item">
                <li><a href="{{ env.concernHealth }}/topics/social-injustice">Social Injustice</a>
                  <p class="ncr-description">Unrest &amp; Protests / Mass Violence &amp; Shootings</p>
                </li>
                <li><a href="{{ env.concernHealth }}/topics/natural-disasters">Natural Disasters</a>
                  <p class="ncr-description">Wildfire / Hurricane / Flood / Earthquake / Tornado</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="ncr-row">
            <div class="ncr-col-sm-3"><span class="ncr-menu-title">Legal &amp;
                Financial</span>
              <ul class="ncr-menu-item">
                <li><a href="{{ env.concernHealth }}/topics/legal-resources">Legal Resources</a></li>
                <li><a href="{{ env.concernHealth }}/topics/financial-resources">Financial Resources</a></li>
              </ul>
            </div>

            <div class="ncr-col-sm-3"><span class="ncr-menu-title">Caring for
                Others</span>
              <ul class="ncr-menu-item">
                <li><a href="{{ env.concernHealth }}/topics/parenting-childcare">Parenting &amp; Childcare</a>
                  <p class="ncr-description">Growing A Family / Parenting / Childcare / Life Milestones</p>
                </li>
                <li><a href="{{ env.concernHealth }}/topics/adult-care">Adult Care</a>
                  <p class="ncr-description">Planning / Housing / Long Term Care / Insurance Advocacy</p>
                </li>
              </ul>
            </div>

            <div class="ncr-col-sm-3"><span class="ncr-menu-title">Success at
                Work</span>
              <ul class="ncr-menu-item">
                <li><a href="{{ env.concernHealth }}/topics/work-stress">Work Stress</a>
                  <p class="ncr-description">Workplace Stress &amp; Change / Burnout</p>
                </li>
                <li><a href="{{ env.concernHealth }}/topics/work-communication">Work Communication</a>
                  <p class="ncr-description">Workplace Communication &amp; Relationships / Remote Work / Harassment
                  </p>
                </li>
              </ul>
            </div>
            <div class="ncr-col-sm-3"><span class="ncr-menu-title">External Resources</span>
              <ul class="ncr-menu-item">
                <li><a href="https://login.concernhealth.com/lifeadviser" target="_blank">LifeAdviser</a>
                  <p class="ncr-description">Forms / Tools / Webinars / Resources</p>
                </li>
              </ul>
              <hr>
            </div>
          </div>
        </div>
      </div><!-- MEGA MENU END-->
      <div class="ncr-search-area" id="search-area"><span class="ncr-close-search" id="close-search">&times;</span>
        <div class="ncr-row">
          <div
            class="ncr-col-sm-12 ncr-d-flex ncr-justify-content-center ncr-align-content-center ncr-align-items-center">
            <form class="ncr-form-inline" id="search-form" onsubmit="submitSearch()">
              <div class="ncr-form-group"><input type="text" class="ncr-form-control ncr-search-box" id="search-box"
                  placeholder="I need help with"></div><i class="ncr-fa ncr-fa-search ncr-search-submit"
                id="search-submit" aria-hidden="true"></i>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<script type="text/javascript" src="../../../../assets/js/navbar.js"></script>