import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DateDifferenceService {

  constructor() { }


  yearsDiff(dob) {
    const difference = new Date().valueOf() - new Date(dob).valueOf();
    const diff = difference / 1000 / 60 / 60 / 24 / 365  // milliseconds to hours (this.difference returns milliseconds)
    return diff;
  }

  hoursDiff(sd) {
    const difference = new Date().valueOf() - new Date(sd).valueOf(); // sd = submitted date
        const diff = Math.round(difference / 1000 / 60 / 60); // milliseconds to hours (this.difference returns milliseconds)
        return diff;
  }
}
