import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

declare let ga: Function;
export enum Dimension {
  COMPANY = 'company',
  LOCATION = 'location',
  AFFILIATION = 'affiliation',
  USERID = 'userid',
  // THE DIMENSIONS IN THIS BLOCK WILL BE MAPPED OVER THE label FIELD WHEN SENDING EVENTS
  RISK_STRATIFICATION = 'risk_stratification',
  RISK_PHQ_2 = 'risk_phq_2',
  RISK_GAD_7 = 'risk_gad_7',
  RISK_PHQ_9 = 'risk_phq_9',
  PRIMARY_ASSESSED_PROBLEMS = 'primary_assessed_problems',
  MENTAL_EMOTIONAL = 'mental_emotional',
  WORK_LIFE = 'work_life',
  CONTENT_VIEW_RATING = 'content_view_rating',
  RECOMMENDATION = 'recommendation',
  STAGE = 'stage',
  RISK_QOL_4 = 'risk_qol_4',
  RISK_WOS_5 = 'risk_wos_5',
  RISK_PSS_4 = 'risk_pss_4',
  // END BLOCK
}

export const DimMapping = {
  eventLabel: [
    Dimension.RISK_STRATIFICATION,
    Dimension.RISK_PHQ_2,
    Dimension.RISK_GAD_7,
    Dimension.RISK_PSS_4,
    Dimension.RISK_PHQ_9,
    Dimension.RISK_QOL_4,
    Dimension.RISK_WOS_5,
    Dimension.PRIMARY_ASSESSED_PROBLEMS,
    Dimension.MENTAL_EMOTIONAL,
    Dimension.WORK_LIFE,
    Dimension.RECOMMENDATION,
    Dimension.STAGE,
  ],
}

export const GA_COMMANDS = {
  ACTION: {
    CREATE: 'create',
    SEND: 'send',
    SET: 'set',
  },
  TYPE: {
    EVENT: 'event',
    PAGEVIEW: 'pageview',
  },
  EVENTS: {
    'Login': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'login'
    },
    'Request-Password': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'request-password'
    },
    'Reset-Password': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'reset-password'
    },
    'Logout': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'logout'
    },
    'Registration': {
      'event': 'user management',
      'eventAction': 'registration',
      'eventLabel': 'success'
    },
    'History': {
      'event': 'History',
      'eventAction': 'Click',
      'eventLabel': 'Show all Services used'
    },
    'More_Provider_Profile': {
      'event': 'Counseling',
      'eventAction': 'More details',
      'eventLabel': 'Provider Profile'
    },
    'in-office': {
      'event': 'Counseling',
      'eventAction': 'Select completed',
      'eventLabel': 'in-office'
    },
    'Less_Provider_Profile': {
      'event': 'Counseling',
      'eventAction': 'Less details',
      'eventLabel': 'Provider Profile'
    },
    'Counselors': {
      'event': 'Counseling',
      'eventAction': 'Recommendation',
      'eventLabel': 'Counselors'
    },
    'User registered': {
      'event': 'user management',
      'eventAction': 'registration',
      'eventLabel': 'success',
      'eventValue': 1,
    },
    'User logged in': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'login',
    },
    'User logged in via SSO': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'sso-login',
    },
    'User changing password.': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'change password',
    },
    'User logged out': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'logout',
    },
    'User request password reset': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'request-password',
    },
    'User reset password': {
      'event': 'user management',
      'eventAction': 'authentication',
      'eventLabel': 'reset-password',
    },
    'doesn\'t apply to me': {
      'event': 'intake',
      'eventAction': 'speed bump',
      'eventLabel': 'doesn\'t apply to me',
    },
    'need help now': {
      'event': 'intake',
      'eventAction': 'immediate care',
      'eventLabel': 'need help now',
    },
    'Primary Assessed problems': {
      'event': 'intake',
      'eventAction': 'presenting problem',
      'eventLabel': 'emotional states',
    },
    'Mental/Emotional': {
      'event': 'intake',
      'eventAction': 'emotions feeling',
      'eventLabel': 'emotions feeling',
    },
    'Work Life': {
      'event': 'intake',
      'eventAction': 'workLife service',
      'eventLabel': 'work life selection',
    },
    'Risk stratification': {
      'event': 'intake',
      'eventAction': 'risk stratification',
      'eventLabel': 'risk level',
    },
    'PHQ-2 at intake': {
      'event': 'intake',
      'eventAction': 'PHQ-2',
      'eventLabel': 'risk level',
    },
    'GAD-7 at baseline': {
      'event': 'mood survey',
      'eventAction': 'GAD-7',
      'eventLabel': 'risk level',
    },
    'PSS-4 at baseline': {
      'event': 'stress assesment',
      'eventAction': 'PSS-4',
      'eventLabel': 'risk level',
    },
    'PHQ-9 at baseline': {
      'event': 'mood survey',
      'eventAction': 'PHQ-9',
      'eventLabel': 'risk level',
    },
    'QOL-4 at baseline': {
      'event': 'qol survey',
      'eventAction': 'QOL-4',
      'eventLabel': 'risk level',
    },
    'WOS-5 at baseline': {
      'event': 'qol survey',
      'eventAction': 'WOS-5',
      'eventLabel': 'risk level',
    },
    'Counseling video': {
      'event': 'counseling',
      'eventAction': 'confirm',
      'eventLabel': 'video',
    },
    'Counseling video completed': {
      'event': 'counseling',
      'eventAction': 'confirm-completed',
      'eventLabel': 'video',
    },
    'Counseling in-office': {
      'event': 'counseling',
      'eventAction': 'select',
      'eventLabel': 'in-office',
    },
    'Counseling not-in-office': {
      'event': 'counseling',
      'eventAction': 'not-select',
      'eventLabel': 'not-in-office',
    },
    'Counseling in-office completed': {
      'event': 'counseling',
      'eventAction': 'select-completed',
      'eventLabel': 'in-office',
    },
    'Counseling Recommendation': {
      'event': 'counseling',
      'eventAction': 'recommentation',
      'eventLabel': 'counselors',
    },
    'Work Life Request': {
      'event': 'work-life',
      'eventAction': 'submit form',
      'eventLabel': 'request w/l services',
    },
    'Self Help Request': {
      'event': 'self-help',
      'eventAction': 'click',
      'eventLabel': 'request digital tools',
    },
    'Go To eM Life': {
      'event': 'eMLife',
      'eventAction': 'click eM Life link',
      'eventLabel': 'Request eM Life',
    },
    'In Person Counseling Request': {
      'event': 'In Person Counseling Request',
      'eventAction': 'click',
      'eventLabel': 'Request In person counseling',
    },
    'Online Counseling Request': {
      'event': 'Online Counseling Request',
      'eventAction': 'click',
      'eventLabel': 'Request online counseling',
    },
    'First Responder Request': {
      'event': 'First Responder Request',
      'eventAction': 'click',
      'eventLabel': 'Request First Responder counseling',
    },
    'Coaching Request': {
      'event': 'Coaching Request',
      'eventAction': 'click',
      'eventLabel': 'Request Coaching',
    },
    'Text Therapy Request': {
      'event': 'Text Therapy',
      'eventAction': 'click',
      'eventLabel': 'Request Text Therapy',
    },
    'Switching providers': {
      'event': 'Switching providers',
      'eventAction': 'click',
      'eventLabel': 'Switching providers',
    },
    'Switching to In-Person from Online': {
      'event': 'Switching to In-Person from Online',
      'eventAction': 'click',
      'eventLabel': 'Switch to In Person Provider',
    },
    'Switching to online counseling': {
      'event': 'Switching to online counseling',
      'eventAction': 'click',
      'eventLabel': 'Switch to Online Provider',
    }
  },
};

@Injectable()
export class AnalyticsService {

  private enabled: boolean;
  private propertyId: string;
  public window: any = window;

  constructor(private location: Location, private router: Router, private cookieService: CookieService) {
    this.enabled = true;
    this.propertyId = environment.trackerId;
    ga(GA_COMMANDS.ACTION.CREATE, this.propertyId, 'auto', {
      userId: this.cookieService.get('userId') || '',
    });
    this.setCompany(this.cookieService.get('companyName') || '');
    this.setCompanyId(this.cookieService.get('companyId') || '');
    this.setLocation(this.cookieService.get('companySiteLocation') || '');
    this.setAffiliation(this.cookieService.get('companyAffiliation') || '');
    this.setUserID(this.cookieService.get('userId') || '');
  }

  trackPageViews() {
    if (this.enabled) {
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
      )
        .subscribe(() => {
          ga(GA_COMMANDS.ACTION.SEND, { hitType: GA_COMMANDS.TYPE.PAGEVIEW, page: this.location.path() });
          this.sendUniNavTracking();
        });
    }
  }

  setCompany(companyName: string) {
    this.cookieService.set('companyName', companyName || '');
    if (this.enabled) {
      ga(GA_COMMANDS.ACTION.SET, Dimension.COMPANY, companyName);
    }
  }

  setCompanyId(companyId: string) {
    this.cookieService.set('companyId', companyId || '');
  }

  removeCompany() {
    this.setCompany('');
  }

  setAffiliation(affiliation: string) {
    this.cookieService.set('companyAffiliation', affiliation || '');
    if (this.enabled) {
      ga(GA_COMMANDS.ACTION.SET, Dimension.AFFILIATION, affiliation);
    }
  }

  removeAffiliation() {
    this.setAffiliation('');
  }

  getLocation(): string {
    return this.cookieService.get('companySiteLocation');
  }

  setLocation(location: string) {
    this.cookieService.set('companySiteLocation', location || '');
    if (this.enabled) {
      ga(GA_COMMANDS.ACTION.SET, Dimension.LOCATION, location);
    }
  }

  removeLocation() {
    this.setLocation('');
  }
  trackEvent(event: string) {
    this.window.dataLayer.push( GA_COMMANDS.EVENTS[event] );
  }

  // send with custom dimention as rating value
  trackGenericEvent(category: string, action?: string, label?: string, value?: string) {
    if (this.enabled) {
      if (value) {
        this.window.dataLayer.push(
          {
            'event': category,
            'eventAction': action,
            'eventLabel': label,
            'eventValue': value
          });
      } else {
        this.window.dataLayer.push(
          {
            'event': category,
            'eventAction': action,
            'eventLabel': label,
          });
      }

    }
  }

  trackRecommendations(eventName: string, value: number) {
    const eventData = GA_COMMANDS.EVENTS[eventName];
    const dimensions = { 'eventValue': value, 'dimension12': value.toString() };
    this.trackEventDimension(eventData, dimensions);
  }



  private setCustomDimentionValue(dimension: Dimension, value) {
    if (this.enabled) {
      ga(GA_COMMANDS.ACTION.SET, dimension, value);
    }
  }

  setUserID(userId: string) {
    this.cookieService.set('userId', userId || '');
    if (this.enabled) {
      ga(GA_COMMANDS.ACTION.SET, 'userId', userId);
      this.setCustomDimentionValue(Dimension.USERID, userId);
    }
  }

  trackEventWithDimensions(eventName: string, dimension: Dimension, values: string[] = [], extraDimensions: any = {}) {
    if (this.enabled) {
      const useAsLabel = DimMapping.eventLabel.includes(dimension);
      values.forEach((custom) => {
        const eventData = { ...GA_COMMANDS.EVENTS[eventName] };
        if (useAsLabel) {
          eventData.eventLabel = custom;
        }
        this.trackEventDimension(eventData, { [dimension]: custom, ...extraDimensions });
      });
    }
  }

  private trackEventDimension(event: any, dimension) {
    const mergedData = {
      ...event,
      ...dimension
    };
    if (this.enabled && event) {
      this.window.dataLayer.push(mergedData);
    }
  }

  // uses the navocat object from the unified navigation bar
  // to send analytics to VARA.
  private sendUniNavTracking() {
    const navocat = window['navocat'];
    if (navocat && navocat.lib && navocat.lib.collectorTrack) {
      navocat.lib.collectorTrack({
        path: this.location.path(),
      }, 'page');
    }
  }
}
