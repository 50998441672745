<div class="animate__animated animate__slideInRight animate__faster">

  <ng-container *ngIf="user.isSso; then sso; else default">
    </ng-container>
  <ng-template #default>
  <div>

    <div class="flex-centered">
      <div class="container">
        <nb-auth-block class="row justify-content-center">
          <form (ngSubmit)="checkAgeMajority()" #form="ngForm" class="col-12 col-xl-8">
            <div class="mt-2 mb-4 text-center">
              <ngx-intake-header [title]="title" styleClasses="mt-0"></ngx-intake-header>
              <ngx-sub-header [caption]="subHeader">Don’t worry, registering you helps us provide you with the best care
                options.

                <ngx-instructions-tooltip [attachToCorner]="false" [popOverMessage]="tooltipMessage">
                </ngx-instructions-tooltip>
              </ngx-sub-header>
            </div>
            <ngx-request-errors *ngIf="showMessages.error && !submitted" [errors]="errors"></ngx-request-errors>
            <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted" class="alert alert-success"
              role="alert">
              <div>
                <strong>Hooray!</strong>
              </div>
              <div *ngFor="let message of messages">{{ message }}</div>
            </div>

            <div class="row required-info">
              * <small>Required Fields</small>
            </div>
            <div class="row">

              <div class="col-xl-6 col-12 col-sm-6 form-group">
                <label for="input-fname" class="label">FIRST NAME<sup>*</sup></label>
                <input name="firstName" [(ngModel)]="user.firstName" id="input-fname" #firstName="ngModel"
                  class="form-control" [class.form-control-danger]="firstName.invalid && firstName.touched"
                  [required]="getConfigValue('forms.validation.firstName.required')"
                  [minlength]="getConfigValue('forms.validation.firstName.minLength')">
                <small class="form-text error"
                  *ngIf="firstName.invalid && firstName.touched && firstName.errors?.required">
                  First name is required!
                </small>
                <small class="form-text error"
                  *ngIf="firstName.invalid && firstName.touched && (firstName.errors?.minlength)">
                  First name should contain from {{getConfigValue('forms.validation.firstName.minLength')}} or more
                  letters
                </small>
              </div>

              <div class="col-xl-6 col-12 col-sm-6 form-group">
                <label for="input-lname" class="label">LAST NAME<sup>*</sup></label>
                <input name="lastName" [(ngModel)]="user.lastName" id="input-lname" #lastName="ngModel"
                  class="form-control" [class.form-control-danger]="lastName.invalid && lastName.touched"
                  [required]="getConfigValue('forms.validation.lastName.required')"
                  [minlength]="getConfigValue('forms.validation.lastName.minLength')"
                  [maxlength]="getConfigValue('forms.validation.lastName.maxLength')">
                <small class="form-text error" *ngIf="lastName.invalid && lastName.touched && lastName.errors?.required">
                  Last name is required!
                </small>
                <small class="form-text error"
                  *ngIf="lastName.invalid && lastName.touched && (lastName.errors?.minlength || lastName.errors?.maxlength)">
                  Last name should contain from {{getConfigValue('forms.validation.lastName.minLength')}} to
                  {{getConfigValue('forms.validation.lastName.maxLength')}}
                  characters
                </small>
              </div>

            </div>

            <div class="form-group">
              <label for="input-email" class="label">EMAIL ADDRESS<sup>*</sup></label>
              <input name="email" [(ngModel)]="user.email" id="input-email" #email="ngModel" class="form-control"
                pattern=".+@.+\..+" [class.form-control-danger]="email.invalid && email.touched || errors[1]"
                [required]="getConfigValue('forms.validation.email.required')">
              <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.pattern">
                Email should be the real one!
              </small>
            </div>

            <div class="row align-items-center mb-5 mb-lg-0">
              <div class="col-xs-12 col-md-6">
                <div class="form-group">
                  <label for="input-lname" class="label">DATE OF BIRTH<sup>*</sup></label>
                  <input name="dateOfBirth" [(ngModel)]="user.dateOfBirth" id="input-dateOfBirth" #dateOfBirth="ngModel"
                    class="form-control" [required]="true" type="date"
                    [class.form-control-danger]="dateOfBirth.invalid && dateOfBirth.touched" ngxValidateDOB>
                  <small class="form-text error"
                    *ngIf="dateOfBirth.invalid && dateOfBirth.touched && dateOfBirth.errors?.required">
                    Date of Birth is required!
                  </small>
                  <small class="form-text error"
                    *ngIf="dateOfBirth.invalid && dateOfBirth.touched && dateOfBirth.errors?.dobInvalid && !dateOfBirth.errors?.required">
                    Date of Birth is invalid!
                  </small>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <a (click)="openModal()" class="align-middle link">
                  <strong>Why is this important?</strong>
                </a>
              </div>
            </div>
            <div class="row gender">
              <div class="num-padding">
                <label for="input-gender" class="label"> Gender<sup>*</sup></label>
              </div>
              <div class="col-xl-6 col-12 col-sm-6 mb-1">
                <div class="form-group">
                  <select name="gender" [(ngModel)]="user.gender" id="input-gender" #gender="ngModel"
                    class="form-control" [class.form-control-danger]="gender.invalid && gender.touched"
                    required>
                    <option *ngFor="let gender of genders" [value]="gender.name">
                      {{ gender.name }}</option>
                  </select>
                  <small class="form-text error"
                    *ngIf="gender.invalid && gender.touched && gender.errors?.required">
                    Gender is required!
                  </small>
                </div>
              </div>
            </div>

            <div class="form-group">
              <hr/>
            </div>

            <div class="row">
              <div class="num-padding">
                <label for="input-phone" class="label"> PRIMARY PHONE NUMBER<sup>*</sup></label>
                <p class="num-only-span ">(Please do not include +1 or 1 at the beginning of your number)</p>
              </div>
              <div class="col-xl-6 col-12 col-sm-6 form-group">
                <div class="form-group">
                  <input name="cellPhone" [(ngModel)]="user.cellPhone" id="input-cell-phone" #cellPhone="ngModel"
                    class="form-control tel" [class.form-control-danger]="cellPhone.invalid && cellPhone.touched"
                    type="tel" pattern="[0-9]{10,15}" mask='(000) 000-0000' minlength="10" maxlength="15" required>
                  <small class="form-text error"
                    *ngIf="cellPhone.invalid && cellPhone.touched && cellPhone.errors?.required">
                    Cell Number is required!
                  </small>
                  <small class="form-text error"
                    *ngIf="cellPhone.invalid && cellPhone.touched && cellPhone.errors?.minlength">
                    Cell Number is too short or invalid!
                  </small>
                  <small class="form-text error"
                    *ngIf="cellPhone.invalid && cellPhone.touched && cellPhone.errors?.minlength && !cellPhone.errors?.minlength">
                    Cell Number must be all digits (0-9)!
                  </small>
                </div>
              </div>
              <div class="col-xl-6 col-12 col-sm-6 form-group home-checkbox">
                <nb-checkbox name="confirmMessages" [(ngModel)]="allowedMessage">
                  It’s okay to leave a voice message at this number
                </nb-checkbox>
              </div>
            </div>
            <div class="form-group">
              <nb-checkbox name="clientPermission" [(ngModel)]="user.clientPermission">
                I agree to receive text messages from Concern about my services.
                I can reply STOP at any time to opt out.
              </nb-checkbox>
            </div>

            <div class="form-group">
              <hr/>
            </div>

            <div class="form-group">
              <label for="input-password" class="label">PASSWORD<sup>*</sup></label>
              <input name="password" [(ngModel)]="user.password" type="password" id="input-password" class="form-control"
                #password="ngModel" [class.form-control-danger]="password.invalid && password.touched"
                [required]="getConfigValue('forms.validation.password.required')"
                [minlength]="getConfigValue('forms.validation.password.minLength')"
                [maxlength]="getConfigValue('forms.validation.password.maxLength')" autocomplete="new-password"
                ngxValidatePassword>
              <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
                Password is required!
              </small>
              <small class="form-text error" *ngIf="(password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength))
                || (password.touched && password.errors?.passwordInvalid)">
                Password must contain at lease one uppercase letter, one lowercase letter, one number and be at least
                {{getConfigValue('forms.validation.password.minLength')}} characters long
              </small>
            </div>

            <div class="form-group">
              <label for="input-re-password" class="label">REPEAT PASSWORD<sup>*</sup></label>
              <input name="rePass" [(ngModel)]="confirmPassword" type="password" id="input-re-password"
                class="form-control" #rePass="ngModel"
                [class.form-control-danger]="(rePass.invalid || password.value != rePass.value) && rePass.touched"
                [required]="getConfigValue('forms.validation.password.required')" autocomplete="new-password">
              <small class="form-text error" *ngIf="rePass.invalid && rePass.touched && rePass.errors?.required">
                Password confirmation is required!
              </small>
              <small class="form-text error"
                *ngIf="rePass.touched && password.value != rePass.value && !rePass.errors?.required">
                Password does not match the confirm password.
              </small>
            </div>

            <div class="form-group accept-group col-sm-12">
              <div class="form-text">
                This information will be confidential and only used to provide a customized experience and to track your progress.
              </div>
            </div>

            <div class="form-group accept-group col-sm-12" *ngIf="getConfigValue('forms.register.terms')">
              <nb-checkbox name="terms" class="ie-width" [(ngModel)]="user.terms"
                [required]="getConfigValue('forms.register.terms')">
                Agree to
                <a routerLink="../terms" target="_blank">
                  <strong>Terms &amp; Conditions</strong>
                </a>
              </nb-checkbox>
            </div>


            <div class="d-flex flex-column align-items-center">
              <button [disabled]="!user.terms || submitted || !form.valid || password.value != rePass.value"
                class="btn btn-block btn-primary" [class.btn-pulse]="submitted">
                Register
              </button>
              <a class="link top-separation" routerLink="../login">ALREADY REGISTERED? CLICK HERE</a>
            </div>
          </form>


        </nb-auth-block>
      </div>
    </div>
  </div>
  </ng-template>
  <ng-template #sso>
  <div>

    <div class="flex-centered">
      <div class="container">
        <nb-auth-block class="row justify-content-center">
          <form (ngSubmit)="checkAgeMajority()" #form="ngForm" class="col-12 col-xl-8">
            <div class="mt-2 mb-4 text-center">
              <ngx-intake-header [title]="title" styleClasses="mt-0"></ngx-intake-header>
              <ngx-sub-header [caption]="subHeader">Don’t worry, registering you helps us provide you with the best care
                options.

                <ngx-instructions-tooltip [attachToCorner]="false" [popOverMessage]="tooltipMessage">
                </ngx-instructions-tooltip>
              </ngx-sub-header>
            </div>
            <ngx-request-errors *ngIf="showMessages.error && !submitted" [errors]="errors"></ngx-request-errors>
            <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted" class="alert alert-success"
              role="alert">
              <div>
                <strong>Hooray!</strong>
              </div>
              <div *ngFor="let message of messages">{{ message }}</div>
            </div>

            <div class="row required-info">
              * <small>Required Fields</small>
            </div>
            <div class="row">

              <div class="col-xl-6 col-12 col-sm-6 form-group">
                <label for="input-fname" class="label">FIRST NAME<sup>*</sup></label>
                <input name="firstName" [(ngModel)]="user.firstName" id="input-fname" #firstName="ngModel"
                  class="form-control" [class.form-control-danger]="firstName.invalid && firstName.touched"
                  [required]="getConfigValue('forms.validation.firstName.required')"
                  [minlength]="getConfigValue('forms.validation.firstName.minLength')">
                <small class="form-text error"
                  *ngIf="firstName.invalid && firstName.touched && firstName.errors?.required">
                  First name is required!
                </small>
                <small class="form-text error"
                  *ngIf="firstName.invalid && firstName.touched && (firstName.errors?.minlength)">
                  First name should contain from {{getConfigValue('forms.validation.firstName.minLength')}} or more
                  letters
                </small>
              </div>

              <div class="col-xl-6 col-12 col-sm-6 form-group">
                <label for="input-lname" class="label">LAST NAME<sup>*</sup></label>
                <input name="lastName" [(ngModel)]="user.lastName" id="input-lname" #lastName="ngModel"
                  class="form-control" [class.form-control-danger]="lastName.invalid && lastName.touched"
                  [required]="getConfigValue('forms.validation.lastName.required')"
                  [minlength]="getConfigValue('forms.validation.lastName.minLength')"
                  [maxlength]="getConfigValue('forms.validation.lastName.maxLength')">
                <small class="form-text error" *ngIf="lastName.invalid && lastName.touched && lastName.errors?.required">
                  Last name is required!
                </small>
                <small class="form-text error"
                  *ngIf="lastName.invalid && lastName.touched && (lastName.errors?.minlength || lastName.errors?.maxlength)">
                  Last name should contain from {{getConfigValue('forms.validation.lastName.minLength')}} to
                  {{getConfigValue('forms.validation.lastName.maxLength')}}
                  characters
                </small>
              </div>

            </div>

            <div class="form-group">
              <label for="input-email" class="label">EMAIL ADDRESS<sup>*</sup></label>
              <input name="email" [(ngModel)]="user.email" id="input-email" #email="ngModel" class="form-control"
                pattern=".+@.+\..+" [class.form-control-danger]="email.invalid && email.touched || errors[1]"
                [required]="getConfigValue('forms.validation.email.required')" disabled style="background-color: #d3d3d373;">
              <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.pattern">
                Email should be the real one!
              </small>
            </div>

            <div class="row align-items-center">
              <div class="col-xs-12 col-md-6">
                <div class="form-group">
                  <label for="input-lname" class="label">DATE OF BIRTH<sup>*</sup></label>
                  <input name="dateOfBirth" [(ngModel)]="user.dateOfBirth" id="input-dateOfBirth" #dateOfBirth="ngModel"
                    class="form-control" [required]="true" type="date"
                    [class.form-control-danger]="dateOfBirth.invalid && dateOfBirth.touched">
                  <small class="form-text error"
                    *ngIf="dateOfBirth.invalid && dateOfBirth.touched && dateOfBirth.errors?.required">
                    Date of Birth is required!
                  </small>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <a (click)="openModal()" class="align-middle link">
                  <strong>Why is this important?</strong>
                </a>
              </div>
            </div>


            <div class="form-group accept-group col-sm-12">
              <div class="form-text">
                This information will be confidential and only used to provide a customized experience and to track your progress.
              </div>
            </div>

            <div class="form-group accept-group col-sm-12" *ngIf="getConfigValue('forms.register.terms')">
              <nb-checkbox name="terms" class="ie-width" [(ngModel)]="user.terms"
                [required]="getConfigValue('forms.register.terms')">
                Agree to
                <a routerLink="../terms" target="_blank">
                  <strong>Terms &amp; Conditions</strong>
                </a>
              </nb-checkbox>
            </div>


            <div class="d-flex flex-column align-items-center">
              <button [disabled]="!user.terms || submitted || !form.valid"
                class="btn btn-block btn-primary" [class.btn-pulse]="submitted">
                Register
              </button>
              <a class="link top-separation" routerLink="../login">ALREADY REGISTERED? CLICK HERE</a>
            </div>
          </form>


        </nb-auth-block>
      </div>
    </div>
  </div>
  </ng-template>
  </div>
