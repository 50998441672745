import { Directive } from '@angular/core';

import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS, AsyncValidatorFn } from '@angular/forms';

import { Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { ConfigService } from '../../../../@core/data/config.service';


export function zipCodeValidator(configService: ConfigService): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        return configService.getStateFromZip(control.value).pipe(
            map(({ state }) => (state ? null : { zip: true })),
            catchError(() => null),
        );
    };
}
@Directive({
    selector: '[ngxValidateZipCode]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ZipCodeValidatorDirective, multi: true }],
})
export class ZipCodeValidatorDirective implements AsyncValidator {
    constructor(private configService: ConfigService) { }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return zipCodeValidator(this.configService)(control);
    }

}


