import { Component, Input, OnInit } from '@angular/core';
import { CaseManagementService } from '../../@core/data/caseManagement.service';
import { ModalOptions, SizeModal } from '../../@theme/components/reusables';
import { ModalService } from '../../@core/utils/modal.service';
import { Router } from '@angular/router';
import { BH_SERVICE } from '../../@core/utils/constants';

@Component({
  selector: 'ngx-no-providers-found',
  templateUrl: './no-providers-found.component.html',
  styleUrls: ['./no-providers-found.component.scss'],
})
export class NoProvidersFoundComponent implements OnInit {

  @Input() caseData: any;
  selectState: string[] = [];
  BH_STRING: string;

  constructor(private modalService: ModalService, private caseMngt: CaseManagementService, private router: Router) { }

  ngOnInit() {
    this.getBhString();
  }

  getBhString() {
    this.BH_STRING = BH_SERVICE;
  }

  selectOnlineTherapy() {
    const modal: ModalOptions = {
      options: false,
    };
    this.modalService.showSwitchModal(SizeModal.LARGE, modal, this.convertCase.bind(this), true);
  }

  convertCase(data) {
    if (data && data.user && data.answer === 'Yes') {
      this.selectState = data.selectState;
      this.updateCase();

    }
  }

  updateCase(reason = '') {
    this.caseMngt.selectedCase = this.caseData;
    this.caseMngt.selectedCase.selectState = this.selectState;
    this.caseMngt.changeVideoToOnlineTherapy(reason, this.selectState);
  }

  goToFacetedSearch() {
    this.router.navigate([`/pages/counselor-faceted-search/${this.caseData.id}`]);
  }
}
