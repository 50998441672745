import { ProgressBarService } from './progress-bar.service';
import * as i0 from "@angular/core";
import * as i1 from "./progress-bar.service";
;
export var STEPS = {
    // let the string value match the router path for convenient routing
    recommendPath: 'worklife-recommend-service',
    feedbackPath: 'worklife-feedback',
    thankyouPath: 'worklife-thankyou',
};
var NpsFlowFormData = /** @class */ (function () {
    function NpsFlowFormData() {
        this.recommendPath = '';
        this.feedbackPath = '';
        this.thankyouPath = '';
    }
    NpsFlowFormData.prototype.reset = function () {
        this.recommendData = null;
        this.feedbackData = '';
    };
    NpsFlowFormData.prototype.clearCarePath = function () {
        this.recommendPath = '';
        this.feedbackPath = '';
        this.thankyouPath = '';
    };
    return NpsFlowFormData;
}());
export { NpsFlowFormData };
var WorklifeNpsSurveyService = /** @class */ (function () {
    function WorklifeNpsSurveyService(progressBarService) {
        this.progressBarService = progressBarService;
        this.formData = new NpsFlowFormData();
        this.workflowSteps = [
            { step: STEPS.recommendPath, valid: false },
            { step: STEPS.feedbackPath, valid: false },
            { step: STEPS.thankyouPath, valid: false },
        ];
    }
    WorklifeNpsSurveyService.prototype.getWorkFlowStepIndex = function (step) {
        return this.workflowSteps.findIndex(function (elem) { return elem.step === step; });
    };
    WorklifeNpsSurveyService.prototype.resetSteps = function () {
        // reset all the steps to invalid for start over.
        this.workflowSteps.forEach(function (elem) { elem.valid = false; });
    };
    WorklifeNpsSurveyService.prototype.resetFormData = function () {
        this.resetSteps();
        this.formData.reset();
        return this.formData;
    };
    WorklifeNpsSurveyService.prototype.clearCarePath = function () {
        this.formData.clearCarePath();
    };
    WorklifeNpsSurveyService.prototype.getNextStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        if (stepIndex < 0 || (stepIndex + 1) >= this.workflowSteps.length)
            return '';
        return this.workflowSteps[stepIndex + 1].step;
    };
    WorklifeNpsSurveyService.prototype.getPreviousStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        var workFlow = this.workflowSteps;
        if (stepIndex <= 0)
            return 'recommend-service';
        return workFlow[stepIndex - 1].step;
    };
    Object.defineProperty(WorklifeNpsSurveyService.prototype, "recommendData", {
        get: function () {
            return this.formData.recommendData;
        },
        set: function (dataRecommend) {
            this.formData.recommendData = dataRecommend;
            this.validateStep(STEPS.recommendPath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorklifeNpsSurveyService.prototype, "feedbackData", {
        get: function () {
            return this.formData.feedbackData;
        },
        set: function (dataSuggestion) {
            this.formData.feedbackData = dataSuggestion;
            this.validateStep(STEPS.feedbackPath);
        },
        enumerable: true,
        configurable: true
    });
    WorklifeNpsSurveyService.prototype.setCarePathProgress = function (step) {
        var blockNumber = this.workflowSteps.map(function (s) { return s.step; }).indexOf(step);
        var numOfSteps = this.workflowSteps.length; // for the employee information screen
        this.progressBarService.progress = { progress: blockNumber + 1, numberOfBlocks: numOfSteps, width: 36 };
    };
    WorklifeNpsSurveyService.prototype.validateStep = function (step) {
        var index = this.getWorkFlowStepIndex(step);
        if (index >= 0) {
            this.workflowSteps[index].valid = true;
        }
        ;
    };
    WorklifeNpsSurveyService.prototype.invalidateStep = function (step) {
        var index = this.getWorkFlowStepIndex(step);
        if (index >= 0) {
            this.workflowSteps[index].valid = false;
        }
        ;
    };
    WorklifeNpsSurveyService.prototype.getFirstInvalidStep = function (step) {
        // if all previous steps are valid, return blank, else return the first invalid step
        // used by the route guard.
        var inValidStep = '';
        this.workflowSteps.some(function (elem) {
            if (elem.step === step) {
                return true;
            }
            else {
                if (!elem.valid) {
                    inValidStep = elem.step;
                    return true;
                }
            }
        });
        return inValidStep;
    };
    WorklifeNpsSurveyService.ngInjectableDef = i0.defineInjectable({ factory: function WorklifeNpsSurveyService_Factory() { return new WorklifeNpsSurveyService(i0.inject(i1.ProgressBarService)); }, token: WorklifeNpsSurveyService, providedIn: "root" });
    return WorklifeNpsSurveyService;
}());
export { WorklifeNpsSurveyService };
