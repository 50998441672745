import { OnInit, AfterViewChecked } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../modal.component';
import { ChangeDetectorRef } from '@angular/core';
var ProviderSelectionModalComponent = /** @class */ (function () {
    function ProviderSelectionModalComponent(activeModal, cdr) {
        this.activeModal = activeModal;
        this.cdr = cdr;
        this._modal = { context: {}, modalHeader: '', options: false };
        this.isModalLoaded = false;
        this.providerSelected = true;
        this.submitted = false;
        this.isValidating = false;
        this.isHomeAddressSame = true;
        this.primaryAddressExist = false;
        this.formattedOriginalAddress = '';
        this.chosenAdress = '';
        this.user = {};
        this.souAgreed = false;
        this.messageOptions = {
            'onsiteCounseling': 'check the box below and someone will contact you within one business day to schedule your appointment \
    based on the next available time slot.',
            'In-Office': 'check the box below and contact your counselor to schedule an appointment that works for you.',
            'coaching': 'check the box below and contact your coach to schedule an appointment that works for you.',
        };
        this.modality = '';
        this.message = '';
        this.buttonText = '';
        this.isCoaching = false;
    }
    Object.defineProperty(ProviderSelectionModalComponent.prototype, "modal", {
        set: function (value) {
            if (Object.keys(value).length > 0) {
                var _a = value.context, provider = _a.provider, isCoach = _a.isCoach, user = _a.user, modality = _a.modality, selected = _a.selected;
                this.selected = selected;
                this.user = user;
                this.modality = modality;
                this.isCoaching = isCoach;
                this.message = this.messageOptions[this.modality];
                if (!!provider) {
                    this.providerSelected = true;
                    this.provider = provider;
                    if (isCoach) {
                        this.heading = "Coach Selected";
                    }
                    else {
                        this.heading = "Counselor Selected";
                    }
                }
                else {
                    this.heading = 'An onsite counselor will be selected for you';
                    this.providerSelected = false;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ProviderSelectionModalComponent.prototype.ngOnInit = function () {
        this.setButtonText();
        if (!this.provider.credentials) {
            this.provider.credentials = [];
            this.provider.credentials.push('Adults');
        }
    };
    ProviderSelectionModalComponent.prototype.ngAfterViewChecked = function () {
        if (document.getElementsByClassName('modal-dialog')[0] && !this.isModalLoaded) {
            var checkbox = document.getElementById('sou-checkbox');
            checkbox.focus();
            checkbox.blur();
            this.modalDialog = document.getElementsByClassName('modal-dialog')[0];
            this.modalDialog.scrollIntoView(true);
            this.isModalLoaded = true;
            this.cdr.detectChanges();
        }
    };
    ProviderSelectionModalComponent.prototype.setButtonText = function () {
        this.buttonText = this.isCoaching ? 'SELECT THIS COACH' : 'SELECT THIS COUNSELOR';
    };
    ProviderSelectionModalComponent.prototype.closeModal = function () {
        this.activeModal.close({ answer: ConfirmationModal.No, user: this.user });
    };
    ProviderSelectionModalComponent.prototype.selectOfficeLocations = function (locations) {
        return locations.filter(function (x) { return x.state; });
    };
    ProviderSelectionModalComponent.prototype.accept = function () {
        this.activeModal.close({ answer: ConfirmationModal.Yes, user: this.user, selected: this.selected });
    };
    ProviderSelectionModalComponent.prototype.completeRequest = function () {
        if (this.submitted)
            return;
        this.submitted = true;
        this.accept();
    };
    return ProviderSelectionModalComponent;
}());
export { ProviderSelectionModalComponent };
