import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  DxAuthComponent,
  DxLoginComponent,
  DxSsoLoginComponent,
  DxLogoutComponent,
  DxRegisterComponent,
  DxRequestPasswordComponent,
  DxResetPasswordComponent,
  DxEmailVerifiedComponent,
  DxVerifyEmailComponent,
  DxEmailVerificationErrorComponent,
  DxChangePasswordComponent,
  TermsComponent,
  PrivacyComponent,
  SouComponent,
  AccountDeactivatedComponent,
} from './@theme/components/auth';
import { environment } from '../environments/environment';
import { MagicLinkComponent } from './sso/magic-link.component';
import { AgeOfMajorityComponent } from './@theme/components/auth/register/age-of-majority/age-of-majority.component';
import { SsoAuthGuardService } from './@core/utils/sso-auth-guard.service';

const normalRoutes: Routes = [
  {
    path: 'landing',
    redirectTo: '/pages/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'bypass/:orgId',
    component: MagicLinkComponent,
  },
  {
    path: 'bypass/:orgId/:ssoFlag',
    component: MagicLinkComponent,
  },
  { path: 'pages', loadChildren: 'app/pages/pages.module#PagesModule' },
  { path: 'provider', loadChildren: 'app/provider/provider.module#ProviderModule' },
  {
    path: 'auth',
    component: DxAuthComponent,
    children: [
      {
        path: '',
        component: DxLoginComponent,
      },
      {
        path: 'login',
        component: DxLoginComponent,
      },
      {
        path: 'sso-login',
        component: DxSsoLoginComponent,
      },
      {
        path: 'change-password',
        component: DxChangePasswordComponent,
        canActivate: [SsoAuthGuardService],
      },
      {
        path: 'register',
        component: DxRegisterComponent,
      },
      {
        path: 'age-of-majority',
        component: AgeOfMajorityComponent,
      },
      {
        path: 'logout',
        component: DxLogoutComponent,
      },
      {
        path: 'request-password',
        component: DxRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: DxResetPasswordComponent,
      },
      {
        path: 'email-verified',
        component: DxEmailVerifiedComponent,
      },
      {
        path: 'email-verification-error',
        component: DxEmailVerificationErrorComponent,
      },
      {
        path: 'verify-email',
        component: DxVerifyEmailComponent,
      },
      {
        path: 'terms',
        component: TermsComponent,
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
      },
      // SOU component temp
      {
        path: 'sou',
        component: SouComponent,
      },
      {
        path: 'account-deactivated',
        component: AccountDeactivatedComponent,
      },
    ],
  },

  { path: '', redirectTo: '/pages/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },

];
const maintenanceRoutes: Routes = [{
  path: 'landing',
  redirectTo: '/pages/dashboard',
  pathMatch: 'full',
},
{ path: '', redirectTo: '/pages/dashboard', pathMatch: 'full' },
  // { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
};
const routes = environment.enableMaintenanceMode ? maintenanceRoutes : normalRoutes;

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
