import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from './configuration';
import { environment } from '../../../environments/environment';
import { emindfulConstants, emindfulRoutes } from './api/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./variables";
import * as i3 from "./configuration";
var EmindfulService = /** @class */ (function () {
    function EmindfulService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = environment.basePath;
        this.rootPath = environment.rootPath;
        this.defaultHeaders = new HttpHeaders();
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    EmindfulService.prototype.getLivePrograms = function () {
        return this.httpClient.get("" + this.basePath + emindfulRoutes.getPrograms + "?format=" + emindfulConstants.live + "&limit=" + emindfulConstants.programLimit);
    };
    EmindfulService.prototype.getOnDemandPrograms = function () {
        return this.httpClient.get("" + this.basePath + emindfulRoutes.getPrograms + "?format=" + emindfulConstants.recorded + "&limit=" + emindfulConstants.programLimit);
    };
    EmindfulService.prototype.redirectToEMLife = function (_a) {
        var link = _a.link, relayState = _a.relayState, inCurrentTab = _a.inCurrentTab;
        var relayStateParam = relayState ? "?RelayState=" + encodeURIComponent(relayState) : '';
        var eMLifeIntegrationUrl = link ? "" + this.rootPath + link : this.rootPath + "/sso/emindful" + relayStateParam;
        this.httpClient.get(eMLifeIntegrationUrl, {
            headers: { 'Content-Type': 'text/plain; charset=utf-8' },
            responseType: 'text'
        }).subscribe(function (samlForm) {
            if (inCurrentTab) {
                window.document.write(samlForm);
            }
            else {
                var windowRef = window.open('', '_blank');
                if (windowRef && windowRef.document) {
                    windowRef.document.write(samlForm);
                }
            }
        });
    };
    EmindfulService.ngInjectableDef = i0.defineInjectable({ factory: function EmindfulService_Factory() { return new EmindfulService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8)); }, token: EmindfulService, providedIn: "root" });
    return EmindfulService;
}());
export { EmindfulService };
