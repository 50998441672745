import { Injectable } from '@angular/core';
import { ProgressBarService } from './progress-bar.service';
export interface Progress {
  progress: number,
  numberOfBlocks: number,
  width?: number
};

export const STEPS = {
  // let the string value match the router path for convenient routing
  recommendPath: 'worklife-recommend-service',
  feedbackPath: 'worklife-feedback',
  thankyouPath: 'worklife-thankyou',
}

export class NpsFlowFormData {
  recommendData: number;
  feedbackData: string;
  recommendPath: string = '';
  feedbackPath: string = '';
  thankyouPath: string = '';
  reset(): void {
    this.recommendData = null;
    this.feedbackData = '';
  }
  clearCarePath(): void {
    this.recommendPath = '';
    this.feedbackPath = '';
    this.thankyouPath = '';
  }
}
@Injectable({
  providedIn: 'root',
})
export class WorklifeNpsSurveyService {
  private formData: NpsFlowFormData = new NpsFlowFormData();
  constructor(private progressBarService: ProgressBarService) { }
  private workflowSteps = [

    { step: STEPS.recommendPath, valid: false },
    { step: STEPS.feedbackPath, valid: false },
    { step: STEPS.thankyouPath, valid: false },
  ]
  private getWorkFlowStepIndex(step: string): number {
    return this.workflowSteps.findIndex(elem => elem.step === step);
  }

  resetSteps(): void {
    // reset all the steps to invalid for start over.
    this.workflowSteps.forEach(elem => { elem.valid = false });
  }
  resetFormData(): NpsFlowFormData {
    this.resetSteps();
    this.formData.reset();
    return this.formData;
  }

  clearCarePath() {
    this.formData.clearCarePath();
  }
  getNextStep(step: string): string {
    const stepIndex = this.getWorkFlowStepIndex(step);
    if (stepIndex < 0 || (stepIndex + 1) >= this.workflowSteps.length) return '';
    return this.workflowSteps[stepIndex + 1].step;
  }

  getPreviousStep(step: string): string {
    const stepIndex = this.getWorkFlowStepIndex(step);
    const workFlow = this.workflowSteps;

    if (stepIndex <= 0) return 'recommend-service';
    return workFlow[stepIndex - 1].step;
  }

  get recommendData(): number {
    return this.formData.recommendData;
  }


  set recommendData(dataRecommend: number) {
    this.formData.recommendData = dataRecommend;
    this.validateStep(STEPS.recommendPath);
  }

  set feedbackData(dataSuggestion: string) {
    this.formData.feedbackData = dataSuggestion;
    this.validateStep(STEPS.feedbackPath);
  }

  get feedbackData(): string {
    return this.formData.feedbackData;
  }

  setCarePathProgress(step: string) {
    const blockNumber = this.workflowSteps.map(s => s.step).indexOf(step);
    const numOfSteps = this.workflowSteps.length; // for the employee information screen

    this.progressBarService.progress = { progress: blockNumber + 1, numberOfBlocks: numOfSteps, width: 36 };
  }

  validateStep(step: string): void {
    const index = this.getWorkFlowStepIndex(step);
    if (index >= 0) { this.workflowSteps[index].valid = true };
  }

  invalidateStep(step: string): void {
    const index = this.getWorkFlowStepIndex(step);
    if (index >= 0) { this.workflowSteps[index].valid = false };
  }

  getFirstInvalidStep(step: string): string {
    // if all previous steps are valid, return blank, else return the first invalid step
    // used by the route guard.
    let inValidStep: string = '';
    this.workflowSteps.some(elem => {
      if (elem.step === step) {
        return true;
      } else {
        if (!elem.valid) {
          inValidStep = elem.step;
          return true;
        }
      }
    });
    return inValidStep;
  }
}
