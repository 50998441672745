
<div class="form-group">
  <label for="input-addressLineOne" class="sr-only">Street Address</label>
  <input name="addressLineOne" [(ngModel)]="address.street" id="input-addressLineOne" #addressLineOne="ngModel"
    class="form-control" placeholder="Street Address"
    [class.form-control-danger]="addressLineOne.invalid && addressLineOne.touched" required>
  <small class="form-text error"
    *ngIf="addressLineOne.invalid && addressLineOne.touched && addressLineOne.errors?.required">
    Street Address is required!
  </small>
</div>

<div class="form-group">
  <label for="input-city" class="sr-only">City</label>
  <input name="city" [(ngModel)]="address.city" id="input-city" #city="ngModel" class="form-control"
    placeholder="City" [class.form-control-danger]="city.invalid && city.touched" required>
  <small class="form-text error" *ngIf="city.invalid && city.touched && city.errors?.required">
    City is required!
  </small>
</div>

<div class="form-group">
  <label for="input-zip" class="sr-only">Zip/Postal Code</label>
  <input name="input-zip" [(ngModel)]="address.postal" id="input-zip" #zip="ngModel" class="form-control"
    placeholder="Zip" [class.form-control-danger]="zip.invalid && zip.touched" required ngxValidateZipCode
    minlength="5">
  <ng-container *ngIf="zip.invalid && zip.touched">
    <small class="form-text error" *ngIf="zip.errors?.required">
      Zip Code is required!
    </small>
    <small class="form-text error" *ngIf="zip.errors?.minlength">
      Zip Code should be at least 5 digits
    </small>
    <small class="form-text error" *ngIf="zip.errors?.zip">
      Zip Code is invalid
    </small>
  </ng-container>
  <small *ngIf="zip.pending">Validating Zip Code...</small>
</div>

<div class="form-group">
  <label for="input-state" class="sr-only">State</label>
  <input name="state" [(ngModel)]="address.state" id="input-state" #state="ngModel" class="form-control"
    placeholder="State" [class.form-control-danger]="state.invalid && state.touched" required>
  <small class="form-text error" *ngIf="state.invalid && state.touched && state.errors?.required">
    State is required!
  </small>
</div>

<div class="form-group">
  <label for="input-country" class="sr-only">Country</label>
  <input name="country" [(ngModel)]="address.country" id="input-country" #country="ngModel" class="form-control"
    placeholder="Country" [class.form-control-danger]="country.invalid && country.touched" required>
  <small class="form-text error" *ngIf="country.invalid && country.touched && country.errors?.required">
    Country is required!
  </small>
</div>
