import { Component, OnInit } from '@angular/core';
import { EmotionalWellbeingSurveyService } from '../../../@core/utils/emotional-wellbeing-survey.service';
import { Router } from '@angular/router';
import { SurveyCalcService } from '../../../@core/utils/survey-calc.service';
import { RISK } from '../../../@core/utils/enums';

const ROUTE_NAME = 'thankyou-survey';
@Component({
  selector: 'ngx-emotional-thankyou',
  templateUrl: './emotional-thankyou.component.html',
  styleUrls: ['./emotional-thankyou.component.scss'],
})
export class EmotionalThankyouComponent implements OnInit {
  riskLevel: RISK = RISK.MEDIUM;
  // risk logic variables
  isRiskHigh: boolean = false;
  isRiskMedium: boolean = false;
  isRiskLow: boolean = false;
  isShowLogin: boolean = false;
  constructor(
    private router: Router,
    private emotionalWellBeingService: EmotionalWellbeingSurveyService, private surveyCalcService: SurveyCalcService) {
    this.emotionalWellBeingService.setCarePathProgress(ROUTE_NAME);
    this.riskLevel = this.surveyCalcService.calculateRisk();
    this.isRiskLow = this.riskLevel === RISK.LOW;
    this.isRiskMedium = this.riskLevel === RISK.MEDIUM;
    this.isRiskHigh = this.riskLevel === RISK.HIGH;
  }

  ngOnInit() {
    if (window.localStorage.getItem('auth_app_token')) {
      this.isShowLogin = true;
    }
  }
  goNext() {
    this.router.navigate(['/auth/login']);

  }

  navigate(): void {
    this.emotionalWellBeingService.updateApprovalMessage('#trendgraph');
    this.router.navigate(['/pages/dashboard'], {fragment: 'trendgraph'});


  }
}
