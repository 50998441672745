import { WORKLIFE_OPTIONS } from '../../pages/intake/work-life/work-life-options';
import { ProgressBarService } from './progress-bar.service';
;
export var STEPS = {
    // let the string value match the router path for convenient routing
    employerInformation2: 'employer-information-2',
    ssoEmail: 'sso-email',
    carePath: 'care-path',
    emotions: 'emotions',
    // emotionIntensity: 'emotion-intensity',
    emotionalState: 'emotional-state',
    moodFrequency: 'mood-frequency',
    speedBump: 'speed-bump',
    lifeSatisfaction: 'life-satisfaction',
    stressIntakeSurvey: 'stress-survey',
    transitionToCounselor: 'transition-to-counselor',
    additionalInsurance: 'additional-insurance',
    counselingFor: 'counseling-for',
    workWithCounselor: 'work-with-counselor',
    dontKnow: 'dont-know-path',
    riskGauge: 'risk-gauge',
    modeOfHelp: 'mode-of-help',
    workLife: 'work-life',
    legalIssue: 'legal-issues',
    parentingAdvice: 'parenting-advice',
    careAdvice: 'care-advice',
    conciergeService: 'concierge-service',
    emergencyService: 'emergency-service',
    financialIssue: 'financial-issues',
    counselingLocation: 'counseling-location',
    workLifeSubCategory: 'work-life-category',
    workLifeRequestForm: 'work-life-request-form',
    counselorLocation: 'counselor-location',
    employeeNumber: 'employee-number',
};
export var STATES = {
    '': '',
    'AL': 'Alabama',
    'AK': 'Alaska',
    // 'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District Of Columbia',
    // 'FM': 'Federated States Of Micronesia',
    'FL': 'Florida',
    'GA': 'Georgia',
    // 'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    // 'MH': 'Marshall Islands',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    // 'MP': 'Northern Mariana Islands',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    // 'PW': 'Palau',
    'PA': 'Pennsylvania',
    // 'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Islands',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',
};
var IntakeFlowFormData = /** @class */ (function () {
    function IntakeFlowFormData() {
        this.carePath = '';
        this.emotions = [];
        this.riskOptions = '';
        // emotionIntensity: string = '';
        this.emotionalStates = [];
        this.moodFrequencies = [];
        this.speedBump = false;
        this.selectState = [];
        this.lifeSatisfaction = '';
        this.stressIntakeSurvey = '';
        this.transitionToCounselor = false;
        this.counselingFor = { participants: '', age_ranges: [] };
        this.modeOfHelp = '';
        this.workWithCounselorOption = '';
        this.workLifeCategory = '';
        this.workLifeSubCategory = '';
        this.workLifeRequestForm = { preferredCommunication: '', description: '' };
        this.workLifeCategoryOption = '';
        this.currentContract = {};
        this.organization = { parentOrg: '', phone: '', id: '', name: '', email: '', timeZone: '', locations: [] };
        this.companyCode = '';
        this.employeeNumber = '';
        this.emotionalCareTypeRequiresCounselingLocationStep = false;
        this.counselingLocation = { selection: '', value: '' };
        this.employerInformation = { companyName: '', companySiteLocation: '', companyAffiliation: '', chosenSiteLocation: {} };
        this.user = {};
        this.requiresEmployeeNumber = false;
        this.riskAtIntake = '';
        this.depressionAtIntake = '';
    }
    IntakeFlowFormData.prototype.reset = function () {
        this.carePath = '';
        this.emotions = [];
        //    this.emotionIntensity = '';
        this.emotionalStates = [];
        this.moodFrequencies = [];
        this.speedBump = false;
        this.totalScore = 0;
        this.lifeSatisfaction = '';
        this.selectState = [];
        this.stressIntakeSurvey = '';
        this.transitionToCounselor = false;
        this.additionalInsurance = { isPartImportnant: '', provider: '' };
        this.counselingFor = { participants: '', age_ranges: [] };
        this.modeOfHelp = '';
        this.workWithCounselorOption = '';
        this.riskOptions = '';
        this.workLifeCategoryOption = '';
        this.counselingLocation = { selection: '', value: '' };
        this.employerInformation = { companyName: '', companySiteLocation: '', companyAffiliation: '', chosenSiteLocation: {} };
        // this.organization = { parentOrg: '', phone: '', id: '', name: '', email: '', timeZone: '', locations: [] };
        // this.currentContract = {};
        this.workLifeCategory = '';
        this.workLifeSubCategory = '';
        this.workLifeRequestForm = { preferredCommunication: '', description: '' };
        this.workWithCounselorOption = '';
        this.user = {};
        this.companyCode = '';
        this.requiresEmployeeNumber = false;
        this.riskAtIntake = '';
        this.depressionAtIntake = '';
        // this.pathListOptions = [];
    };
    IntakeFlowFormData.prototype.clearCarePath = function () {
        this.carePath = '';
        this.emotions = [];
        //    this.emotionIntensity = '';
        this.selectState = [];
        this.emotionalStates = [];
        this.moodFrequencies = [];
        this.speedBump = false;
        this.totalScore = 0;
        this.lifeSatisfaction = '';
        this.stressIntakeSurvey = '';
        this.transitionToCounselor = false;
        this.additionalInsurance = { isPartImportnant: '', provider: '' };
        this.counselingFor = { participants: '', age_ranges: [] };
        this.modeOfHelp = '';
        this.workWithCounselorOption = '';
        this.riskOptions = '';
        this.workLifeCategoryOption = '';
        this.counselingLocation = { selection: '', value: '' };
        // this.currentContract = {};
        this.workLifeCategory = '';
        this.workLifeSubCategory = '';
        this.workLifeRequestForm = { preferredCommunication: '', description: '' };
        this.workWithCounselorOption = '';
        this.requiresEmployeeNumber = false;
        this.riskAtIntake = '';
        this.depressionAtIntake = '';
        // this.pathListOptions = [];
    };
    return IntakeFlowFormData;
}());
export { IntakeFlowFormData };
var IntakeFlowService = /** @class */ (function () {
    function IntakeFlowService(progressBarService) {
        this.progressBarService = progressBarService;
        this.formData = new IntakeFlowFormData();
        this.typeOfCounseling = '';
        this.workflowSteps = [
            // the order matters
            // { step: STEPS.employerInformation2, valid: false },
            // { step: STEPS.ssoEmail, valid: false },
            { step: STEPS.carePath, valid: false },
            { step: STEPS.emotionalState, valid: false },
            { step: STEPS.moodFrequency, valid: false },
            { step: STEPS.speedBump, valid: false },
            { step: STEPS.riskGauge, valid: false },
            { step: STEPS.stressIntakeSurvey, valid: false },
            { step: STEPS.lifeSatisfaction, valid: false },
            { step: STEPS.modeOfHelp, valid: false },
            // { step: STEPS.employeeNumber, valid: false },
            // { step: STEPS.transitionToCounselor, valid: false },
            // { step: STEPS.workWithCounselor, valid: false },
            { step: STEPS.counselingLocation, valid: false },
            // { step: STEPS.additionalInsurance, valid: false },
            { step: STEPS.counselingFor, valid: false },
        ];
        this.workLifeSteps = [
            // the order matters
            { step: STEPS.workLife, valid: false },
            { step: STEPS.workLifeSubCategory, valid: false },
        ];
    }
    Object.defineProperty(IntakeFlowService.prototype, "counselingLocationDetail", {
        get: function () {
            return this.formData.counselingLocation;
        },
        set: function (value) {
            this.formData.counselingLocation = value;
            this.validateStep(STEPS.counselingLocation);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "organization", {
        get: function () {
            return this.formData.organization;
        },
        set: function (value) {
            this.formData.organization = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "employerInformation", {
        get: function () {
            return this.formData.employerInformation;
        },
        set: function (employerInformation) {
            this.formData.employerInformation = employerInformation;
            this.validateStep(STEPS.employerInformation2);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "carePath", {
        get: function () {
            return this.formData.carePath;
        },
        set: function (path) {
            // update value and set step to valid.
            // we trust that the relevant screen validated this data.
            this.formData.carePath = path;
            this.validateStep(STEPS.carePath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "riskOptions", {
        get: function () {
            return this.formData.riskOptions;
        },
        set: function (riskOptions) {
            this.formData.riskOptions = riskOptions;
            this.validateStep(STEPS.riskGauge);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "pathListOptions", {
        get: function () {
            return this.formData.pathListOptions;
        },
        set: function (pathOptions) {
            this.formData.pathListOptions = pathOptions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "employeeNumber", {
        get: function () {
            return this.formData.employeeNumber;
        },
        set: function (employeeNumber) {
            this.formData.employeeNumber = employeeNumber;
            this.validateEmployeeNumberStep();
        },
        enumerable: true,
        configurable: true
    });
    IntakeFlowService.prototype.validateEmployeeNumberStep = function () {
        this.validateStep(STEPS.employeeNumber);
    };
    Object.defineProperty(IntakeFlowService.prototype, "workLifeCategoryOption", {
        // work life
        get: function () {
            return this.formData.workLifeCategoryOption;
        },
        set: function (type) {
            this.formData.workLifeCategoryOption = type;
            this.workLifeCategory = this.getCategoryFromWorkLifeOptions(type);
        },
        enumerable: true,
        configurable: true
    });
    IntakeFlowService.prototype.getCategoryFromWorkLifeOptions = function (category) {
        if (category === WORKLIFE_OPTIONS.LEGAL_ISSUE) {
            category = STEPS.legalIssue;
        }
        else if (category === WORKLIFE_OPTIONS.FINANCIAL_ISSUE) {
            category = STEPS.financialIssue;
        }
        else if (category === WORKLIFE_OPTIONS.PARENTING_ADVICE) {
            category = STEPS.parentingAdvice;
        }
        else {
            category = STEPS.careAdvice;
        }
        return category;
    };
    Object.defineProperty(IntakeFlowService.prototype, "workLifeCategory", {
        get: function () {
            return this.formData.workLifeCategory;
        },
        set: function (type) {
            this.formData.workLifeCategory = type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "workLifeSubCategory", {
        get: function () {
            return this.formData.workLifeSubCategory;
        },
        set: function (type) {
            this.formData.workLifeSubCategory = type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "workLifeRequestForm", {
        get: function () {
            return this.formData.workLifeRequestForm;
        },
        set: function (type) {
            this.formData.workLifeRequestForm = type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "lifeSatisfaction", {
        // Life-satisfaction
        get: function () {
            return this.formData.lifeSatisfaction;
        },
        set: function (lifeSatisfaction) {
            this.formData.lifeSatisfaction = lifeSatisfaction;
            this.validateStep(STEPS.lifeSatisfaction);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "stressIntakeSurvey", {
        get: function () {
            return this.formData.stressIntakeSurvey;
        },
        set: function (stressIntakeSurvey) {
            this.formData.stressIntakeSurvey = stressIntakeSurvey;
            this.validateStep(STEPS.stressIntakeSurvey);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "emotionalStates", {
        get: function () {
            return this.formData.emotionalStates;
        },
        set: function (states) {
            this.formData.emotionalStates = states;
            this.validateStep(STEPS.emotionalState);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "selectState", {
        get: function () {
            return this.formData.selectState;
        },
        set: function (zipState) {
            this.formData.selectState = zipState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "totalScore", {
        set: function (score) {
            this.formData.totalScore = score;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "speedBump", {
        get: function () {
            return this.formData.speedBump;
        },
        set: function (seen) {
            this.formData.speedBump = seen;
            this.validateStep(STEPS.speedBump);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "moodFrequencies", {
        get: function () {
            return this.formData.moodFrequencies;
        },
        set: function (frequencies) {
            this.formData.moodFrequencies = frequencies;
            this.validateStep(STEPS.moodFrequency);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "transitionToCounselor", {
        get: function () {
            return this.formData.transitionToCounselor;
        },
        set: function (transitioned) {
            this.formData.transitionToCounselor = transitioned;
            this.validateStep(STEPS.transitionToCounselor);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "additionalInsurance", {
        get: function () {
            return this.formData.additionalInsurance;
        },
        set: function (selections) {
            this.formData.additionalInsurance = selections;
            this.validateStep(STEPS.additionalInsurance);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "counselingFor", {
        get: function () {
            return this.formData.counselingFor;
        },
        set: function (selections) {
            this.formData.counselingFor = selections;
            this.validateStep(STEPS.counselingFor);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "modeOfHelp", {
        get: function () {
            return this.formData.modeOfHelp;
        },
        set: function (mode) {
            this.formData.modeOfHelp = mode;
            this.validateStep(STEPS.modeOfHelp);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "workWithCounselorOption", {
        get: function () {
            return this.formData.workWithCounselorOption;
        },
        set: function (option) {
            this.formData.workWithCounselorOption = option;
            this.validateStep(STEPS.workWithCounselor);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "user", {
        get: function () {
            return this.formData.user;
        },
        set: function (value) {
            this.formData.user = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "companyCode", {
        get: function () {
            return this.formData.companyCode;
        },
        set: function (value) {
            this.formData.companyCode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "emotionalCareTypeRequiresCounselingLocationStep", {
        get: function () {
            return this.formData.emotionalCareTypeRequiresCounselingLocationStep;
        },
        set: function (value) {
            this.formData.emotionalCareTypeRequiresCounselingLocationStep = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "riskAtIntake", {
        get: function () {
            return this.formData.riskAtIntake;
        },
        set: function (value) {
            this.formData.riskAtIntake = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntakeFlowService.prototype, "depressionAtIntake", {
        get: function () {
            return this.formData.depressionAtIntake;
        },
        set: function (value) {
            this.formData.depressionAtIntake = value;
        },
        enumerable: true,
        configurable: true
    });
    IntakeFlowService.prototype.getFormData = function () {
        return this.formData;
    };
    IntakeFlowService.prototype.resetFormData = function () {
        this.resetSteps();
        this.formData.reset();
        return this.formData;
    };
    IntakeFlowService.prototype.clearCarePath = function () {
        this.formData.clearCarePath();
    };
    IntakeFlowService.prototype.isFormValid = function () {
        // check that all formData fields were populated.
        return !this.workflowSteps.some(function (elem) {
            return elem.valid === false;
        });
    };
    IntakeFlowService.prototype.getWorkFlowStepIndex = function (step) {
        return this.workflowSteps.findIndex(function (elem) { return elem.step === step; });
    };
    IntakeFlowService.prototype.validateStep = function (step) {
        var index = this.getWorkFlowStepIndex(step);
        if (index >= 0) {
            this.workflowSteps[index].valid = true;
        }
        ;
    };
    IntakeFlowService.prototype.invalidateStep = function (step) {
        var index = this.getWorkFlowStepIndex(step);
        if (index >= 0) {
            this.workflowSteps[index].valid = false;
        }
        ;
    };
    IntakeFlowService.prototype.getNextStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        if (stepIndex < 0 || (stepIndex + 1) >= this.workflowSteps.length)
            return '';
        return this.workflowSteps[stepIndex + 1].step;
    };
    IntakeFlowService.prototype.getPreviousStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        var workFlow = this.workflowSteps;
        if (stepIndex <= 0) {
            stepIndex = this.workLifeSteps.findIndex(function (elem) { return elem.step === step; });
            workFlow = this.workLifeSteps;
        }
        if (stepIndex <= 0)
            return 'care-path';
        return workFlow[stepIndex - 1].step;
    };
    IntakeFlowService.prototype.disableStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        if (stepIndex < 0)
            return false;
        this.workflowSteps.splice(stepIndex, 1);
        return true;
    };
    IntakeFlowService.prototype.reEnableStep = function (step, turnOffIfEnabled) {
        if (turnOffIfEnabled === void 0) { turnOffIfEnabled = false; }
        var result = this.workflowSteps.find(function (element) { return element.step === step; });
        if (result && turnOffIfEnabled) {
            var stepIndex = this.getWorkFlowStepIndex(step);
            if (stepIndex < 0)
                return false;
            this.workflowSteps.splice(stepIndex, 1);
            return false;
        }
        else if (result || turnOffIfEnabled) {
            return;
        }
        else {
            this.workflowSteps.splice(0, 0, { step: step, valid: false });
            return true;
        }
    };
    IntakeFlowService.prototype.resetSteps = function () {
        // reset all the steps to invalid for start over.
        this.workflowSteps.forEach(function (elem) { elem.valid = false; });
    };
    IntakeFlowService.prototype.getFirstInvalidStep = function (step) {
        // if all previous steps are valid, return blank, else return the first invalid step
        // used by the route guard.
        var inValidStep = '';
        this.workflowSteps.some(function (elem) {
            if (elem.step === step) {
                return true;
            }
            else {
                if (!elem.valid) {
                    inValidStep = elem.step;
                    return true;
                }
            }
        });
        return inValidStep;
    };
    IntakeFlowService.prototype.setCarePathProgress = function (step) {
        var blockNumber = this.workflowSteps.map(function (s) { return s.step; }).indexOf(step);
        var numOfSteps = this.workflowSteps.length + 1; // for the employee information screen
        this.progressBarService.progress = { progress: blockNumber + 1, numberOfBlocks: numOfSteps, width: 10 };
    };
    return IntakeFlowService;
}());
export { IntakeFlowService };
