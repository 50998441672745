/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./concerned-welfare.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular-slider/ngx-slider/angular-slider-ngx-slider.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular-slider/ngx-slider";
import * as i5 from "@angular/common";
import * as i6 from "./concerned-welfare.component";
import * as i7 from "../../../@core/utils/nps-survey.service";
import * as i8 from "@angular/router";
var styles_ConcernedWelfareComponent = [i0.styles];
var RenderType_ConcernedWelfareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConcernedWelfareComponent, data: {} });
export { RenderType_ConcernedWelfareComponent as RenderType_ConcernedWelfareComponent };
function View_ConcernedWelfareComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "font-500"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " - ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedValue; var currVal_1 = _co.selectedText; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ConcernedWelfareComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["--"]))], null, null); }
export function View_ConcernedWelfareComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "animate__animated animate__slideInRight animate__faster"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row justify-content-center text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "col-12 top header-text font-size-36 font-500 font-montserrat color-black"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" I believe my counselor or coach is concerned about my welfare. "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h5", [["class", "small_title mt32 mb45"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please indicate how you feel about this statement."])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "ngx-slider", [["class", "mb75 ngx-slider"]], [[2, "vertical", null], [2, "animate", null], [2, "with-legend", null], [1, "disabled", 0], [1, "aria-label", 0]], [[null, "valueChange"], [null, "click"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.value = $event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.changeValueEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ɵa_0, i2.RenderType_ɵa)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.ɵa]), i1.ɵdid(8, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "remove_pointer": 0 }), i1.ɵdid(10, 4964352, null, 1, i4.ɵa, [i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef, i1.NgZone], { value: [0, "value"], options: [1, "options"] }, { valueChange: "valueChange" }), i1.ɵqud(335544320, 1, { tooltipTemplate: 0 }), (_l()(), i1.ɵeld(12, 0, null, null, 5, "p", [["class", "survey_answer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your answer : "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConcernedWelfareComponent_1)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConcernedWelfareComponent_2)), i1.ɵdid(17, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "row justify-content-center no-gutters mb24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "btn btn-primary col-12 col-md-5"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CONTINUE "])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fas fa-arrow-circle-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_5 = "mb75"; var currVal_6 = _ck(_v, 9, 0, !_co.isShowAnswer); _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_7 = _co.value; var currVal_8 = _co.options; _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_9 = _co.isShowAnswer; _ck(_v, 15, 0, currVal_9); var currVal_10 = !_co.isShowAnswer; _ck(_v, 17, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 10).sliderElementVerticalClass; var currVal_1 = i1.ɵnov(_v, 10).sliderElementAnimateClass; var currVal_2 = i1.ɵnov(_v, 10).sliderElementWithLegendClass; var currVal_3 = i1.ɵnov(_v, 10).sliderElementDisabledAttr; var currVal_4 = i1.ɵnov(_v, 10).sliderElementAriaLabel; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_11 = (_co.submitted || _co.disableButton); _ck(_v, 19, 0, currVal_11); }); }
export function View_ConcernedWelfareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-concerned-welfare", [], null, null, null, View_ConcernedWelfareComponent_0, RenderType_ConcernedWelfareComponent)), i1.ɵdid(1, 114688, null, 0, i6.ConcernedWelfareComponent, [i7.NpsSurveyService, i8.Router, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConcernedWelfareComponentNgFactory = i1.ɵccf("ngx-concerned-welfare", i6.ConcernedWelfareComponent, View_ConcernedWelfareComponent_Host_0, {}, {}, []);
export { ConcernedWelfareComponentNgFactory as ConcernedWelfareComponentNgFactory };
