
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { userRoutes } from './constants';

const SURVEY_EMAIL_URL = '/run/sendEmailSurvey';

@Injectable({ providedIn: 'root' })
export class SendEmailService {
  protected basePath = 'https://localhost:4200/v1';
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

sendSurveyEmail(userData: any, companyName: any): Observable<any> {
  return this.httpClient.post<any>(`${this.basePath}${SURVEY_EMAIL_URL}`, {user: userData, company: companyName })
    .pipe(catchError(this.handleError));
}

resendVerification(email) {
  return this.httpClient.post<any>(`${this.basePath}${userRoutes.postResendVerification}`, {email});
}

private handleError(error: HttpErrorResponse) {
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', error.error.message);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }

  // return an observable with a user-facing error message
  return throwError(
    'Something bad happened; please try again later.');
};

}
