import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DEFAULT_COUNTRY = 'USA';
var GeocodeingService = /** @class */ (function () {
    function GeocodeingService(http) {
        this.http = http;
        this.GEOCODE_ENDPOINT = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
        this.API_KEY = 'AIzaSyCr2FFHgGQ3gZMoLi3G6ucKIKd2g9YJ3fM';
    }
    GeocodeingService.prototype.ngOnInit = function () { };
    GeocodeingService.prototype.getGeocodeFromAddress = function (address) {
        return this.http.get(this.GEOCODE_ENDPOINT +
            address.street + ' ' + address.city + ' ' + address.state + ' ' +
            DEFAULT_COUNTRY + '&key=' + this.API_KEY)
            .pipe(catchError(this.handleError));
    };
    GeocodeingService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    ;
    GeocodeingService.ngInjectableDef = i0.defineInjectable({ factory: function GeocodeingService_Factory() { return new GeocodeingService(i0.inject(i1.HttpClient)); }, token: GeocodeingService, providedIn: "root" });
    return GeocodeingService;
}());
export { GeocodeingService };
