<div class="main">
  <div class="content">
    <h1 class="title">{{title}}</h1>
    <div class="description">
      {{description}}
    </div>
  </div>
  <div class="d-flex  justify-content-center justify-content-md-start">
      <a class="btn button-text btn-outline-secondary" routerLink="{{getHelpLink}}">{{btnText}}</a>
    </div>
</div>