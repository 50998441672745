import { Component, Input, ElementRef, AfterContentInit } from '@angular/core';

@Component({
    selector: 'ngx-labelled',
    styleUrls: ['./labelled.component.scss'],
    template: `
    <div class="form-group row">
        <label class="col-sm-6 d-flex py-0 py-0-sm align-items-center justify-content-start justify-content-sm-end
         col-form-label w-100"  for="{{target}}">{{label}}</label>
        <div class="col-sm-6 py-0 py-0-sm"><ng-content></ng-content></div>
        <div class="col-sm-6 offset-sm-6"><ng-content select="small"></ng-content></div>
    </div>
    `,
})
export class LabelledComponent implements AfterContentInit {
    @Input('for') target: string;
    @Input() label: string;

    constructor(private el: ElementRef) { }

    ngAfterContentInit(): void {
        const inputAll = this.el.nativeElement.querySelector('input, select');
        // const input = this.el.nativeElement.querySelector('input');

        if (this.target && inputAll ) {
            inputAll.setAttribute('id', this.target);
        }
        /* if (this.label && input ) {
            input.setAttribute('placeholder', this.label);
        }*/
    }
}
