import { Component, Input} from '@angular/core';

@Component({
  selector: 'ngx-text',
  templateUrl: 'text.component.html',
  styleUrls: ['text.component.scss'],
})
export class TextComponent  {
@Input() kind: String;
  constructor() {}
}
