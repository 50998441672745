var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BasicAuthScreen } from '../auth.component';
var DxRequestPasswordComponent = /** @class */ (function (_super) {
    __extends(DxRequestPasswordComponent, _super);
    function DxRequestPasswordComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.prefix = 'forms.requestPassword';
        _this.analyticsTrack = 'Request-Password';
        _this.user = {};
        _this.title = 'Enter your email address to reset your password';
        return _this;
    }
    DxRequestPasswordComponent.prototype.requestPass = function () {
        var _this = this;
        this.submitted = true;
        this.user.email = this.user.email.trim();
        this.serviceCall(function () { return _this.service.requestPassword(_this.strategy, { email: _this.user.email }); });
    };
    return DxRequestPasswordComponent;
}(BasicAuthScreen));
export { DxRequestPasswordComponent };
