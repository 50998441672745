
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

interface ProviderLicense {
    clinicalLicenseType: string,
    id: string,
    licenseExpiry: string,
    licenseNumber: string,
    stateProvince: string
};
@Injectable({ providedIn: 'root' })
export class ProviderUiService {
    formatProviderLicence(providerLicenses: Array<ProviderLicense>): Array<string> {
        return _.chain(providerLicenses)
            .groupBy('clinicalLicenseType')
            .map((v, k) => ({ clinicalLicenseType: k, state: _.uniq(v.map((z) => z.stateProvince)).join(', ') }))
            // [{clinicalLicenseType: 'Licensed Clinical Social Worker', state: "CA, NY" }]
            .map((z: { clinicalLicenseType: string, state: string }) => z.clinicalLicenseType + ': ' + z.state)
            // ["Licensed Clinical Social Worker - CA, NY"]
            .value();
    }
}
