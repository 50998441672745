import { Observable, of } from 'rxjs';
import { Inject, Injectable, Optional } from '@angular/core';
// import * as Boom from 'boom';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AnalyticsService } from '../utils/analytics.service';
import { Configuration } from './configuration';
import { BASE_PATH } from './variables';

@Injectable()
export class LoggingService {
    protected basePath = 'https://localhost:4200/v1';
    public configuration = new Configuration();

    constructor(private httpClient: HttpClient, private analyticsService: AnalyticsService, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

    recordLogError(err: Error): Observable<any> {
        const original = { message: err.message || 'error', stack: err.stack };
        this.analyticsService.trackGenericEvent('network-error', window.location + '', err.message);
        return this.httpClient
            .post<any>(`${this.basePath}/run/log-error`, { error: original, timesptamp: new Date().getTime() })
            .pipe(catchError(this.handleError));
    }

    handleError(error: HttpErrorResponse) {
        // return an observable with a user-facing error message
        // return throwError(error);
        return of({});
    };

}
