import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { NpsSurveyService } from '../../../@core/utils/nps-survey.service';
import { Router, ActivatedRoute } from '@angular/router';
const ROUTE_NAME = 'recommend-service';

@Component({
  selector: 'ngx-recommend-services',
  templateUrl: './recommend-services.component.html',
  styleUrls: ['./recommend-services.component.scss'],
})
export class RecommendServicesComponent implements OnInit {
  previousValue: number;
  submitted: boolean = false;
  value: number = -2;
  disableButton: boolean = true;
  isShowAnswer: boolean;
  constructor(private npsSurveyService: NpsSurveyService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.npsSurveyService.setCarePathProgress(ROUTE_NAME);
  }

  ngOnInit() {
    if (this.npsSurveyService.recommendData) {
      this.value = this.npsSurveyService.recommendData;
      this.previousValue = this.value;
      this.isShowAnswer = true;
    }

    this.isValidState();

  }
  options: Options = {

    showTicks: true,
    stepsArray: [{
      value: 0,
      legend: 'Not likely at all',
    }, {
      value: 1,
    }, {
      value: 2,
    }, {
      value: 3,
    }, {
      value: 4,
    }, {
      value: 5,
    }, {
      value: 6,
    }, {
      value: 7,
    }, {
      value: 8,
    }, {
      value: 9,
    }, {
      value: 10,
      legend: 'Extremely likely',
    }],
  };



  isValidState() {
    this.disableButton = this.value === -2 ? true : false;

  }


  changeValueEvent(value) {
    this.value = value;
    if (this.value === this.previousValue) {
      this.previousValue = -2;
      this.value = -2;
      this.isShowAnswer = false;
    } else {
      this.previousValue = this.value;
      this.isShowAnswer = true;
    }
    this.isValidState();
  }

  goNext(): void {
    this.submitted = true;
    this.npsSurveyService.recommendData = this.value;
    const nextStep = this.npsSurveyService.getNextStep(ROUTE_NAME);

    this.router.navigate([`../${nextStep}`], { relativeTo: this.activatedRoute });

  }

}
