/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recommend-services.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular-slider/ngx-slider/angular-slider-ngx-slider.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular-slider/ngx-slider";
import * as i5 from "@angular/common";
import * as i6 from "./recommend-services.component";
import * as i7 from "../../../@core/utils/nps-survey.service";
import * as i8 from "@angular/router";
var styles_RecommendServicesComponent = [i0.styles];
var RenderType_RecommendServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecommendServicesComponent, data: {} });
export { RenderType_RecommendServicesComponent as RenderType_RecommendServicesComponent };
export function View_RecommendServicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "animate__animated animate__slideInRight animate__faster slider_10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row justify-content-center text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "col-12 top header-text font-size-36 font-500 font-montserrat mb45 color-black"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How likely are you to recommend Concern services to family, friends, co-workers? "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "ngx-slider", [["class", "mb125 ngx-slider"]], [[2, "vertical", null], [2, "animate", null], [2, "with-legend", null], [1, "disabled", 0], [1, "aria-label", 0]], [[null, "valueChange"], [null, "click"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.value = $event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.changeValueEvent(_co.value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ɵa_0, i2.RenderType_ɵa)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.ɵa]), i1.ɵdid(6, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "remove_pointer": 0 }), i1.ɵdid(8, 4964352, null, 1, i4.ɵa, [i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef, i1.NgZone], { value: [0, "value"], options: [1, "options"] }, { valueChange: "valueChange" }), i1.ɵqud(335544320, 1, { tooltipTemplate: 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "row justify-content-center no-gutters nps_continue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-primary col-12 col-md-5 mb64"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["CONTINUE "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fas fa-arrow-circle-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_5 = "mb125"; var currVal_6 = _ck(_v, 7, 0, !_co.isShowAnswer); _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_7 = _co.value; var currVal_8 = _co.options; _ck(_v, 8, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 8).sliderElementVerticalClass; var currVal_1 = i1.ɵnov(_v, 8).sliderElementAnimateClass; var currVal_2 = i1.ɵnov(_v, 8).sliderElementWithLegendClass; var currVal_3 = i1.ɵnov(_v, 8).sliderElementDisabledAttr; var currVal_4 = i1.ɵnov(_v, 8).sliderElementAriaLabel; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_9 = (_co.submitted || _co.disableButton); _ck(_v, 11, 0, currVal_9); }); }
export function View_RecommendServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-recommend-services", [], null, null, null, View_RecommendServicesComponent_0, RenderType_RecommendServicesComponent)), i1.ɵdid(1, 114688, null, 0, i6.RecommendServicesComponent, [i7.NpsSurveyService, i8.Router, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecommendServicesComponentNgFactory = i1.ɵccf("ngx-recommend-services", i6.RecommendServicesComponent, View_RecommendServicesComponent_Host_0, {}, {}, []);
export { RecommendServicesComponentNgFactory as RecommendServicesComponentNgFactory };
