import { BehaviorSubject } from 'rxjs';
/**
 *
 * @param items items to replace or append on the value array of the behavior subject
 * @param theSubject the cache/observable of the underlying list of data
 */
export function upsertItem(items: any[], theSubject: BehaviorSubject<any[]>) {
    const updated = theSubject.getValue().slice(0);

    for (const one of items) {
        const indexAt = updated.findIndex(entry => entry.id === one.id);

        if (indexAt === -1) {
            updated.unshift(one);
        } else {
            updated[indexAt] = one;
        }
    }

    theSubject.next(updated);
}
