var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../../@core/data/users.service';
import { ConfirmationModal } from '../modal.component';
import { GeocodeingService } from '../../../../../pages/dashboard/display-appointment/geocodingService';
var caselessCompare = function (left, right) { return (left || '').toUpperCase() === (right || '').toUpperCase(); };
var ɵ0 = caselessCompare;
export var isValidAddress = function (address) { return address.street && address.city && address.state && address.country; };
export function trimAddressComponent(address) {
    address.street = address.street ? address.street.trim() : '';
    address.city = address.city ? address.city.trim() : '';
    address.state = address.state ? address.state.trim() : '';
    address.postal = address.postal ? address.postal.trim() : '';
    address.country = address.country ? address.country.trim() : 'USA';
    return address;
}
export function addressesAreSame(savedAddress, inputAddress) {
    return (caselessCompare(savedAddress.street, inputAddress.street) &&
        caselessCompare(savedAddress.city, inputAddress.city) &&
        caselessCompare(savedAddress.state, inputAddress.state) &&
        caselessCompare(savedAddress.postal, inputAddress.postal) &&
        caselessCompare(savedAddress.country, inputAddress.country));
}
export function clearAddress(address) {
    address.street = '';
    address.city = '';
    address.state = '';
    address.postal = '';
    address.country = '';
}
var AddressSouModalComponent = /** @class */ (function () {
    function AddressSouModalComponent(userService, activeModal, geoService) {
        this.userService = userService;
        this.activeModal = activeModal;
        this.geoService = geoService;
        this._modal = { context: {}, modalHeader: '', options: false };
        this.user = {};
        this.messages = [];
        this.errors = [];
        this.address = {};
        this.agreeToSou = false;
        this.addressNotFilledIn = false;
        this.suggestedAddresses = [];
        this.originalAddress = {};
        this.userAddress = '';
        this.formattedOriginalAddress = '';
    }
    Object.defineProperty(AddressSouModalComponent.prototype, "modal", {
        get: function () { return this._modal; },
        set: function (value) {
            this._modal = value;
            this.user = __assign({}, value.context.user, { addresses: value.context.user.addresses.map(function (item) { return (__assign({}, item)); }) });
            this.checkPrimaryAddress();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressSouModalComponent.prototype, "souRequired", {
        get: function () { return this.modal.context.sou && !this.agreeToSou; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddressSouModalComponent.prototype, "addressRequired", {
        get: function () { return this.modal.context.address && this.addressNotFilledIn; },
        enumerable: true,
        configurable: true
    });
    AddressSouModalComponent.prototype.closeModal = function () {
        this.activeModal.close(ConfirmationModal.No);
    };
    AddressSouModalComponent.prototype.accept = function () {
        this.checkPrimaryAddress();
        if (!this.addressRequired && !this.souRequired) {
            this.activeModal.close(ConfirmationModal.Yes);
        }
    };
    AddressSouModalComponent.prototype.checkPrimaryAddress = function () {
        this.primaryAddressIndex = this.user.addresses.findIndex(function (add) { return add.name === 'Primary'; });
        this.address = this.user.addresses[this.primaryAddressIndex];
        this.addressNotFilledIn = !isValidAddress(trimAddressComponent(this.address));
    };
    AddressSouModalComponent.prototype.validateSavedAddress = function () {
        var _this = this;
        this.parseAddress();
        this.messages = [];
        this.errors = [];
        this.userService
            .updateUser(this.user)
            .toPromise()
            .then(function (result) { return _this.afterUserUpdated(result.data); })
            .catch(function (error) { return (_this.errors = ['Your address could not be updated.']); });
    };
    AddressSouModalComponent.prototype.parseAddress = function () {
        var _a = this.userAddress.split(','), street = _a[0], city = _a[1], postState = _a[2], country = _a[3];
        var _b = postState.trim().split(' '), state = _b[0], postal = _b[1];
        Object.assign(this.user.addresses[0], {
            street: street.trim(),
            city: city.trim(),
            state: state,
            postal: postal,
            country: country.trim(),
        });
    };
    AddressSouModalComponent.prototype.validateUserAddress = function () {
        var _this = this;
        this.originalAddress = this.user.addresses[0];
        this.geoService.getGeocodeFromAddress(this.originalAddress)
            .subscribe(function (data) {
            var filtered = data.results.filter(function (address) {
                if (_this.isGeoAddressValid(address)) {
                    return address;
                }
            });
            _this.formattedOriginalAddress = _this.originalAddress.street + ", " + _this.originalAddress.city + ",\n                 " + _this.originalAddress.state + " " + _this.originalAddress.postal + ", " + _this.originalAddress.country;
            // if returned address equals user input, just save
            if (filtered.length === 1 &&
                filtered[0].formatted_address === _this.formattedOriginalAddress.replace(/\n/, ' ').replace(/\s\s+/, ' ')) {
                _this.userAddress = _this.formattedOriginalAddress;
                _this.validateSavedAddress();
            }
            else if (filtered.length === 0) {
                // f no address is returned, save nonetheless
                _this.userAddress = _this.formattedOriginalAddress;
                _this.validateSavedAddress();
            }
            else {
                // if there are addresses, suggest them
                _this.userAddress = filtered[0].formatted_address;
                _this.suggestedAddresses = filtered;
            }
        });
    };
    AddressSouModalComponent.prototype.isGeoAddressValid = function (address) {
        var validcomponent = address.address_components.filter(function (a) { return a.types.includes('postal_code'); });
        if (validcomponent.length > 0 &&
            (address.types.includes('route') ||
                address.types.includes('street_address') ||
                address.types.includes('premise'))) {
            return true;
        }
        return false;
    };
    AddressSouModalComponent.prototype.afterUserUpdated = function (updated) {
        Object.assign(this.modal.context.user, updated);
        this.accept();
    };
    return AddressSouModalComponent;
}());
export { AddressSouModalComponent };
export { ɵ0 };
