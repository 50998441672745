import { Component, OnInit } from '@angular/core';
import { ConfirmationModal, ModalOptions } from '../modal.component';
import { SERVICE_TYPE } from '../..';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';
import { LOCAL_SERVICE } from '../../../../../@core/utils/constants';
@Component({
  selector: 'ngx-counselor-details-modal',
  templateUrl: './counselor-details-modal.component.html',
  styleUrls: ['./counselor-details-modal.component.scss'],
})
export class CounselorDetailsModalComponent implements OnInit {

  _modal: ModalOptions = { context: {}, modalHeader: '', options: false };

  headerTitle: string;
  serviceType: SERVICE_TYPE;
  user: any = {};
  heading: string;
  souAgreed: boolean = false;
  modality: string = '';
  message = '';
  contract: any;
  org: any;
  submitted: boolean = false;

  zipCode: string = '';
  radius: string = '';
  counselingFor: string = '';
  reasonForSwitch: string = '';
  reasons: any;

  set modal(value: ModalOptions) {
    if (Object.keys(value).length > 0) {
      this.message = ``;
      this.heading = `Switching to ${LOCAL_SERVICE} Counseling?`;
    }
  }

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.reasons = environment.reasons;
  }

  closeModal() {
    this.activeModal.close({ answer: ConfirmationModal.No, user: this.user });
  }

  accept(): void {
    this.activeModal.close({ answer: ConfirmationModal.Yes,
      user: this.user,
      radius: this.radius,
      zipCode: this.zipCode,
      counselingFor: this.counselingFor,
      reasonForSwitch: this.reasonForSwitch,
    });
  }

  completeRequest () {
    if (this.submitted) return;
    this.submitted = true;
    this.accept();
  }
}
