/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./video.component";
var styles_VideoComponent = [i0.styles];
var RenderType_VideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoComponent, data: {} });
export { RenderType_VideoComponent as RenderType_VideoComponent };
export function View_VideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "title col-12 col-xl-8 col-md-12 col-sm-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [["class", "content col-12 col-xl-8 col-md-12 col-sm-12 text-center description"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "row justify-content-center video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Concern Screen"], ["class", "img-fluid ipad"], ["src", "../../../assets/images/landing/concern-ipad.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.description; _ck(_v, 6, 0, currVal_1); }); }
export function View_VideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-landing-video", [], null, null, null, View_VideoComponent_0, RenderType_VideoComponent)), i1.ɵdid(1, 114688, null, 0, i2.VideoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoComponentNgFactory = i1.ɵccf("ngx-landing-video", i2.VideoComponent, View_VideoComponent_Host_0, {}, {}, []);
export { VideoComponentNgFactory as VideoComponentNgFactory };
