import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stripProtocol' })
export class StripProtocol implements PipeTransform {

    transform(url: string): string {
        if (url) {
            return url.replace(/^https?:/, '');
        }
        return url;
    }
}
