import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';

import { Observable, forkJoin, of } from 'rxjs';

import { EmployerInformationService } from './employer-information.service';
import { CookieService } from 'ngx-cookie-service';
import { ContractService, OrgService } from '../../../@core/data';

@Injectable()
export class EmployerInformationResolver implements Resolve<Observable<any>> {
    constructor(
        private employerInformation: EmployerInformationService,
        private cookieService: CookieService,
        private orgService: OrgService,
        private contractService: ContractService) { }

    resolve() {

        if (this.cookieService.check('companyName') || this.cookieService.check('companyId')) {
            const companyName = this.cookieService.get('companyName');
            const companyId = this.cookieService.get('companyId');
            let orgData: any = {};
            let contractData: any = {};
            if (companyId) {
                orgData = this.orgService.getOrgId(companyId);
            } else {
                orgData = this.orgService.getOrgByName(companyName);
            }
            contractData = this.employerInformation.getContractByOrgId(companyId);
            const leafContracts = this.contractService.getSiteLocationContracts(companyId);
            return forkJoin(of(companyName), orgData, contractData, leafContracts);

        } else {
            return forkJoin(of(''), of({}), of({}))
        }
    }
}
