import { ProgressBarService } from './progress-bar.service';
;
export var STEPS = {
    // let the string value match the router path for convenient routing
    recommendPath: 'recommend-service',
    mycounselerPath: 'my-counselor',
    scheduledPath: 'scheduled-appointments',
    concernedPath: 'concerned-welfare',
    suggestionPath: 'suggestion-box',
    loginPath: 'nps-thankyou',
};
var NpsFlowFormData = /** @class */ (function () {
    function NpsFlowFormData() {
        this.recommendPath = '';
        this.mycounselerPath = '';
        this.scheduledPath = '';
        this.concernedPath = '';
        this.suggestionPath = '';
        this.loginPath = '';
    }
    NpsFlowFormData.prototype.reset = function () {
        this.recommendData = null;
        this.myCounseling = '';
        this.scheduledData = '';
        this.concernedData = '';
        this.suggestionData = null;
    };
    NpsFlowFormData.prototype.clearCarePath = function () {
        this.recommendPath = '';
        this.mycounselerPath = '';
        this.scheduledPath = '';
        this.concernedPath = '';
        this.suggestionPath = '';
        this.loginPath = '';
    };
    return NpsFlowFormData;
}());
export { NpsFlowFormData };
var NpsSurveyService = /** @class */ (function () {
    function NpsSurveyService(progressBarService) {
        this.progressBarService = progressBarService;
        this.formData = new NpsFlowFormData();
        this.workflowSteps = [
            { step: STEPS.recommendPath, valid: false },
            { step: STEPS.mycounselerPath, valid: false },
            { step: STEPS.scheduledPath, valid: false },
            { step: STEPS.concernedPath, valid: false },
            { step: STEPS.suggestionPath, valid: false },
            { step: STEPS.loginPath, valid: true },
        ];
        this.selectedArr = [
            { value: 1, text: 'Strongly disagree' },
            { value: 2, text: 'Disagree' },
            { value: 3, text: 'Not sure' },
            { value: 4, text: 'Agree' },
            { value: 5, text: 'Strongly agree' },
        ];
    }
    NpsSurveyService.prototype.getWorkFlowStepIndex = function (step) {
        return this.workflowSteps.findIndex(function (elem) { return elem.step === step; });
    };
    NpsSurveyService.prototype.resetSteps = function () {
        // reset all the steps to invalid for start over.
        this.workflowSteps.forEach(function (elem) { elem.valid = false; });
    };
    NpsSurveyService.prototype.resetFormData = function () {
        this.resetSteps();
        this.formData.reset();
        return this.formData;
    };
    NpsSurveyService.prototype.clearCarePath = function () {
        this.formData.clearCarePath();
    };
    NpsSurveyService.prototype.getNextStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        if (stepIndex < 0 || (stepIndex + 1) >= this.workflowSteps.length)
            return '';
        return this.workflowSteps[stepIndex + 1].step;
    };
    NpsSurveyService.prototype.getPreviousStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        var workFlow = this.workflowSteps;
        if (stepIndex <= 0)
            return 'recommend-service';
        return workFlow[stepIndex - 1].step;
    };
    Object.defineProperty(NpsSurveyService.prototype, "recommendData", {
        get: function () {
            return this.formData.recommendData;
        },
        set: function (dataRecommend) {
            this.formData.recommendData = dataRecommend;
            this.validateStep(STEPS.recommendPath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NpsSurveyService.prototype, "worklifeRecommendData", {
        get: function () {
            return this.formData.worklifeRecommendData;
        },
        set: function (dataRecommend) {
            this.formData.worklifeRecommendData = dataRecommend;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NpsSurveyService.prototype, "mycounselorData", {
        get: function () {
            return this.formData.myCounseling;
        },
        set: function (dataCounselor) {
            this.formData.myCounseling = dataCounselor;
            this.validateStep(STEPS.mycounselerPath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NpsSurveyService.prototype, "scheduledData", {
        get: function () {
            return this.formData.scheduledData;
        },
        set: function (dataSchedule) {
            this.formData.scheduledData = dataSchedule;
            this.validateStep(STEPS.scheduledPath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NpsSurveyService.prototype, "concernedData", {
        get: function () {
            return this.formData.concernedData;
        },
        set: function (dataConcern) {
            this.formData.concernedData = dataConcern;
            this.validateStep(STEPS.concernedPath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NpsSurveyService.prototype, "suggestionData", {
        get: function () {
            return this.formData.suggestionData;
        },
        set: function (dataSuggestion) {
            this.formData.suggestionData = dataSuggestion;
            this.validateStep(STEPS.suggestionPath);
        },
        enumerable: true,
        configurable: true
    });
    NpsSurveyService.prototype.setCarePathProgress = function (step) {
        var blockNumber = this.workflowSteps.map(function (s) { return s.step; }).indexOf(step);
        var numOfSteps = this.workflowSteps.length; // for the employee information screen
        this.progressBarService.progress = { progress: blockNumber + 1, numberOfBlocks: numOfSteps, width: 17 };
    };
    NpsSurveyService.prototype.validateStep = function (step) {
        var index = this.getWorkFlowStepIndex(step);
        if (index >= 0) {
            this.workflowSteps[index].valid = true;
        }
        ;
    };
    NpsSurveyService.prototype.invalidateStep = function (step) {
        var index = this.getWorkFlowStepIndex(step);
        if (index >= 0) {
            this.workflowSteps[index].valid = false;
        }
        ;
    };
    NpsSurveyService.prototype.getFirstInvalidStep = function (step) {
        // if all previous steps are valid, return blank, else return the first invalid step
        // used by the route guard.
        var inValidStep = '';
        this.workflowSteps.some(function (elem) {
            if (elem.step === step) {
                return true;
            }
            else {
                if (!elem.valid) {
                    inValidStep = elem.step;
                    return true;
                }
            }
        });
        return inValidStep;
    };
    return NpsSurveyService;
}());
export { NpsSurveyService };
