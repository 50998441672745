/**
 * Luma API
 * Luma API
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ModelCase } from '../model/modelCase';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { authorizationRoutes, caseRoutes } from './constants';
import { environment } from '../../../../environments/environment';


@Injectable()
export class CaseService {
    protected basePath = environment.basePath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create Case
     * Create Case
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCase(body: ModelCase, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createCase(body: ModelCase, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createCase(body: ModelCase, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createCase(body: ModelCase, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createCase.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${environment.lbPath}${caseRoutes.case}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Case
     * Delete Case
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCaseById(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCaseById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCaseById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCaseById(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteCaseById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/data/class/case/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find Cases for current user
     * Find Cases for current user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCase(observe?: 'body', reportProgress?: boolean): Observable<Array<ModelCase>>;
    public findCase(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ModelCase>>>;
    public findCase(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ModelCase>>>;
    public findCase(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ModelCase>>(`${this.basePath}/data/class/case`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find Cases Older than a year
     * Find Cases Older Than a Year
     * @param currentOrgId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCasesOlderThanAYear(currentOrgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ModelCase>>;
    public findCasesOlderThanAYear(currentOrgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ModelCase>>>;
    public findCasesOlderThanAYear(currentOrgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ModelCase>>>;
    public findCasesOlderThanAYear(currentOrgId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (currentOrgId === null || currentOrgId === undefined) {
            throw new Error('Required parameter currentOrgId was null or undefined when calling findCasesOlderThanAYear.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (currentOrgId !== undefined) {
            queryParameters = queryParameters.set('currentOrgId', <any>currentOrgId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ModelCase>>(`${this.basePath}/run/cases-older-than-year`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find Open Cases
     * Find Open Cases
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findOpenCases(observe?: 'body', reportProgress?: boolean): Observable<Array<ModelCase>>;
    public findOpenCases(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ModelCase>>>;
    public findOpenCases(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ModelCase>>>;
    public findOpenCases(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ModelCase>>(`${this.basePath}${caseRoutes.getOpenCase}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Case by Id
     * Get Case by Id
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCaseById(id: string, observe?: 'body', reportProgress?: boolean): Observable<ModelCase>;
    public getCaseById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelCase>>;
    public getCaseById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelCase>>;
    public getCaseById(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCaseById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelCase>(`${this.basePath}${caseRoutes.getCaseData}/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send case info to concern for approval
     * For cases that require special approval this functions sends case info to concern
     * @param caseData body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendEmailForCaseApproval(caseData: ModelCase, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendEmailForCaseApproval(caseData: ModelCase, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendEmailForCaseApproval(caseData: ModelCase, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendEmailForCaseApproval(caseData: ModelCase, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (caseData === null || caseData === undefined) {
            throw new Error('Required parameter caseData was null or undefined when calling sendEmailForCaseApproval.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/run/sendEmailForCaseApproval`,
            caseData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Case
     * Update Case
     * @param id
     * @param body body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCaseById(id: string, body: ModelCase, observe?: 'body', reportProgress?: boolean): Observable<ModelCase>;
    public updateCaseById(id: string, body: ModelCase, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelCase>>;
    public updateCaseById(id: string, body: ModelCase, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelCase>>;
    public updateCaseById(id: string, body: ModelCase, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCaseById.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCaseById.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (id !== undefined) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.put<ModelCase>(`${environment.lbPath}${caseRoutes.case}`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getPfStatus(authId) {
        return this.httpClient.post<any>(`${this.basePath}${authorizationRoutes.postPfStatus}`, { authorizationId: authId });
    }

    public getShouldLimit(contractId: string, organizationId: string): Observable<any> {
        return this.httpClient.post<any>(`${this.basePath}${caseRoutes.postShouldLimitCase}`, { contractId, organizationId });
    }
}
