import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../modal.component';
import { environment } from '../../../../../../environments/environment';
import { STATES } from '../../../../../@core/utils/intake-flow.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { BH_SERVICE } from '../../../../../@core/utils/constants';
var SwitchModalComponent = /** @class */ (function () {
    function SwitchModalComponent(activeModal) {
        this.activeModal = activeModal;
        this._modal = { context: {}, modalHeader: '', options: false };
        this.user = {};
        this.souAgreed = false;
        this.modality = '';
        this.message = '';
        this.submitted = false;
        this.artcleLink = environment.onlineTherapyArticle;
        this.states = STATES;
        this.counselingFor = '';
        this.selectState = [];
        this.focus$ = new Subject();
        this.click$ = new Subject();
    }
    Object.defineProperty(SwitchModalComponent.prototype, "modal", {
        set: function (value) {
            if (Object.keys(value).length > 0) {
                this.message = "";
                this.heading = 'Is Online Counseling right for you?';
            }
        },
        enumerable: true,
        configurable: true
    });
    SwitchModalComponent.prototype.ngOnInit = function () {
        this.getUSAStateCodes();
        this.stateList = Object.values(this.states);
        this.stateList.shift();
        this.getBhString();
    };
    SwitchModalComponent.prototype.getBhString = function () {
        this.BH_STRING = BH_SERVICE;
    };
    SwitchModalComponent.prototype.getUSAStateCodes = function () {
        return Object.keys(this.states);
    };
    SwitchModalComponent.prototype.closeModal = function () {
        this.activeModal.close({ answer: ConfirmationModal.No, user: this.user });
    };
    SwitchModalComponent.prototype.getStates = function () {
        return this.stateList;
    };
    SwitchModalComponent.prototype.accept = function () {
        this.activeModal.close({ answer: ConfirmationModal.Yes, user: this.user, selectState: this.selectState });
    };
    SwitchModalComponent.prototype.completeRequest = function () {
        if (this.submitted)
            return;
        this.submitted = true;
        for (var eachCode in this.states) {
            if (this.states[eachCode] === this.value) {
                this.selectState.push(eachCode);
                this.selectState.push(this.states[eachCode]);
            }
        }
        this.accept();
    };
    return SwitchModalComponent;
}());
export { SwitchModalComponent };
