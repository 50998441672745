import { Injectable } from '@angular/core';
import {
    CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot,
} from '@angular/router';
import { NpsSurveyService } from './nps-survey.service';


@Injectable()
export class NpsFlowGuardService implements CanActivate {
    constructor(private router: Router, private npsSurveyService: NpsSurveyService) { }

    canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const path: string = router.routeConfig.path;
        return this.verifyWorkflow(path);
    }

    verifyWorkflow(path: string): boolean {
        const invalidStep = this.npsSurveyService.getFirstInvalidStep(path);

        if (invalidStep.length > 0) {
            // redirect to invalid step
            const url = `/pages/nps-survey/${invalidStep}`;
            this.router.navigate([url]);
            return false;
        }
        return true;
    }
}
