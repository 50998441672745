import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal, ModalOptions } from '../modal.component';

@Component({
  selector: 'ngx-confirm-babykit-modal',
  templateUrl: './confirm-babykit-modal.component.html',
  styleUrls: ['./confirm-babykit-modal.component.scss'],
})
export class ConfirmBabyKitModalComponent implements OnInit {
  _modal: ModalOptions = { context: {}, modalHeader: '', options: false };

  user: any = {};

  set modal(value: ModalOptions) {

    if (Object.keys(value).length > 0) {

      const { user } = value.context;
      this.user = user;
    }
  }

  get submittedDate(): number {
    return new Date(this.user.babyKitRequest.submitted).getTime();
  }

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }

  accept(): void {
    this.activeModal.close(ConfirmationModal.Yes);
  }
}
