import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-landing-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {

  title: String;
  description: String;
  constructor() {
    this.title = 'Getting Started Is Easy';
    this.description = 'We\'ll ask you a few simple questions and then create a custom' +
      ' dashboard with recommendations tailored to your unique needs.';
  }

  ngOnInit() {
  }

}
