import { filter, pairwise } from 'rxjs/operators';
import { Router, RoutesRecognized } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var PreviousUrlService = /** @class */ (function () {
    function PreviousUrlService(router) {
        this.router = router;
        this.navigationUrls = this.router.events
            .pipe(filter(function (evt) { return evt instanceof RoutesRecognized; }), pairwise());
    }
    PreviousUrlService.ngInjectableDef = i0.defineInjectable({ factory: function PreviousUrlService_Factory() { return new PreviousUrlService(i0.inject(i1.Router)); }, token: PreviousUrlService, providedIn: "root" });
    return PreviousUrlService;
}());
export { PreviousUrlService };
