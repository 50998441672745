import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../modal.component';
import { BH_SERVICE } from '../../../../../@core/utils/constants';
@Component({
  selector: 'ngx-betterhelp-transition-modal',
  templateUrl: './betterhelp-transition-modal.component.html',
  styleUrls: ['./betterhelp-transition-modal.component.scss'],
})
export class BetterHelpTransitionModalComponent implements OnInit {
  heading: string = 'How BetterHelp matches you with a Counselor';
  BH_STRING: string

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.getBhString();
  }

  getBhString() {
    this.BH_STRING = BH_SERVICE;
  }

  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }

  accept(): void {
    this.activeModal.close(ConfirmationModal.Yes);
  }
}
