import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { NbAuthResult, NbAuthService, NbAuthSimpleToken, NbTokenStorage } from '@nebular/auth';
import { Observable } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { get as _get } from 'lodash-es';
import { environment } from '../environments/environment';
import { userRoutes } from './@core/data/api/constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  ERROR_CODES: any = {
    EMAIL_NOT_VERIFIED: 'LOGIN_FAILED_EMAIL_NOT_VERIFIED',
    INVALID_ACCESS_TOKEN: 'INVALID_ACCESS_TOKEN',
    LOGIN_ACCOUNT_DEACTIVATED: 'LOGIN_FAILED_ACCOUNT_DEACTIVATED',
    CREATION_ACCOUNT_DEACTIVATED: 'ACCOUNT_CREATION_FAILED_ACCOUNT_DEACTIVATED',
  };
  constructor(private authService: NbAuthService, public tokenStorage: NbTokenStorage, public router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBaasRequest(request)) {
      const headers = {
        setHeaders: {},
      };

      // insert access token if it is valid and we are not attempting to login
      const token = this.tokenStorage.get().getValue();
      if (token && this.requiresAuthentication(request)) {
        if (this.router.url.includes(userRoutes.postUserResetPassword)) {
          // reset password uses a special token passed to the page on redirect
          request = request.clone({ setParams: { 'resetToken': token } });
        } else {
          const currentToken = this.getCurrentToken();
          headers.setHeaders['Authorization'] = `Bearer ${currentToken}`;
        }
      }

      request = request.clone(headers);
    }

    return next.handle(request).pipe(tap(event => { }, err => {
      if (this.isUnauthorizedError(err)) {

        const error_code = _get(err, 'error.error.code', null);

        if (error_code === this.ERROR_CODES.EMAIL_NOT_VERIFIED) {
          this.router.navigate(['/auth/verify-email']);
          return;
        }

        if (error_code === this.ERROR_CODES.INVALID_ACCESS_TOKEN) {
          this.router.navigate(['/auth/login']);
          this.tokenStorage.clear();
          return;
        } else {
          this.tokenStorage.clear();
          // redirect to the login route
          this.router.navigate(['/auth/login']);
          return;
        }
      }
    }));
  }

  private isUnauthorizedError(err: any) {
    return err instanceof HttpErrorResponse && err.status === 401;
  }

  private requiresAuthentication(request: HttpRequest<any>) {
    const url = request.url;
    return (!url.includes('/login') || url.includes('/login/history')) && !url.includes('/blobs');
  }

  private isBaasRequest(request: HttpRequest<any>) {
    return request.url.includes('/v1/') || request.url.includes('sso') || request.url.startsWith('https://api.us1.engaugetx.com');
  }

  private getCurrentToken(): string {
    const tokenData = JSON.parse(window.localStorage.getItem('token_data'));
    const { accessToken, expiresIn, refreshToken, retrievalDate } = tokenData;
    const retrievalNewDate = new Date(retrievalDate);
    const currentNewDate = Math.floor(new Date().getTime() / 1000);
    const expirationDate = Math.floor(retrievalNewDate.getTime() / 1000) + expiresIn;
    const startExpirationRange = expirationDate - environment.secondsBeforeRefreshToken;
    const isTokenAboutToExpire = currentNewDate >= startExpirationRange && currentNewDate < expirationDate;

    let currentToken = accessToken;

    if (currentNewDate >= expirationDate) {
      this.resetUserAuthentication();
    }

    if (isTokenAboutToExpire) {
      const earlyToken = {
        ...tokenData,
        retrievalDate: new Date().toISOString(),
      }
      window.localStorage.setItem('token_data', JSON.stringify(earlyToken));

      this.authService.refreshToken('email', {refreshToken}).pipe(
        first(),
        catchError(() => {
          this.resetUserAuthentication();
          return currentToken;
        }),
      ).subscribe((result: NbAuthResult) => {
        if (result && result.isSuccess()) {
          const response = result.getResponse().body;
          const newToken = {
            ...earlyToken,
            accessToken: response.accessToken,
            expiresIn: response.expiresIn,
          };
          currentToken = newToken.accessToken;
          window.localStorage.setItem('token_data', JSON.stringify(newToken));
          this.tokenStorage.set(new NbAuthSimpleToken(newToken, 'token'));
        } else {
          this.resetUserAuthentication();
        }
      })
    }

    return currentToken;
  }

  private resetUserAuthentication(): void {
    window.localStorage.removeItem('token_data');
    this.tokenStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
