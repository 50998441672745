import { Component } from '@angular/core';

@Component({
  selector: 'ngx-widget-content',
  templateUrl: 'widget-content.component.html',
  styleUrls: ['widget-content.component.scss'],
})
export class WidgetContentComponent {
  constructor() { }
}
