/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./magic-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./magic-link.component";
import * as i3 from "@angular/router";
import * as i4 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i5 from "../pages/intake/employer-information/employer-information.service";
import * as i6 from "../@core/data/caseManagement.service";
import * as i7 from "../@core/data/api/contract.service";
import * as i8 from "../@core/utils/analytics.service";
var styles_MagicLinkComponent = [i0.styles];
var RenderType_MagicLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MagicLinkComponent, data: {} });
export { RenderType_MagicLinkComponent as RenderType_MagicLinkComponent };
export function View_MagicLinkComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_MagicLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-magic-link", [], null, null, null, View_MagicLinkComponent_0, RenderType_MagicLinkComponent)), i1.ɵdid(1, 114688, null, 0, i2.MagicLinkComponent, [i3.ActivatedRoute, i4.CookieService, i3.Router, i5.EmployerInformationService, i6.CaseManagementService, i7.ContractService, i8.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MagicLinkComponentNgFactory = i1.ɵccf("ngx-magic-link", i2.MagicLinkComponent, View_MagicLinkComponent_Host_0, {}, {}, []);
export { MagicLinkComponentNgFactory as MagicLinkComponentNgFactory };
