import { Component, OnInit, Input } from '@angular/core';
import { ModalOptions, SizeModal } from '../../../@theme/components/reusables';
import { ModalService } from '../../../@core/utils/modal.service';
import { CaseManagementService } from '../../../@core/data/caseManagement.service';
import { ModelCase } from '../../../@core/data';
import { BH_SERVICE } from '../../../@core/utils/constants';

@Component({
  selector: 'ngx-online-therapy-card',
  templateUrl: './online-therapy-card.component.html',
  styleUrls: ['./online-therapy-card.component.scss'],
})
export class OnlineTherapyCardComponent implements OnInit {

  @Input() selected: ModelCase;
  selectState: string[] = [];
  BH_STRING: string;
  constructor(private modalService: ModalService, private caseMngt: CaseManagementService) { }

  ngOnInit() {
    this.getBhString();
  }

  getBhString() {
    this.BH_STRING = BH_SERVICE
  }

  selectOnlineTherapy() {
    const modal: ModalOptions = {
      options: false,
    };
    this.modalService.showSwitchModal(SizeModal.LARGE, modal, this.convertCase.bind(this), true);
  }

  convertCase(data) {
    if (data && data.user && data.answer === 'Yes') {
      this.selectState = data.selectState;
      this.updateCase();
    }
  }

  updateCase(reason = '') {
    this.caseMngt.selectedCase = this.selected;
    this.caseMngt.selectedCase.selectState = this.selectState;
    this.caseMngt.changeVideoToOnlineTherapy(reason, this.selectState);
  }
}
