var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, AfterViewChecked, } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../modal.component';
import { UserService } from '../../../../../@core/data/users.service';
import { GeocodeingService } from '../../../../../pages/dashboard/display-appointment/geocodingService';
import { BabyKitService } from '../../../../../@core/data/babykit.service';
import { SERVICE_TYPE } from '../../../../../@theme/components/reusables';
import { CaseManagementService } from '../../../../../@core/data/caseManagement.service';
import { ChangeDetectorRef } from '@angular/core';
import { BH_MODE_OF_HELP } from '../../../../../@core/utils/constants';
import { STATES } from '../../../../../@core/utils/intake-flow.service';
import { ConfigService } from '../../../../../@core/data/config.service';
var POSTAL_CODE = 'postal_code', ROUTE = 'route', STREET_ADDRESS = 'street_address', PREMISE = 'premise';
var DEFAULT_COUNTRY = 'USA';
var DEFAULT_STATE_CITIES = {
    DC: ['Washington'],
};
var AddressFormModalComponent = /** @class */ (function () {
    function AddressFormModalComponent(activeModal, userService, geoService, babyKitService, caseMgtSrvc, cdr, configService) {
        this.activeModal = activeModal;
        this.userService = userService;
        this.geoService = geoService;
        this.babyKitService = babyKitService;
        this.caseMgtSrvc = caseMgtSrvc;
        this.cdr = cdr;
        this.configService = configService;
        this._modal = { context: {}, modalHeader: '', options: false };
        this.isShipping = false;
        this.submitted = false;
        this.isValidating = false;
        this.isHomeAddressSame = true;
        this.primaryAddressExist = false;
        this.formattedOriginalAddress = '';
        this.chosenAdress = '';
        this.suggestedAddresses = [];
        this.user = {};
        this.showEmergencyContact = true;
        this.showPCPInfo = true;
        this.pcpAgreed = true;
        this.pcpInfoExist = true;
        this.filteredCities = [];
        this.alwaysPromptForAnAddress = false;
        this.isModalLoaded = false;
        this.loadingCities = false;
        this.showCityDropdown = false;
        this.showPcpCityDropdown = false;
        this.filteredPcpCities = [];
        this.loadingPcpCities = false;
        this.tempUser = {
            addresses: [{}, {}],
            emergencyContact: {},
        };
        this.cityValid = true;
        this.pcpCityValid = true;
    }
    Object.defineProperty(AddressFormModalComponent.prototype, "modal", {
        set: function (value) {
            if (Object.keys(value).length > 0) {
                var _a = value.context, user = _a.user, contract = _a.contract, org = _a.org, serviceType = _a.serviceType, isShipping = _a.isShipping, title = _a.title, showEmergencyContact = _a.showEmergencyContact, alwaysPromptForAnAddress = _a.alwaysPromptForAnAddress, selected = _a.selected;
                this.user = user;
                if (!this.user.emergencyContact) {
                    this.user.emergencyContact = {
                        name: '',
                        phoneNumber: '',
                        relationship: '',
                    };
                }
                this.selected = selected;
                this.contract = contract;
                this.org = org;
                this.user.fullName = this.user.firstName + " " + this.user.lastName;
                this.serviceType = serviceType;
                this.showEmergencyContact = showEmergencyContact;
                this.alwaysPromptForAnAddress = alwaysPromptForAnAddress;
                var userAddress = this.user.addresses[0];
                this.primaryAddressExist =
                    userAddress.state &&
                        userAddress.street &&
                        userAddress.postal &&
                        userAddress.city
                        ? true
                        : false;
                this.pcpInfoExist =
                    this.user.UserDefinedTextField1 &&
                        this.user.addresses[1].state &&
                        this.user.addresses[1].street &&
                        this.user.addresses[1].postal &&
                        this.user.addresses[1].city &&
                        this.user.TTYPhone
                        ? true
                        : false;
                if (isShipping) {
                    this.isShipping = isShipping;
                }
                if (title) {
                    this.headerTitle = title;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    AddressFormModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setHeader();
        this.tempUser = JSON.parse(JSON.stringify(this.user));
        var selectedState = this.user.addresses[0].state || this.selected.selectState[0];
        if (selectedState) {
            this.getcities(selectedState, false).then(function () {
                if (_this.filteredCities.length > 0) {
                    _this.tempUser.addresses[0].city = _this.filteredCities[0];
                }
            });
            var possiblePCPAddress = this.user.addresses[1] &&
                (this.user.addresses[1].state || selectedState);
            this.tempUser.addresses[1].state = possiblePCPAddress;
            this.tempUser.addresses[0].state = possiblePCPAddress;
            this.getcities(possiblePCPAddress, true).then(function () {
                if (_this.filteredPcpCities.length > 0) {
                    _this.tempUser.addresses[1].city = _this.filteredPcpCities[0];
                }
            });
        }
        else {
            var babyKitLocationZip = this.selected && this.selected.counselingLocationZip;
            var counselingLocationZip = this.selected &&
                this.selected.counselingLocation &&
                this.selected.counselingLocation.zip;
            var zip_1 = babyKitLocationZip || counselingLocationZip;
            if (zip_1) {
                this.configService.getStateFromZip(zip_1).subscribe(function (data) {
                    _this.tempUser.addresses[0].state = data.state;
                    _this.tempUser.addresses[0].postal = zip_1;
                    _this.getcities(data.state, false).then(function () {
                        if (_this.filteredCities.length > 0) {
                            _this.tempUser.addresses[0].city = _this.filteredCities[0];
                        }
                    });
                    _this.getcities(data.state, true).then(function () {
                        if (_this.filteredPcpCities.length > 0) {
                            _this.tempUser.addresses[1].city = _this.filteredPcpCities[0];
                        }
                    });
                });
            }
        }
    };
    AddressFormModalComponent.prototype.getStates = function () {
        return Object.keys(STATES);
    };
    AddressFormModalComponent.prototype.getIndexState = function (state) {
        var stateKeys = Object.keys(STATES);
        var stateKey = stateKeys.find(function (key) { return STATES[key] === state; });
        return stateKeys.indexOf(stateKey);
    };
    AddressFormModalComponent.prototype.handleStateChange = function (state, pcp) {
        var _this = this;
        if (!state) {
            if (pcp) {
                this.tempUser.addresses[1].city = '';
                this.filteredPcpCities = [];
            }
            else {
                this.tempUser.addresses[0].city = '';
                this.filteredCities = [];
            }
            this.updateCityValidity(pcp);
            return;
        }
        this.getcities(state, pcp).then(function () {
            var pcpCurrentCity = _this.tempUser.addresses[1].city;
            var userCurrentCity = _this.tempUser.addresses[0].city;
            if (!pcp && !_this.filteredCities.includes(userCurrentCity)) {
                _this.tempUser.addresses[0].city = _this.filteredCities[0];
            }
            else if (pcp && !_this.filteredPcpCities.includes(pcpCurrentCity)) {
                _this.tempUser.addresses[1].city = _this.filteredPcpCities[0];
            }
            _this.updateCityValidity(pcp);
        });
    };
    AddressFormModalComponent.prototype.getmycities = function () {
        return this.cities;
    };
    AddressFormModalComponent.prototype.getpcpcities = function () {
        return this.pcpcities;
    };
    AddressFormModalComponent.prototype.sortResults = function (arr) {
        return arr.sort(function (n1, n2) {
            if (n1 > n2) {
                return 1;
            }
            if (n1 < n2) {
                return -1;
            }
            return 0;
        });
    };
    AddressFormModalComponent.prototype.getcities = function (state, pcp) {
        var _this = this;
        return new Promise(function (resolve) {
            if (!state) {
                if (pcp) {
                    _this.tempUser.addresses[1].city = '';
                    _this.filteredPcpCities = [];
                }
                else {
                    _this.tempUser.addresses[0].city = '';
                    _this.filteredCities = [];
                }
                resolve();
                return;
            }
            var defaultCities = DEFAULT_STATE_CITIES[state];
            if (defaultCities) {
                if (pcp) {
                    _this.pcpcities = defaultCities;
                    _this.filteredCities = defaultCities;
                    _this.filteredPcpCities = defaultCities;
                    if (_this.user.addresses[1].state) {
                        _this.user.addresses[1].city = defaultCities[0];
                    }
                }
                else {
                    _this.cities = defaultCities;
                    _this.filteredCities = defaultCities;
                    if (_this.user.addresses[0].state) {
                        _this.user.addresses[0].city = defaultCities[0];
                    }
                }
                resolve();
                return;
            }
            if (pcp) {
                _this.loadingPcpCities = true;
            }
            else {
                _this.loadingCities = true;
            }
            _this.configService.getCitiesFromState(state).subscribe(function (data) {
                var sortCitiesResults = _this.sortCitiesResults(data.results.map(function (_a) {
                    var name = _a.name;
                    return name;
                }));
                var uniqueCities = sortCitiesResults.filter(function (value, index, array) { return array.indexOf(value) === index; });
                if (pcp) {
                    _this.pcpcities = uniqueCities;
                    _this.filteredPcpCities = uniqueCities;
                    _this.loadingPcpCities = false;
                }
                else {
                    _this.cities = uniqueCities;
                    _this.filteredCities = _this.cities;
                    _this.loadingCities = false;
                }
                resolve();
            });
        });
    };
    AddressFormModalComponent.prototype.updateCityValidity = function (isPcp) {
        if (isPcp) {
            this.pcpCityValid =
                !this.pcpAgreed ||
                    this.isCityValid(this.tempUser.addresses[1].city, this.filteredPcpCities, true);
        }
        else {
            this.cityValid = this.isCityValid(this.tempUser.addresses[0].city, this.filteredCities, false);
        }
    };
    AddressFormModalComponent.prototype.isCityValid = function (city, filteredCities, isPcp) {
        if (isPcp === void 0) { isPcp = false; }
        if (!this.pcpAgreed && isPcp) {
            return true;
        }
        return city && filteredCities.includes(city);
    };
    AddressFormModalComponent.prototype.onCityInput = function (input) {
        if (!this.tempUser.addresses[0].state) {
            this.filteredCities = [];
            return;
        }
        this.filteredCities = this.cities.filter(function (city) {
            return city.toLowerCase().includes(input.toLowerCase());
        });
        this.showCityDropdown = true;
        this.updateCityValidity(false);
    };
    AddressFormModalComponent.prototype.onPcpCityInput = function (input) {
        if (!this.tempUser.addresses[1].state) {
            this.filteredPcpCities = [];
            return;
        }
        this.filteredPcpCities = this.pcpcities.filter(function (city) {
            return city.toLowerCase().includes(input.toLowerCase());
        });
        this.showPcpCityDropdown = true;
        this.updateCityValidity(true);
    };
    AddressFormModalComponent.prototype.hideCityDropdownIfValid = function () {
        if (this.cityValid) {
            this.showCityDropdown = false;
        }
    };
    AddressFormModalComponent.prototype.hidePcpCityDropdownIfValid = function () {
        if (this.pcpCityValid) {
            this.showPcpCityDropdown = false;
        }
    };
    AddressFormModalComponent.prototype.selectCity = function (city) {
        this.tempUser.addresses[0].city = city;
        this.showCityDropdown = false;
        this.updateCityValidity(false);
    };
    AddressFormModalComponent.prototype.selectPcpCity = function (city) {
        this.tempUser.addresses[1].city = city;
        this.cdr.detectChanges();
        this.showPcpCityDropdown = false;
        this.updateCityValidity(true);
    };
    AddressFormModalComponent.prototype.validateCity = function () {
        if (this.user.addresses[0].city &&
            this.filteredCities.includes(this.user.addresses[0].city)) {
            this.user.addresses[0].city = this.user.addresses[0].city;
        }
        else {
            this.user.addresses[0].city = '';
        }
        this.cdr.detectChanges();
    };
    AddressFormModalComponent.prototype.validatePcpCity = function () {
        if (this.user.addresses[1].city &&
            this.filteredPcpCities.includes(this.user.addresses[1].city)) {
            this.user.addresses[1].city = this.user.addresses[1].city;
        }
        else {
            this.user.addresses[1].city = '';
        }
        this.cdr.detectChanges();
    };
    AddressFormModalComponent.prototype.toggleCityDropdown = function () {
        this.showCityDropdown = !this.showCityDropdown;
    };
    AddressFormModalComponent.prototype.togglePcpCityDropdown = function () {
        this.showPcpCityDropdown = !this.showPcpCityDropdown;
    };
    AddressFormModalComponent.prototype.hideCityDropdown = function () {
        var _this = this;
        setTimeout(function () {
            _this.showCityDropdown = false;
        }, 200);
    };
    AddressFormModalComponent.prototype.hidePcpCityDropdown = function () {
        var _this = this;
        setTimeout(function () {
            _this.showPcpCityDropdown = false;
        }, 200);
    };
    AddressFormModalComponent.prototype.sortCitiesResults = function (results) {
        return results.sort();
    };
    AddressFormModalComponent.prototype.onClick = function (event) {
        var target = event.target;
        var insideForm = target.closest('.form-group') || target.closest('.form-control');
        var insideCityDropdown = target.closest('.dropdown-wrapper');
        if (!insideCityDropdown) {
            this.showCityDropdown = false;
            this.showPcpCityDropdown = false;
        }
        if (insideForm && !insideCityDropdown) {
            this.hideDropdowns();
        }
    };
    AddressFormModalComponent.prototype.hideDropdowns = function () {
        this.showCityDropdown = false;
        this.showPcpCityDropdown = false;
    };
    AddressFormModalComponent.prototype.ngAfterViewChecked = function () {
        if (document.getElementsByClassName('modal-dialog')[0] &&
            !this.isModalLoaded) {
            this.inputStreet = document.getElementById('input-addressLineOne');
            this.inputStreet.focus();
            this.inputStreet.blur();
            this.dialogModal = document.getElementsByClassName('modal-dialog')[0];
            this.dialogModal.scrollIntoView(true);
            this.isModalLoaded = true;
            this.cdr.detectChanges();
        }
    };
    AddressFormModalComponent.prototype.closeModal = function () {
        this.activeModal.close({
            answer: ConfirmationModal.No,
            user: this.user,
            selected: this.selected,
        });
    };
    AddressFormModalComponent.prototype.accept = function () {
        this.activeModal.close({
            answer: ConfirmationModal.Yes,
            user: this.user,
            selected: this.selected,
        });
    };
    AddressFormModalComponent.prototype.validateUserAddress = function (form) {
        var _this = this;
        if (this.submitted)
            return;
        this.submitted = true;
        var userAddress = this.user.addresses[0];
        var street = userAddress.street, city = userAddress.city, state = userAddress.state, postal = userAddress.postal;
        this.geoService.getGeocodeFromAddress(userAddress).subscribe(function (data) {
            var filtered = data.results.filter(function (address) {
                return _this.isGeoAddressValid(address);
            });
            _this.formattedOriginalAddress = street + ", " + city + ", " + state + " " + postal + ", " + DEFAULT_COUNTRY;
            _this.submitted = false;
            if (filtered.length > 0 &&
                // do a case insensitive comparison here
                filtered[0].formatted_address.toUpperCase() !==
                    _this.formattedOriginalAddress
                        .replace(/\n/, ' ')
                        .replace(/\s\s+/, ' ')
                        .toUpperCase()) {
                _this.suggestedAddresses = filtered;
                _this.chosenAdress = filtered[0].formatted_address;
                _this.isValidating = true;
            }
            else {
                _this.chosenAdress = _this.formattedOriginalAddress;
                _this.submitForm(form);
            }
        }, function (err) {
            _this.submitted = false;
            console.error(err);
        });
    };
    AddressFormModalComponent.prototype.isGeoAddressValid = function (address) {
        var validcomponent = address.address_components.filter(function (a) {
            return a.types.includes(POSTAL_CODE);
        });
        if (validcomponent.length > 0 &&
            (address.types.includes(ROUTE) ||
                address.types.includes(STREET_ADDRESS) ||
                address.types.includes(PREMISE))) {
            return true;
        }
        return false;
    };
    AddressFormModalComponent.prototype.parseAddress = function () {
        if (!this.chosenAdress) {
            console.error('chosenAdress is undefined');
            return;
        }
        var parts = this.chosenAdress.split(',');
        if (parts.length < 3) {
            console.error('chosenAdress format is incorrect');
            return;
        }
        var street = parts[0] ? parts[0].trim() : '';
        var city = parts[1] ? parts[1].trim() : '';
        var postState = parts[2] ? parts[2].trim() : '';
        if (!postState) {
            console.error('postState is undefined');
            return;
        }
        var _a = postState.split(' '), state = _a[0], postal = _a[1];
        if (!state || !postal) {
            console.error('state or postal is undefined');
            return;
        }
        Object.assign(this.user.addresses[0], {
            street: street,
            city: city,
            state: state,
            postal: postal,
            country: DEFAULT_COUNTRY,
        });
    };
    AddressFormModalComponent.prototype.onSubmitClicked = function (form) {
        if (this.submitted)
            return;
        this.submitted = true;
        this.submitForm(form);
    };
    AddressFormModalComponent.prototype.submitForm = function (form) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.submitted)
                            return [2 /*return*/];
                        this.user = JSON.parse(JSON.stringify(this.tempUser));
                        if (this.selected) {
                            if (this.selected.modeOfHelp === BH_MODE_OF_HELP) {
                                this.user.UserDefinedCheckbox1 = this.pcpAgreed;
                                if (!this.pcpAgreed) {
                                    delete this.user.UserDefinedTextField1;
                                    delete this.user.TTYPhone;
                                    this.user.addresses[1].street = null;
                                    this.user.addresses[1].postal = null;
                                    this.user.addresses[1].suite = null;
                                    this.user.addresses[1].city = null;
                                    this.user.addresses[1].state = null;
                                }
                            }
                        }
                        if (!this.pcpAgreed) {
                            this.filteredPcpCities = [];
                            this.tempUser.addresses[1].city = '';
                        }
                        this.parseAddress();
                        return [4 /*yield*/, this.updateUserData()];
                    case 1:
                        _a.sent();
                        this.submitted = false;
                        form.reset();
                        if (!this.isHomeAddressSame &&
                            !this.primaryAddressExist &&
                            this.isShipping) {
                            this.serviceType = SERVICE_TYPE.PROFILE;
                            this.isShipping = false;
                            this.isValidating = false;
                            this.headerTitle = '';
                            return [2 /*return*/];
                        }
                        this.accept();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddressFormModalComponent.prototype.updateUserData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, userPrimaryAddress;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.serviceType;
                        switch (_a) {
                            case SERVICE_TYPE.BABYKIT: return [3 /*break*/, 1];
                            case SERVICE_TYPE.PROFILE: return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 5];
                    case 1:
                        _b = this;
                        return [4 /*yield*/, this.babyKitService.updateUserBabyKit(this.user, this.contract, this.org, this.isHomeAddressSame)];
                    case 2:
                        _b.user = _c.sent();
                        this.closeModal();
                        return [3 /*break*/, 5];
                    case 3:
                        userPrimaryAddress = this.user.addresses[0];
                        if (userPrimaryAddress) {
                            this.user.addresses[0].country = DEFAULT_COUNTRY;
                        }
                        return [4 /*yield*/, this.userService.updateUser(this.user).toPromise()];
                    case 4:
                        _c.sent();
                        this.primaryAddressExist = true;
                        return [3 /*break*/, 5];
                    case 5:
                        this.caseMgtSrvc
                            .refreshUser()
                            .then()
                            .catch(function (err) {
                            throw err;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AddressFormModalComponent.prototype.setHeader = function () {
        if (this.serviceType === SERVICE_TYPE.BABYKIT) {
            this.header =
                'Please provide your shipping information and your new baby kit will be on its way';
        }
        else {
            this.header = this.primaryAddressExist
                ? 'Please review and update the following contact information'
                : 'We need the following contact information from you';
        }
    };
    return AddressFormModalComponent;
}());
export { AddressFormModalComponent };
