import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { isEmpty, get } from 'lodash-es';
import { SurveyDataService, PRE, POST } from '../../utils/survey-data.service';
import { CaseManagementService } from '../caseManagement.service';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { emotionalSurveyRoutes } from './constants';
import { environment } from '../../../../environments/environment';

const MOOD_SURVEY_URL = '/data/class/moodSurvey';
const ANXIETY_SURVEY_URL = '/data/class/anxietySurvey';
const STRESS_SURVEY_URL = '/data/class/stressSurvey';
const QOL_SURVEY_URL = '/data/class/qolSurvey';
const WOS_SURVEY_URL = '/data/class/wosSurvey';
const UPDATE_USER_SURVEY_URL = '/run/updateUserSurvey';
// const LIMIT_LAST_THREE = '?filter[limit]=3&filter[order]=createdAt DESC';
const LIMIT_LAST_THREE = '?limit=3&order=-1';
const NPS_SURVEY_URL = '/data/class/npsSurvey';

const WORKLIFE_NPS_SURVEY = '/data/class/worklifeNpsSurvey'
const DAY = 1000 * 60 * 60 * 24;
const MIN_DAYS_TAG = 30; // follow up surveys during first 16 days cannot be logged as post

export function mapDaysToSurveyPeriod(actualDays: number): number {
  // slide the scale down by 16 days so that day 16 to 46 are in period 30, 47 to 76 are in period 60 etc
  const days = actualDays - MIN_DAYS_TAG;
  const rangePeriod = Math.floor(days / 30) + 1;  // period number starting at 1
  return Math.min(30 * rangePeriod, 120);   // 30 day periods up to 120 days...
}

@Injectable({ providedIn: 'root' })
export class SurveyService {
  protected basePath = environment.basePath;
  public configuration = new Configuration();
  public hasMoodResult: boolean = false;
  public hasEmotionalData: boolean = false;
  public hasAnxietyResult: boolean = false;
  public hasStressResult: boolean = false;
  public hasQolResult: boolean = false;
  public user: any;
  public surveyTrendData: any;
  surveyConfigs: any;

  constructor(private httpClient: HttpClient, private activeData: SurveyDataService, private userService: CaseManagementService, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    // get the survey configs so we can calculate 30, 60, 90 day offsets on survey  results
    userService.getUser().subscribe(user => this.surveyConfigs = get(user || {}, 'surveys', {}));
    userService.getUser().subscribe(user => {
      this.user = user;
    });
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  private setStageTypeBasedOnExistingConfiguration(surveyData: any, lastSurveyData: any, anchorDateField: string) {
    let prePostType = get(lastSurveyData, 'config.lastTaken', null) ? POST : PRE;
    const anchorDate = get(this.surveyConfigs, anchorDateField, null);

    if (prePostType === PRE || !anchorDate) {
      surveyData.config.period = 0;
    } else {
      const diff = new Date(surveyData.config.lastTaken).getTime() - new Date(anchorDate).getTime();
      const actualDays = Math.floor(diff / DAY);

      // do not tag record with 'type' PRE/POST or 'period' 30/60/90/90 when less than 16 days after anchor date
      if (actualDays < MIN_DAYS_TAG) { prePostType = PRE }

      surveyData.config.period = mapDaysToSurveyPeriod(actualDays);
    }

    surveyData.config.type = prePostType;
  }

  updateNpsSurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeNpsSurveyData, 'mood.date');
    return this.httpClient.post<any>(`${this.basePath}${NPS_SURVEY_URL}`, surveyData, this.user)
      .pipe(catchError(this.handleError));
  }

  updateEmotionalSurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeEmotionalSurveyData, 'mood.date');
    return this.httpClient.post<any>(`${this.basePath}${emotionalSurveyRoutes.emotionalSurvey}`, surveyData, this.user)
      .pipe(catchError(this.handleError));
  }

  createEmotionalSurvey(body: any): Observable<any> {
    return this.httpClient.post<any>(`${this.basePath}${emotionalSurveyRoutes.eapxEmotionalSurvey}`,
      body,
    );
  }

  createNpsSurvey(body: any): Observable<any> {
    return this.httpClient.post<any>(`${this.basePath}/run/nps-survey`,
      body,
    );
  }
  createWorklifeNpsSurvey(body: any): Observable<any> {
    return this.httpClient.post<any>(`${this.basePath}/run/worklifeNpsSurvey`,
      body,
    );
  }
  updateWorklifeNpsSurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeWorklifeNpsSurveyData, 'mood.date');
    return this.httpClient.post<any>(`${this.basePath}${WORKLIFE_NPS_SURVEY}`, surveyData, this.user)
      .pipe(catchError(this.handleError));
  }

  updateIntakeSurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeIntakeSurveyData, 'mood.date');
    return this.httpClient.post<any>(`${this.basePath}${emotionalSurveyRoutes.emotionalSurvey}`, surveyData, this.user)
      .pipe(catchError(this.handleError));
  }

  updateMoodSurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeMoodSurveyData, 'mood.date');
    this.surveyTrendData = this.createSurveyTrendData(surveyData, this.activeData.activeMoodSurveyData, this.user);
    return this.httpClient.post<any>(`${this.basePath}${MOOD_SURVEY_URL}`, surveyData)
      .pipe(catchError(this.handleError));
  }

  getMoodSurvey(): Observable<any> {
    const track = result =>
      this.hasMoodResult = result && result.result.length > 0 && !isEmpty(result.result[0].data);
    return this.httpClient.get<any>(`${this.basePath}${MOOD_SURVEY_URL}${LIMIT_LAST_THREE}`, {})
      .pipe(
        map((data: any) => {
          // rewversed for the chart purposes
          data.result.reverse();
          return data;
        }),
        catchError(this.handleError),
        tap(track.bind(this)),
      );
  }

  getEmotionalSurvey(): Observable<any> {
    const track = result =>
      this.hasEmotionalData = result && result.result.length > 0 && !isEmpty(result.result[0].data);
    return this.httpClient.get<any>(`${this.basePath}${emotionalSurveyRoutes.emotionalSurvey}${LIMIT_LAST_THREE}`, {})
      .pipe(
        map((data: any) => {
          // rewversed for the chart purposes
          data.result.reverse();
          return data;
        }),
        catchError(this.handleError),
        tap(track.bind(this)),
      );
  }

  getAnxietySurvey(): Observable<any> {
    const track = result =>
      this.hasAnxietyResult = result && result.result.length > 0 && !isEmpty(result.result[0].data);
    return this.httpClient.get<any>(`${this.basePath}${ANXIETY_SURVEY_URL}${LIMIT_LAST_THREE}`, {})
      .pipe(
        map((data: any) => {
          // rewversed for the chart purposes
          data.result.reverse();
          return data;
        }),
        catchError(this.handleError),
        tap(track.bind(this)),
      );
  }

  updateAnxietySurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeAnxietySurveyData, 'anxiety.date');
    this.surveyTrendData = this.createSurveyTrendData(surveyData, this.activeData.activeAnxietySurveyData, this.user);
    return this.httpClient.post<any>(`${this.basePath}${ANXIETY_SURVEY_URL}`, surveyData)
      .pipe(catchError(this.handleError));
  }

  getStressSurvey(): Observable<any> {
    const track = result =>
      this.hasStressResult = result && result.result.length > 0 && !isEmpty(result.result[0].data);
    return this.httpClient.get<any>(`${this.basePath}${STRESS_SURVEY_URL}${LIMIT_LAST_THREE}`, {})
      .pipe(
        map((data: any) => {
          // rewversed for the chart purposes
          data.result.reverse();
          return data;
        }),
        catchError(this.handleError),
        tap(track.bind(this)),
      );
  }

  updateStressSurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeStressSurveyData, 'stress.date')
    this.surveyTrendData = this.createSurveyTrendData(surveyData, this.activeData.activeStressSurveyData, this.user);
    return this.httpClient.post<any>(`${this.basePath}${STRESS_SURVEY_URL}`, surveyData)
      .pipe(catchError(this.handleError));
  }

  getQualitySurvey(): Observable<any> {
    const track = result =>
      this.hasQolResult = result && result.result.length > 0 && !isEmpty(result.result[0].data);
    return this.httpClient.get<any>(`${this.basePath}${QOL_SURVEY_URL}${LIMIT_LAST_THREE}`, {})
      .pipe(
        map((data: any) => {
          // reversed for the chart purposes
          data.result.reverse();
          return data;
        }),
        catchError(this.handleError),
        tap(track.bind(this)),
      );
  }

  updateQualitySurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeQualitySurveyData, 'qol.date');
    this.surveyTrendData = this.createQolSurveyTrendData(surveyData, this.activeData.activeQualitySurveyData, this.user);
    return this.httpClient.post<any>(`${this.basePath}${QOL_SURVEY_URL}`, surveyData)
      .pipe(catchError(this.handleError));
  }

  getWosSurvey(): Observable<any> {
    return this.httpClient.get<any>(`${this.basePath}${WOS_SURVEY_URL}`, {})
      .pipe(catchError(this.handleError));
  }

  updateWosSurvey(surveyData: any): Observable<any> {
    this.setStageTypeBasedOnExistingConfiguration(surveyData, this.activeData.activeWosSurveyData, 'wos.date');

    return this.httpClient.post<any>(`${this.basePath}${WOS_SURVEY_URL}`, surveyData)
      .pipe(catchError(this.handleError));
  }

  updateUserSurvey(riskLevel: string, type: string): Observable<any> {
    const trendData = this.surveyTrendData;
    return this.httpClient.post<any>(`${this.basePath}${UPDATE_USER_SURVEY_URL}`, { riskLevel, type, trendData })
      .pipe(tap(x => this.userService.refreshUser().then()), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  public createSurveyTrendData(surveyData: any, lastSurveyData: any, user: any): any {
    let lastScore = 0;
    let createdAt = null;

    if (lastSurveyData.stage && lastSurveyData.stages.length !== 0) {
      lastScore = lastSurveyData.stages[lastSurveyData.stages.length - 1].score;
      createdAt = lastSurveyData.createdAt;
    } else {
      createdAt = surveyData.config.lastTaken;
    }

    const surveyTrendData = {
      'surveyName': surveyData.config.name,
      'client': user.internalId,
      'organization': user.orgId,
      'firstTaken': createdAt,
      'lastTaken': surveyData.config.lastTaken,
      'surveyType': surveyData.config.type,
      'periodLength': surveyData.config.period,
      'surveyScore': surveyData.data.user_score,
      'changeFromLastPeriod': surveyData.data.user_score - lastScore,
      'changeFromInitial': 0 + surveyData.data.user_score,
    };

    return surveyTrendData;
  }

  public createQolSurveyTrendData(surveyData: any, lastSurveyData: any, user: any): any {
    const surveyTrendData = [];
    let createdAt = null;
    let lastScore = 0;

    if (lastSurveyData.stage && lastSurveyData.stages.length !== 0) {
      lastScore = lastSurveyData.stages[lastSurveyData.stages.length - 1].score;
      createdAt = lastSurveyData.createdAt;
    } else {
      createdAt = surveyData.config.lastTaken;
    }

    for (const property in surveyData.data) {
      if (property) {
        let previousScore = 0
        if (lastScore === 0) {
          previousScore = 0;
        } else {
          previousScore = lastScore[property]
        }
        const data = {
          'surveyName': `${surveyData.config.name}-${property}`,
          'client': user.internalId,
          'organization': user.orgId,
          'firstTaken': createdAt,
          'lastTaken': surveyData.config.lastTaken,
          'surveyType': surveyData.config.type,
          'periodLength': surveyData.config.period,
          'surveyScore': surveyData.data[property],
          'changeFromLastPeriod': surveyData.data[property] - previousScore,
          'changeFromInitial': 0 + surveyData.data[property],
        };

        surveyTrendData.push(data);
      }

    }
    return surveyTrendData;
  }
}
