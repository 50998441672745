import { Address, CaseEmployerInformation } from './models';
import { EmergencyContact } from './emergecnyContact';


/**
 * Luma API
 * Luma API
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/*  tslint:disable */

export interface User {
    id?: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    dateOfBirth: string;
    gender?: string;
    email: string;
    emailAlias?: string;
    allowEmailMessage?: boolean;
    cellPhone?: string;
    allowedCellPhoneMessage?: boolean;
    workPhone?: string;
    allowedWorkPhoneMessage?: boolean;
    homePhone?: string;
    allowedHomePhoneMessage?: boolean;
    preferredPhone?: string;
    preferredCommunicationMethod?: User.PreferredCommunicationMethodEnum;
    timeZone?: string;
    addresses?: Array<Address>;
    emergencyContact?: EmergencyContact;
    employerInformation?: CaseEmployerInformation;
    orgId?: string;
    emLifeCategories?: Array<string>;
    clientPermission?: boolean;
}
export namespace User {
    export type PreferredCommunicationMethodEnum = 'email' | 'phone' | 'no-preference';
    export const PreferredCommunicationMethodEnum = {
        Email: 'email' as PreferredCommunicationMethodEnum,
        Phone: 'phone' as PreferredCommunicationMethodEnum,
        NoPreference: 'no-preference' as PreferredCommunicationMethodEnum
    }
}
