import { NgModule } from '@angular/core';


import { ThemeModule } from '../@theme/theme.module';
import { LandingComponent } from './landing.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { LandingRoutingModule } from './landing-routing.module';
import { HomeComponent } from './home/home.component';
import { DetailComponent } from './detail/detail.component';
import { HelpComponent } from './help/help.component';
import { VideoComponent } from './video/video.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  imports: [
    LandingRoutingModule,
    ThemeModule,
  ],
  declarations: [
    LandingComponent,
    HomeComponent,
    DetailComponent,
    HelpComponent,
    VideoComponent,
    FooterComponent,
    MaintenanceComponent,
  ],
})
export class LandingModule { }
