<div class="container-fluid animate__animated animate__slideInRight animate__faster nps-content">
  <div class="container">
    <div class="row justify-content-center survey_container">
      <div class="survey-wrapper">
        <div class="custom-slider">
          <ngx-progress-bar-nps [progress]="progressBarService.progressChange | async" [visible]="showBar">
          </ngx-progress-bar-nps>
          <div class="animate__animated animate__slideInRight animate__faster d-block main_block">
            <router-outlet></router-outlet>
          </div>
          <div *ngIf="isVisibleOnStep" class="mb-4 mx-auto">
            <ngx-nps-navigation-buttons [backOnly]="backOnly">
            </ngx-nps-navigation-buttons>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
