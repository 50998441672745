<div class="header-container pt-4" [class.left]="position === 'normal'" [class.right]="position === 'inverse'">

  <div class="logo-containter ie-header-logo">
    <button class="d-md-none d-lg-none hamburger" (click)="showMenu()" *ngIf="user">
      &#9776;
    </button>

    <img class="luma-logo" [routerLink]="user ? '/pages/dashboard': '/landing'" src="/assets/images/logo/luma-white.png" alt="Luma logo">

  </div>

  <div class="ie-header-help" *ngIf="checkUser">
    <a routerLink="{{getHelpLink}}" class="help-text">{{getHelpText}}</a>
  </div>
  <div>
    <nb-sidebar tag="left" class="sidebar" [responsive]="false" [start]="true" [fixed]="true" [state]="compacted" *ngIf="canShowSidebar">
      <nb-menu [items]="userMenu" [tag]="'user-menu'" [tag]="sidebar" [inverse]="false" class="sidebar-menu"></nb-menu>
    </nb-sidebar>
    <nb-actions size="medium" class="header-container ie-header-user" [class.right]="position === 'normal'" [class.left]="position === 'inverse'">
      <nb-action *nbIsGranted="['view', 'user']">
        <nb-user [nbContextMenu]="userMenu" [nbContextMenuTag]="'user-menu'" [name]="user?.name" *ngIf="user"></nb-user>
      </nb-action>
    </nb-actions>

    <a href="tel:{{supportNumber}}">
      <div class="supportNumber">
        <span class="support-text">
          <b>{{supportText}}</b>
        </span>
        <span class="support-sub-text">
          {{supportSubText}}
          <b>{{supportNumber}}</b>
        </span>
      </div>
    </a>

  </div>
</div>