import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.scss'],
})
export class IconListComponent implements OnInit {
  @Input() heading: string;
  @Input() items: string[];
  @Input() path: string;

  constructor() { }

  ngOnInit() {
  }

}
