import * as i0 from "@angular/core";
/**
 * The SharedStateService is meant to pass state between page transitions during the lifetime of the Angular application.
 * This service does not maintain state when a full page refresh occurs.
 */
var SharedStateService = /** @class */ (function () {
    function SharedStateService() {
        this.state = {};
    }
    SharedStateService.prototype.getValue = function (key, namespace) {
        if (namespace === void 0) { namespace = null; }
        if (namespace) {
            // TODO: Implement
            throw new Error('NotImplementedError: Namespaces are not yet implemented in the SharedStateService.');
        }
        return this.state[key];
    };
    SharedStateService.prototype.setValue = function (key, value, namespace) {
        if (namespace === void 0) { namespace = null; }
        if (namespace) {
            // TODO: Implement
            throw new Error('NotImplementedError: Namespaces are not yet implemented in the SharedStateService.');
        }
        this.state[key] = value;
    };
    SharedStateService.ngInjectableDef = i0.defineInjectable({ factory: function SharedStateService_Factory() { return new SharedStateService(); }, token: SharedStateService, providedIn: "root" });
    return SharedStateService;
}());
export { SharedStateService };
