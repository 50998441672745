// Risk results
export enum RISK {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
};

// YES, NO Responses
export enum RESPONSE {
    YES = 'Yes',
    NO = 'No',
};
// list of emotions
export enum EMOTIONS {
    down_depressed = 'Feeling down or depressed',
    worried_anxious_stressed = 'Worried, anxious, or stressed out',
    tired_exhausted = 'Tired or exhausted',
    upset_angry = 'Upset or angry',
    afraid = 'Afraid of someone or something',
    dont_know = 'I don\'t know',
};
// intenisty of emotions
export enum INTENSITY {
    MILD = 'Mild',
    MODERATE = 'Moderate',
    SEVERE = 'Severe',
};
// PHQ-2 Responses
export enum PHQ_RESPONSES {
    NOT_AT_ALL = 'Not at All',
    SEVERAL_DAYS = 'Several Days',
    MORE_THAN_HALF = 'More Than Half the Days',
    ALMOST_EVERY_DAY = 'Almost Everyday',
};

// Satisfied Responses
export enum SATISFIED_RESPONSES {
    VERY_SATISFIED = 'Very Satisfied',
    SLIGHTLY_SATISFIED = 'Slightly Satisfied',
    SLIGHTLY_DISSATISFIED = 'Slightly Dissatisfied',
    VERY_DISSATISFIED = 'Very Dissatisfied',
};

// emotional states
export enum EMOTIONAL_STATES {
    STRUGGLES_AT_WORK_OR_SCHOOL = 'Struggles at work or school',
    FAMILY_OR_PARENTING = 'Family or parenting',
    RELATIONSHIP_COUPLE = 'Relationship/couple',
    MEDICAL_PROBLEMS = 'Medical problems',
    PSYCHIATRIC_PROBLEM = 'Depression/mood',
    SLEEP = 'Sleep',
    TRAUMATIC_EVENT = 'Traumatic event',
    LOSS_LOVED_ONE = 'Loss of loved one',
    HEAVY_USE = 'Heavy use of alcohol/other substances',
    ANXIETY = 'Anxiety/panic',
};

export enum LIFE_RESPONSES {
    VERY_SATISFIED = 'Very Satisfied',
    SLIGHTLY_SATISFIED = 'Slightly Satisfied',
    SLIGHTLY_DISSATISFIED = 'Slightly Dissatisfied',
    VERY_DISSATISFIED = 'Very Dissatisfied',
};

export enum STRESS_RESPONSES {
    NEVER = 'Never',
    ONLY_A_LITTLE = 'Only a Little',
    SOMETIMES = 'Sometimes',
    FAIRLY_OFTEN = 'Fairly Often',
    VERY_OFTEN = 'Very Often',
};
