import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ListItem } from '../../../../../@core/data';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { ModalService } from '../../../../../@core/utils/modal.service';
import { ModalOptions, ConfirmationModal } from '../../../../../@theme/components/reusables';
import { Observable } from 'rxjs';

export class WorkLifeServiceRequest {
  // Company
  companyName?: string = '';
  chosenSiteLocation: any = '';
  companyAffiliation: string = '';

  // User
  id: string = '';
  firstName?: string = '';
  lastName?: string = '';
  address?: any = '';
  preferredPhone: string = '';
  phoneNumber: string = '';
  allowedHomePhoneMessage: string = '';
  email: string = '';
  allowEmailMessage: string = '';
  dateOfBirth: string = '';
  gender: string = '';

  // caseData
  workLifeCategory: string = '';
  workLifeSubCategory: string = '';

  // internalCase
  referralSource: string = '';

  // Form
  methodsOfCommunication: string = '';
  description: string = '';
}


@Component({
  selector: 'ngx-work-life-request-form-modal',
  templateUrl: './work-life-request-form-modal.component.html',
  styleUrls: ['./work-life-request-form-modal.component.scss'],
})
export class WorkLifeRequestFormModalComponent implements OnInit {
  heading: string = '';
  myGroup: FormGroup;
  // methodsOfCommunications: string[] = [];
  communicationType: string = '';
  description: string = '';

  get isValidState(): boolean {
    // return this.selectedIndex >= 0;
    return true;
  }

  set modal(value: ModalOptions) {

    if (Object.keys(value).length > 0) {

      const { modalHeader } = value;

      if (modalHeader) {
        this.heading = modalHeader;
      }
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  configs$: Observable<ListItem[]>;

  ngOnInit() {
    // this.workLifeForm = this.formBuilder.group(new WorkLifeServiceRequest());
    // this.workLifeForm.get('description').setValidators([Validators.required]);
    // this.workLifeForm.get('methodsOfCommunication').setValidators([Validators.required]);

    // TODO: use a mapping (from preferredPhone) to determine which phone number to show


    /*this.methodsOfCommunications = [
      'Email',
      'Phone',
      'Text Message',
    ]; */

    this.myGroup = new FormGroup({
      preferredComunicationMethod: new FormControl(),
   });
  }

  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }

  accept(): void {
    this.activeModal.close({ answer: ConfirmationModal.Yes,
      communicationType: this.communicationType,
      description: this.description,
    });
  }
}
