<nb-card>
  <nb-card-body class="flex-centered">
    <nb-auth-block>
      <ngx-intake-header title="STATEMENT OF UNDERSTANDING" styleClasses="mt-0"></ngx-intake-header>
      <div class="privacy">
        <p>Please read carefully:</p><br>

        <ol class="list-group">
          <li>
            <p>
                Your, your partner’s or eligible family member’s organization contracts with Concern to provide, at no charge
                to you, assessment, referral and/or short-term coaching or counseling services. Participation in the
                Concern program is voluntary.
            </p>
          </li>
          <li>
            <p>
                You may schedule visits beyond the EAP visits when you and your Concern coach or counselor agree that continued care is the best plan. Any charges
                incurred for services beyond the EAP visits are your sole responsibility. You may be eligible for partial reimbursement of these charges through your
                or your partner’s insurance coverage.
            </p>
          </li>
          <li>
            <p>
                Concern requires 24 hours' notice to cancel or reschedule visits. Each appointment you fail to keep or cancel with less than 24 hours' notice will
                count against your allotted visits.
            </p>
          </li>
          <li>
            <p>
                Our services are confidential. Neither Concern nor your coach or counselor will reveal any information about you to anyone, including your employer,
                without your written permission, unless permitted or required by applicable federal or state law. In cases of imminent risk of harm to self or
                others, coaches or counselors may disclose confidential information. Counselors are also required to report any suspicion of child or elder abuse
                and to warn victims of potentially dangerous individuals.
            </p>
          </li>
          <li>
            <p>
              A "Notice of Privacy Practices" that describes how your protected health information may be used and
              disclosed and how you can
              access this information is provided in our Combined Evidence of Coverage and Disclosure Form. You can
              email Concern at
              <a href="mailto:info@concernhealth.com">info@concernhealth.com</a> or call Concern at <a
                href="tel:8003444222">800.344.4222</a> to request this document be sent to you.</p>
          </li>
          <li>
            <p>
              You can request an interpreter at no cost to facilitate communications with Concern or a counselor. To
              request an interpreter or ask about written information in your language,
              you may call Concern at <a href="tel:8003444222">800.344.4222.</a> Find further information at
              <a
                href="https://employees.concernhealth.com/language-assistance-program.">https://employees.concernhealth.com/language-assistance-program.</a>
            </p>
          </li>
          <li>
            <p>
              Concern clients have certain rights afforded all counseling clients. Please contact Concern at <a
                href="tel:8003444222">800.344.4222</a>
              or <a href="mailto:info@concernhealth.com">info@concernhealth.com</a> if you would like a copy of these
              rights.
            </p>
          </li>
          <li>
            <p>
                When you receive services from Concern, you agree to have any issue (except claims of malpractice) decided by neutral binding arbitration and are
                giving up your right to a jury trial. If you have questions about this, please contact your employer’s benefits department.
            </p>
          </li>
          <li>
            <p>
              A formal procedure is available to handle any complaints about services. You may contact a Concern
              clinical manager for details at <a href="tel:8003444222">800.344.4222</a>
              or <a href="mailto:feedback@concernhealth.com">feedback@concernhealth.com</a>
            </p>
          </li>
          <hr>
          <h3 class="title">Applicable to Video Counseling ONLY</h3>
          <br>
          <li>
            <p>
                At the beginning of each video session you will be asked for your physical address at the time of that session, and for an identified emergency
                contact within 25 miles.
            </p>
          </li>
          <li>
            <p>
              You or your counselor can discontinue video-based counseling sessions if:
            </p>
            <ol class="list-group" type="a">
              <li>
                <p>It is felt that the video counseling connections are not adequate for the situation;</p>
              </li>
              <li>
                <p>It is felt that you would be better suited for face-to-face evaluation or counseling;</p>
              </li>
              <li>
                <p>You do not think this is the best counselor to suit your needs.</p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Technical difficulties experienced during a session, which detract from the quality of the session, will result in you receiving an additional session.
            </p>
          </li>
          <hr>
          <li>
            <p>
                I understand that if I have provided an email address and consent, I may receive electronic information including referral, resource, and survey
                material from Concern. I understand that information sent to an email address may not be secure, and that I may retract this consent at any time
                by calling Concern.
            </p>
          </li>
        </ol>
        <nb-checkbox name="agreeToSou" [(ngModel)]="agreeToSou" (ngModelChange)="agreeToSouChange.emit($event)">
            By checking this box, you're agreeing to <span style="color: #00aec2; font-weight: bold;"><a href="assets/docs/ConcernSOU-20191206.pdf" target="_blank">Concern's Statement of Understanding</a></span>.
         </nb-checkbox>
      </div>
    </nb-auth-block>
  </nb-card-body>
</nb-card>
