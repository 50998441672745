var SurveyData = /** @class */ (function () {
    function SurveyData() {
        this.IntakeSurveyData = {};
        this.moodSurveyData = {};
        this.npsSurveyData = {};
        this.emotionalSurveyData = {};
        this.worklifeNpsSurveyData = {};
        this.anxietySurveyData = {};
        this.stressSurveyData = {};
        this.qualityOfLifeData = {};
        this.wosData = {};
    }
    return SurveyData;
}());
export { SurveyData };
var SurveyStage = /** @class */ (function () {
    function SurveyStage() {
        this.type = '';
        this.score = 0;
        this.taken = '';
    }
    return SurveyStage;
}());
export { SurveyStage };
export var PRE = 'pre';
export var POST = 'post';
var SurveyDataService = /** @class */ (function () {
    function SurveyDataService() {
        this.surveyData = new SurveyData();
    }
    SurveyDataService.prototype.selectAnswers = function (selectedSurveyAnswers) {
        if (this.activeAnxietySurveyData) {
            this.activeAnxietySurveyData.data = selectedSurveyAnswers;
            this.activeAnxietySurveyData.config.lastTaken = new Date().toISOString();
            this.activeAnxietySurveyData.config.showSurvey = false;
        }
        if (this.activeStressSurveyData) {
            this.activeStressSurveyData.data = selectedSurveyAnswers;
            this.activeStressSurveyData.config.lastTaken = new Date().toISOString();
            this.activeStressSurveyData.config.showSurvey = false;
        }
    };
    Object.defineProperty(SurveyDataService.prototype, "activeIntakeSurveyData", {
        get: function () { return this.surveyData.IntakeSurveyData; },
        set: function (data) { this.surveyData.IntakeSurveyData = data; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDataService.prototype, "activeMoodSurveyData", {
        get: function () { return this.surveyData.moodSurveyData; },
        set: function (data) { this.surveyData.moodSurveyData = data; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDataService.prototype, "activeNpsSurveyData", {
        get: function () { return this.surveyData.npsSurveyData; },
        set: function (data) { this.surveyData.npsSurveyData = data; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDataService.prototype, "activeEmotionalSurveyData", {
        get: function () { return this.surveyData.emotionalSurveyData; },
        set: function (data) { this.surveyData.emotionalSurveyData = data; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDataService.prototype, "activeWorklifeNpsSurveyData", {
        get: function () { return this.surveyData.worklifeNpsSurveyData; },
        set: function (data) { this.surveyData.worklifeNpsSurveyData = data; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDataService.prototype, "activeAnxietySurveyData", {
        get: function () { return this.surveyData.anxietySurveyData; },
        set: function (data) { this.surveyData.anxietySurveyData = data; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDataService.prototype, "activeStressSurveyData", {
        get: function () { return this.surveyData.stressSurveyData; },
        set: function (data) { this.surveyData.stressSurveyData = data; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDataService.prototype, "activeQualitySurveyData", {
        get: function () { return this.surveyData.qualityOfLifeData; },
        set: function (data) { this.surveyData.qualityOfLifeData = data; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SurveyDataService.prototype, "activeWosSurveyData", {
        get: function () { return this.surveyData.wosData; },
        set: function (data) { this.surveyData.wosData = data; },
        enumerable: true,
        configurable: true
    });
    SurveyDataService.prototype.userMoodRisk = function (score) {
        if (score === void 0) { score = 0; }
        if (score >= 0 && score <= 4) {
            return 'None - Minimal depression';
        }
        else if (score >= 5 && score <= 9) {
            return 'Mild depression';
        }
        else if (score >= 10 && score <= 14) {
            return 'Moderate depression';
        }
        else if (score >= 15 && score <= 19) {
            return 'Moderately severe depression';
        }
        else {
            return 'Severe depression';
        }
    };
    SurveyDataService.prototype.userMoodDescription = function (score, maxScore) {
        if (score === void 0) { score = 0; }
        if (score >= 0 && score <= 4) {
            return "You scored " + score + " out of " + maxScore + " which is NOT consistent with the symptoms of clinical depression.\n                    Keep doing the things that support your health, wellness and mood.";
        }
        else if (score >= 5 && score <= 9) {
            return "You scored " + score + " out of " + maxScore + ". This score may be consistent with the symptoms of mild depression.\n                    Physical activity and social connection are two of the top things that can help when you feel down.\n                    Review your personalized recommendations.";
        }
        else if (score >= 10 && score <= 14) {
            return "You scored " + score + " out of " + maxScore + " \u2014 which is consistent with the symptoms of moderate clinical depression.\n                    We recommend that you reach out to a licensed mental health professional at Concern today.";
        }
        else if (score >= 15 && score <= 19) {
            return "You scored " + score + " out of a possible " + maxScore + ".\n                    This score is highly consistent with the symptoms of clinical depression.\n                    We strongly recommend that you talk to a licensed mental health professional at\n                    Concern today by calling (800) 344-4222.";
        }
        else {
            return "You scored " + score + " out of a possible " + maxScore + " which is highly consistent with the symptoms of clinical depression.\n                    We strongly recommend that you talk to a licensed mental health professional at Concern today by calling (800) 344-4222.\n                    Please talk to someone today.";
        }
    };
    SurveyDataService.prototype.userMoodRiskLevel = function (score) {
        if (score >= 0 && score <= 9) {
            return 'LOW';
        }
        else if (score >= 10 && score <= 14) {
            return 'MODERATE';
        }
        else {
            return 'HIGH';
        }
    };
    SurveyDataService.prototype.userAnxietyRisk = function (score) {
        if (score >= 0 && score <= 4) {
            return 'None - Minimal anxiety';
        }
        else if (score >= 5 && score <= 9) {
            return 'Mild anxiety';
        }
        else if (score >= 10 && score <= 14) {
            return 'Moderate anxiety';
        }
        else if (score >= 15 && score <= 21) {
            return 'Severe anxiety';
        }
    };
    SurveyDataService.prototype.userAnxietyDescription = function (score) {
        if (score === void 0) { score = 0; }
        if (score >= 0 && score <= 4) {
            return "You scored " + score + " out of 21, which is NOT consistent with general anxiety disorder.\n            Keep doing the things that support your health, wellness and mental wellbeing.";
        }
        else if (score >= 5 && score <= 9) {
            return "You scored " + score + " out of 21. This score is consistent with mild symptoms of general anxiety disorder.\n            Review your personalized recommendations to learn more.";
        }
        else if (score >= 10 && score <= 14) {
            return "You scored " + score + " out of 21, which is consistent with moderate symptoms of general anxiety disorder.\n            We recommend that you reach out to a licensed mental health professional at Concern today.";
        }
        else if (score >= 15 && score <= 21) {
            return "You scored " + score + " out of a possible 21. This score is consistent with severe symptoms of general anxiety disorder.\n          We strongly recommend that you talk to a licensed mental health professional at Concern today by calling (800) 344-4222.";
        }
    };
    SurveyDataService.prototype.userAnxietyRiskLevel = function (score) {
        if (score >= 0 && score <= 9) {
            return 'LOW';
        }
        else if (score >= 10 && score <= 14) {
            return 'MODERATE';
        }
        else {
            return 'HIGH';
        }
    };
    SurveyDataService.prototype.userStressRisk = function (score) {
        if (score >= 0 && score <= 7) {
            return 'None - Minimal stress';
        }
        else {
            return 'Severe stress';
        }
    };
    SurveyDataService.prototype.userStressDescription = function (score) {
        if (score === void 0) { score = 0; }
        if (score >= 0 && score <= 8) {
            return "Your stress level is low - let\u2019s help you keep it that way!\n            Consider exploring <a href=\"/sso/emindful\">programs from a mindfulness expert.</a>";
        }
        else {
            return "Dealing with a high level of stress in your life? Consider <a href=\"/sso/emindful\">a stress management program</a>\n            from a mindfulness expert.";
        }
    };
    SurveyDataService.prototype.userStressRiskLevel = function (score) {
        if (score >= 0 && score <= 7) {
            return 'LOW';
        }
        else {
            return 'HIGH';
        }
    };
    SurveyDataService.prototype.userQualityOfLifeRisk = function (score) {
        if (score === void 0) { score = 0; }
        // TODO: implement mapping
        return "You scored " + score + ". Implement userQualityOfLifeRisk mapping";
    };
    SurveyDataService.prototype.userQualityOfLifeDescription = function (score) {
        if (score === void 0) { score = 0; }
        // TODO: implement mapping
        return "You scored " + score + ". Implement userQualityOfLifeDescription mapping";
    };
    SurveyDataService.prototype.userWosRisk = function (score) {
        if (score === void 0) { score = 0; }
        // TODO: implement mapping
        return "You scored " + score + ". Implement userWosRisk mapping";
    };
    SurveyDataService.prototype.userWosDescription = function (score) {
        if (score === void 0) { score = 0; }
        // TODO: implement mapping
        return "You scored " + score + ". Implement userWosDescription mapping";
    };
    return SurveyDataService;
}());
export { SurveyDataService };
