<div class="row justify-content-center align-items-center main py-5 mx-1">
  <div class="col-12">

    <div class="row justify-content-center ">
      <div class="col-12 text-center">
        <h1>{{title}}</h1>
        <h4 class="mx-1"> {{content}}</h4>
      </div>
    </div>

    <div class="row justify-content-center my-4 mx-4">
      <div class="col-12">

        <!-- icons-->
        <div class="row">
          <div class="col-md-6 col-xl-3 col-12 mt-xl-0 mt-4" *ngFor="let option of selfHelpOptions">

            <div class="text-center">
              <img [src]="option.icon" class="img-fluid" style="width: 11em;">
              <h4 class="link">{{option.title}}</h4>
              <div>{{option.description}}</div>
            </div>

          </div>
        </div>

        <div class="row justify-content-center align-items-center mt-5 mb-2">
          <div class="col-lg-12 text-center">
            <a class="btn btn-primary" routerLink="{{getHelpLink}}">{{btnText}}</a>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <p class="landing-text account-text">Already have an account?
              <a class="link" routerLink="../auth/login">Sign in</a>
              now
            </p>
          </div>
        </div>
      </div>

    </div>

  </div>