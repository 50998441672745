<div class="modal-content" style="margin-top:0px;">

  <span class="close-icon" (click)="closeModal()">&#10005;</span>
  <div class="text-center message-container">
    <div class="row justify-content-center align-content-center align-items-center no-gutters mb-5">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <div class="counselor-text justify-content-center align-content-center align-items-center">
          <div class="media mx-auto provider-media">
            <img class="modal-icon" src="../../../../../../assets/icons/onlie-card-icon.png"
              alt="Online Counsling Icon">
          </div>
          <p class="modal-heading">{{BH_STRING}} Counseling</p>
          <p class="modal-sub-heading">Video, Phone, Chat, or Text</p>
          <p>
            <img class="concern-logo" src="../../../../../../assets/images/logo/concern-one-color-logo.png"
              alt="Concern Logo">
            <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
            <img class="bh-logo" src="../../../../../../assets/icons/bh_logo_small.png" alt="BetterHelp Logo">
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="first-line">
    <div class="row first-left-side">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <p class="section-heading">ABOUT BETTERHELP</p>
        <p class="about-bh">
          Concern in partnership with BetterHelp matches you with a licensed therapist to easily access counseling.
        </p>
      </div>
    </div>

    <div class="row first-right-side">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <p class="section-heading">ONCE THERE, YOU WILL:</p>
        <ul class="bh-steps">
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Enter your email and answer some basic questions. It takes about 5 minutes.</p>
          </div>
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Create your FREE BetterHelp account.</p>
          </div>
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Get matched with a licensed counselor.</p>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <br>
  <!-- State filter  -->
  <div class="stateModel switch_dropdown">
    <div class="text-center message-container">
      <div class="col-12 col-md-7 mx-auto form-group">
        <p class="practice-state">The counselor should be able to practice in:</p>
        <div class="dropdown">
          <select name="within" class="form-control within" [(ngModel)]="value">
            <option *ngFor="let state of getStates()" [value]="state">
              {{state}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- State filter -->
    <div class="second-line">
      <div class="row second-left-side">
        <div class="col-xs-12 col-md-12 col-xl-12">
          <p class="section-heading bh-section-heading">BETTERHELP SOLUTIONS</p>
          <div class="logos">
            <div class="child1">
              <div class="d-flex individuals">
                <p class="paratitle">For Individuals: </p>
                <img class="bh-help-logo" src="../../../../../../assets/icons/logo-betterhelp-small.png"
                  alt="BetterHelp logo">
              </div>
              <div class="d-flex couples">
                <p>For Couples: </p>
                <img class="regain-help-logo" src="../../../../../../assets/icons/logo-Regain.png" alt="Regain Logo">
              </div>
              <div class="d-flex teens">
                <p>For Teens: </p>
                <img class="teens-help-logo" src="../../../../../../assets/icons/logo-teamcounseling.png"
                  alt="Teen Counseling Logo">
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="container button-holder">
        <div class="row text-center justify-content-center align-content-center align-items-center no-gutters">
          <button class="btn btn-lg btn-primary mt-2 select-counselor-btn" [disabled]="!value"
            (click)="completeRequest()" [class.btn-pulse]="submitted" style="padding-bottom: 36px;">SELECT ONLINE
            COUNSELING
          </button>
        </div>
      </div>
    </div>
