import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmotionalWellbeingSurveyComponent } from './emotional-wellbeing-survey.component';
import { FeelingsSurveyComponent } from './feelings-survey/feelings-survey.component';
import { EmotionalSurveyRoutingModule } from './emotional-wellbeing-survey-routing.module';
import { SatisfiedLifeComponent } from './satisfied-life/satisfied-life.component';
import { StressLevelComponent } from './stress-level/stress-level.component';
import { EmotionalThankyouComponent } from './emotional-thankyou/emotional-thankyou.component';
import { ThemeModule } from '../../@theme/theme.module';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    EmotionalSurveyRoutingModule,

  ],
  declarations: [EmotionalWellbeingSurveyComponent, FeelingsSurveyComponent, SatisfiedLifeComponent, StressLevelComponent, EmotionalThankyouComponent],
})
export class EmotionalWellbeingSurveyModule { }
