
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { ContentService } from './api/content.service';
import { Content } from './model/content';
import { upsertItem } from '../utils/upsertItem';

@Injectable({ providedIn: 'root' })
export class ContentManagementService implements OnInit {
    private _contentId: string;

    public get contentId(): string {
        return this._contentId;
    }

    public set contentId(value: string) {
        this._contentId = value;
    }

    private userContents: BehaviorSubject<Array<Content>>;

    constructor(private _contentService: ContentService) {
        this.userContents = new BehaviorSubject<Array<Content>>([]);
        this.ngOnInit();
    }

    ngOnInit(): void {
    }

    public getUserContent(): Observable<any[]> { return this.userContents.asObservable(); }

    public getContentById(): Observable<any> {
        return <Observable<any>>of(this.userContents.value.filter((content: Content) => content.id === this.contentId));
    }

    public getAllRecommendedContent(ids: Array<String>, benefits: Array<String> = []): void {
        this._contentService.getContentByCaseIds(ids, benefits).subscribe((contents: Array<Content>) => {
            upsertItem(contents, this.userContents);
        }, (err) => {
            this.userContents.error(err);
        });
    }

    public resetVars(): void {
        this._contentId = '';
    }
    public resetCache(): void {
        this.resetVars();
        this.userContents.next([]);
    }
}
