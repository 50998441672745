import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Provider } from './model/models';
import { Configuration } from './configuration';
import { BASE_PATH } from './variables';
import { providerRoutes, removeFalsyProps } from './api/constants';
import { environment } from '../../../environments/environment';

export interface ProviderFilter {
  zipCode?: string,
  radius?: string,
  modality?: string,
  stateLicensed?: string,
  onSiteOrgName?: string,
  gender?: string,
  specialty?: string,
  insurancePanel?: string,
  ethnicity?: string,
  availabilities?: string;
}

interface ProviderAddressDto {
  id?: string;
  name?: string | null;
  type?: string | null;
  suite?: string | null;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  postal?: string | null;
  country?: string | null;
};
interface ProviderLicenseDto {
  id?: string | null;
  licenseExpiry?: string | null;
  licenseNumber?: string | null;
  clinicalLicenseType?: string | null;
  stateProvince?: string | null;
};

export interface FindProviderResponse {
  _id?: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  emailAlias: string;
  createdAt?: Date;
  failedAttempts: number;
  gender: string;
  updatedAt?: Date;
  internalId?: string;
  externalId?: string;
  id?: string;
  allowedCellPhoneMessage?: string;
  workPhone?: string;
  allowedWorkPhoneMessage?: string;
  homePhone?: string;
  allowedHomePhoneMessage?: string | boolean;
  preferredPhone?: string;
  preferredCommunicationMethod?: string;
  dateOfBirth?: string;
  cellPhone?: string;
  timeZone?: string;
  role?: number;
  roles?: string[];
  phone?: string;
  ethnicity?: string;
  profileImage?: string;
  imageModifiedOn?: string;
  videoEnabled?: boolean;
  onlineSchedulingEnabled?: boolean;
  onSiteEnabled?: boolean;
  thumbnail?: string;
  photo?: string;
  startDate?: string;
  specializations?: string[];
  insurancePanels?: string[];
  clinicalPanels?: string[];
  credentials?: string[];
  biography?: string;
  associations?: string[];
  officeLocations?: ProviderAddressDto[];
  providerLicenses?: ProviderLicenseDto[];
  digitalPlatformEnabled?: boolean;
  availability?: boolean;
  betterHelpId?: number;
}

@Injectable()
export class CounselorService {
  protected basePath = environment.basePath;
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }
  onSiteProviders: BehaviorSubject<Provider[]> = new BehaviorSubject(null);
  public getOnsiteCounselors(orgId: string): void {
    if (!!orgId) {
      const providersByOrgPath = providerRoutes.getProvidersByOrg.replace(':orgId', orgId);
      this.httpClient.get<Provider[]>(`${this.basePath}${providersByOrgPath}`)
        .subscribe((providers) => this.onSiteProviders.next(providers));
    }
  }

  public getCounselorsAPI(providersId: Array<string>): Observable<any> {
    return this.httpClient.get<any>(`${environment.lbPath}${providerRoutes.getCounselorProvidersByIds}?id=${providersId}`)
  }

  public findProviders(caseId: string, filter: ProviderFilter): Observable<FindProviderResponse[]> {
    const cleanedFilter = Object.entries(removeFalsyProps(filter));
    const filterRecords = cleanedFilter.map(([key, value]) => [key, String(value)]);
    const filterString = filterRecords.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
    const urlSearchParam = new URLSearchParams(filterString);
    return this.httpClient.get<FindProviderResponse[]>(`${environment.lbPath}${providerRoutes.getProviders}/${caseId}?${urlSearchParam.toString()}`)
  }
  public findProviderSpecialization(state: string): Observable<any> {
    return this.httpClient.get<any>(`${this.basePath}${providerRoutes.getProviderBySpecialization}?stateLicensed=${state}&clinicalPanel=BetterHelp`)
  }
}
