<ng-container *ngIf="providerSelected; then providerSelected; else noProviderSelected">
</ng-container>
<ng-template #noProviderSelected>
  <!-- <div class="modal-header text-center">
    <span class="lead text-center">
      <p>Review your Address</p>
    </span>
  </div>

  <form class="col-md-8 col-lg-8 col-sm-12 mx-auto" #form="ngForm">

    <div class="modal-content">
      <div class="text-center message-container">
        <ngx-address-validation (addressChange)="chosenAdress = $event" [original]="formattedOriginalAddress"
          [suggested]="suggestedAddresses"></ngx-address-validation>
      </div>
    </div>

    <div class="modal-footer text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-6">
            <button class="btn btn-lg btn-primary" (click)="closeModal()">Cancel</button>
          </div>
          <div class="col-6">
            <button [disabled]="form.invalid" [class.btn-pulse]="submitted" class="btn btn-lg btn-primary"
              (click)="submitForm(form)">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>

  </form> -->
</ng-template>

<ng-template #providerSelected>

  <div class="modal-content" style="margin-top:0px;" *ngIf="provider">

    <span class="close-icon" (click)="closeModal()">&#10005;</span>
    <div class="text-center message-container">
      <div class="row justify-content-center align-content-center align-items-center no-gutters mb-5">
        <div class="col-xs-12 col-md-7 col-xl-7">
          <div class="counselor-text justify-content-center align-content-center align-items-center">
            <div class="media mx-auto provider-media">
              <ngx-counselor-image [counselor]="provider"></ngx-counselor-image>
            </div>
            <ngx-counselor-name-licences [counselor]="provider"></ngx-counselor-name-licences>
          </div>
        </div>
      </div>
    </div>

    <div class="first-line">
      <div class="row first-left-side">
        <div class="col-xs-12 col-md-12 col-xl-12">
          <p *ngIf="selected.modeOfHelp!=='online-therapy'" class="section-heading">ABOUT {{provider.firstName |
            uppercase}}</p>
          <p *ngIf="selected.modeOfHelp==='online-therapy'" class="section-heading">ABOUT {{provider.lastName |
            uppercase}}</p>
          <p class="counselor-bio">{{ provider.biography }}</p>
        </div>
      </div>

      <div class="row first-right-side">
        <div class="col-xs-12 col-md-12 col-xl-12">
          <p class="section-heading">SPECIALTIES</p>
          <ul class="specialties-list square-bullets">
            <li class="specialty bullets" *ngFor="let specialty of provider.specializations">{{ specialty }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="second-line">
      <div class="row second-left-side" [ngClass]="{'w-100':selected.modeOfHelp==='online-therapy'}">
        <div class="col-xs-12 col-md-12 col-xl-12">
          <p class="section-heading">LOCATION</p>
          <div class="location-details d-flex" *ngFor="let officeLocation of selectOfficeLocations(provider?.officeLocations)">
            <img class="pin-icon" src="../../../../../../assets/icons/map-pin.svg" alt="Map Pin Icon">
            <!-- <i class="fa fa-map-marker" style="color: #00a7ce;" aria-hidden="true"></i> -->
            <p class="office-location">{{officeLocation.street}} {{officeLocation.city}}
              {{officeLocation.state}} {{officeLocation.postal}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="selected.modeOfHelp!=='online-therapy'" class="row second-right-side">
        <div class="col-xs-12 col-md-12 col-xl-12 d-flex">

          <div class="serving-container">
            <p class="section-heading">SERVING</p>
            <ul class="serving-list square-bullets">
              <li class="serving bullets" *ngFor="let credential of provider.credentials">{{ credential }}</li>
            </ul>
          </div>

          <div class="insurance-container">
            <p class="section-heading">INSURANCE</p>
            <ul class="insurance-list square-bullets">
              <li class="insurance bullets" *ngFor="let insurance of provider.insurancePanels">{{ insurance }}</li>
            </ul>
          </div>

        </div>
      </div>
    </div>

    <div class="row text-center justify-content-center align-content-center align-items-center no-gutters"
      style="margin-top: 25px;">
      <div class="col-12 col-md-9 col-xl-9">
        <p>
          <input type="checkbox" name="sou" id="sou-checkbox" class="sou-checkbox mr-2" [(ngModel)]="souAgreed"> I agree
          to
          <span>
            <a href="../../../../../../assets/docs/ConcernSOU-2021-10-19.pdf" target="_blank">
              <div style="font-weight: 900; display: inline-block; margin-top: 15px;">Concern's
                Statement of Understanding
              </div>
            </a>
          </span>
        </p>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="container button-holder" style="margin-top: 40px;">
      <div class="row text-center justify-content-center align-content-center align-items-center">
        <button class="btn btn-lg btn-primary mt-2 select-counselor-btn" (click)="completeRequest()"
          [disabled]="!souAgreed" [class.btn-pulse]="submitted" style="padding-bottom: 36px;">{{ buttonText }}
        </button>
      </div>
    </div>
  </div>

</ng-template>
