import { Injectable } from '@angular/core';
import { EmotionalWellbeingSurveyService } from './emotional-wellbeing-survey.service';
import { RISK } from './enums';

const NO_SCORE = 0;
const LOW_SCORE = 1;
const MEDIUM_SCORE = 2;
const HIGH_SCORE = 3;
const HIGHEST_SCORE = 4;

const PHQ_SCORE_MAP = {
  'Not at All': NO_SCORE,
  'Several Days': LOW_SCORE,
  'More Than Half the Days': MEDIUM_SCORE,
  'Almost Everyday': HIGH_SCORE,
};
const SAT_SCORE_MAP = {
  'Very Satisfied': LOW_SCORE,
  'Slightly Satisfied': MEDIUM_SCORE,
  'Slightly Dissatisfied': HIGH_SCORE,
  'Very Dissatisfied': HIGHEST_SCORE,
};
const STRESS_SCORE_MAP = {
  'Never': NO_SCORE,
  'Only a Little': LOW_SCORE,
  'Sometimes': MEDIUM_SCORE,
  'Fairly Often': HIGH_SCORE,
  'Very Often': HIGHEST_SCORE,
}

@Injectable({
  providedIn: 'root',
})


export class SurveyCalcService {

  constructor(private emotionalSurvey: EmotionalWellbeingSurveyService) { }

  private feelingsData: string[] = []; // Capture Feelings data
  private feelings: string[] = [];
  private stressData: string[] = []; // Capture Stress data
  private satisfiedData: string[] = []; // Capture satisfied data
  PHQ2_1_Score: number;
  PHQ2_2_Score: number;
  PHQ2_3_Score: number;
  PHQ2_4_Score: number;
  SAT_1_SCORE: number;
  STRESS_1_SCORE: number;
  // gather data from emotional service
  private gatherData() {
    this.feelings = this.emotionalSurvey.feelingsData;
    this.feelingsData = Object.values(this.feelings);
    this.stressData = this.emotionalSurvey.stressLifeData;
    this.satisfiedData = this.emotionalSurvey.satisfiedLifeData;
    this.PHQ2_1_Score = this.getEmotionIntensityScore(0);
    this.PHQ2_2_Score = this.getEmotionIntensityScore(1);
    this.PHQ2_3_Score = this.getEmotionIntensityScore(2);
    this.PHQ2_4_Score = this.getEmotionIntensityScore(3);
    this.SAT_1_SCORE = this.getSatisfactionIntensityScore(0);
    this.STRESS_1_SCORE = this.getStressIntensityScore(0);
  }

  scoreMap() {

  }
  // Calculate Risk from responses
  calculateRisk(): RISK {
    this.gatherData();
    const PHQ_Total = this.PHQ2_1_Score + this.PHQ2_2_Score + this.PHQ2_3_Score + this.PHQ2_4_Score;
    const EMOTIONAL_TOTAL = PHQ_Total + this.SAT_1_SCORE + this.STRESS_1_SCORE;
    if (EMOTIONAL_TOTAL <= 8) {
      return RISK.LOW;
    } else if (EMOTIONAL_TOTAL > 8 && EMOTIONAL_TOTAL < 15) {
      return RISK.MEDIUM;
    } else {
      return RISK.HIGH;
    }

  }

  private getEmotionIntensityScore(index): number {
    return PHQ_SCORE_MAP[this.feelingsData[index]];
  }
  private getSatisfactionIntensityScore(index): number {
    return SAT_SCORE_MAP[this.satisfiedData[index]];
  }
  private getStressIntensityScore(index): number {
    return STRESS_SCORE_MAP[this.stressData[index]];
  }
}

