<nb-layout>
    <nb-layout-header class="layout-header">
        <ngx-header></ngx-header>
    </nb-layout-header>

    <nb-layout-column>
        <div class="flex-centered col-xl-8 col-lg-10 col-md-8 col-sm-12">
            <router-outlet></router-outlet>
        </div>
    </nb-layout-column>
    <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
    </nb-layout-footer>
</nb-layout>