import { Component, OnInit } from '@angular/core';
import { LUMA_THEME } from '../../styles/theme.luma';
import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '../../../@core/utils/analytics.service';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: 'footer.component.html',
})
export class FooterComponent implements OnInit {
  siteName: string = LUMA_THEME.variables.siteName;
  year: number = new Date().getFullYear();
  env: any;

  constructor(
    private analytics: AnalyticsService,
  ) { }

  ngOnInit() {
    this.env = environment;
  }

  GaEvent(linkName) {
    this.analytics.trackGenericEvent('footer', 'link clicked', `${linkName}`);
  }
}
