import { OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { ModalService } from '../../../../../@core/utils/modal.service';
import { ConfirmationModal } from '../../../../../@theme/components/reusables';
var WorkLifeServiceRequest = /** @class */ (function () {
    function WorkLifeServiceRequest() {
        // Company
        this.companyName = '';
        this.chosenSiteLocation = '';
        this.companyAffiliation = '';
        // User
        this.id = '';
        this.firstName = '';
        this.lastName = '';
        this.address = '';
        this.preferredPhone = '';
        this.phoneNumber = '';
        this.allowedHomePhoneMessage = '';
        this.email = '';
        this.allowEmailMessage = '';
        this.dateOfBirth = '';
        this.gender = '';
        // caseData
        this.workLifeCategory = '';
        this.workLifeSubCategory = '';
        // internalCase
        this.referralSource = '';
        // Form
        this.methodsOfCommunication = '';
        this.description = '';
    }
    return WorkLifeServiceRequest;
}());
export { WorkLifeServiceRequest };
var WorkLifeRequestFormModalComponent = /** @class */ (function () {
    function WorkLifeRequestFormModalComponent(activeModal) {
        this.activeModal = activeModal;
        this.heading = '';
        // methodsOfCommunications: string[] = [];
        this.communicationType = '';
        this.description = '';
    }
    Object.defineProperty(WorkLifeRequestFormModalComponent.prototype, "isValidState", {
        get: function () {
            // return this.selectedIndex >= 0;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkLifeRequestFormModalComponent.prototype, "modal", {
        set: function (value) {
            if (Object.keys(value).length > 0) {
                var modalHeader = value.modalHeader;
                if (modalHeader) {
                    this.heading = modalHeader;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    WorkLifeRequestFormModalComponent.prototype.ngOnInit = function () {
        // this.workLifeForm = this.formBuilder.group(new WorkLifeServiceRequest());
        // this.workLifeForm.get('description').setValidators([Validators.required]);
        // this.workLifeForm.get('methodsOfCommunication').setValidators([Validators.required]);
        // TODO: use a mapping (from preferredPhone) to determine which phone number to show
        /*this.methodsOfCommunications = [
          'Email',
          'Phone',
          'Text Message',
        ]; */
        this.myGroup = new FormGroup({
            preferredComunicationMethod: new FormControl(),
        });
    };
    WorkLifeRequestFormModalComponent.prototype.closeModal = function () {
        this.activeModal.close(ConfirmationModal.No);
    };
    WorkLifeRequestFormModalComponent.prototype.accept = function () {
        this.activeModal.close({ answer: ConfirmationModal.Yes,
            communicationType: this.communicationType,
            description: this.description,
        });
    };
    return WorkLifeRequestFormModalComponent;
}());
export { WorkLifeRequestFormModalComponent };
