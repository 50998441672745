/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./thankyou-survey.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./thankyou-survey.component";
import * as i4 from "../../../@core/utils/nps-survey.service";
import * as i5 from "@angular/router";
var styles_ThankyouSurveyComponent = [i0.styles];
var RenderType_ThankyouSurveyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThankyouSurveyComponent, data: {} });
export { RenderType_ThankyouSurveyComponent as RenderType_ThankyouSurveyComponent };
function View_ThankyouSurveyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row justify-content-center no-gutters mb24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-primary col-12 col-md-5"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["LOGIN "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", " fas fa-arrow-circle-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "login_text d-block col-12 mt10 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login to access your full services"]))], null, null); }
export function View_ThankyouSurveyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "animate__animated animate__slideInRight animate__faster thankyou"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row justify-content-center text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "h4", [["class", "col-12 top header-text font-size-36 font-500 font-montserrat color-black mb64"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Thank you for completing this "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "break-line"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["survey! "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThankyouSurveyComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isShowLogin; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_ThankyouSurveyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-thankyou-survey", [], null, null, null, View_ThankyouSurveyComponent_0, RenderType_ThankyouSurveyComponent)), i1.ɵdid(1, 114688, null, 0, i3.ThankyouSurveyComponent, [i4.NpsSurveyService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThankyouSurveyComponentNgFactory = i1.ɵccf("ngx-thankyou-survey", i3.ThankyouSurveyComponent, View_ThankyouSurveyComponent_Host_0, {}, {}, []);
export { ThankyouSurveyComponentNgFactory as ThankyouSurveyComponentNgFactory };
