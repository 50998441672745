import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from './configuration';
import { BASE_PATH } from './variables';
import { environment } from '../../../environments/environment';
import { emindfulConstants, emindfulRoutes } from './api/constants';

@Injectable({
  providedIn: 'root',
})
export class EmindfulService {
  protected basePath =  environment.basePath;
  protected rootPath =  environment.rootPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
  }

  getLivePrograms(): Observable<any> {
    return this.httpClient.get<any>(`${this.basePath}${emindfulRoutes.getPrograms}?format=${emindfulConstants.live}&limit=${emindfulConstants.programLimit}`);
  }

  getOnDemandPrograms(): Observable<any> {
    return this.httpClient.get<any>(`${this.basePath}${emindfulRoutes.getPrograms}?format=${emindfulConstants.recorded}&limit=${emindfulConstants.programLimit}`);
  }

  redirectToEMLife({ link, relayState, inCurrentTab }: { link?: string, relayState?: string, inCurrentTab?: boolean }) {
    const relayStateParam = relayState ? `?RelayState=${encodeURIComponent(relayState)}` : '';
    const eMLifeIntegrationUrl = link ? `${this.rootPath}${link}` : `${this.rootPath}/sso/emindful${relayStateParam}`;
    this.httpClient.get(eMLifeIntegrationUrl,
      {
        headers: { 'Content-Type': 'text/plain; charset=utf-8' },
        responseType: 'text'
      }).subscribe((samlForm) => {
        if (inCurrentTab) {
          window.document.write(samlForm);
        } else {
          const windowRef = window.open('', '_blank');
          if (windowRef && windowRef.document) {
            windowRef.document.write(samlForm);
          }
        }
      });
  }
}
