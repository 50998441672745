import { Component, OnInit } from '@angular/core';
import { NpsSurveyService } from '../../../@core/utils/nps-survey.service';
import { Router } from '@angular/router';
const ROUTE_NAME = 'nps-thankyou';
@Component({
  selector: 'ngx-thankyou-survey',
  templateUrl: './thankyou-survey.component.html',
  styleUrls: ['./thankyou-survey.component.scss'],
})
export class ThankyouSurveyComponent implements OnInit {
  isShowLogin: boolean = false;
  constructor(private npsSurveyService: NpsSurveyService, public router: Router) {
    this.npsSurveyService.setCarePathProgress(ROUTE_NAME);
  }

  ngOnInit() {
    if (window.localStorage.getItem('auth_app_token')) {
      this.isShowLogin = true;
    }
  }
  login() {
    this.router.navigate(['/pages/dashboard']);
  }
}
