import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal, ModalOptions } from '../modal.component';
import { SERVICE_TYPE } from '../../../../../@theme/components/reusables';
import { Provider, Address } from '../../../../../@core/data';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'ngx-provider-selection-modal',
  templateUrl: './provider-selection-modal.component.html',
  styleUrls: ['./provider-selection-modal.component.scss'],
})
export class ProviderSelectionModalComponent implements OnInit, AfterViewChecked {
  _modal: ModalOptions = { context: {}, modalHeader: '', options: false };

  headerTitle: string;
  modalDialog: any;
  isModalLoaded = false;
  providerSelected: boolean = true;
  submitted: boolean = false;
  isValidating: boolean = false;
  isHomeAddressSame: boolean = true;
  primaryAddressExist: boolean = false;
  formattedOriginalAddress: string = '';
  chosenAdress: string = '';
  serviceType: SERVICE_TYPE;
  user: any = {};
  heading: string;
  provider: Provider;
  souAgreed: boolean = false;
  messageOptions = {
    'onsiteCounseling': 'check the box below and someone will contact you within one business day to schedule your appointment \
    based on the next available time slot.',
    'In-Office': 'check the box below and contact your counselor to schedule an appointment that works for you.',
    'coaching': 'check the box below and contact your coach to schedule an appointment that works for you.',
  }
  modality: string = '';
  selected: string;
  message = '';
  contract: any;
  org: any;
  licences: string[];
  buttonText: string = '';
  isCoaching: boolean = false;

  set modal(value: ModalOptions) {
    if (Object.keys(value).length > 0) {
      const { provider, isCoach, user, modality, selected } = value.context;
      this.selected = selected;
      this.user = user;
      this.modality = modality;
      this.isCoaching = isCoach;
      this.message = this.messageOptions[this.modality];
      if (!!provider) {
        this.providerSelected = true;
        this.provider = provider;
        if (isCoach) {
          this.heading = `Coach Selected`;
        } else {
          this.heading = `Counselor Selected`;
        }
      } else {
        this.heading = 'An onsite counselor will be selected for you';
        this.providerSelected = false;
      }
    }
  }

  constructor(private activeModal: NgbActiveModal, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.setButtonText();
    if (!this.provider.credentials) {
      this.provider.credentials = [];
      this.provider.credentials.push('Adults');
    }
  }

  ngAfterViewChecked() {
    if (document.getElementsByClassName('modal-dialog')[0] && !this.isModalLoaded) {
      const checkbox = document.getElementById('sou-checkbox');
      checkbox.focus();
      checkbox.blur();

      this.modalDialog = document.getElementsByClassName('modal-dialog')[0];
      this.modalDialog.scrollIntoView(true);
      this.isModalLoaded = true;
      this.cdr.detectChanges();
    }
  }

  setButtonText() {
    this.buttonText = this.isCoaching ? 'SELECT THIS COACH' : 'SELECT THIS COUNSELOR';
  }

  closeModal() {
    this.activeModal.close({ answer: ConfirmationModal.No, user: this.user });
  }

  selectOfficeLocations(locations: Array<Address>) {
    return locations.filter(
      (x) => x.state
    );
  }

  accept(): void {
    this.activeModal.close({ answer: ConfirmationModal.Yes, user: this.user, selected: this.selected });
  }

  completeRequest() {
    if (this.submitted) return;
    this.submitted = true;
    this.accept();
  }
}
