import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-request-errors',
  templateUrl: 'request-errors.component.html',
  styleUrls: ['request-errors.component.scss'],
})
export class RequestErrorsComponent implements OnInit {
  @Input() errors: Array<String> = [];
  @Input() showTitle: boolean = true;
  @Input() title: String = 'Oops!';

  constructor() { }

  ngOnInit() {
  }

}
