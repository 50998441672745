import { EventEmitter, OnChanges, SimpleChanges, } from '@angular/core';
var FilterSelectorComponent = /** @class */ (function () {
    function FilterSelectorComponent() {
        this.multiple = false;
        this.autoClose = false;
        this.disabled = false;
        this.showAll = true;
        this.required = false;
        this.selectedIndexChange = new EventEmitter();
        this.isOpen = false;
        this.isTruncated = true;
        this._noteTemplate = '{text}';
    }
    Object.defineProperty(FilterSelectorComponent.prototype, "noteTemplate", {
        set: function (value) {
            this._noteTemplate = value;
            this.updateNote();
        },
        enumerable: true,
        configurable: true
    });
    FilterSelectorComponent.prototype.ngOnChanges = function (changes) {
        // Always show notes for active items
        if (changes.items &&
            changes.items.currentValue &&
            changes.items.currentValue.length > 0 &&
            changes.items.currentValue.some(function (element) { return element.active; })) {
            this.updateNote();
        }
    };
    FilterSelectorComponent.prototype.onClick = function (item, index) {
        // implement multiple or single select logic
        if (this.multiple) {
            item.active = !item.active;
        }
        else {
            this.items.forEach(function (element) { return (element.active = element === item ? !item.active : false); });
        }
        if (this.autoClose) {
            this.isOpen = false;
        }
        this.selectedIndex = index;
        this.selectedIndexChange.emit(index);
        this.updateNote();
    };
    FilterSelectorComponent.prototype.updateNote = function () {
        if (!this.items) {
            return;
        }
        var activeTitles = this.items
            .filter(function (option) { return option.active; })
            .map(function (option) { return option.title; })
            .join(', ');
        this.note = this.supplant(this._noteTemplate, {
            text: activeTitles ? activeTitles : '-',
        });
    };
    FilterSelectorComponent.prototype.toggle = function () {
        if (!this.disabled) {
            this.isOpen = !this.isOpen;
        }
    };
    FilterSelectorComponent.prototype.itemTitle = function (item) {
        return item.title;
    };
    FilterSelectorComponent.prototype.supplant = function (format, data) {
        return format.replace(/{([^{}]*)}/g, function (a, b) {
            var r = data[b];
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        });
    };
    return FilterSelectorComponent;
}());
export { FilterSelectorComponent };
