import { Injectable } from '@angular/core';
import { ProgressBarService } from './progress-bar.service';

export interface Progress {
  progress: number,
  numberOfBlocks: number,
  width?: number
};

export const STEPS = {
  // let the string value match the router path for convenient routing
  recommendPath: 'recommend-service',
  mycounselerPath: 'my-counselor',
  scheduledPath: 'scheduled-appointments',
  concernedPath: 'concerned-welfare',
  suggestionPath: 'suggestion-box',
  loginPath: 'nps-thankyou',
}

export class NpsFlowFormData {
  recommendData: number;
  worklifeRecommendData: number;
  myCounseling: string;
  scheduledData: string;
  concernedData: string;
  suggestionData: string;
  recommendPath: string = '';
  mycounselerPath: string = '';
  scheduledPath: string = '';
  concernedPath: string = '';
  suggestionPath: string = '';
  loginPath: string = '';
  reset(): void {
    this.recommendData = null;
    this.myCounseling = '';
    this.scheduledData = '';
    this.concernedData = '';
    this.suggestionData = null;
  }
  clearCarePath(): void {
    this.recommendPath = '';
    this.mycounselerPath = '';
    this.scheduledPath = '';
    this.concernedPath = '';
    this.suggestionPath = '';
    this.loginPath = '';

  }
}



@Injectable()
export class NpsSurveyService {
  private formData: NpsFlowFormData = new NpsFlowFormData();

  constructor(private progressBarService: ProgressBarService) { }

  private workflowSteps = [

    { step: STEPS.recommendPath, valid: false },
    { step: STEPS.mycounselerPath, valid: false },
    { step: STEPS.scheduledPath, valid: false },
    { step: STEPS.concernedPath, valid: false },
    { step: STEPS.suggestionPath, valid: false },
    { step: STEPS.loginPath, valid: true },

  ]
  selectedArr = [

    { value: 1, text: 'Strongly disagree' },
    { value: 2, text: 'Disagree' },
    { value: 3, text: 'Not sure' },
    { value: 4, text: 'Agree' },
    { value: 5, text: 'Strongly agree' },
  ]

  private getWorkFlowStepIndex(step: string): number {
    return this.workflowSteps.findIndex(elem => elem.step === step);
  }

  resetSteps(): void {
    // reset all the steps to invalid for start over.
    this.workflowSteps.forEach(elem => { elem.valid = false });
  }
  resetFormData(): NpsFlowFormData {
    this.resetSteps();
    this.formData.reset();
    return this.formData;
  }

  clearCarePath() {
    this.formData.clearCarePath();
  }
  getNextStep(step: string): string {
    const stepIndex = this.getWorkFlowStepIndex(step);
    if (stepIndex < 0 || (stepIndex + 1) >= this.workflowSteps.length) return '';
    return this.workflowSteps[stepIndex + 1].step;
  }

  getPreviousStep(step: string): string {
    const stepIndex = this.getWorkFlowStepIndex(step);
    const workFlow = this.workflowSteps;

    if (stepIndex <= 0) return 'recommend-service';
    return workFlow[stepIndex - 1].step;
  }

  get recommendData(): number {
    return this.formData.recommendData;
  }


  set recommendData(dataRecommend: number) {
    this.formData.recommendData = dataRecommend;
    this.validateStep(STEPS.recommendPath);
  }

  get worklifeRecommendData(): number {
    return this.formData.worklifeRecommendData;
  }


  set worklifeRecommendData(dataRecommend: number) {
    this.formData.worklifeRecommendData = dataRecommend;
  }

  get mycounselorData(): string {
    return this.formData.myCounseling;
  }


  set mycounselorData(dataCounselor: string) {
    this.formData.myCounseling = dataCounselor;
    this.validateStep(STEPS.mycounselerPath);
  }

  get scheduledData(): string {
    return this.formData.scheduledData;
  }


  set scheduledData(dataSchedule: string) {
    this.formData.scheduledData = dataSchedule;
    this.validateStep(STEPS.scheduledPath);
  }


  get concernedData(): string {
    return this.formData.concernedData;
  }


  set concernedData(dataConcern: string) {
    this.formData.concernedData = dataConcern;
    this.validateStep(STEPS.concernedPath);
  }

  get suggestionData(): string {
    return this.formData.suggestionData;
  }


  set suggestionData(dataSuggestion: string) {
    this.formData.suggestionData = dataSuggestion;
    this.validateStep(STEPS.suggestionPath);
  }

  setCarePathProgress(step: string) {
    const blockNumber = this.workflowSteps.map(s => s.step).indexOf(step);
    const numOfSteps = this.workflowSteps.length; // for the employee information screen

    this.progressBarService.progress = { progress: blockNumber + 1, numberOfBlocks: numOfSteps, width: 17 };
  }

  validateStep(step: string): void {
    const index = this.getWorkFlowStepIndex(step);
    if (index >= 0) { this.workflowSteps[index].valid = true };
  }

  invalidateStep(step: string): void {
    const index = this.getWorkFlowStepIndex(step);
    if (index >= 0) { this.workflowSteps[index].valid = false };
  }

  getFirstInvalidStep(step: string): string {
    // if all previous steps are valid, return blank, else return the first invalid step
    // used by the route guard.
    let inValidStep: string = '';
    this.workflowSteps.some(elem => {
      if (elem.step === step) {
        return true;
      } else {
        if (!elem.valid) {
          inValidStep = elem.step;
          return true;
        }
      }
    });
    return inValidStep;
  }



}
