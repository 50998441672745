import { Injectable } from '@angular/core';


/**
 * The SharedStateService is meant to pass state between page transitions during the lifetime of the Angular application.
 * This service does not maintain state when a full page refresh occurs.
 */
@Injectable({
  providedIn: 'root',
})
export class SharedStateService {

    private state = {

    };

    constructor() { }

    getValue(key: string, namespace: string = null) {
      if (namespace) {
        // TODO: Implement
        throw new Error('NotImplementedError: Namespaces are not yet implemented in the SharedStateService.')
      }

      return this.state[key];
    }

    setValue(key, value, namespace: string = null) {
      if (namespace) {
        // TODO: Implement
        throw new Error('NotImplementedError: Namespaces are not yet implemented in the SharedStateService.')
      }

      this.state[key] = value;
    }
}
