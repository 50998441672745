/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counselor-name-licences.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./counselor-name-licences.component";
import * as i4 from "../../../../@core/data/api/content.service";
import * as i5 from "../../../../@core/data/providerUi.service";
var styles_CounselorNameLicencesComponent = [i0.styles];
var RenderType_CounselorNameLicencesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounselorNameLicencesComponent, data: {} });
export { RenderType_CounselorNameLicencesComponent as RenderType_CounselorNameLicencesComponent };
function View_CounselorNameLicencesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "provider-license"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
export function View_CounselorNameLicencesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "media-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "media-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CounselorNameLicencesComponent_1)), i1.ɵdid(4, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.licences; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.counselor.firstName; var currVal_1 = _co.counselor.lastName; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_CounselorNameLicencesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-counselor-name-licences", [], null, null, null, View_CounselorNameLicencesComponent_0, RenderType_CounselorNameLicencesComponent)), i1.ɵdid(1, 114688, null, 0, i3.CounselorNameLicencesComponent, [i4.ContentService, i5.ProviderUiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CounselorNameLicencesComponentNgFactory = i1.ɵccf("ngx-counselor-name-licences", i3.CounselorNameLicencesComponent, View_CounselorNameLicencesComponent_Host_0, { counselor: "counselor" }, {}, []);
export { CounselorNameLicencesComponentNgFactory as CounselorNameLicencesComponentNgFactory };
