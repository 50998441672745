var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpErrorResponse } from '@angular/common/http';
import { NbAuthService, NbAuthSimpleToken, NbTokenStorage } from '@nebular/auth';
import { catchError, first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { get as _get } from 'lodash-es';
import { environment } from '../environments/environment';
import { userRoutes } from './@core/data/api/constants';
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(authService, tokenStorage, router) {
        this.authService = authService;
        this.tokenStorage = tokenStorage;
        this.router = router;
        this.ERROR_CODES = {
            EMAIL_NOT_VERIFIED: 'LOGIN_FAILED_EMAIL_NOT_VERIFIED',
            INVALID_ACCESS_TOKEN: 'INVALID_ACCESS_TOKEN',
            LOGIN_ACCOUNT_DEACTIVATED: 'LOGIN_FAILED_ACCOUNT_DEACTIVATED',
            CREATION_ACCOUNT_DEACTIVATED: 'ACCOUNT_CREATION_FAILED_ACCOUNT_DEACTIVATED',
        };
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        if (this.isBaasRequest(request)) {
            var headers = {
                setHeaders: {},
            };
            // insert access token if it is valid and we are not attempting to login
            var token = this.tokenStorage.get().getValue();
            if (token && this.requiresAuthentication(request)) {
                if (this.router.url.includes(userRoutes.postUserResetPassword)) {
                    // reset password uses a special token passed to the page on redirect
                    request = request.clone({ setParams: { 'resetToken': token } });
                }
                else {
                    var currentToken = this.getCurrentToken();
                    headers.setHeaders['Authorization'] = "Bearer " + currentToken;
                }
            }
            request = request.clone(headers);
        }
        return next.handle(request).pipe(tap(function (event) { }, function (err) {
            if (_this.isUnauthorizedError(err)) {
                var error_code = _get(err, 'error.error.code', null);
                if (error_code === _this.ERROR_CODES.EMAIL_NOT_VERIFIED) {
                    _this.router.navigate(['/auth/verify-email']);
                    return;
                }
                if (error_code === _this.ERROR_CODES.INVALID_ACCESS_TOKEN) {
                    _this.router.navigate(['/auth/login']);
                    _this.tokenStorage.clear();
                    return;
                }
                else {
                    _this.tokenStorage.clear();
                    // redirect to the login route
                    _this.router.navigate(['/auth/login']);
                    return;
                }
            }
        }));
    };
    TokenInterceptor.prototype.isUnauthorizedError = function (err) {
        return err instanceof HttpErrorResponse && err.status === 401;
    };
    TokenInterceptor.prototype.requiresAuthentication = function (request) {
        var url = request.url;
        return (!url.includes('/login') || url.includes('/login/history')) && !url.includes('/blobs');
    };
    TokenInterceptor.prototype.isBaasRequest = function (request) {
        return request.url.includes('/v1/') || request.url.includes('sso') || request.url.startsWith('https://api.us1.engaugetx.com');
    };
    TokenInterceptor.prototype.getCurrentToken = function () {
        var _this = this;
        var tokenData = JSON.parse(window.localStorage.getItem('token_data'));
        var accessToken = tokenData.accessToken, expiresIn = tokenData.expiresIn, refreshToken = tokenData.refreshToken, retrievalDate = tokenData.retrievalDate;
        var retrievalNewDate = new Date(retrievalDate);
        var currentNewDate = Math.floor(new Date().getTime() / 1000);
        var expirationDate = Math.floor(retrievalNewDate.getTime() / 1000) + expiresIn;
        var startExpirationRange = expirationDate - environment.secondsBeforeRefreshToken;
        var isTokenAboutToExpire = currentNewDate >= startExpirationRange && currentNewDate < expirationDate;
        var currentToken = accessToken;
        if (currentNewDate >= expirationDate) {
            this.resetUserAuthentication();
        }
        if (isTokenAboutToExpire) {
            var earlyToken_1 = __assign({}, tokenData, { retrievalDate: new Date().toISOString() });
            window.localStorage.setItem('token_data', JSON.stringify(earlyToken_1));
            this.authService.refreshToken('email', { refreshToken: refreshToken }).pipe(first(), catchError(function () {
                _this.resetUserAuthentication();
                return currentToken;
            })).subscribe(function (result) {
                if (result && result.isSuccess()) {
                    var response = result.getResponse().body;
                    var newToken = __assign({}, earlyToken_1, { accessToken: response.accessToken, expiresIn: response.expiresIn });
                    currentToken = newToken.accessToken;
                    window.localStorage.setItem('token_data', JSON.stringify(newToken));
                    _this.tokenStorage.set(new NbAuthSimpleToken(newToken, 'token'));
                }
                else {
                    _this.resetUserAuthentication();
                }
            });
        }
        return currentToken;
    };
    TokenInterceptor.prototype.resetUserAuthentication = function () {
        window.localStorage.removeItem('token_data');
        this.tokenStorage.clear();
        this.router.navigate(['/auth/login']);
    };
    return TokenInterceptor;
}());
export { TokenInterceptor };
