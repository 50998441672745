import { Component, Input, OnInit } from '@angular/core';
import { Provider } from '../../../../@core/data/model/models';
import { ContentService } from '../../../../@core/data/api/content.service';
import { ProviderUiService } from '../../../../@core/data/providerUi.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'ngx-counselor-name-licences',
  templateUrl: './counselor-name-licences.component.html',
  styleUrls: ['./counselor-name-licences.component.scss'],
})
export class CounselorNameLicencesComponent implements OnInit {

  @Input() counselor: Provider;
  licences: string[];

  constructor(private contentService: ContentService, private providerUiService: ProviderUiService) { }

  ngOnInit(): void {
    this.licences = this.providerLicenses();
  }
  providerLicenses(): string[] {
    const licences = (<any>this.counselor).providerLicenses || [];
    if (this.counselor && licences.length > 0 && licences.find(x => x.clinicalLicenseType)) {
      return this.providerUiService.formatProviderLicence(licences);
    }
    return [];
  }
  getInitials(): string {
    if (this.counselor) {
      return this.counselor.firstName[0] + this.counselor.lastName[0];
    }
    return '';
  }

  getProviderImage(): Observable<any> {
    return this.contentService.getProviderImage(this.counselor.internalId);
  }
}
