<nb-card>
  <nb-card-body class="flex-centered">
    <nb-auth-block>
      <ngx-intake-header title="Privacy Statement" styleClasses="mt-0"></ngx-intake-header>
      <div class="privacy">
        <h6 class="text-center">Concern Digital Platform Privacy Statement</h6>
        <div class="text-center"><b>Effective September 24, 2020</b></div> <br />
        <p>
          CONCERN: EAP (hereinafter “Concern”) respects your right with regards to data privacy
          and data protection when you communicate (online or offline) with us through our various
          websites, mobile applications, offline programs and events. We have therefore developed
          this Privacy Statement to inform you how Concern and its affiliates collect, store, use, share,
          process and protect the personal information about our users (“you”). It also tells you how
          you can access and update your personal information and make certain choices about how
          your personal information is used.
        </p>
        <p>
          You acknowledge, agree with and will be bound by this Privacy Statement, which applies to
          the Concern Digital Platform website and mobile application (collectively the “Mobile Web
          App”). By using the Mobile Web App in any manner, you acknowledge that you accept the
          practices and policies outlined in this Privacy Statement and you consent that Concern will
          collect, use, and share your information as described in this Privacy Statement.
        </p>
        <p>
          If you do not agree to this Privacy Statement, please do not use our websites or applications
          and do not give us any of your personal information. We reserve the right to make changes
          to this Privacy Statement at any time. An updated Privacy Statement will be posted on this
          website with material changes prominently noted. We encourage you to regularly review
          this Privacy Statement to make sure you are aware of any changes and how your
          information may be used.
        </p>
        <p>
          Please be informed that the Concern related entity in USA which operates the website you are
          visiting and provides the services you are receiving is the Data Controller of any personal
          information you submit to Concern. If you have any queries regarding your Data Controller
          please contact us at <a href="mailto:info@concernhealth.com">info@concernhealth.com</a> or call <a
            href="tel:800-344-4222">800-344-4222</a>. If you have any requests
          concerning your personal information or any queries with regard to this Privacy Statement please
          contact <a href="mailto:info@concernhealth.com">info@concernhealth.com</a> or call <a
            href="tel:800-344-4222">800-344-4222</a>.
        </p>
        <p>
          <b>1. What does this Privacy Statement cover?</b>
        </p>
        <p>
          This Privacy Statement covers our treatment of the information you provide, including
          personally identified or identifiable information or data that we gather from you or when
          you are accessing or using the Mobile Web App (collectively “Personal Information”). This
          Privacy Statement does not apply to the practices of companies or institutions that we do
          not own or control, or to individuals that we do not employ or manage. Your use of the
          Mobile Web App will allow you to provide us with Personal Information, including but not
          limited to information about you, your family and dependents. The Mobile Web App
          collects your Personal Information for the purposes of improving and enhancing the user
          experience with the Mobile Web App/website, and may be used to create de-identified
          aggregate reports that are shared with the employer. We do not knowingly collect or solicit
          Personal Information from anyone under the age of 18 or knowingly allow such persons to
          register for the Mobile Web App. If you are under 18, you must not attempt to register for
          the Mobile Web App and you must not send any information about yourself to us, including
          but not limited to your name, address, telephone number, or email address. In the event
          that we learn that we have collected Personal Information from a person under age 18, we
          will delete that information. If you believe that we might have any information from or
          about a person under the age of 18, please contact us at <a
            href="mailto:info@concernhealth.com">info@concernhealth.com</a>.<br />
        </p>
        <p>
        <p><b>2. What information do we collect?</b></p>
        We may collect from you various types of information (personal and aggregated), as
        described in this section. Personal Information (as used in this Privacy Statement) refers to
        such types of information that can be used to personally identify you, whereas aggregate
        information does not allow for such identification on its own. In some instances (unless
        otherwise noted below), we may combine/link one type of information with another type
        of information (e.g., personal contact information with account login and/or demographic
        information) and store them together in our records. In cases where Personal Information
        and aggregate information are combined, such information will always be considered
        Personal Information. We strive to limit the amount of Personal Information we collect and
        store to that which is reasonably necessary to provide you the relevant services. We
        generally store your Personal Information for as long as your account is active with us or as
        needed to provide you the relevant services, provided however, we may store your
        Personal Information for a longer period of time to comply with legal or regulatory
        obligations, resolve disputes, enforce our agreements, or for records management
        purposes. The Mobile Web App will obtain information from you in two ways. The first is
        information that you manually enter into the Mobile Web App. The second is information
        that the Mobile Web App collects automatically about you.
        <p>2.1 Information You Manually Enter into the Mobile Web App</p>
        <p>
          We receive and store any information you manually enter into the Mobile Web App. For
          example, we collect Personal Information that directly identifies you, including name, email
          address, and birthdate. We collect this information to verify (i) your identity, (ii) that you reside
          within the United States, and (iii) that you are over 18 years of age.
        </p>
        <p>
          We may also collect information that you voluntarily share with us about your experience in
          using the Mobile Web App. Examples may include unsolicited comments and suggestions,
          testimonials, or other questions or feedback related to our service.
        </p>
        <p>
          We may also collect Personal information that is required for you to establish a unique account
          with us or for us to give you access to your specific account profile. Examples may include your
          login ID/email address, screen name, password, and/or security question and answer. We only
          collect unique login information for those activities that require an account. Your login
          information, especially your password, should be kept confidential and never shared with anyone
          else. To change your login email address or password, log in to the relevant account profile
          section of our site or contact us at <a href="mailto:info@concernhealth.com">info@concernhealth.com</a>
        </p>
        <p>
          We may also collect any other information that we might need for a specific form, feature, or
          other service that you use or request. This information will vary depending on the method of
          collection and the specific purpose(s) for which the information is being collected.
        <p>
          2.2 Information Collected Automatically
        </p>
        <p>
          This Mobile Web App may also automatically receive technical information relating to your
          usage of the Mobile Web App, such as the operating system, device, features used, content
          viewed and downloaded, the dates and times of your interactions with the Mobile Web App and
          other information. We typically collect this information through automated technologies such as
          cookies, and such information may also be collected through the use of third party tracking
          services (such as Google Analytics) that collect data in the aggregate such as number of visits to
          a particular page, or the amount of time spent on a web site. If you access the Mobile Web App
          via a mobile device such as a smartphone, the collected information may also include your
          phone’s unique device ID, and other similar mobile device data. We may use this information to
          understand, customize and improve user experience with the Mobile Web App. For example, we
          may engage analytics services to analyze this information in order to help us understand how
          visitors engage with and navigate the Mobile Web App, how and when features within the
          Mobile Web App are used and by how many users.
        </p>

        <p>2.3 Push Notifications</p>
        <p>
          “Push notifications” are messages that are sent to your smartphone to remind you of what you
          need to do in the Mobile Web App. The Mobile Web App will send you push notifications
          throughout your experience.
        </p>
        <p>3. For what purposes do we use your Personal Information?</p>
        <p>
          We will use the Personal Information you provide in a manner that is consistent with this Privacy
          Statement. If you provide information, including Personal Information, for a certain reason, we
          may use it in connection with the reason for which it was provided and for the following:
        </p>

        <p>3.1 Supporting the Mobile Web App’s Functionality</p>
        <p>Information may be used to assist the Concern team in identifying, tracking and reporting usage
          data in order to improve the application or services.</p>
        <p> 3.2 Account Maintenance</p>
        <p> We may use your Personal Information to maintain your accounts with us. This typically
          involves the use of Personal Information that was originally collected to set up your account
          (e.g., personal contact information, account login information, demographic information).</p>
        <p>3.3 Customer Service</p>
        <p>
          We may collect and use your Personal Information to provide you customer service, including
          responses to your inquiries. This typically requires the collection and use of certain personal
          contact information (such as your name or email address) and information regarding the reason
          for your inquiry (e.g., technical issue, question/complaint, general question, etc.). Customer
          service may be provided through various forms of communication, including email
          correspondence, access center support, and live online chat features.
        </p>
        <p>3.4 Mobile Web App Improvement and Personalization</p>
        <p>We may collect and use your information, including Personal Information, to improve and
          personalize your experience on our Mobile Web App. This is typically done through automated
          tracking technologies that collect and remember certain account login information, technical
          information, and/or previous website usage information. For example, we might remember your
          login ID/email address or screen name so you can quickly login the next time you visit the
          Mobile Web App.
        </p>
        <p> We may use your Personal Information to contact you to ask if you would be willing to provide
          testimonials or participate in additional research opportunities including linking your Mobile
          Web App data with other health data with your permission.
        </p>
        <p>3.5 Services and Educational Communication</p>
        <p>Personal Information may be used to provide groups of similar characteristics and interests with
          content relating to behavioral wellness and available health service resources, including selfmanagement
          recommendations.
        </p>
        <p>We may collect and use your information, including Personal Information, to send you
          educational communications and communications relating to the services we provide, such as
          email communications. These communications will keep you up-to-date on the news, events, and
          additional services from Concern. Sending you communications mostly requires the collection
          and use of certain personal contact information and demographic information.
        </p>
        <p>We understand communication preferences may change overtime. If at any point you do not
          wish to receive further communication, you will have the option to opt-out within the content of
          message sent. Alternatively, if you have an account with us, you can change your
          communication preferences under the relevant edit-account section of our site (if available) or by
          contacting us at <a href="mailto:info@concernhealth.com">info@concernhealth.com</a>.
        </p>
        <p>
          Note: the opt-out preference will not prevent our team send important notifications about your
          account activities (e.g., account confirmations, password changes, etc.), and other important
          announcements.
        </p>
        <p>
          If you participate in a promotion that involved a third party company, and, as part of that
          promotion, you agreed to receive future marketing communications directly from that third party,
          you will need to contact the third party to opt-out from such communications. This process may
          be outlined in the third party’s privacy policy
        </p>
        <p>3.6 Other General Purposes (e.g., website security, internal research)</p>

        <p>
          We may collect and use your account information and non-medical data/information for other
          general business purposes, such as to maintain the day-to-day operation and security of the
          Mobile Web App and to conduct internal marketing and demographic studies. These activities
          mostly require the collection and use of certain de-identified demographic information, technical
          computer information, website usage information, and consumer feedback.
        </p>
        <p><b>4. How do we protect your Personal Information?</b></p>
        <p>
          We use technical and organizational measures to protect the confidentiality and security of your
          Personal Information collected through the various methods described in this Privacy Statement.
          All information that is collected through the Mobile Web App will be sent to a secure data server
          that will replace the direct identifiers listed above (your name, email address, and date of birth)
          with a code to help protect your identity.
        </p>
        <p>
          Access to the Mobile Web App on your smartphone is protected by the username and password
          you selected after you registering for the Mobile Web App. Your username and password needs
          to be entered every time you access the Mobile Web App unless you choose to remember your
          email, which then the Mobile Web App will pre-populate your email address. In any event, your
          password needs to be entered every time you access the Mobile Web App. You should take steps
          to prevent unauthorized access to your account and personal information by selecting and
          protecting your username and password Mobile Web App appropriately and limiting access to
          your smartphone.
        </p>
        <p>
          We endeavor to protect the privacy of the Personal Information we hold in our records, but we
          cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and
          other factors, may compromise the security of your Personal Information at any time.
        </p>
        <p>
          The Mobile Web App may contain links to educational websites. We are not responsible for the
          privacy policies and/or practices on websites that may be accessed through these links. When
          following a link to another website, you should read that website’s privacy policy and make sure
          that you agree with it and can abide by it.
        </p>
        <p><b>5. Will the Mobile Web App share any of the information it receives?</b></p>
        <p>
          We may share your Personal Information with our affiliated companies, companies with whom
          Concern has a relationship through either direct or indirect ownership (some of whom are outside
          the United States) (collectively “Concern Group”). Concern may also share your personal
          information with third parties (some of whom are outside the United States), but only in the
          following circumstances:
        </p>
        <p>5.1 Service Providers, Agents and Contractors</p>
        <p>
          We may use third parties, like service providers, agents or contractors, to provide support for the
          internal operations of the Mobile Web App and to assist us with administering the Mobile Web
          App or the various functions, programs and promotions (and similar) available to you and
          generally for the purposes described in this Privacy Statement. Some of these third parties may
          be located outside the country where you accessed the Mobile Web App. These third parties and
          its employees may come into contact with your Personal Information in the course of providing
          their services to us. Any such third party must contractually commit to provide equivalent levels
          of security for your Personal Information as set forth herein and, where required, are bound by a
          legal agreement to keep your Personal Information secure and to process it only on the specific
          instructions of Concern. We may also disclose your information, including Personal Information,
          if we are required to do so by law, or if in our good faith judgment, such action is reasonably
          necessary to comply with legal processes, regulatory requirements, judicial proceedings or court
          order, to respond to any claims, or to protect the safety or rights of Concern, its customers, or the
          public.
        </p>
        <p>
          In the event of a merger or acquisition of all or part of Concern by another company, or in the
          event that Concern were to sell or dispose of all or a part of the Concern business or assets, you
          understand and consent that the acquirer would have access and assume control over the
          information maintained by that Concern business, which could include your information,
          including Personal Information. Similarly, your information, including Personal Information,
          may be transferred as part of a corporate reorganization, insolvency proceeding, or other similar
          event, if permitted by and done in accordance with applicable law.
        </p>
        <p>5.3 Aggregate Information</p>
        <p>
          We may share aggregated demographic or survey information with third parties, but this
          information is in anonymous form and does not contain any Personal Information. The aggregate
          information that we share may include information that is captured through the use of cookies
          and other similar tracking technology. At this time, we do not respond to Web browser "do not
          track" signals.
        </p>
        <p>
          We reserve the right to disclose information, including Personal Information, collected through
          the Mobile Web App as required by law, when we believe disclosure is necessary to comply with
          a regulatory requirement, judicial proceeding, court order, or legal process served on us, or to
          protect safety, rights, or property.
        </p>
        <p>
          <b>6. What happens if you refuse to provide your Personal Information?</b>
        </p>
        <p>
          The following outlines the choices you have with respect to the collection and use of your
          Personal Information by us.
        </p>
        <p>
          6.1 You can opt out and close your account, which will terminate your ability to use the Mobile
          Web App.
        </p>
        <p>
          6.2 You may choose not to register and/or accept the terms & conditions for the Mobile Web
          App.
        </p>
        <p>
          If you choose not to accept the terms & conditions, you will be unable to use the Mobile Web
          App. If you discontinue use of the Mobile Web App, we will retain all of information, including
          Personal Information, about you that were collected prior to that point and continue to use such
          information as described in this Privacy Statement.
        <p>
          <b>7. How long do we keep Personal Information?</b>
        </p>
        <p>
          We will keep your Personal Information for as long as it is reasonably necessary taking into
          consideration our need to answer queries or resolve problems, provide improved and new
          services and comply with legal requirements under applicable law(s). This means that we may
          retain your Personal Information for a reasonable period after you stop using Concern services or
          stop using this website or application. After this period, your Personal Information will be
          deleted from all systems in the Concern Group.
        </p>
        <p><b>8. How can you contact us?</b></p>
        <p>
          If you have any questions, complaints, or comments regarding this Privacy Statement or our
          information collection practices, please contact us by: Calling our Customer Service toll free
          access center at <a href="tel:1-800-344-4222">1-800-344-4222</a> Writing to us at Customer Service:
          <a href="mailto:info@concernhealth.com">info@concernhealth.com</a> or 2490 Hospital Drive, Suite 310, Mountain
          View CA 94040
        </p>
        <p><b>9. What limitations are there on my use of the Mobile Web App?</b></p>
        <p>
          By using the Mobile Web App, you agree that you will use the Mobile Web App in
          accordance with the Terms of Use, will not do anything to interfere with or disrupt the
          operation of the Mobile Web App, will provide only accurate and current information
          through the Mobile Web App, and will not impersonate anyone else in your use of the
          Mobile Web App. You further agree not to transmit content that you do not have the right
          to transmit or that infringes the rights of any party, and you agree to use the Mobile Web
          App in compliance with all Applicable laws. You understand that the Mobile Web App or
          portions of it may be subject to patent, copyright, trademark and other intellectual
          property protection and that the ownership of software and other intellectual property
          related to the Mobile Web App, as well the goodwill associated therewith, remains with the
          owner/licensee of “Concern Digital Access” and/or its licensors. You agree that any
          improvements or other changes to the Mobile Web App are the sole property of the owners
          of the “Concern Digital Access” Mobile Web App.
        </p>
        <p><b>10. Can Concern change the terms of this Privacy Statement?</b></p>
        <p>
          Concern will occasionally make changes and corrections to this Privacy Statement. Please check
          this Privacy Statement regularly to see the changes and how these may impact you. Use of
          information we collect now is subject to the version of the Privacy Statement in effect at the time
          such information is used.
        </p>

      </div>
    </nb-auth-block>
  </nb-card-body>
</nb-card>