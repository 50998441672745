<ng-container>

  <div class="modal-content" style="margin-top:0px;">

    <span class="close-icon" (click)="closeModal()">&#10005;</span>
    <span class="lead text-center">
      <p *ngIf="modeOfHelp === 'in-person' && modeOfHelp === switchFrom" class="header-text">You are switching to another {{LOCAL_STRING}} Counselor</p>
      <p *ngIf="modeOfHelp === 'in-person' && modeOfHelp !== switchFrom" class="header-text">You are switching to {{LOCAL_STRING}} Counselor</p>
      <p *ngIf="modeOfHelp === 'online'" class="header-text">You are switching to {{LOCAL_STRING}} Counseling</p>
      <p *ngIf="modeOfHelp === 'first-responder'" class="header-text">You are switching to another First Responder Counselor</p>
      <p *ngIf="modeOfHelp === 'physician-counseling' && modeOfHelp === switchFrom" class="header-text">You are switching to another Physician Counselor</p>
      <p *ngIf="modeOfHelp === 'physician-counseling' && modeOfHelp !== switchFrom" class="header-text">You are switching to Physician Counselor</p>
    </span>

    <div class="form">
      <div class="row description">
        <div class="col-xs-12 col-md-12 col-xl-12 justify-content-center align-content-center align-items-center no-gutters">
          <p class="switch-reason">Why would you like to switch counselors?</p>
          <select class="form-control reason-for-switch" id="reason-for-switch" name="reason-for-switch" [(ngModel)]="reasonForSwitch">
            <option *ngFor="let reason of reasons" [value]="reason.id">{{ reason.value }}</option>
          </select>
        </div>
      </div>
      <div  class="row description">
        <div class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center align-content-center align-items-center no-gutters">
          <p *ngIf="modeOfHelp !== 'physician-counseling'" class="question-text form-text">Where should we look to find matching counselors?</p>
          <p *ngIf="modeOfHelp === 'physician-counseling'" class="question-text form-text">Please verify the state in which you reside:</p>
        </div>
      </div>
      <div *ngIf="modeOfHelp !== 'physician-counseling'"  class="row description">

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="form-group">
              <label for="radius" class="form-label sub-form-text">WITHIN <span class="red-asterisk">*</span></label>
              <select class="form-control radius" id="radius" name="radius" [(ngModel)]="radius">
                <option value="">Select a radius</option>
                <option [ngValue]="radius" *ngFor="let radius of zipradii;">{{radius}} miles</option>
              </select>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 fix-mix">
            <div class="form-group">
              <label for="zip-code" class="form-label sub-form-text">ZIP CODE <span class="red-asterisk">*</span></label>
              <input type="text" pattern="\d{5}-?(\d{4})?" class="form-control zip-code" id="zip-code" [(ngModel)]="zipCode" >
            </div>
          </div>

      </div>
      <div *ngIf="modeOfHelp === 'physician-counseling'"  class="row description">
        <div class="col-12 col-md-7 mx-auto form-group">
          <div class="dropdown">
            <select name="within" class="form-control within" [(ngModel)]="selectedState">
              <option *ngFor="let state of getStates()" [value]="state">
                {{ state }}
              </option>
            </select>
          </div>
        </div>

      </div>
      <div *ngIf="modeOfHelp !== 'first-responder' && modeOfHelp !== 'physician-counseling'" class="row description">
        <div class="ol-xs-12 col-md-12 col-xl-12 justify-content-center align-content-center align-items-center no-gutters">
          <div class="form-group">
            <label for="counseling-for" class="form-label form-text">Who is the counseling for? <span class="red-asterisk">*</span></label>
            <select class="form-control counseling-for" id="1counseling-for" name="counseling-for" [(ngModel)]="counselingFor">
              <option value="">Select an option</option>
              <option value="Individual">Individual</option>
              <option value="Family">Family</option>
              <option value="Couple">Couple</option>
            </select>
          </div>
        </div>
      </div>
    </div>



    <div class="row text-center justify-content-center align-content-center align-items-center no-gutters">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <p *ngIf="modeOfHelp === 'online'" class="yes-text">Do you still wish to switch to local counseling?</p>
        <p *ngIf="modeOfHelp === 'in-person'" class="yes-text">Do you still wish to switch to another local counselor?</p>
        <p *ngIf="modeOfHelp === 'first-responder'" class="yes-text">Do you still wish to switch to another First Responder counselor?</p>
        <p *ngIf="modeOfHelp === 'physician-counseling'" class="yes-text">Do you still wish to switch to another physician counselor?</p>
      </div>
    </div>

    <div class="row text-center justify-content-center align-content-center align-items-center no-gutters">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <button class="btn btn-primary close-btn" (click)="closeModal()">NO, DON'T CHANGE ANYTHING</button>
      </div>
    </div>

    <div class="row text-center justify-content-center align-content-center align-items-center no-gutters">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <button *ngIf="modeOfHelp === 'online'" [ngClass]="{'disabled': !isValidZipCode() || radius === '' || counselingFor === ''}" class="btn btn-primary continue-btn" (click)="completeRequest()">
          YES, I WANT TO SWITCH TO A LOCAL COUNSELOR
        </button>
        <button  *ngIf="modeOfHelp === 'in-person'" [ngClass]="{'disabled': !isValidZipCode()  || radius === '' || counselingFor === ''}" class="btn btn-primary continue-btn" (click)="completeRequest()">
          YES, I WANT TO SWITCH TO ANOTHER LOCAL COUNSELOR
        </button>
        <button  *ngIf="modeOfHelp === 'first-responder'" [ngClass]="{'disabled': !isValidZipCode()  || radius === '' }" class="btn btn-primary continue-btn fr-btn" (click)="completeRequest()">
          YES, I WANT TO SWITCH TO ANOTHER FIRST RESPONDER COUNSELOR
        </button>
        <button  *ngIf="modeOfHelp === 'physician-counseling'" [ngClass]="{'disabled': selectedState === '' || reasonForSwitch==='' }" class="btn btn-primary continue-btn fr-btn" (click)="completeRequest()">
          YES, I WANT TO SWITCH TO ANOTHER PHYSICIAN COUNSELOR
        </button>
      </div>
    </div>

  </div>

</ng-container>
