
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
// import { map, retryWhen, delay, take } from 'rxjs/operators';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isEmpty } from 'lodash-es';
import { Configuration } from './configuration';
import { BASE_PATH } from './variables';
import { environment } from '../../../environments/environment';
import { caseRoutes, loginHistoryRoutes, riskHistoryRoutes, userRoutes } from './api/constants';
import { mapAllowedMessageProps } from '../utils/user-service-utils';

const GET_LOGIN_HISTORY_URL = '/run/get-login-history';
// const LOCAL_USER_URL = '/v1/run/local-user';

@Injectable({ providedIn: 'root' })
export class UserService {
  reloadDashboard: boolean = false;
  protected basePath = environment.basePath;
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getUser(): Observable<any> {
    return this.httpClient.get<any>(`${this.basePath}${userRoutes.user}`)
      .pipe(map((user: any) => {
        if (isEmpty(user)) return null;
        return {
          name: `${user.firstName} ${user.lastName}`, picture: 'assets/images/kate.png', ssoUser: user.ssoUser || false,
          profileComplete: user.profileComplete || false
        }
      }));
  }
  getSsoUser(): Observable<any> {
    return this.httpClient.get<any>(`${this.basePath}${userRoutes.user}`)
      .pipe(map((user: any) => ({ data: user })));
  }

  getAllUserData(): Observable<any> {
    return this.httpClient.get<any>(`${this.basePath}${userRoutes.user}`)
      .pipe(tap((user: any) => {
        if (!user.internalId) {
          throw new Error('Missing InternalId');
        }
      }), map((user: any) => ({ data: user })));
  }

  updateUser(userData: any): Observable<any> {
    const userToUpdate = { ...userData };
    userToUpdate.allowedCellPhoneMessage = mapAllowedMessageProps(userToUpdate.allowedCellPhoneMessage);
    userToUpdate.allowedHomePhoneMessage = mapAllowedMessageProps(userToUpdate.allowedCellPhoneMessage);
    userToUpdate.allowedWorkPhoneMessage = mapAllowedMessageProps(userToUpdate.allowedCellPhoneMessage);
    return this.httpClient.put<any>(
      `${this.basePath}${userRoutes.user}`, userToUpdate,
    ).pipe(map((user: any) => ({ data: user })));
  }

  saveLoginHistory(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.basePath}${loginHistoryRoutes.postLoginHistory}`, data,
    ).pipe(map((response: any) => ({ data: response })));
  }

  getLoginHistory(data: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.basePath}${GET_LOGIN_HISTORY_URL}`, data,
    ).pipe(map((response: any) => ({ data: response })));
  }

  requestWorkLifeServices(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.basePath}${caseRoutes.postRequestWorkLifeServices}`, data,
    ).pipe(map((response: any) => ({ data: response })));
  }

  getpreferredPhoneNumber(user) {
    const preferredPhone = user.preferredPhone;

    if (preferredPhone === 'Home') {
      return user.homePhone;
    } else if (preferredPhone === 'Work') {
      return user.workPhone;
    } else if (preferredPhone === 'Cell') {
      return user.cellPhone;
    } else {
      return user.cellPhone;
    }
  }

  saveRiskHistory(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.basePath}${riskHistoryRoutes.postRiskHistory}`, data,
    ).pipe(map((response: any) => ({ data: response })));
  }
}
