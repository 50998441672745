import { Component, OnInit, Input} from '@angular/core';
import { IntakeFlowService, STEPS } from '../../../../@core/utils/intake-flow.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-intake-navigation-buttons',
  templateUrl: './intake-navigation-buttons.component.html',
  styleUrls: ['./intake-navigation-buttons.component.scss'],
})
export class IntakeNavigationButtonsComponent implements OnInit {
  @Input() backOnly: boolean;


  constructor(private router: Router, private intakeFlowService: IntakeFlowService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() { }

  goBack(): void {
    try {
      const currentUrl = this.router.url;
      const arrPath = currentUrl.split('/');
      const currentStep = arrPath[arrPath.length - 1];
      const previousStep = this.intakeFlowService.getPreviousStep(currentStep);
      this.router.navigate([`${previousStep}`], { relativeTo: this.activatedRoute });
    } catch (exception) {
      console.error(exception);
    }
  }

  startOver(): void {
    try {
      this.intakeFlowService.clearCarePath();
      const firstStep = STEPS.carePath;
      this.router.navigate([`${firstStep}`], { relativeTo: this.activatedRoute });
    } catch (exception) {
      console.error(exception);
    }

  }
}
