import { Directive, HostListener, Input, Optional } from '@angular/core';
import { AnalyticsService } from '../../@core/utils/analytics.service';
import { AnalyticsContextDirective } from './analytics-context.directive';

@Directive({
    selector: '[ngxAnalytics]',
})
export class AnalyticsDirective {
    @Input('ngxAnalytics') analytics: string = '';

    constructor(private ga: AnalyticsService, @Optional() private context: AnalyticsContextDirective) { }

    @HostListener('click', ['$event'])
    onClick(event: Event) {
        const { category: contextCategory = '', action: contextAction = '' } = this.context || {};
        const [ category, action, label, value] = this.analytics.toLowerCase().split(':').map(x => x.trim());

        this.ga.trackGenericEvent(category || contextCategory, action || contextAction, label, value);
    }
}
