/**
 * Luma API
 * Luma API
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CaseInternalCase {
    id?: string;
    /**
     * platform will always default to 'counseling'.
     */
    caseClass?: string;
    /**
     * wherther the case requires concern to approve it before a counselor can open it
     */
    approvalRequired?: boolean;
    /**
     * platform will always default to 'Luma'.
     */
    referralSource?: string;
    /**
     * telephone option will not be present on Luma V1.
     */
    preferredModality?: CaseInternalCase.PreferredModalityEnum;
    authorizationId?: string;
    providerId?: string;
    /**
     * the organization (Company) that the case is related to
     */
    orgId?: string;
    /**
     * this will map to primaryServiceUser or serviceRequester
     */
    patientId?: string;
    /**
     * list of valid types will come from the contract
     */
    clientType?: string;
    assessedCondition?: string;
    presentingCondition?: string;
    referralDate?: string;
    /**
     * read-only field for platform
     */
    openDate?: string;
    /**
     * read-only field for platform
     */
    closeDate?: string;
    /**
     * are we allowed to send the user an email survey
     */
    surveyPermission?: boolean;
    nextAppointment?: string;
}
export namespace CaseInternalCase {
    export type PreferredModalityEnum = 'on-site' | 'offsite' | 'video' | 'telephone' | 'no-preference';
    export const PreferredModalityEnum = {
        OnSite: 'on-site' as PreferredModalityEnum,
        Offsite: 'offsite' as PreferredModalityEnum,
        Video: 'video' as PreferredModalityEnum,
        Telephone: 'telephone' as PreferredModalityEnum,
        NoPreference: 'no-preference' as PreferredModalityEnum,
    }
}
