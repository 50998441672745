var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router, ActivatedRoute } from '@angular/router';
import { NbAuthService, NbTokenStorage, NbAuthResult, } from '@nebular/auth';
import { BasicAuthScreen } from '../auth.component';
import { AnalyticsService } from '../../../../@core/utils/analytics.service';
import { tap } from 'rxjs/operators';
import { CookieService } from '../../../../../../node_modules/ngx-cookie-service';
import { IntakeFlowService } from '../../../../@core/utils/intake-flow.service';
var DxResetPasswordComponent = /** @class */ (function (_super) {
    __extends(DxResetPasswordComponent, _super);
    function DxResetPasswordComponent(service, options, router, route, tokenStorage, analytics, intakeService, cookieService) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, service, options, router, route, analytics, intakeService, cookieService) || this;
        _this.service = service;
        _this.options = options;
        _this.router = router;
        _this.route = route;
        _this.tokenStorage = tokenStorage;
        _this.intakeService = intakeService;
        _this.cookieService = cookieService;
        _this.prefix = 'forms.resetPassword';
        _this.analyticsTrack = 'Reset-Password';
        _this.user = {};
        _this.redirectDelay = 1000;
        return _this;
    }
    DxResetPasswordComponent.prototype.resetPass = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            var code = params.code;
            _this.submitted = true;
            _this.serviceCall(function () {
                return _this.service.resetPassword(_this.strategy, { code: code, newPassword: _this.user.password }).pipe(tap(function (result) {
                    if (result.isSuccess()) {
                        _this.redirectUrl = '/auth/login';
                    }
                }));
            });
        });
    };
    return DxResetPasswordComponent;
}(BasicAuthScreen));
export { DxResetPasswordComponent };
