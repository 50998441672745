import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IntakeFlowService } from '../../../../@core/utils/intake-flow.service';

@Component({
    selector: 'ngx-content-radio',
    styleUrls: ['./content-radio.component.scss'],
    templateUrl: './content-radio.component.html',
})
export class ContentRadioComponent {
    @Input() items: ContentRadioItem[];
    @Input() heading: string;
    @Input() questions: string[];
    @Input() button: string;
    @Input() showContent: boolean[] = new Array(3).fill(false);
    @Input() callbackFunction: (args: any) => void;
    @Input() intakeFlowService: IntakeFlowService;
    @Input() selectedPath: (args: any) => void;
    @Input() STEPS: any;
    @Input() router: Router;
    @Input() activatedRoute: ActivatedRoute;
    @Input() iconListSource: any[];
    @Input() selectedCarePathIndex: number;
    @Input() selectedIndex: number;
    @Output() selectedIndexChange = new EventEmitter<number>();

    itemClicked(index: number) {
        if (this.items[index] != null && !this.items[index].disabled) {
            this.selectedIndex = index;
            this.selectedIndexChange.emit(index);
            this.showContent.fill(false);
            this.showContent[this.selectedIndex] = true
        }
    }

    unsetActiveTab() {
        this.showContent.fill(false);
        this.selectedIndex = undefined;
    }

    get visibleItems(): number {
        return this.items.filter( item => !item.invisible).length;
    }
}
export class ContentRadioItem {
    icon: string;
    iconUrl?: string;
    title: string;
    description: string;
    invisible: boolean;
    disabled?: boolean;
    bgColor: string;
}
