import { Injectable } from '@angular/core';
import { Progress } from './intake-flow.service';
import { BehaviorSubject } from 'rxjs';

@Injectable( { providedIn: 'root'} )
export class ProgressBarService {
    progressChange: BehaviorSubject<Progress> = new BehaviorSubject<Progress>(null);

    constructor() {}

    set progress(value: Progress ) { this.progressChange.next({ ...value }); };
    get progress(): Progress {  return this.progressChange.getValue(); }
}

