/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./request-errors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./request-errors.component";
var styles_RequestErrorsComponent = [i0.styles];
var RenderType_RequestErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RequestErrorsComponent, data: {} });
export { RenderType_RequestErrorsComponent as RenderType_RequestErrorsComponent };
function View_RequestErrorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_RequestErrorsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "small", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_RequestErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-danger"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequestErrorsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequestErrorsComponent_3)), i1.ɵdid(4, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errors; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RequestErrorsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RequestErrorsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errors && (_co.errors.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RequestErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-request-errors", [], null, null, null, View_RequestErrorsComponent_0, RenderType_RequestErrorsComponent)), i1.ɵdid(1, 114688, null, 0, i3.RequestErrorsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RequestErrorsComponentNgFactory = i1.ɵccf("ngx-request-errors", i3.RequestErrorsComponent, View_RequestErrorsComponent_Host_0, { errors: "errors", showTitle: "showTitle", title: "title" }, {}, []);
export { RequestErrorsComponentNgFactory as RequestErrorsComponentNgFactory };
