import { LumaMenuService } from './LumaMenuService';

import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NbTokenStorage, NbTokenService } from '@nebular/auth';
import { NbContextMenuDirective, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { LUMA_THEME } from '../../styles/theme.luma';
import { ModalOptions, SizeModal } from '../reusables';
import { takeWhile } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { NbMenuBag } from '@nebular/theme/components/menu/menu.service';
import { Router } from '@angular/router';
import { ModalService } from '../../../@core/utils/modal.service';
import { IntakeFlowService } from '../../../@core/utils/intake-flow.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { CookieService } from 'ngx-cookie-service';

(<any>window).lumaAuth = {
  acronym: '',
  name: '',
  loggedIn: false,
  ssoUser: false,
  profileComplete: false,
};

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  siteName: string = LUMA_THEME.variables.siteName;
  supportNumber: string = LUMA_THEME.variables.supportNumber;
  supportText: string = LUMA_THEME.variables.supportText;
  supportSubText: string = LUMA_THEME.variables.supportSubText;
  getHelpText: string = LUMA_THEME.variables.getHelpText;
  getHelpLink: string;

  @Input() position = 'normal';
  @ViewChild(NbContextMenuDirective) menuInstance;

  user: any;
  private alive: boolean = true;

  activeModal;

  userMenu = [
    { title: 'My Profile', link: '/pages/profile' },
    { title: 'Terms & Conditions', link: '/auth/privacy' },
    { title: 'Change Password', link: '/auth/change-password' },
    { title: 'Request Additional Services', link: '/pages/intake/employer-information-2' },
    { title: 'Logout' /*, link : '/auth/logout'*/ },
  ];

  canShowSidebar: boolean;
  userServiceSubscription: Subscription;

  constructor(
    private menuService: LumaMenuService,
    private userService: UserService,
    public tokenStorage: NbTokenStorage,
    protected tokenService: NbTokenService,
    private modalService: ModalService,
    private analytics: AnalyticsService,
    private cookieService: CookieService,
    private router: Router,
    private sidebarService: NbSidebarService,
    private intakeFlowService: IntakeFlowService,
  ) {
    this.canShowSidebar = false;
    this.hideContextArea();
  }

  ngOnInit() {
    this.subscribeOnItemClick();

    this.tokenService.tokenChange().subscribe(token => {
      if (token && token.isValid()) {
        if (!this.userServiceSubscription) {
          this.userServiceSubscription = this.userService
            .getUser()
            .subscribe((user: any) => {
              this.user = user;
              this.updateUniBar();
              this.updateMobileUniBar();
            });
        }
      } else {
        this.user = null;
        this.updateUniBar();
        this.updateMobileUniBar();
      }
    });

    // Check if company context is set
    if (this.intakeFlowService.getFormData().currentContract) {
      this.getHelpLink = '/pages/intake/care-path';
    } else {
      this.getHelpLink = '/pages/intake/care-path';
    }
  }

  ngOnDestroy() {
    this.alive = false;
    if (this.userServiceSubscription) {
      this.userServiceSubscription.unsubscribe();
    }
  }

  private subscribeOnItemClick() {
    this.filterForUserMenu(this.menuService.onItemSelect()).subscribe(
      menuItem => {
        // hide anynchronously
        this.hideMenu();
      },
    );

    this.filterForUserMenu(this.menuService.onItemClick()).subscribe(
      menuItem => {
        if (menuItem.item.title === 'Logout') {
          this.showStaticModal();
        }

        // hide anynchronously
        this.hideMenu();
      },
    );
  }

  get checkUser() {
    if (this.tokenStorage.get().getValue()) return true;
  }

  private hideMenu() {
    setTimeout(() => {
      if (this.menuInstance) this.menuInstance.hide();
      if (this.sidebarService) this.sidebarService.toggle(false, 'left');
    });
  }

  private filterForUserMenu(
    observable: Observable<NbMenuBag>,
  ): Observable<NbMenuBag> {
    return observable.pipe(takeWhile(() => this.alive));
  }

  showMenu() {
    this.canShowSidebar = true;
    this.sidebarService.toggle(false, 'left');
  }

  showStaticModal() {
    const modal: ModalOptions = {
      modalHeader: 'Are you sure you want to Logout?',
      options: true,
    }
    this.modalService.showModal(SizeModal.MEDIUM, modal, this.navigateLogout.bind(this));
  }

  navigateLogout() {
    this.analytics.setUserID(null);
    this.cookieService.delete('userId');
    this.cookieService.delete('ssoUser');
    this.router.navigate(['/auth/logout']);
  }

  hideContextArea() {
    const contextAuth = document.getElementById('inner-context');
    if (contextAuth) {
      contextAuth.style.display = 'none';
    }
  }

  // updates UI in Unified Nav Bar
  updateUniBar() {
    const contextAuth = document.getElementById('inner-context');
    const contextLoggedIn = document.getElementById('context-login');
    const contextLoggedout = document.getElementById('context-logout');
    const userPicture = document.getElementById('user-picture');
    const userName = document.getElementById('user-name');
    const changePasswordLink = document.getElementById('change-password');

    const elemsReady = contextAuth && contextLoggedIn && contextLoggedout;

    if (this.user && this.user.name) {
      const matches = this.user.name.match(/\b(\w)/g);
      const acronym = matches[0] + matches[1];

      if ((this.user.profileComplete !== undefined && this.user.profileComplete === false)
          && this.user.ssoUser) {
          contextAuth.style.display = 'none';
      }

      if (elemsReady) {
        contextLoggedout.style.display = 'none';
        contextLoggedIn.style.display = 'block';
        userPicture.innerHTML = '<i class="fa fa-user-circle" aria-hidden="true"></i>';
        userName.innerText = this.user.name;
        contextAuth.style.display = 'block';

        if (this.user.ssoUser !== undefined && this.user.ssoUser === true) {
          changePasswordLink.style.display = 'none';
        } else {
          changePasswordLink.style.display = 'block';
        }
      }

      (<any>window).lumaAuth = {
        acronym: acronym,
        name: this.user.name,
        loggedIn: true,
        ssoUser: this.user.ssoUser,
        profileComplete: this.user.profileComplete,
      };

    } else {
      if (elemsReady) {
        contextLoggedIn.style.display = 'none';
        contextLoggedout.style.display = 'block';
        userPicture.innerText = '';
        userName.innerText = '';
      }

      (<any>window).lumaAuth = {
        acronym: '',
        name: '',
        loggedIn: false,
      };
    }
    if (contextAuth) {
      contextAuth.style.display = 'block';
    }
  }

  updateMobileUniBar() {
    const mobileContextAuth = document.getElementById('mobile-inner-context');
    const mobileContextLoggedIn = document.getElementById('mobile-context-login');
    const mobileContextLoggedout = document.getElementById('mobile-context-logout');
    const mobileUserPicture = document.getElementById('mobile-user-picture');
    const mobileUserName = document.getElementById('mobile-user-name');
    const mobileChangePasswordLink = document.getElementById('mobile-change-password');

    const elemsReady = mobileContextAuth && mobileContextLoggedIn && mobileContextLoggedout;

    if (this.user && this.user.name) {
      const matches = this.user.name.match(/\b(\w)/g);
      const acronym = matches[0] + matches[1];

      if ((this.user.profileComplete !== undefined && this.user.profileComplete === false)
          && this.user.ssoUser) {
            mobileContextAuth.style.display = 'none';
      }

      if (elemsReady) {
        mobileContextLoggedout.style.display = 'none';
        mobileContextLoggedIn.style.display = 'block';
        mobileUserPicture.innerHTML = '<i class="fa fa-user-circle" aria-hidden="true"></i>';
        mobileUserName.innerText = this.user.name;
        mobileContextAuth.style.display = 'block';

        if (this.user.ssoUser !== undefined && this.user.ssoUser === true) {
          mobileChangePasswordLink.style.display = 'none';
        } else {
          mobileChangePasswordLink.style.display = 'block';
        }
      }

      (<any>window).lumaAuth = {
        acronym: acronym,
        name: this.user.name,
        loggedIn: true,
        ssoUser: this.user.ssoUser,
        profileComplete: this.user.profileComplete,
      };

    } else {
      if (elemsReady) {
        mobileContextLoggedIn.style.display = 'none';
        mobileContextLoggedout.style.display = 'block';
        mobileUserPicture.innerText = '';
        mobileUserName.innerText = '';
      }

      (<any>window).lumaAuth = {
        acronym: '',
        name: '',
        loggedIn: false,
      };
    }
    if (mobileContextAuth) {
      mobileContextAuth.style.display = 'block';
    }
  }

}
