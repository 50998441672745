import * as i0 from "@angular/core";
var DateDifferenceService = /** @class */ (function () {
    function DateDifferenceService() {
    }
    DateDifferenceService.prototype.yearsDiff = function (dob) {
        var difference = new Date().valueOf() - new Date(dob).valueOf();
        var diff = difference / 1000 / 60 / 60 / 24 / 365; // milliseconds to hours (this.difference returns milliseconds)
        return diff;
    };
    DateDifferenceService.prototype.hoursDiff = function (sd) {
        var difference = new Date().valueOf() - new Date(sd).valueOf(); // sd = submitted date
        var diff = Math.round(difference / 1000 / 60 / 60); // milliseconds to hours (this.difference returns milliseconds)
        return diff;
    };
    DateDifferenceService.ngInjectableDef = i0.defineInjectable({ factory: function DateDifferenceService_Factory() { return new DateDifferenceService(); }, token: DateDifferenceService, providedIn: "root" });
    return DateDifferenceService;
}());
export { DateDifferenceService };
