import { EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IntakeFlowService } from '../../../../@core/utils/intake-flow.service';
var ContentRadioComponent = /** @class */ (function () {
    function ContentRadioComponent() {
        this.showContent = new Array(3).fill(false);
        this.selectedIndexChange = new EventEmitter();
    }
    ContentRadioComponent.prototype.itemClicked = function (index) {
        if (this.items[index] != null && !this.items[index].disabled) {
            this.selectedIndex = index;
            this.selectedIndexChange.emit(index);
            this.showContent.fill(false);
            this.showContent[this.selectedIndex] = true;
        }
    };
    ContentRadioComponent.prototype.unsetActiveTab = function () {
        this.showContent.fill(false);
        this.selectedIndex = undefined;
    };
    Object.defineProperty(ContentRadioComponent.prototype, "visibleItems", {
        get: function () {
            return this.items.filter(function (item) { return !item.invisible; }).length;
        },
        enumerable: true,
        configurable: true
    });
    return ContentRadioComponent;
}());
export { ContentRadioComponent };
var ContentRadioItem = /** @class */ (function () {
    function ContentRadioItem() {
    }
    return ContentRadioItem;
}());
export { ContentRadioItem };
