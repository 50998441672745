import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../modal.component';
var ConfirmBabyKitModalComponent = /** @class */ (function () {
    function ConfirmBabyKitModalComponent(activeModal) {
        this.activeModal = activeModal;
        this._modal = { context: {}, modalHeader: '', options: false };
        this.user = {};
    }
    Object.defineProperty(ConfirmBabyKitModalComponent.prototype, "modal", {
        set: function (value) {
            if (Object.keys(value).length > 0) {
                var user = value.context.user;
                this.user = user;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmBabyKitModalComponent.prototype, "submittedDate", {
        get: function () {
            return new Date(this.user.babyKitRequest.submitted).getTime();
        },
        enumerable: true,
        configurable: true
    });
    ConfirmBabyKitModalComponent.prototype.ngOnInit = function () {
    };
    ConfirmBabyKitModalComponent.prototype.closeModal = function () {
        this.activeModal.close(ConfirmationModal.No);
    };
    ConfirmBabyKitModalComponent.prototype.accept = function () {
        this.activeModal.close(ConfirmationModal.Yes);
    };
    return ConfirmBabyKitModalComponent;
}());
export { ConfirmBabyKitModalComponent };
