import { Component, OnInit } from '@angular/core';
import { PillSelectorItem } from '../../../@theme/components/reusables';
import { Router, ActivatedRoute } from '@angular/router';
import { EmotionalWellbeingSurveyService } from '../../../@core/utils/emotional-wellbeing-survey.service';
import { STRESS_RESPONSES, RISK } from '../../../@core/utils/enums';

const RESPONSES = [
  STRESS_RESPONSES.NEVER,
  STRESS_RESPONSES.ONLY_A_LITTLE,
  STRESS_RESPONSES.SOMETIMES,
  STRESS_RESPONSES.FAIRLY_OFTEN,
  STRESS_RESPONSES.VERY_OFTEN,
];
const ROUTE_NAME = 'stress-life';
@Component({
  selector: 'ngx-stress-level',
  templateUrl: './stress-level.component.html',
  styleUrls: ['./stress-level.component.scss'],
})
export class StressLevelComponent implements OnInit {

  title: string;
  subHeader: string;
  isShowValid: boolean;
  pillItems: PillSelectorItem[];
  submitted: boolean = false;
  selectedFrequencyIndices: [number] = [-1];
  tooltip = `This question helps us understand the degree of stress you are experiencing so that we can guide you to the right level of support.`;
  feelingsData: string[];
  stressData: string[];
  satisfiedData: string[];
  riskLevel: RISK;
  feelingsNervous: string;
  controlWorryingData: string;
  feelingDepressedData: string;
  littleInterestData: string;

  constructor(private router: Router,
    private emotionalWellBeingService: EmotionalWellbeingSurveyService,
    private activatedRoute: ActivatedRoute) {
    this.emotionalWellBeingService.setCarePathProgress(ROUTE_NAME);

    this.pillItems = [
      {
        caption: ``,
        items: RESPONSES,
      },

    ];
  }


  ngOnInit() {
    this.emotionalWellBeingService.setCarePathProgress(ROUTE_NAME);
    if (this.emotionalWellBeingService.stressLifeData) {
      this.selectedFrequencyIndices[0] = this.pillItems[0].items.findIndex(e => e === this.emotionalWellBeingService.stressLifeData[0]);
      this.isValidState();
    }

  }


  isValidState() {
    this.isShowValid = this.selectedFrequencyIndices[0] < 0;
  }


  goNext(): void {
    this.submitted = true;
    try {
      const stressLifeData = [
        this.pillItems[0].items[this.selectedFrequencyIndices[0]],
      ];
      this.emotionalWellBeingService.stressLifeData = stressLifeData;


      const nextStep = this.emotionalWellBeingService.getNextStep(ROUTE_NAME);
      this.router.navigate([`../${nextStep}`], { relativeTo: this.activatedRoute });
    } catch (err) { this.submitted = false }
  }


}
