import { Address } from '../../../../@core/data';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
var ɵ0 = function (form, group) { return group || form; };
var AddressComponent = /** @class */ (function () {
    function AddressComponent() {
        this.primaryAddressIndex = -1;
        this.address = {};
    }
    return AddressComponent;
}());
export { AddressComponent };
export { ɵ0 };
