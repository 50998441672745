/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sub-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./sub-header.component";
var styles_SubHeaderComponent = [i0.styles];
var RenderType_SubHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubHeaderComponent, data: {} });
export { RenderType_SubHeaderComponent as RenderType_SubHeaderComponent };
function View_SubHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "headline row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-12 col-md-9"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headline; _ck(_v, 2, 0, currVal_0); }); }
function View_SubHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row justify-content-center icon-position"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "caption col-12"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_SubHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "sub-header mt-1 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubHeaderComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubHeaderComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headline; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.caption; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_SubHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubHeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-sub-header", [], null, null, null, View_SubHeaderComponent_0, RenderType_SubHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.SubHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubHeaderComponentNgFactory = i1.ɵccf("ngx-sub-header", i3.SubHeaderComponent, View_SubHeaderComponent_Host_0, { headline: "headline", caption: "caption", visible: "visible" }, {}, ["*"]);
export { SubHeaderComponentNgFactory as SubHeaderComponentNgFactory };
