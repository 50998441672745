import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal, ModalOptions } from '../modal.component';
import { SERVICE_TYPE } from '../..';
import { Provider } from '../../../../../@core/data';
import { environment } from '../../../../../../environments/environment';
import { STATES } from '../../../../../@core/utils/intake-flow.service';
import { ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { BH_SERVICE } from '../../../../../@core/utils/constants';
@Component({
  selector: 'ngx-switch-modal',
  templateUrl: './switch-modal.component.html',
  styleUrls: ['./switch-modal.component.scss'],
})
export class SwitchModalComponent implements OnInit {
  _modal: ModalOptions = { context: {}, modalHeader: '', options: false };

  headerTitle: string;
  serviceType: SERVICE_TYPE;
  user: any = {};
  heading: string;
  provider: Provider;
  souAgreed: boolean = false;
  modality: string = '';
  message = '';
  contract: any;
  org: any;
  submitted: boolean = false;
  artcleLink: String = environment.onlineTherapyArticle;

  public states = STATES;
  stateList: string[];
  BH_STRING: string;
  counselingFor: string = '';
  selectState: string[] = [];
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  value: any;

  set modal(value: ModalOptions) {
    if (Object.keys(value).length > 0) {
      this.message = ``;
      this.heading = 'Is Online Counseling right for you?';
    }
  }

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.getUSAStateCodes();
    this.stateList = Object.values(this.states)
    this.stateList.shift();
    this.getBhString()
  }

  getBhString() {
    this.BH_STRING = BH_SERVICE;
  }

  getUSAStateCodes(): Array<string> {
    return Object.keys(this.states);
  }
  closeModal() {
    this.activeModal.close({ answer: ConfirmationModal.No, user: this.user });
  }
  getStates() {
    return this.stateList;
  }
  accept(): void {
    this.activeModal.close({ answer: ConfirmationModal.Yes, user: this.user, selectState: this.selectState });
  }

  completeRequest() {
    if (this.submitted) return;
    this.submitted = true;
    for (const eachCode in this.states) {
      if (this.states[eachCode] === this.value) {
        this.selectState.push(eachCode)
        this.selectState.push(this.states[eachCode])
      }
    }
    this.accept();
  }
}
