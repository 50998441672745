import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../../@core/utils/analytics.service';

@Component({
  selector: 'ngx-in-the-moment',
  styleUrls: ['./in-the-moment.component.scss'],
  templateUrl: './in-the-moment.component.html',
})

export class InTheMomentComponent implements OnInit {
  showInfo: boolean = false;
  showCallInfo: boolean = false;
  isDashboard: boolean = false;
  isIntake: boolean = false;

  constructor(
    private analytics: AnalyticsService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (this.router.url.includes('intake')) {
      this.isIntake = true;
    }

    if (this.router.url.includes('dashboard')) {
      this.isDashboard = true;
    }
  }

  toggler() {
    this.showInfo = !this.showInfo;
    if (this.showInfo) {
      this.analytics.trackGenericEvent('immediate support', 'toggle', 'message');
    }
  }

  createItmsCase() {
    this.analytics.trackGenericEvent('immediate support', 'click', 'Yes, I want immediate support');
  }
}
