import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-emotional-pill-selector',
  templateUrl: './emotional-pill-selector.component.html',
  styleUrls: ['./emotional-pill-selector.component.scss'],
})
export class EmotionalPillSelectorComponent implements OnInit {

  @Input() items: string[];
  @Input() caption: string;
  @Input() selectedIndex: number;
  @Input() selectorInnerClass: string = 'col-12 col-sm-10';
  @Input() selectorRowClass: string = 'row'
  @Output() selectedItemIndex = new EventEmitter<number>();
  @Output() isDisable: EventEmitter<any> = new EventEmitter();


  MOBILE_MAX_WIDTH: number = 768;
  isSmallScreen: boolean;
  selectedPill: boolean;
  previousindex: number = -2;
  index: number;
  isActiveClass: boolean = false;
  constructor() {
    this.isSmallScreen = window.screen.width <= this.MOBILE_MAX_WIDTH;
  }


  ngOnInit() {
    if (this.selectedIndex !== 1) {
      this.index = this.selectedIndex;
      this.previousindex = this.index;

    }

  }
  onClick(index) {

    if (this.previousindex === index) {
      this.isActiveClass = false;
      this.selectedIndex = -1
      this.previousindex = -1;
    } else {
      this.isActiveClass = true;
      this.selectedIndex = index;
      this.previousindex = index;
    }
    this.selectedItemIndex.emit(this.selectedIndex);
    this.isDisable.emit();
  }
  onResize() {
    this.isSmallScreen = window.screen.width <= this.MOBILE_MAX_WIDTH;
  }

};




export class PillSelectorItem {
  caption: string;
  items: string[];
}
