import { BehaviorSubject, of } from 'rxjs';
import { OnInit } from '@angular/core';
import { ContentService } from './api/content.service';
import { upsertItem } from '../utils/upsertItem';
import * as i0 from "@angular/core";
import * as i1 from "./api/content.service";
var ContentManagementService = /** @class */ (function () {
    function ContentManagementService(_contentService) {
        this._contentService = _contentService;
        this.userContents = new BehaviorSubject([]);
        this.ngOnInit();
    }
    Object.defineProperty(ContentManagementService.prototype, "contentId", {
        get: function () {
            return this._contentId;
        },
        set: function (value) {
            this._contentId = value;
        },
        enumerable: true,
        configurable: true
    });
    ContentManagementService.prototype.ngOnInit = function () {
    };
    ContentManagementService.prototype.getUserContent = function () { return this.userContents.asObservable(); };
    ContentManagementService.prototype.getContentById = function () {
        var _this = this;
        return of(this.userContents.value.filter(function (content) { return content.id === _this.contentId; }));
    };
    ContentManagementService.prototype.getAllRecommendedContent = function (ids, benefits) {
        var _this = this;
        if (benefits === void 0) { benefits = []; }
        this._contentService.getContentByCaseIds(ids, benefits).subscribe(function (contents) {
            upsertItem(contents, _this.userContents);
        }, function (err) {
            _this.userContents.error(err);
        });
    };
    ContentManagementService.prototype.resetVars = function () {
        this._contentId = '';
    };
    ContentManagementService.prototype.resetCache = function () {
        this.resetVars();
        this.userContents.next([]);
    };
    ContentManagementService.ngInjectableDef = i0.defineInjectable({ factory: function ContentManagementService_Factory() { return new ContentManagementService(i0.inject(i1.ContentService)); }, token: ContentManagementService, providedIn: "root" });
    return ContentManagementService;
}());
export { ContentManagementService };
