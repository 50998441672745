import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class ErrorsHandler implements ErrorHandler {
    constructor(private injector: Injector) { }
    handleError(error: Error) {
        // check enviroment production
        console.error(error);
        if (typeof error === 'object' && error.message.match(/Cannot read property/)) return;
        // const logging = this.injector.get(LoggingService);
        const toastr = this.injector.get(ToastrService);
        if (error instanceof HttpErrorResponse) {
            // logging.recordLogError(error).subscribe(logged => { }, err => { });
            if (!error.url.includes('/me')) {
                // not interested in unauthorized when user logs out.
                // toastr.error(error.message, 'An error occured', { tapToDismiss: true, closeButton: true })
                toastr.error('An error has occured. User experience may be affected.',
                'An error occured', { tapToDismiss: true, closeButton: true })
            }
        }

    }
}
