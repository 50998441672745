import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'ngx-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FilterSelectorComponent implements OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() description: string;
  @Input() note: string;
  @Input() multiple: boolean = false;
  @Input() items: any[];
  @Input() autoClose: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showAll: boolean = true;
  @Input() required: boolean = false;

  @Input() selectedIndex: number;
  @Output() selectedIndexChange = new EventEmitter<number>();
  isOpen: boolean = false;
  isTruncated: boolean = true;

  _noteTemplate: string = '{text}';
  @Input() set noteTemplate(value: string) {
    this._noteTemplate = value;
    this.updateNote();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Always show notes for active items
    if (
      changes.items &&
      changes.items.currentValue &&
      changes.items.currentValue.length > 0 &&
      changes.items.currentValue.some((element) => element.active)
    ) {
      this.updateNote();
    }
  }

  onClick(item: any, index: number) {
    // implement multiple or single select logic
    if (this.multiple) {
      item.active = !item.active;
    } else {
      this.items.forEach(
        (element) => (element.active = element === item ? !item.active : false)
      );
    }

    if (this.autoClose) {
      this.isOpen = false;
    }

    this.selectedIndex = index;
    this.selectedIndexChange.emit(index);
    this.updateNote();
  }

  updateNote() {
    if (!this.items) {
      return;
    }

    const activeTitles = this.items
      .filter((option) => option.active)
      .map((option) => option.title)
      .join(', ');
    this.note = this.supplant(this._noteTemplate, {
      text: activeTitles ? activeTitles : '-',
    });
  }

  toggle() {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
    }
  }

  itemTitle(item: any): string {
    return item.title;
  }

  supplant(format: string, data: any) {
    return format.replace(/{([^{}]*)}/g, (a: any, b: any) => {
      const r = data[b];
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    });
  }
}
