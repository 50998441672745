import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuardService } from '../../@core/utils/auth-guard.service';
import { FeelingsSurveyComponent } from './feelings-survey/feelings-survey.component';
import { EmotionalWellbeingSurveyComponent } from './emotional-wellbeing-survey.component';
import { SatisfiedLifeComponent } from './satisfied-life/satisfied-life.component';
import { StressLevelComponent } from './stress-level/stress-level.component';
import { EmotionalThankyouComponent } from './emotional-thankyou/emotional-thankyou.component';
import { EmotionalSurveyGuardService } from '../../@core/utils/emotional-survey-guard.service';


const routes: Routes = [{
  path: '',
  component: EmotionalWellbeingSurveyComponent,
  children: [
    {
      path: 'feelings',
      component: FeelingsSurveyComponent,
      canActivate: [EmotionalSurveyGuardService],
    },
    {
      path: 'satisfied-life',
      component: SatisfiedLifeComponent,
      canActivate: [EmotionalSurveyGuardService],
    },
    {
      path: 'stress-life',
      component: StressLevelComponent,
      canActivate: [EmotionalSurveyGuardService],
    },

    {
      path: 'thankyou-survey',
      component: EmotionalThankyouComponent,
      canActivate: [EmotionalSurveyGuardService],
    },


  ],
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService, EmotionalSurveyGuardService],
})
export class EmotionalSurveyRoutingModule { }
