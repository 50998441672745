
import { Observable } from 'rxjs';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListItem, Config } from './model/models';
import { Configuration } from './configuration';
import { BASE_PATH } from './variables';
import { environment } from '../../../environments/environment';
import { betterHelpRoutes, configConstants, configRoutes } from './api/constants';


export interface ConfigModel {
  ClientType: ListItem[],
  Credential: ListItem[],
  Ethnicity: ListItem[],
  Gender: ListItem[],
  InsurancePanel: ListItem[],
  Specialization: ListItem[],
  WorkLifeServiceType: ListItem[],
  Visibilities: ListItem[],
  Languages: ListItem[],
  Availabilities: ListItem[],
}
export interface StateModel {
  state?: string,
  msg?: string,
}

@Injectable()
export class ConfigService {
  protected basePath = environment.basePath;
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }
  getConfigsEapx(): Observable<ConfigModel> {
    return this.httpClient.get<ConfigModel>(`${this.basePath}${configRoutes.getEAPXParams}?onlyActive=${configConstants.onlyActive}`);
  }

  getConfigsVaraLuma(): Observable<Config> {
    return this.httpClient.get<Config>(`${this.basePath}/run/listConfigs`);
  }

  getCitiesFromState(state: String): Observable<any> {
    return this.httpClient.get<any>(`${this.basePath}${configRoutes.getCitiesFromState}?state=${state}`);
  }

  getStateFromZip(zip: String): Observable<StateModel> {
    return this.httpClient.get<StateModel>(`${this.basePath}${configRoutes.getStateFromZip}?zipcode=${zip}`);
  }

  getBetterHelpLink(data: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.basePath}${betterHelpRoutes.getBetterHelp}`, data);
  }

  getCoachingSecret(): Observable<Config> {
    return this.httpClient.get<Config>(`${this.basePath}${configRoutes.getCoachingConfig}`);
  }
}
