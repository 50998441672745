import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'ngx-sou',
  templateUrl: './sou.component.html',
  styleUrls: ['./sou.component.scss'],
})
export class SouComponent {

  @Output() agreeToSouChange = new EventEmitter<boolean>();
  @Input()  agreeToSou: boolean;

  constructor() { }
}
