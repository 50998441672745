<div class="container">
  <div class="row justify-content-center">
    <h1 class="title col-12 col-xl-8 col-md-12 col-sm-12 text-center">{{title}}</h1>
  </div>
  <div class="row justify-content-center">
    <h4 class="content col-12 col-xl-8 col-md-12 col-sm-12 text-center description">{{description}}</h4>
  </div>
  <div class="row justify-content-center video">
    <img class="img-fluid ipad" alt="Concern Screen" src="../../../assets/images/landing/concern-ipad.png" />
  </div>
</div>