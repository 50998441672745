import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BH_SERVICE, LOCAL_SERVICE, BH_MODE_OF_HELP, LOCAL_COUNSELING_MODE_OF_HELP, COACHING_MODE_OF_HELP, PERSONAL_COACHING_SERVICE_USED, EM_LIFE_MODALITY, GUIDED_MINDFULNESS_DASHBOARD_LABEL } from '../../utils/constants';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { userHistoryRoutes } from './constants';
import { environment } from '../../../../environments/environment';

export enum HISTORY_TYPE {
  USER_REGISTRATION = 'Registered',
  CASE_CREATED = 'Case created',
  CASE_UPDATED = 'Case updated',
  CASE_AUTHORIZED = 'Case authorized',
  WORK_LIFE_SERVICE = 'Work life service',
  MOOD_QUESTIONNAIRE = 'Mood Questionnaire',
  ANXIETY_QUESTIONNAIRE = 'Anxiety Questionnaire',
  STRESS_QUESTIONNAIRE = 'Stress Assesment',
  MOOD_ARTICLE = 'Mood article',
  ANXIETY_ARTICLE = 'Anxiety article',
  STRESS_ARTICLE = 'Stress article',
  SELF_HELP_VIDEO = 'Self-help video',
  SELF_HELP_ARTICLE = 'Self-help article',
  SELF_HELP_APPLICATION = 'Self-help application',
  SELF_HELP_WEBSITE = 'Self-help website',
  SELF_HELP_TOOL = 'Self-help tool',
  QUALITY_OF_LIFE_ARTICLE = 'Quality of Life article',
  QUALITY_OF_LIFE_QUESTIONNAIRE = 'Quality of Life Questionnaire',
  WOS_QUESTIONNAIRE = 'Workplace Outcome Suite Questionnaire',
  BABYKIT_REQUESTED = 'Babykit Requested',
}

@Injectable()
export class HistoryService {
  MAPPING = {
    questionary: [
      HISTORY_TYPE.MOOD_QUESTIONNAIRE, HISTORY_TYPE.WORK_LIFE_SERVICE,
      HISTORY_TYPE.ANXIETY_QUESTIONNAIRE, HISTORY_TYPE.QUALITY_OF_LIFE_QUESTIONNAIRE, HISTORY_TYPE.WOS_QUESTIONNAIRE, HISTORY_TYPE.STRESS_QUESTIONNAIRE],
    cases: [HISTORY_TYPE.CASE_CREATED, HISTORY_TYPE.CASE_UPDATED, HISTORY_TYPE.CASE_AUTHORIZED, HISTORY_TYPE.BABYKIT_REQUESTED],
    registration: [HISTORY_TYPE.USER_REGISTRATION],
    content: [
      HISTORY_TYPE.MOOD_ARTICLE, HISTORY_TYPE.ANXIETY_ARTICLE, HISTORY_TYPE.QUALITY_OF_LIFE_ARTICLE, HISTORY_TYPE.SELF_HELP_VIDEO,
      HISTORY_TYPE.SELF_HELP_ARTICLE, HISTORY_TYPE.SELF_HELP_APPLICATION, HISTORY_TYPE.STRESS_ARTICLE],
  };
  protected basePath = environment.basePath;
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getHistory(limit?: number): Observable<any> {
    const params = limit ? `?limit=${limit}` : ``;
    return this.httpClient
      .get<any>(`${this.basePath}${userHistoryRoutes.userHistory}${params}`)
      .pipe(catchError(this.handleError));
  }

  createHistory(historyType: HISTORY_TYPE, data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.basePath}${userHistoryRoutes.userHistory}`, { historyType: historyType, date: new Date(), data })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
      return throwError('Something bad happened; please try again later.');
    }
  }

  getHistorType(historyType: string) {
    return Object.keys(this.MAPPING).find(key => this.MAPPING[key].find(history => history === historyType));
  }

  isCaseOrUserHistory(historyType: string) {
    const selections: Array<string> = [HISTORY_TYPE.CASE_AUTHORIZED, HISTORY_TYPE.USER_REGISTRATION, HISTORY_TYPE.CASE_CREATED,
    HISTORY_TYPE.CASE_UPDATED, HISTORY_TYPE.BABYKIT_REQUESTED];
    return selections.includes(historyType);
  }

  capitalize(word: string) {
    return word[0].toUpperCase() + word.slice(1)
  }

  registerTitle(history) {
    const { historyType, data: { historyTitle: modeOfHelp } } = history;
    if (historyType === HISTORY_TYPE.USER_REGISTRATION || historyType === HISTORY_TYPE.BABYKIT_REQUESTED) {
      return historyType;
    }

    if (historyType === HISTORY_TYPE.CASE_UPDATED) {
      const historyAction = 'updated'
      if (modeOfHelp === BH_MODE_OF_HELP) {
        return `${BH_SERVICE} counseling case ${historyAction}`
      } else if (modeOfHelp === LOCAL_COUNSELING_MODE_OF_HELP) {
        return `${LOCAL_SERVICE} counseling case ${historyAction}`
      } else {
        return `${this.capitalize(modeOfHelp)} case ${historyAction}`;
      }
    }

    const action = historyType === HISTORY_TYPE.CASE_AUTHORIZED ? 'authorized' : 'created';
    switch (modeOfHelp) {
      case BH_MODE_OF_HELP:
        return `${BH_SERVICE} counseling case ${action}`;
      case LOCAL_COUNSELING_MODE_OF_HELP:
        return `${LOCAL_SERVICE} counseling case ${action}`;
      case COACHING_MODE_OF_HELP:
        return `${PERSONAL_COACHING_SERVICE_USED} case ${action}`;
      case EM_LIFE_MODALITY:
        return `${GUIDED_MINDFULNESS_DASHBOARD_LABEL} case ${action}`;
      default:
        return `${this.capitalize(modeOfHelp)} case ${action}`;
    }
  }
}

