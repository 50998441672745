<div class="animate__animated animate__slideInRight animate__faster feelings_content">

  <div class="row justify-content-center no-gutters">
    <h4 class="col-12 top font-size-36 font-weight-500 mb32 text-center font-black">Over the
      <span class="mobile-bold">past 2 weeks</span>,
      how often have you been
      bothered
      by these feelings or thoughts?
      <ng-container *ngIf="tooltip">
        <ngx-instructions-tooltip [popOverMessage]="tooltip" [attachToCorner]="false"></ngx-instructions-tooltip>
      </ng-container>
    </h4>
  </div>
  <ngx-emotional-pill-selector [caption]="pillItems[0].caption" [items]="pillItems[0].items"
    (selectedItemIndex)="selectedFrequencyIndices[0] = $event" [(selectedIndex)]="selectedFrequencyIndices[0]"
    (isDisable)="isValidState()">
  </ngx-emotional-pill-selector>
  <div class="divider"></div>
  <ngx-emotional-pill-selector [caption]="pillItems[1].caption" [items]="pillItems[1].items"
    (selectedItemIndex)="selectedFrequencyIndices[1] = $event" [(selectedIndex)]="selectedFrequencyIndices[1]"
    (isDisable)="isValidState()">
  </ngx-emotional-pill-selector>
  <ngx-emotional-pill-selector [caption]="pillItems[2].caption" [items]="pillItems[2].items"
    (selectedItemIndex)="selectedFrequencyIndices[2] = $event" [(selectedIndex)]="selectedFrequencyIndices[2]"
    (isDisable)="isValidState()">
  </ngx-emotional-pill-selector>
  <ngx-emotional-pill-selector [caption]="pillItems[3].caption" [items]="pillItems[3].items"
    (selectedItemIndex)="selectedFrequencyIndices[3] = $event" [(selectedIndex)]="selectedFrequencyIndices[3]"
    (isDisable)="isValidState()">
  </ngx-emotional-pill-selector>

  <div class="row justify-content-center no-gutters mt8">
    <button class="btn btn-primary col-12 col-md-5 mb24" (click)="goNext()"
      [disabled]="submitted || isShowValid">CONTINUE
      <i class="fas fa-arrow-circle-right"></i></button>
  </div>
</div>