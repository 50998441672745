import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from '../../@core/utils/progress-bar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-emotional-wellbeing-survey',
  templateUrl: './emotional-wellbeing-survey.component.html',
  styleUrls: ['./emotional-wellbeing-survey.component.scss'],
})
export class EmotionalWellbeingSurveyComponent implements OnInit {
  previousValue: number = -1;
  itmsCaseData: any;
  exception = [];
  goBackOnly = ['feelings', 'thankyou-survey'];
  noBar = [];
  constructor(public progressBarService: ProgressBarService, private router: Router) { }

  ngOnInit() {
  }
  get isVisibleOnStep(): boolean {
    const currentUrl = this.router.url;
    const arrPath = currentUrl.split('/');
    const currentStep = arrPath[arrPath.length - 1];
    return !this.exception.includes(currentStep);
  }

  get backOnly(): boolean {
    const currentUrl = this.router.url;
    const arrPath = currentUrl.split('/');
    const currentStep = arrPath[arrPath.length - 1];
    return this.goBackOnly.includes(currentStep);
  }

  get showBar(): boolean {
    const currentUrl = this.router.url;
    const arrPath = currentUrl.split('/');
    const currentStep = arrPath[arrPath.length - 1];
    return !this.noBar.includes(currentStep);
  }
}
