import { OnInit, EventEmitter } from '@angular/core';
var FULL_WIDTH_COL = 12;
var SMALL_WIDTH_COL = 3;
var WIDTH_BREAK = 3;
var MultiSelectorComponent = /** @class */ (function () {
    function MultiSelectorComponent() {
        this._items = [];
        this.selectedIndices = [];
        this.clearAllIndex = -1; // used to identify a selection that should stand on its own in the multi-selector
        this.selectionLimit = 0;
        this.selectedIndicesChange = new EventEmitter();
    }
    Object.defineProperty(MultiSelectorComponent.prototype, "items", {
        get: function () {
            return this._items;
        },
        set: function (elems) {
            this._items = elems;
            this.setColumnNumber();
        },
        enumerable: true,
        configurable: true
    });
    MultiSelectorComponent.prototype.ngOnInit = function () {
        if (this.selectionLimit > 0 && this.selectedIndices.length > this.selectionLimit) {
            this.selectedIndices = this.selectedIndices.slice(this.selectedIndices.length - this.selectionLimit);
        }
    };
    MultiSelectorComponent.prototype.onClick = function (index) {
        if (!this.selectedIndices.includes(index)) {
            if (this.isClearIndexIncluded(index)) {
                this.selectedIndices = [];
            }
            this.selectedIndices.push(index);
            if (this.selectionLimit > 0 && this.selectedIndices.length > this.selectionLimit) {
                this.selectedIndices.shift();
            }
        }
        else {
            var position = this.selectedIndices.indexOf(index);
            this.selectedIndices.splice(position, 1);
        }
        this.selectedIndicesChange.emit(this.selectedIndices);
    };
    MultiSelectorComponent.prototype.isSelected = function (index) {
        var result = this.selectedIndices.includes(index);
        return result;
    };
    /** Creates layout of selectors based on bootstrap
    If number of items is divisible by 3, layout would be 3 in a row
    otherwise, they are stacked one by one**/
    MultiSelectorComponent.prototype.setColumnNumber = function () {
        this.col_number = this.items.length % WIDTH_BREAK === 0
            ? SMALL_WIDTH_COL : FULL_WIDTH_COL;
    };
    // check if clearAllIndex is set and is included in the selected selectedIndices
    MultiSelectorComponent.prototype.isClearIndexIncluded = function (index) {
        return index === this.clearAllIndex || this.selectedIndices.includes(this.clearAllIndex);
    };
    return MultiSelectorComponent;
}());
export { MultiSelectorComponent };
