<div class="nav-btn" *ngIf="!backOnly">
  <div (click)="goBack()">
    <span class="Go-Back">GO BACK</span>
  </div>
  <div (click)="startOver()">
    <span class="Start-Over">START OVER</span>
  </div>
</div>

<!-- <div class="nav-btn" *ngIf="backOnly">
  <div (click)="goBack()">
    <span class="go-back-no-border">GO BACK</span>
  </div>
</div> -->
