import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Provider, Address, ModelCase, Contract } from '../../../@core/data';
import { CalendarAppointmentService } from '../../dashboard/schedule/services/calendar-appointment.service';
import { CaseManagementService } from '../../../@core/data/caseManagement.service';
import {
  ModalOptions,
  SizeModal,
  ConfirmationModal,
  SERVICE_TYPE,
} from '../../../@theme/components/reusables';
import { ModalService } from '../../../@core/utils/modal.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { filter, first } from 'rxjs/operators';
import { ProviderUiService } from '../../../@core/data/providerUi.service';
import {
  ONSITE_MODALITY,
  FIRST_RESPONDERS_IDENTIFIER,
} from '../../../@core/utils/constants';
import { splitFirstResponderLastName } from '../../../utils';

@Component({
  selector: 'ngx-counselor',
  templateUrl: './counselor.component.html',
  styleUrls: ['./counselor.component.scss'],
})
export class CounselorComponent implements OnInit {
  @Input() counselor: Provider;
  @Input() caseData: ModelCase;
  @Input() selected: ModelCase;
  @Input() modality: string;
  hideMe = true;
  adultRanges = ['Ages 41-60', 'Ages 18-40', 'Ages 61+'];
  ageRanges = [];
  user: any = {};
  submitted: boolean = false;
  licences: string[];
  isCoachingCase: boolean = false;
  isAuthorized: boolean;
  counselorModalHeader: string = '';
  currentContract: Contract;
  counselorTitle = [];
  firstResponder: string;
  counselorLastName: string;

  constructor(
    private router: Router,
    private appointmentservice: CalendarAppointmentService,
    private activatedRoute: ActivatedRoute,
    private analytics: AnalyticsService,
    private caseMngt: CaseManagementService,
    private modalService: ModalService,
    private providerUiService: ProviderUiService
  ) {}

  ngOnInit() {
    // filter out the adult age ranges if any just add adult to the array
    this.isCheckOnlineTherapy();
    this.ageRanges = this.counselor.specializations.filter(
      (s) => this.ageRanges.indexOf(s) !== -1
    );
    this.licences = this.providerLicenses();

    if (this.ageRanges.length < this.counselor.specializations.length) {
      // add adult
      this.ageRanges.push('Adults');
    }

    if (
      this.counselor.lastName
        .toLowerCase()
        .includes(FIRST_RESPONDERS_IDENTIFIER[0])
    ) {
      // counselors last name has first responder at the end
      // so we are taking splitting it to get the last name
      // by itself
      this.counselorTitle = splitFirstResponderLastName(
        this.counselor.lastName
      );
      this.counselorLastName = this.counselorTitle[0];
      this.firstResponder = this.counselorTitle[1];
    } else {
      this.counselorLastName = this.counselor.lastName;
    }

    this.caseMngt
      .getUser()
      .pipe(filter((model) => !!model))
      .subscribe((model) => (this.user = model));
    this.checkCoaching();
    this.isAuthorized = this.selected.authNumber ? true : false;
    this.caseMngt
      .getContract()
      .pipe(
        filter((model) => !!model),
        first()
      )
      .subscribe((model) => (this.currentContract = model));
  }

  isCheckOnlineTherapy() {
    if (this.selected.modeOfHelp === 'online-therapy') {
      this.counselor.firstName = '';
    }
  }

  providerLicenses(): string[] {
    const licences = (<any>this.counselor).providerLicenses || [];
    if (
      this.counselor &&
      licences.length > 0 &&
      licences.find((x) => x.clinicalLicenseType)
    ) {
      return this.providerUiService.formatProviderLicence(licences);
    }
    return [];
  }

  bookAppointment() {
    if (this.submitted) return;
    this.submitted = true;

    this.caseMngt.selectedCase = this.selected;
    this.appointmentservice.provider = this.counselor;

    if (this.counselor.onlineSchedulingEnabled) {
      // i should be navigate to, counselor availability page , when my counselor supports online booking.
      this.router.navigate([
        // `/pages/dashboard/schedule/${this.counselor.externalId}`,
        `/pages/dashboard/schedule-appointment/${this.counselor.internalId}/${this.modality}`,
      ]);
    } else {
      // i should be navigated to the contact counselor page, when my counselor does not support online booking.
      this.router.navigate(['./counselor-contact'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  scheduleAppointment() {
    if (this.submitted) return;
    this.submitted = true;

    this.caseMngt.selectedCase = this.selected;
    this.appointmentservice.provider = this.counselor;

    this.router.navigate([
      `/pages/dashboard/schedule-appointment/${this.counselor.internalId}/${this.modality}`,
    ]);
  }

  showProviderSelectionModal() {
    this.modalService.showProviderSelectionModal(
      SizeModal.LARGE,
      {
        context: {
          provider: this.counselor,
          isCoach: this.isCoachingCase,
          selection: this.getCounselorModality(),
          user: this.user,
          selected: this.selected,
          modality: this.modality,
        },
      },
      this.continueProviderSelectionSubmission.bind(this),
      true
    );
  }

  continueProviderSelectionSubmission(data) {
    if (data && data.answer === 'Yes') {
      this.modalService.showAddressFormModal(
        SizeModal.LARGE,
        {
          context: {
            user: this.user,
            serviceType: SERVICE_TYPE.PROFILE,
            isShipping: false,
            showEmergencyContact: true,
            alwaysPromptForAnAddress: false,
            selected: this.selected,
          },
        },
        this.continueSubmission.bind(this),
        true
      );
    }
  }

  continueSubmission(data) {
    if (data && data.user && data.answer === 'Yes') {
      this.user = data.user;
      if (this.isCoachingCase) {
        this.processCoachingCase();
      } else if (this.modality === ONSITE_MODALITY) {
        this.processOnsiteCounselingCase();
      } else this.processInOfficeCounselingCase();
    }
  }
  processOnsiteCounselingCase() {
    this.caseMngt.selectedCase = this.selected;
    this.caseMngt.assignToProvider(this.counselor, true);
  }
  processCoachingCase() {
    this.caseMngt.selectedCase = this.selected;
    this.caseMngt.assignToCoach(this.counselor, true);
  }
  processInOfficeCounselingCase() {
    this.caseMngt.selectedCase = this.selected;
    this.caseMngt.assignToProvider(this.counselor, true);
  }
  getCounselorModality(): string {
    if (this.currentContract && this.currentContract.onSiteEnabled) {
      return this.counselor.videoEnabled
        ? 'Video'
        : this.counselor.onSiteEnabled
        ? 'Onsite'
        : 'In-Office';
    } else {
      return '';
    }
  }

  streetCityStatePostal(location: Address) {
    return [location.street, location.city, location.state, location.postal]
      .filter((x) => x)
      .join(' ');
  }

  selectOfficeLocations(locations: Array<Address>) {
    return locations.filter(
      (x) => x.state
    );
  }

  showCounselorConfirmation(answer?) {
    if (this.selected.modeOfHelp === 'coaching') {
      this.counselorModalHeader = `Are you sure you want to select this coach?`;
    } else {
      this.counselorModalHeader = `Are you sure you want to select this counselor?`;
    }
    if (answer && answer === ConfirmationModal.Yes) {
      const modal: ModalOptions = {
        modalHeader: this.counselorModalHeader,
        modalContent: `After selecting you will have to call Concern
        <a href="tel:800-344-4222">800-344-4222</a> if you want to change your selection`,
        options: true,
      };
      this.modalService.showModal(
        SizeModal.LARGE,
        modal,
        this.confirmationCounselor.bind(this),
        true
      );
    }
  }

  selectCounselor() {
    this.showCounselorConfirmation(ConfirmationModal.Yes);
  }

  confirmationCounselor(answer) {
    if (answer && answer === ConfirmationModal.No) {
      this.analytics.trackEvent('Counseling not-in-office');
      return;
    }

    // const context = { address: true, sou: true, user: this.user };
    // this.modalService.showAddressSouModal(SizeModal.LARGE, { context }, this.confirmSouAcceptance.bind(this), true);
  }

  confirmSouAcceptance(answer) {
    if (answer && answer === ConfirmationModal.Yes) {
      this.caseMngt.selectedCase = this.selected;
      // send event about confirmation counselor in-office
      if (this.getCounselorModality() === 'In-Office') {
        this.analytics.trackEvent('Counseling in-office');
      }
      this.appointmentservice.provider = this.counselor;
      if (this.isCoachingCase) {
        this.caseMngt.assignToCoach(this.counselor);
      } else {
        this.caseMngt.assignToProvider(this.counselor);
      }
      // this.router.navigate(['/pages/dashboard/counselor-contact']);
    } else {
      this.analytics.trackEvent('Counseling not-in-office');
      return;
    }
  }

  validateAddress() {
    const [primaryAddress] = this.user.addresses;
    const { city, state, country } = primaryAddress;
    if (city && state && country) {
      // continue with selection
      this.selectCounselor();
    } else {
      // fill out address
      this.showAddressForm();
    }
  }

  showAddressForm() {
    this.modalService.showAddressFormModal(
      SizeModal.LARGE,
      {
        context: {
          user: this.user,
          serviceType: SERVICE_TYPE.PROFILE,
          isShipping: false,
        },
      },
      this.continueAddressSubmission.bind(this),
      true
    );
  }

  continueAddressSubmission(data) {
    if (data && data.user && data.answer === 'Yes') {
      this.user = data.user;
      this.selectCounselor();
    }
  }

  checkCoaching() {
    if (this.selected.modeOfHelp === 'coaching') {
      this.isCoachingCase = true;
    }
  }

  showCounselorDetails() {
    this.modalService.showCounselorDetailsModal(
      SizeModal.LARGE,
      {
        context: {
          provider: this.counselor,
          isCoach: this.isCoachingCase,
          selection: this.getCounselorModality(),
          user: this.user,
          modality: this.modality,
        },
      },
      this.convertCase.bind(this),
      true
    );
  }

  convertCase() {
    return '';
  }
}
