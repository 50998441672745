import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
// import { CarouselModule } from 'ngx-owl-carousel-o';
// time before idle timeout
var IDLE_TIME = environment.idleTime;
var TIMEOUT = 10;
var cookieConfig = {
    'cookie': {
        'domain': window.location.hostname,
    },
    'palette': {
        'popup': {
            'background': '#252e39',
        },
        'button': {
            'background': '#14a7d0',
        },
    },
    'theme': 'classic',
    // "type": "informational",
    'position': 'bottom',
    // "static": true,
    'content': {
        'message': "This website collects cookies, which are defined as small data sets that are sent by our website\n    and stored on your device during your browsing session. These cookies are used to collect information about\n    how you interact with our website and allow us to remember you in order to improve and customize your browsing\n    experience while on the mobile web app and for analytics using Google Analytics. If you decline to accept our\n    cookies, a single session cookie will be used in your browser to remember your preferences during that session\n    only. Please refer to your browser settings for more information about how to manage your cookies.",
        'dismiss': 'Got it!',
        // "deny": "Refuse cookies",
        'link': 'Learn more',
        'href': '/#/auth/privacy',
    },
};
var ɵ0 = environment.basePath;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
