var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { UserService } from './users.service';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "./users.service";
import * as i2 from "ngx-cookie-service/cookie-service/cookie.service";
var BabyKitService = /** @class */ (function () {
    function BabyKitService(userService, cookieService) {
        this.userService = userService;
        this.cookieService = cookieService;
    }
    BabyKitService.prototype.updateUserBabyKit = function (user, contract, org, isHomeAddressSame) {
        return __awaiter(this, void 0, void 0, function () {
            var addresses, userObj, primaryAddress, filteredUser, updateUser, data, requestData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        addresses = user.addresses, userObj = __rest(user, ["addresses"]);
                        primaryAddress = addresses[0];
                        filteredUser = isHomeAddressSame ? user : userObj;
                        updateUser = __assign({}, filteredUser, { babyKitRequest: {
                                contractId: contract.id,
                                submitted: new Date().toISOString(),
                                shipTo: user.fullName,
                                shippingAddress: __assign({}, primaryAddress),
                            } });
                        return [4 /*yield*/, this.userService.updateUser(updateUser).toPromise()];
                    case 1:
                        data = (_a.sent()).data;
                        requestData = this.gatherRequestData(data, primaryAddress, contract, org);
                        this.userService.requestWorkLifeServices(requestData).subscribe();
                        return [2 /*return*/, data];
                }
            });
        });
    };
    BabyKitService.prototype.gatherRequestData = function (user, address, contract, org) {
        return {
            userId: user.id,
            internalId: user.internalId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            dateOfBirth: user.dateOfBirth,
            gender: user.gender,
            allowedHomePhoneMessage: user.allowedHomePhoneMessage ? 'Yes' : 'No',
            allowEmailMessage: user.allowEmailMessage ? 'Yes' : 'No',
            phoneNumber: this.userService.getpreferredPhoneNumber(user),
            preferredPhone: user.preferredPhone,
            address: address,
            preferredComunicationMethod: user.preferredCommunicationMethod || '',
            employerInformation: {
                companyName: this.cookieService.get('companyName') || '',
                companySiteLocation: this.cookieService.get('companySiteLocation') || '',
                companyAffiliation: this.cookieService.get('companyAffiliation') || '',
            },
            organization: { id: org.id, name: org.name },
            currentContract: { id: contract.id, orgId: org.id, onSiteEnabled: contract.onSiteEnabled || false },
            chosenSiteLocation: { name: this.cookieService.get('companySiteLocation') },
            companyName: org.name,
            workLifeCategory: 'parenting-advice',
            workLifeSubCategory: 'Concern\'s New Baby Kit',
            workLifeCategoryOption: 'parenting-advice',
            submittedDate: new Date().toISOString(),
            description: '',
            modeOfHelp: '',
            carePath: 'work-life',
        };
    };
    BabyKitService.ngInjectableDef = i0.defineInjectable({ factory: function BabyKitService_Factory() { return new BabyKitService(i0.inject(i1.UserService), i0.inject(i2.CookieService)); }, token: BabyKitService, providedIn: "root" });
    return BabyKitService;
}());
export { BabyKitService };
