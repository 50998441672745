import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export const ConfirmationModal = {
  Yes: 'Yes',
  No: 'No',
};

export enum SizeModal {
  LARGE = 'lg',
  MEDIUM = 'sm',
};
export class ModalOptions {
  modalHeader?: string = '';
  firstMessage?: string = '';
  secondMessage?: string = '';
  modalContent?: string = '';
  options: boolean = true;
  context?: any = {};

  constructor(header) {
    this.modalHeader = header;
    this.options = true;
  }
}

export enum SERVICE_TYPE {
  BABYKIT = 'babykit',
  PROFILE = 'profile',
};

@Component({
  selector: 'ngx-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  modal: ModalOptions;

  constructor(private activeModal: NgbActiveModal) { }

  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }

  accept(): void {
    this.activeModal.close(ConfirmationModal.Yes);
  }
}
