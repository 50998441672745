import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-moment-support',
  templateUrl: './moment-support.component.html',
  styleUrls: ['./moment-support.component.scss'],
})
export class MomentSupportComponent implements OnInit {

  chatOpen: boolean = false;
  helpOpen: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  helpOptionClicked() {
    this.helpOpen = !this.helpOpen;
    if (this.chatOpen) {
      this.chatOpen = false;
    }
  }

  chatOptionClicked() {
    this.chatOpen = !this.chatOpen;
    if (this.helpOpen) {
      this.helpOpen = false;
    }
  }
}
