<nb-card>
  <nb-card-body class="flex-centered">
    <nb-auth-block class="col-12">
      <ngx-intake-header title="Account Deactivated" styleClasses="mt-0"></ngx-intake-header>
      <div class="row justify-content-center no-gutters">
        <div *ngIf="type == 'register';else login">
          <div class="row justify-content-center no-gutters">
            <div class="form-text sub-title col-10">
              Your account has been deactivated because you haven’t logged on to the Concern platform within a year. Please reset your password to unlock your account.
            </div>
          </div>
          <div class="row justify-content-center no-gutters">
            <a class="link" [routerLink]="'/auth/request-password'">
              REQUEST PASSWORD CHANGE
            </a>
          </div>
        </div>
    
        <ng-template #login>
          <div class="form-text sub-title col-10">
            Your account has been deactivated because you haven’t logged on to the Concern platform within a year. Please check your email for a link to reactivate your account.
          </div>
        </ng-template>
      
      </div>
      <br/>
    </nb-auth-block>
    </nb-card-body>
    </nb-card>