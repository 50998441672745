import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../../../@core/data/users.service';
import { ConfirmationModal, ModalOptions } from '../modal.component';
import { Address } from '../../../../../@core/data';
import { GeocodeingService } from '../../../../../pages/dashboard/display-appointment/geocodingService';
import { GeocodedAddress } from '../../../../../pages/dashboard/display-appointment/models/GeocodedAddress';

const caselessCompare = (left, right) => (left || '').toUpperCase() === (right || '').toUpperCase();

export const isValidAddress: any = address => address.street && address.city && address.state && address.country;

export function trimAddressComponent(address) {
  address.street = address.street ? address.street.trim() : '';
  address.city = address.city ? address.city.trim() : '';
  address.state = address.state ? address.state.trim() : '';
  address.postal = address.postal ? address.postal.trim() : '';
  address.country = address.country ? address.country.trim() : 'USA';
  return address;
}

export function addressesAreSame(savedAddress, inputAddress) {
  return (
    caselessCompare(savedAddress.street, inputAddress.street) &&
    caselessCompare(savedAddress.city, inputAddress.city) &&
    caselessCompare(savedAddress.state, inputAddress.state) &&
    caselessCompare(savedAddress.postal, inputAddress.postal) &&
    caselessCompare(savedAddress.country, inputAddress.country)
  );
}

export function clearAddress(address) {
  address.street = '';
  address.city = '';
  address.state = '';
  address.postal = '';
  address.country = '';
}

@Component({
  selector: 'ngx-address-sou-modal',
  templateUrl: './address-sou-modal.component.html',
  styleUrls: ['./address-sou-modal.component.scss'],
})
export class AddressSouModalComponent {
  _modal: ModalOptions = { context: {}, modalHeader: '', options: false };

  get modal(): ModalOptions { return this._modal; }
  set modal(value: ModalOptions) {
    this._modal = value;

    this.user = { ...value.context.user, addresses: value.context.user.addresses.map(item => ({ ...item })) };

    this.checkPrimaryAddress();
  }

  user: any = {};
  messages: string[] = [];
  errors: string[] = [];

  primaryAddressIndex: any;
  address: Address = {};

  agreeToSou: boolean = false;
  addressNotFilledIn: boolean = false;
  suggestedAddresses: GeocodedAddress[] = [];
  originalAddress: Address = {};
  userAddress: string = '';
  formattedOriginalAddress: string = '';

  get souRequired() { return this.modal.context.sou && !this.agreeToSou; }
  get addressRequired() { return this.modal.context.address && this.addressNotFilledIn; }

  constructor(private userService: UserService, private activeModal: NgbActiveModal, private geoService: GeocodeingService) { }

  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }

  accept(): void {
    this.checkPrimaryAddress();

    if (!this.addressRequired && !this.souRequired) {
      this.activeModal.close(ConfirmationModal.Yes);
    }
  }

  private checkPrimaryAddress() {
    this.primaryAddressIndex = this.user.addresses.findIndex(add => add.name === 'Primary');
    this.address = this.user.addresses[this.primaryAddressIndex];
    this.addressNotFilledIn = !isValidAddress(trimAddressComponent(this.address));
  }

  validateSavedAddress() {
    this.parseAddress();
    this.messages = [];
    this.errors = [];

    this.userService
      .updateUser(this.user)
      .toPromise()
      .then(result => this.afterUserUpdated(result.data))
      .catch(error => (this.errors = ['Your address could not be updated.']));
  }
  parseAddress() {
    const [street, city, postState, country] = this.userAddress.split(',');
    const [state, postal] = postState.trim().split(' ');

    Object.assign(this.user.addresses[0], {
      street: street.trim(),
      city: city.trim(),
      state: state,
      postal: postal,
      country: country.trim(),
    });
  }

  validateUserAddress() {
    this.originalAddress = this.user.addresses[0];
    this.geoService.getGeocodeFromAddress(this.originalAddress)
      .subscribe((data) => {
        const filtered = data.results.filter((address) => {
          if (this.isGeoAddressValid(address)) {
            return address;
          }
        });
        this.formattedOriginalAddress = `${this.originalAddress.street}, ${this.originalAddress.city},
                 ${this.originalAddress.state} ${this.originalAddress.postal}, ${this.originalAddress.country}`;

        // if returned address equals user input, just save
        if (filtered.length === 1 &&
          filtered[0].formatted_address === this.formattedOriginalAddress.replace(/\n/, ' ').replace(/\s\s+/, ' ')) {
          this.userAddress = this.formattedOriginalAddress;
          this.validateSavedAddress();
        } else if (filtered.length === 0) {
          // f no address is returned, save nonetheless
          this.userAddress = this.formattedOriginalAddress;
          this.validateSavedAddress();
        } else {
          // if there are addresses, suggest them
          this.userAddress = filtered[0].formatted_address;
          this.suggestedAddresses = filtered;
        }
      });
  }

  isGeoAddressValid(address: any): boolean {
    const validcomponent = address.address_components.filter(a => a.types.includes('postal_code'));

    if (validcomponent.length > 0 &&
      (address.types.includes('route') ||
        address.types.includes('street_address') ||
        address.types.includes('premise'))) {
      return true;
    }
    return false;
  }

  private afterUserUpdated(updated: any) {
    Object.assign(this.modal.context.user, updated);
    this.accept();
  }
}
