<div class="sub-heading">
    We couldn't verify your address. Please choose or edit which version of the address you want to use.
</div>

<div class="address-headings">Original Address</div>
<div class="form-check row">
  <input class="form-check-input col-1" type="radio" name="address" [id]="original" [value]="original" [ngModel]="chosenAddress" (ngModelChange)="addressChange.emit($event)">
  <label class="form-check-label col-11 text-left" [for]="original">
    {{ original }}
  </label>
</div>

<div class="address-headings">Suggested Address</div>
<div class="form-check row" *ngFor="let address of suggested; let i = index">
  <input class="form-check-input col-1" type="radio" name="address" [id]="address.formatted_address" [value]="address.formatted_address" [ngModel]="chosenAddress" (ngModelChange)="addressChange.emit($event)">
  <label class="form-check-label col-11 text-left" [for]="address.formatted_address">
    {{ address.formatted_address }}
  </label>
</div>