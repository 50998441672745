import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { IntakeFlowService } from '../../@core/utils/intake-flow.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-landing-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  responsive = false;
  toggler: Boolean = false;
  subHeaderText: String;
  headerText: String;
  buttonText: String;
  buttonTextOutline: String;
  getHelpLink: String;
  enableMaintenanceMode: Boolean = environment.enableMaintenanceMode;

  @ViewChild('exploreServicesBtn') exploreServices;

  @Output()
  learnMoreNav: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor(private intakeFlowService: IntakeFlowService) {
    this.buttonText = 'ACCESS SERVICES';
    this.headerText = 'Personalized Counseling, Life-Balance, and Emotional Wellbeing Resources';
  }

  ngOnInit() {
    // Check if company context is set
    if (this.intakeFlowService.getFormData().currentContract) {
      this.getHelpLink = '/pages/intake/care-path';
    } else {
      this.getHelpLink = '/pages/intake/care-path';
    }
  }

  makeResponsive() {
    this.responsive = !this.responsive;
  }

  public emitLearnMoreEvent() {
    this.toggler = !this.toggler;
    this.learnMoreNav.emit(this.toggler);
  }
}
