import { Injectable } from '@angular/core';
import {
    CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot,
} from '@angular/router';
import { EmotionalWellbeingSurveyService } from './emotional-wellbeing-survey.service';


@Injectable()
export class EmotionalSurveyGuardService implements CanActivate {
    constructor(private router: Router, private emotionalWellbeing: EmotionalWellbeingSurveyService) { }

    canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const path: string = router.routeConfig.path;
        return this.verifyWorkflow(path);
    }

    verifyWorkflow(path: string): boolean {
        const invalidStep = this.emotionalWellbeing.getFirstInvalidStep(path);

        if (invalidStep.length > 0) {
            // redirect to invalid step
            const url = `/pages/emotional-wellbeing-survey/${invalidStep}`;
            this.router.navigate([url]);
            return false;
        }
        return true;
    }
}
