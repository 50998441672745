import * as _ from 'lodash';
import * as i0 from "@angular/core";
;
var ProviderUiService = /** @class */ (function () {
    function ProviderUiService() {
    }
    ProviderUiService.prototype.formatProviderLicence = function (providerLicenses) {
        return _.chain(providerLicenses)
            .groupBy('clinicalLicenseType')
            .map(function (v, k) { return ({ clinicalLicenseType: k, state: _.uniq(v.map(function (z) { return z.stateProvince; })).join(', ') }); })
            .map(function (z) { return z.clinicalLicenseType + ': ' + z.state; })
            .value();
    };
    ProviderUiService.ngInjectableDef = i0.defineInjectable({ factory: function ProviderUiService_Factory() { return new ProviderUiService(); }, token: ProviderUiService, providedIn: "root" });
    return ProviderUiService;
}());
export { ProviderUiService };
