import { ProgressBarService } from './progress-bar.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./progress-bar.service";
;
export var STEPS = {
    // let the string value match the router path for convenient routing
    feelingsPath: 'feelings',
    stressPath: 'stress-life',
    satisfiedPath: 'satisfied-life',
    emotionThankyou: 'thankyou-survey',
};
var EmotionalFlowFormData = /** @class */ (function () {
    function EmotionalFlowFormData() {
        this.feelingsPath = '';
        this.stressPath = '';
        this.satisfiedPath = '';
        this.emotionThankyou = '';
        this.feelingsData = [];
        this.stressData = [];
        this.satisfiedData = [];
    }
    // suggestionData: string;
    // recommendPath: string = '';
    // mycounselerPath: string = '';
    // scheduledPath: string = '';
    // concernedPath: string = '';
    // suggestionPath: string = '';
    // loginPath: string = '';
    EmotionalFlowFormData.prototype.reset = function () {
        this.feelingsData = [];
        this.satisfiedData = [];
        this.stressData = [];
        // this.emotionThankyou = null;
    };
    EmotionalFlowFormData.prototype.clearCarePath = function () {
        this.feelingsPath = '';
        this.stressPath = '';
        this.satisfiedPath = '';
        this.emotionThankyou = '';
    };
    return EmotionalFlowFormData;
}());
export { EmotionalFlowFormData };
var EmotionalWellbeingSurveyService = /** @class */ (function () {
    function EmotionalWellbeingSurveyService(progressBarService) {
        this.progressBarService = progressBarService;
        this.formData = new EmotionalFlowFormData();
        this.approvalStageMessage = new BehaviorSubject('message from service');
        this.currentApprovalStageMessage = this.approvalStageMessage.asObservable();
        this.workflowSteps = [
            { step: STEPS.feelingsPath, valid: false },
            { step: STEPS.stressPath, valid: false },
            { step: STEPS.satisfiedPath, valid: false },
            { step: STEPS.emotionThankyou, valid: false },
        ];
    }
    EmotionalWellbeingSurveyService.prototype.validateStep = function (step) {
        var index = this.getWorkFlowStepIndex(step);
        if (index >= 0) {
            this.workflowSteps[index].valid = true;
        }
        ;
    };
    EmotionalWellbeingSurveyService.prototype.setCarePathProgress = function (step) {
        var blockNumber = this.workflowSteps.map(function (s) { return s.step; }).indexOf(step);
        var numOfSteps = this.workflowSteps.length; // for the employee information screen
        this.progressBarService.progress = { progress: blockNumber + 1, numberOfBlocks: numOfSteps, width: 50 };
    };
    EmotionalWellbeingSurveyService.prototype.getWorkFlowStepIndex = function (step) {
        return this.workflowSteps.findIndex(function (elem) { return elem.step === step; });
    };
    EmotionalWellbeingSurveyService.prototype.resetSteps = function () {
        // reset all the steps to invalid for start over.
        this.workflowSteps.forEach(function (elem) { elem.valid = false; });
    };
    EmotionalWellbeingSurveyService.prototype.resetFormData = function () {
        this.resetSteps();
        this.formData.reset();
        return this.formData;
    };
    EmotionalWellbeingSurveyService.prototype.getNextStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        if (stepIndex < 0 || (stepIndex + 1) >= this.workflowSteps.length)
            return '';
        return this.workflowSteps[stepIndex + 1].step;
    };
    EmotionalWellbeingSurveyService.prototype.updateApprovalMessage = function (message) {
        this.approvalStageMessage.next(message);
    };
    EmotionalWellbeingSurveyService.prototype.getPreviousStep = function (step) {
        var stepIndex = this.getWorkFlowStepIndex(step);
        var workFlow = this.workflowSteps;
        if (stepIndex <= 0)
            return 'feelings';
        return workFlow[stepIndex - 1].step;
    };
    EmotionalWellbeingSurveyService.prototype.clearCarePath = function () {
        this.formData.clearCarePath();
    };
    EmotionalWellbeingSurveyService.prototype.getFirstInvalidStep = function (step) {
        // if all previous steps are valid, return blank, else return the first invalid step
        // used by the route guard.
        var inValidStep = '';
        this.workflowSteps.some(function (elem) {
            if (elem.step === step) {
                return true;
            }
            else {
                if (!elem.valid) {
                    inValidStep = elem.step;
                    return true;
                }
            }
        });
        return inValidStep;
    };
    Object.defineProperty(EmotionalWellbeingSurveyService.prototype, "feelingsData", {
        get: function () {
            return this.formData.feelingsData;
        },
        set: function (feelings) {
            this.formData.feelingsData = feelings;
            this.validateStep(STEPS.feelingsPath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmotionalWellbeingSurveyService.prototype, "satisfiedLifeData", {
        get: function () {
            return this.formData.satisfiedData;
        },
        set: function (satisfiedData) {
            this.formData.satisfiedData = satisfiedData;
            this.validateStep(STEPS.satisfiedPath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmotionalWellbeingSurveyService.prototype, "stressLifeData", {
        get: function () {
            return this.formData.stressData;
        },
        set: function (stressData) {
            this.formData.stressData = stressData;
            this.validateStep(STEPS.stressPath);
        },
        enumerable: true,
        configurable: true
    });
    EmotionalWellbeingSurveyService.ngInjectableDef = i0.defineInjectable({ factory: function EmotionalWellbeingSurveyService_Factory() { return new EmotionalWellbeingSurveyService(i0.inject(i1.ProgressBarService)); }, token: EmotionalWellbeingSurveyService, providedIn: "root" });
    return EmotionalWellbeingSurveyService;
}());
export { EmotionalWellbeingSurveyService };
