/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./home.component";
import * as i5 from "../../@core/utils/intake-flow.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "row masthead justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "container ie-landing-home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12 col-xl-2 col-md-8 col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome to Concern"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12 col-xl-8 col-md-10 col-sm-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [["class", "landing-text header-text"], ["style", "margin-top: 100px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Under Maintenance"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", " container row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "col-12 text-center "]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "col-12 text-center"]], null, null, null, null, null))], null, null); }
function View_HomeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Getting Started "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Click on EXPLORE SERVICES to use Concern\u2019s Digital Access, then answer a few questions to get help with counseling, self-help, or life balance services, and get personalized recommendations tailored to fit your needs. "]))], null, null); }
function View_HomeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [["class", "row masthead justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 24, "div", [["class", "container ie-landing-home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-12 welcome"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Welcome to Concern "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "row justify-content-center header-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h1", [["class", "landing-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Personalized Counseling, Life-Balance,"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [["class", "landing-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["and Emotional Wellbeing Resources"])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["homepageInstructionsStepOne", 2]], null, 0, null, View_HomeComponent_4)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "col-12 text-center stepContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, [[1, 0], ["exploreServicesBtn", 1]], null, 2, "a", [["class", "btn button-text btn-outline-secondary"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵeld(18, 0, null, null, 7, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 5, "p", [["class", "landing-text account-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Already have an account? "])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "a", [["class", "sing-in"], ["routerLink", "../auth/login"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Sign in"])), (_l()(), i1.ɵted(-1, null, [" now "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.getHelpLink, ""); _ck(_v, 16, 0, currVal_2); var currVal_6 = "../auth/login"; _ck(_v, 23, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 16).target; var currVal_1 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_0, currVal_1); var currVal_3 = _co.buttonText; _ck(_v, 17, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 23).target; var currVal_5 = i1.ɵnov(_v, 23).href; _ck(_v, 22, 0, currVal_4, currVal_5); }); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { exploreServices: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["maintenace", 2]], null, 0, null, View_HomeComponent_2)), (_l()(), i1.ɵand(0, [["normal", 2]], null, 0, null, View_HomeComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enableMaintenanceMode; var currVal_1 = i1.ɵnov(_v, 3); var currVal_2 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-landing-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i4.HomeComponent, [i5.IntakeFlowService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("ngx-landing-home", i4.HomeComponent, View_HomeComponent_Host_0, {}, { learnMoreNav: "learnMoreNav" }, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
