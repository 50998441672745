import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../modal.component';
import { environment } from '../../../../../../environments/environment';

const EMLIFE_MODAL_FLAG = 'EMLIFE_MODAL_FLAG';

@Component({
  selector: 'ngx-emindful-modal',
  templateUrl: './emindful-modal.component.html',
  styleUrls: ['./emindful-modal.component.scss'],
})
export class EmindfulModalComponent implements OnInit {

  linkDomain: string = environment.resHubDomain;

  constructor(
    private activeModal: NgbActiveModal,
    ) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close({ answer: ConfirmationModal.No });
  }

  accept() {
      this.activeModal.close({ answer: ConfirmationModal.Yes });
  }

  changeModalFlag(event) {
    const { checked } = event.target;
    if (checked)
      window.localStorage.setItem(EMLIFE_MODAL_FLAG, 'hide');
    else
      window.localStorage.removeItem(EMLIFE_MODAL_FLAG);
  }
}
