/**
 * Service used for calculating risk based on answers to personal questions
 * captured in emotional care path
 */
import { Injectable } from '@angular/core';
import { IntakeFlowService } from './intake-flow.service';
import { RISK} from './enums';

// scores
const NO_SCORE = 0;
const LOW_SCORE = 1;
const MEDIUM_SCORE = 2;
const HIGH_SCORE = 3;
const HIGHEST_SCORE = 4;

// life-satisfy
const SAT_SCORE_MAP = {
    'Very Satisfied': LOW_SCORE,
    'Slightly Satisfied': MEDIUM_SCORE,
    'Slightly Dissatisfied': HIGH_SCORE,
    'Very Dissatisfied': HIGHEST_SCORE,
};
// stress
const STRESS_SCORE_MAP = {
    'Never': NO_SCORE,
    'Only a Little': LOW_SCORE,
    'Sometimes': MEDIUM_SCORE,
    'Fairly Often': HIGH_SCORE,
    'Very Often': HIGHEST_SCORE,
}

const PHQ_SCORE_MAP = {
    'Not at All': NO_SCORE,
    'Several Days': LOW_SCORE,
    'More Than Half the Days': MEDIUM_SCORE,
    'Almost Everyday': HIGH_SCORE,
};

@Injectable()
export class RiskCalcService {

    private moodFrequencies: string[] = []; // PHQ-4 Responses
    private riskOptions: string = ''; // Risk Gauge Screen - RG
    private lifeSatisfyData: string = '';
    private stressData: string = '';
    public moodFrequency: string = '';

    constructor(private intakeFlowService: IntakeFlowService) { }
    // gather data from intake service
    private gatherData() {
        this.riskOptions = this.intakeFlowService.riskOptions;
        this.moodFrequency = this.intakeFlowService.moodFrequencies;
        this.moodFrequencies = Object.values(this.moodFrequency);
        this.lifeSatisfyData = this.intakeFlowService.lifeSatisfaction;
        this.stressData = this.intakeFlowService.stressIntakeSurvey;
    }

    // returns The RISK (in string) from calculated score
    private scoreToRisk(EMOTIONAL_TOTAL: number): RISK {
        if (this.riskOptions === 'No') {
            if (EMOTIONAL_TOTAL <= 8) {
                return RISK.LOW;
            } else if (EMOTIONAL_TOTAL >= 8 && EMOTIONAL_TOTAL < 15) {
                return RISK.MEDIUM;
            } else {
                return RISK.HIGH;
            }

        } else {
            return RISK.HIGH;
        }

    }


    private getMoodFrequencyScore(index): number {
        return PHQ_SCORE_MAP[this.moodFrequencies[index]];
    }

    private getSatisfactionIntensityScore(): number {
        return SAT_SCORE_MAP[this.lifeSatisfyData]
    }
    private getStressIntensityScore(): number {
        return STRESS_SCORE_MAP[this.stressData];
    }

    // Calculate Risk from responses
    calculateRisk(): RISK {
        this.gatherData();
        const PHQ2_1_Score = this.getMoodFrequencyScore(0);
        const PHQ2_2_Score = this.getMoodFrequencyScore(1);
        const PHQ2_3_Score = this.getMoodFrequencyScore(2);
        const PHQ2_4_Score = this.getMoodFrequencyScore(3);
        const PHQ_Total = PHQ2_1_Score + PHQ2_2_Score + PHQ2_3_Score + PHQ2_4_Score;
        const SAT_1_SCORE = this.getSatisfactionIntensityScore();
        const STRESS_1_SCORE = this.getStressIntensityScore();
        const EMOTIONAL_TOTAL = PHQ_Total + SAT_1_SCORE + STRESS_1_SCORE;

        return this.scoreToRisk(EMOTIONAL_TOTAL);
    }

    checkScore() {
        const PHQ2_1_Score = this.getMoodFrequencyScore(0);
        const PHQ2_2_Score = this.getMoodFrequencyScore(1);
        const PHQ2_3_Score = this.getMoodFrequencyScore(2);
        const PHQ2_4_Score = this.getMoodFrequencyScore(3);
        const PHQ_Total = PHQ2_1_Score + PHQ2_2_Score + PHQ2_3_Score + PHQ2_4_Score;
        const SAT_1_SCORE = this.getSatisfactionIntensityScore();
        const STRESS_1_SCORE = this.getStressIntensityScore();
        const EMOTIONAL_TOTAL = PHQ_Total + SAT_1_SCORE + STRESS_1_SCORE;
        this.intakeFlowService.totalScore = EMOTIONAL_TOTAL;
        return EMOTIONAL_TOTAL;
    }



}

