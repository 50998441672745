import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Configuration } from './configuration';
import { providerRoutes, removeFalsyProps } from './api/constants';
import { environment } from '../../../environments/environment';
;
;
var CounselorService = /** @class */ (function () {
    function CounselorService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = environment.basePath;
        this.configuration = new Configuration();
        this.onSiteProviders = new BehaviorSubject(null);
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    CounselorService.prototype.getOnsiteCounselors = function (orgId) {
        var _this = this;
        if (!!orgId) {
            var providersByOrgPath = providerRoutes.getProvidersByOrg.replace(':orgId', orgId);
            this.httpClient.get("" + this.basePath + providersByOrgPath)
                .subscribe(function (providers) { return _this.onSiteProviders.next(providers); });
        }
    };
    CounselorService.prototype.getCounselorsAPI = function (providersId) {
        return this.httpClient.get("" + environment.lbPath + providerRoutes.getCounselorProvidersByIds + "?id=" + providersId);
    };
    CounselorService.prototype.findProviders = function (caseId, filter) {
        var cleanedFilter = Object.entries(removeFalsyProps(filter));
        var filterRecords = cleanedFilter.map(function (_a) {
            var key = _a[0], value = _a[1];
            return [key, String(value)];
        });
        var filterString = filterRecords.map(function (_a) {
            var key = _a[0], value = _a[1];
            return encodeURIComponent(key) + "=" + encodeURIComponent(value);
        }).join('&');
        var urlSearchParam = new URLSearchParams(filterString);
        return this.httpClient.get("" + environment.lbPath + providerRoutes.getProviders + "/" + caseId + "?" + urlSearchParam.toString());
    };
    CounselorService.prototype.findProviderSpecialization = function (state) {
        return this.httpClient.get("" + this.basePath + providerRoutes.getProviderBySpecialization + "?stateLicensed=" + state + "&clinicalPanel=BetterHelp");
    };
    return CounselorService;
}());
export { CounselorService };
