import { Component, Input } from '@angular/core';

@Component({
    selector: 'ngx-intake-header',
    styleUrls: ['./intake-header.component.scss'],
    template: `
    <div class="row justify-content-center {{styleClasses}} text-center">
        <h4 class="col-12 top header-text {{fontSize}} {{fontWeight}}" [ngClass]="{'urgent-red':showSpecialMsg===true}">
           {{title}}
            <ng-container *ngIf="tooltip">
                <ngx-instructions-tooltip [popOverMessage]="tooltip" [attachToCorner]="false"></ngx-instructions-tooltip>
            </ng-container>
        </h4>
    </div>
    `,
})
export class IntakeHeaderComponent {
    @Input() title: string;
    @Input() styleClasses: string = 'mt-4';
    @Input() tooltip: String;
    @Input() showSpecialMsg: boolean;
    @Input() fontSize: string = 'font-size-24';
    @Input() fontWeight: string = 'font-weight-400';

}
