
import { Observable, of } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { Content } from '../model/content';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { contentRoutes, providerRoutes } from './constants';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class ContentService {
    protected basePath: string = environment.basePath;

    constructor(private _httpClient: HttpClient,
        private domSanitizer: DomSanitizer,
        @Optional() @Inject(BASE_PATH) basePath, private cookieService: CookieService) {
        if (basePath) {
            this.basePath = basePath;
        }
    }

    saveContentRating({ contentId, isHelpful, isSaved }): Observable<any> {
        return this._httpClient.post<any>(`${this.basePath}${contentRoutes.postContentRating}`, { contentId: contentId, isHelpful, isSaved });
    }

    /*
       get all the self help recommended contents by case ids
    */
    getContentByCaseIds(caseIds: Array<String>, benefits: Array<String> = []): Observable<Array<any>> {
        const org = this.cookieService.check('companyName') ? this.cookieService.get('companyName') : '';
        return this._httpClient.post<Array<String>>(`${this.basePath}${contentRoutes.getRecommendedContents}`, { caseIds, org, benefits });
    }

    getContentById(id: string): Observable<Content> {
        return this._httpClient.get<any>(`${this.basePath}${contentRoutes.getContentById}?id=${id}`);
    }

    /** Get General Content */
    getGeneralContent(): Observable<Content[]> {
        return this._httpClient.get<any>(`${this.basePath}${contentRoutes.getGeneralContent}`);
    }

    cache: any = {};
    ring: any[] = [];
    max = 20;

    getProviderImage(providerId: any) {
        const item = this.cache[providerId];
        if (item) { return item };

        const added = this.cache[providerId] = this._httpClient.get(`${this.basePath}${providerRoutes.getProviderImage}/${providerId}`, { responseType: 'blob' })
        .pipe(
            map(blob => this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob))),
                catchError(err => of(null)),
        );
        this.ring.push(providerId);

        if (this.ring.length > this.max) {
            const removeId = this.ring.shift();
            delete this.cache[removeId];
        }

        return added;
    }

    getServiceContent(services: any): Observable<Content[]> {
        const organization = this.cookieService.check('companyName') ? this.cookieService.get('companyName') : '';
        return this._httpClient.post<any>(`${environment.basePath}${contentRoutes.getServiceContent}`, { services, organization });
    }

    getFAQ(): Observable<Array<Content>> {
        return this._httpClient.get<any>(`${this.basePath}${contentRoutes.getFAQ}`);
    }
}
