import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

const FULL_WIDTH_COL: number = 12;
const SMALL_WIDTH_COL: number = 3;
const WIDTH_BREAK: number = 3;

@Component({
  selector: 'ngx-multi-selector',
  templateUrl: './multi-selector.component.html',
  styleUrls: ['./multi-selector.component.scss'],
})
export class MultiSelectorComponent implements OnInit {
  private _items: string[] = [];
  col_number: number;

  @Input() selectedIndices: number[] = [];
  @Input() clearAllIndex: number = -1; // used to identify a selection that should stand on its own in the multi-selector
  @Input() selectionLimit: number = 0;
  @Output() selectedIndicesChange = new EventEmitter<number[]>();

  get items() {
    return this._items;
  }

  @Input()
  set items(elems: string[]) {
    this._items = elems;
    this.setColumnNumber();
  }

  constructor() { }

  ngOnInit() {
    if (this.selectionLimit > 0 && this.selectedIndices.length > this.selectionLimit) {
      this.selectedIndices = this.selectedIndices.slice(this.selectedIndices.length - this.selectionLimit);
    }
  }

  onClick(index: number) {
    if (!this.selectedIndices.includes(index)) {
      if (this.isClearIndexIncluded(index)) {
        this.selectedIndices = [];
      }
      this.selectedIndices.push(index);
      if (this.selectionLimit > 0 && this.selectedIndices.length > this.selectionLimit) {
        this.selectedIndices.shift();
      }
    } else {
      const position = this.selectedIndices.indexOf(index);
      this.selectedIndices.splice(position, 1);
    }
    this.selectedIndicesChange.emit(this.selectedIndices);
  }

  isSelected(index: number) {
    const result = this.selectedIndices.includes(index);
    return result;
  }

  /** Creates layout of selectors based on bootstrap
  If number of items is divisible by 3, layout would be 3 in a row
  otherwise, they are stacked one by one**/
  setColumnNumber() {
    this.col_number = this.items.length % WIDTH_BREAK === 0
      ? SMALL_WIDTH_COL : FULL_WIDTH_COL;
  }
  // check if clearAllIndex is set and is included in the selected selectedIndices
  isClearIndexIncluded(index) {
    return index === this.clearAllIndex || this.selectedIndices.includes(this.clearAllIndex);
  }

}
