import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-cloud-button',
  templateUrl: './cloud-button.component.html',
  styleUrls: ['./cloud-button.component.scss'],
})
export class CloudButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
