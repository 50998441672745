var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
export var Dimension;
(function (Dimension) {
    Dimension["COMPANY"] = "company";
    Dimension["LOCATION"] = "location";
    Dimension["AFFILIATION"] = "affiliation";
    Dimension["USERID"] = "userid";
    // THE DIMENSIONS IN THIS BLOCK WILL BE MAPPED OVER THE label FIELD WHEN SENDING EVENTS
    Dimension["RISK_STRATIFICATION"] = "risk_stratification";
    Dimension["RISK_PHQ_2"] = "risk_phq_2";
    Dimension["RISK_GAD_7"] = "risk_gad_7";
    Dimension["RISK_PHQ_9"] = "risk_phq_9";
    Dimension["PRIMARY_ASSESSED_PROBLEMS"] = "primary_assessed_problems";
    Dimension["MENTAL_EMOTIONAL"] = "mental_emotional";
    Dimension["WORK_LIFE"] = "work_life";
    Dimension["CONTENT_VIEW_RATING"] = "content_view_rating";
    Dimension["RECOMMENDATION"] = "recommendation";
    Dimension["STAGE"] = "stage";
    Dimension["RISK_QOL_4"] = "risk_qol_4";
    Dimension["RISK_WOS_5"] = "risk_wos_5";
    Dimension["RISK_PSS_4"] = "risk_pss_4";
    // END BLOCK
})(Dimension || (Dimension = {}));
export var DimMapping = {
    eventLabel: [
        Dimension.RISK_STRATIFICATION,
        Dimension.RISK_PHQ_2,
        Dimension.RISK_GAD_7,
        Dimension.RISK_PSS_4,
        Dimension.RISK_PHQ_9,
        Dimension.RISK_QOL_4,
        Dimension.RISK_WOS_5,
        Dimension.PRIMARY_ASSESSED_PROBLEMS,
        Dimension.MENTAL_EMOTIONAL,
        Dimension.WORK_LIFE,
        Dimension.RECOMMENDATION,
        Dimension.STAGE,
    ],
};
export var GA_COMMANDS = {
    ACTION: {
        CREATE: 'create',
        SEND: 'send',
        SET: 'set',
    },
    TYPE: {
        EVENT: 'event',
        PAGEVIEW: 'pageview',
    },
    EVENTS: {
        'Login': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'login'
        },
        'Request-Password': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'request-password'
        },
        'Reset-Password': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'reset-password'
        },
        'Logout': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'logout'
        },
        'Registration': {
            'event': 'user management',
            'eventAction': 'registration',
            'eventLabel': 'success'
        },
        'History': {
            'event': 'History',
            'eventAction': 'Click',
            'eventLabel': 'Show all Services used'
        },
        'More_Provider_Profile': {
            'event': 'Counseling',
            'eventAction': 'More details',
            'eventLabel': 'Provider Profile'
        },
        'in-office': {
            'event': 'Counseling',
            'eventAction': 'Select completed',
            'eventLabel': 'in-office'
        },
        'Less_Provider_Profile': {
            'event': 'Counseling',
            'eventAction': 'Less details',
            'eventLabel': 'Provider Profile'
        },
        'Counselors': {
            'event': 'Counseling',
            'eventAction': 'Recommendation',
            'eventLabel': 'Counselors'
        },
        'User registered': {
            'event': 'user management',
            'eventAction': 'registration',
            'eventLabel': 'success',
            'eventValue': 1,
        },
        'User logged in': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'login',
        },
        'User logged in via SSO': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'sso-login',
        },
        'User changing password.': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'change password',
        },
        'User logged out': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'logout',
        },
        'User request password reset': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'request-password',
        },
        'User reset password': {
            'event': 'user management',
            'eventAction': 'authentication',
            'eventLabel': 'reset-password',
        },
        'doesn\'t apply to me': {
            'event': 'intake',
            'eventAction': 'speed bump',
            'eventLabel': 'doesn\'t apply to me',
        },
        'need help now': {
            'event': 'intake',
            'eventAction': 'immediate care',
            'eventLabel': 'need help now',
        },
        'Primary Assessed problems': {
            'event': 'intake',
            'eventAction': 'presenting problem',
            'eventLabel': 'emotional states',
        },
        'Mental/Emotional': {
            'event': 'intake',
            'eventAction': 'emotions feeling',
            'eventLabel': 'emotions feeling',
        },
        'Work Life': {
            'event': 'intake',
            'eventAction': 'workLife service',
            'eventLabel': 'work life selection',
        },
        'Risk stratification': {
            'event': 'intake',
            'eventAction': 'risk stratification',
            'eventLabel': 'risk level',
        },
        'PHQ-2 at intake': {
            'event': 'intake',
            'eventAction': 'PHQ-2',
            'eventLabel': 'risk level',
        },
        'GAD-7 at baseline': {
            'event': 'mood survey',
            'eventAction': 'GAD-7',
            'eventLabel': 'risk level',
        },
        'PSS-4 at baseline': {
            'event': 'stress assesment',
            'eventAction': 'PSS-4',
            'eventLabel': 'risk level',
        },
        'PHQ-9 at baseline': {
            'event': 'mood survey',
            'eventAction': 'PHQ-9',
            'eventLabel': 'risk level',
        },
        'QOL-4 at baseline': {
            'event': 'qol survey',
            'eventAction': 'QOL-4',
            'eventLabel': 'risk level',
        },
        'WOS-5 at baseline': {
            'event': 'qol survey',
            'eventAction': 'WOS-5',
            'eventLabel': 'risk level',
        },
        'Counseling video': {
            'event': 'counseling',
            'eventAction': 'confirm',
            'eventLabel': 'video',
        },
        'Counseling video completed': {
            'event': 'counseling',
            'eventAction': 'confirm-completed',
            'eventLabel': 'video',
        },
        'Counseling in-office': {
            'event': 'counseling',
            'eventAction': 'select',
            'eventLabel': 'in-office',
        },
        'Counseling not-in-office': {
            'event': 'counseling',
            'eventAction': 'not-select',
            'eventLabel': 'not-in-office',
        },
        'Counseling in-office completed': {
            'event': 'counseling',
            'eventAction': 'select-completed',
            'eventLabel': 'in-office',
        },
        'Counseling Recommendation': {
            'event': 'counseling',
            'eventAction': 'recommentation',
            'eventLabel': 'counselors',
        },
        'Work Life Request': {
            'event': 'work-life',
            'eventAction': 'submit form',
            'eventLabel': 'request w/l services',
        },
        'Self Help Request': {
            'event': 'self-help',
            'eventAction': 'click',
            'eventLabel': 'request digital tools',
        },
        'Go To eM Life': {
            'event': 'eMLife',
            'eventAction': 'click eM Life link',
            'eventLabel': 'Request eM Life',
        },
        'In Person Counseling Request': {
            'event': 'In Person Counseling Request',
            'eventAction': 'click',
            'eventLabel': 'Request In person counseling',
        },
        'Online Counseling Request': {
            'event': 'Online Counseling Request',
            'eventAction': 'click',
            'eventLabel': 'Request online counseling',
        },
        'First Responder Request': {
            'event': 'First Responder Request',
            'eventAction': 'click',
            'eventLabel': 'Request First Responder counseling',
        },
        'Coaching Request': {
            'event': 'Coaching Request',
            'eventAction': 'click',
            'eventLabel': 'Request Coaching',
        },
        'Text Therapy Request': {
            'event': 'Text Therapy',
            'eventAction': 'click',
            'eventLabel': 'Request Text Therapy',
        },
        'Switching providers': {
            'event': 'Switching providers',
            'eventAction': 'click',
            'eventLabel': 'Switching providers',
        },
        'Switching to In-Person from Online': {
            'event': 'Switching to In-Person from Online',
            'eventAction': 'click',
            'eventLabel': 'Switch to In Person Provider',
        },
        'Switching to online counseling': {
            'event': 'Switching to online counseling',
            'eventAction': 'click',
            'eventLabel': 'Switch to Online Provider',
        }
    },
};
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(location, router, cookieService) {
        this.location = location;
        this.router = router;
        this.cookieService = cookieService;
        this.window = window;
        this.enabled = true;
        this.propertyId = environment.trackerId;
        ga(GA_COMMANDS.ACTION.CREATE, this.propertyId, 'auto', {
            userId: this.cookieService.get('userId') || '',
        });
        this.setCompany(this.cookieService.get('companyName') || '');
        this.setCompanyId(this.cookieService.get('companyId') || '');
        this.setLocation(this.cookieService.get('companySiteLocation') || '');
        this.setAffiliation(this.cookieService.get('companyAffiliation') || '');
        this.setUserID(this.cookieService.get('userId') || '');
    }
    AnalyticsService.prototype.trackPageViews = function () {
        var _this = this;
        if (this.enabled) {
            this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }))
                .subscribe(function () {
                ga(GA_COMMANDS.ACTION.SEND, { hitType: GA_COMMANDS.TYPE.PAGEVIEW, page: _this.location.path() });
                _this.sendUniNavTracking();
            });
        }
    };
    AnalyticsService.prototype.setCompany = function (companyName) {
        this.cookieService.set('companyName', companyName || '');
        if (this.enabled) {
            ga(GA_COMMANDS.ACTION.SET, Dimension.COMPANY, companyName);
        }
    };
    AnalyticsService.prototype.setCompanyId = function (companyId) {
        this.cookieService.set('companyId', companyId || '');
    };
    AnalyticsService.prototype.removeCompany = function () {
        this.setCompany('');
    };
    AnalyticsService.prototype.setAffiliation = function (affiliation) {
        this.cookieService.set('companyAffiliation', affiliation || '');
        if (this.enabled) {
            ga(GA_COMMANDS.ACTION.SET, Dimension.AFFILIATION, affiliation);
        }
    };
    AnalyticsService.prototype.removeAffiliation = function () {
        this.setAffiliation('');
    };
    AnalyticsService.prototype.getLocation = function () {
        return this.cookieService.get('companySiteLocation');
    };
    AnalyticsService.prototype.setLocation = function (location) {
        this.cookieService.set('companySiteLocation', location || '');
        if (this.enabled) {
            ga(GA_COMMANDS.ACTION.SET, Dimension.LOCATION, location);
        }
    };
    AnalyticsService.prototype.removeLocation = function () {
        this.setLocation('');
    };
    AnalyticsService.prototype.trackEvent = function (event) {
        this.window.dataLayer.push(GA_COMMANDS.EVENTS[event]);
    };
    // send with custom dimention as rating value
    AnalyticsService.prototype.trackGenericEvent = function (category, action, label, value) {
        if (this.enabled) {
            if (value) {
                this.window.dataLayer.push({
                    'event': category,
                    'eventAction': action,
                    'eventLabel': label,
                    'eventValue': value
                });
            }
            else {
                this.window.dataLayer.push({
                    'event': category,
                    'eventAction': action,
                    'eventLabel': label,
                });
            }
        }
    };
    AnalyticsService.prototype.trackRecommendations = function (eventName, value) {
        var eventData = GA_COMMANDS.EVENTS[eventName];
        var dimensions = { 'eventValue': value, 'dimension12': value.toString() };
        this.trackEventDimension(eventData, dimensions);
    };
    AnalyticsService.prototype.setCustomDimentionValue = function (dimension, value) {
        if (this.enabled) {
            ga(GA_COMMANDS.ACTION.SET, dimension, value);
        }
    };
    AnalyticsService.prototype.setUserID = function (userId) {
        this.cookieService.set('userId', userId || '');
        if (this.enabled) {
            ga(GA_COMMANDS.ACTION.SET, 'userId', userId);
            this.setCustomDimentionValue(Dimension.USERID, userId);
        }
    };
    AnalyticsService.prototype.trackEventWithDimensions = function (eventName, dimension, values, extraDimensions) {
        var _this = this;
        if (values === void 0) { values = []; }
        if (extraDimensions === void 0) { extraDimensions = {}; }
        if (this.enabled) {
            var useAsLabel_1 = DimMapping.eventLabel.includes(dimension);
            values.forEach(function (custom) {
                var eventData = __assign({}, GA_COMMANDS.EVENTS[eventName]);
                if (useAsLabel_1) {
                    eventData.eventLabel = custom;
                }
                _this.trackEventDimension(eventData, __assign((_a = {}, _a[dimension] = custom, _a), extraDimensions));
                var _a;
            });
        }
    };
    AnalyticsService.prototype.trackEventDimension = function (event, dimension) {
        var mergedData = __assign({}, event, dimension);
        if (this.enabled && event) {
            this.window.dataLayer.push(mergedData);
        }
    };
    // uses the navocat object from the unified navigation bar
    // to send analytics to VARA.
    AnalyticsService.prototype.sendUniNavTracking = function () {
        var navocat = window['navocat'];
        if (navocat && navocat.lib && navocat.lib.collectorTrack) {
            navocat.lib.collectorTrack({
                path: this.location.path(),
            }, 'page');
        }
    };
    return AnalyticsService;
}());
export { AnalyticsService };
