<nb-layout>
    <ngx-header></ngx-header>
    <nb-layout-column class="landing">
        <div class="container-fluid home">
            <ngx-landing-home (learnMoreNav)="learnMoreNavFn($event);"></ngx-landing-home>
        </div>
        <div class="container">
            <ngx-landing-detail [learnMoreNavEvent]="learnMoreNavEvent"></ngx-landing-detail>
        </div>
        <div class="container-fluid med-gray">
            <ngx-landing-help></ngx-landing-help>
        </div>
        <div class="container">
            <ngx-landing-video></ngx-landing-video>
        </div>
        <div class="container-fluid">
            <ngx-landing-footer></ngx-landing-footer>
        </div>
        <!-- <ngx-floating-button></ngx-floating-button> -->
    </nb-layout-column>
</nb-layout>

