import { Component, Input, Optional } from '@angular/core';
import { Address } from '../../../../@core/data';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';

@Component({
  selector: 'ngx-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [NgForm, [new Optional(), NgModelGroup]],
      useFactory: (form: NgForm, group: NgModelGroup): ControlContainer => group || form,
    },
  ],
})
export class AddressComponent {
  @Input() primaryAddressIndex: number = -1;
  @Input() address: Address = {};

  constructor() {}
}
