/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-verified.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../reusables/intake-header/intake-header.component.ngfactory";
import * as i3 from "../../reusables/intake-header/intake-header.component";
import * as i4 from "../auth-block/auth-block.component.ngfactory";
import * as i5 from "../auth-block/auth-block.component";
import * as i6 from "@angular/common";
import * as i7 from "./email-verified.component";
import * as i8 from "@angular/router";
var styles_DxEmailVerifiedComponent = [i0.styles];
var RenderType_DxEmailVerifiedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DxEmailVerifiedComponent, data: {} });
export { RenderType_DxEmailVerifiedComponent as RenderType_DxEmailVerifiedComponent };
function View_DxEmailVerifiedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row logos"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-sm-12 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Concern logo"], ["class", "concern-logo"], ["src", "./../../../../assets/icons/concern-one-color-logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus seperator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "eMindful logo"], ["class", "emindful-logo"], ["src", "../../../../../assets/icons/emlife-logo.png"]], null, null, null, null, null))], null, null); }
function View_DxEmailVerifiedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-intake-header", [["styleClasses", "mt-0"], ["title", "You're all set. Let's Login and see your personal dashboard."]], null, null, null, i2.View_IntakeHeaderComponent_0, i2.RenderType_IntakeHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.IntakeHeaderComponent, [], { title: [0, "title"], styleClasses: [1, "styleClasses"] }, null)], function (_ck, _v) { var currVal_0 = "You're all set. Let's Login and see your personal dashboard."; var currVal_1 = "mt-0"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DxEmailVerifiedComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-intake-header", [["styleClasses", "mt-0"], ["title", "You're all set. Let's Login and see the available eMLife programs."]], null, null, null, i2.View_IntakeHeaderComponent_0, i2.RenderType_IntakeHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.IntakeHeaderComponent, [], { title: [0, "title"], styleClasses: [1, "styleClasses"] }, null)], function (_ck, _v) { var currVal_0 = "You're all set. Let's Login and see the available eMLife programs."; var currVal_1 = "mt-0"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_DxEmailVerifiedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "flex-centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "nb-auth-block", [], null, null, null, i4.View_DxAuthBlockComponent_0, i4.RenderType_DxAuthBlockComponent)), i1.ɵdid(3, 49152, null, 0, i5.DxAuthBlockComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DxEmailVerifiedComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DxEmailVerifiedComponent_2)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DxEmailVerifiedComponent_3)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "div", [["class", "row justify-content-center no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary col-12 col-md-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Login "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEmindful; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.isEmindful; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.isEmindful; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_DxEmailVerifiedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nb-email-verified-page", [], null, null, null, View_DxEmailVerifiedComponent_0, RenderType_DxEmailVerifiedComponent)), i1.ɵdid(1, 114688, null, 0, i7.DxEmailVerifiedComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DxEmailVerifiedComponentNgFactory = i1.ɵccf("nb-email-verified-page", i7.DxEmailVerifiedComponent, View_DxEmailVerifiedComponent_Host_0, {}, {}, []);
export { DxEmailVerifiedComponentNgFactory as DxEmailVerifiedComponentNgFactory };
