import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit {
@Input() headline: string;
@Input() caption: string;
@Input() visible: boolean = true;
  constructor() { }

  ngOnInit() {
  }

}
