/**
 * Luma API
 * Luma API
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
/* tslint:disable */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable } from 'rxjs';

import { Provider } from '../model/provider';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProviderService {

    protected basePath = 'https://localhost:4200/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Find Provider
     * Find Provider
     * @param specialty 
     * @param onSiteOrgName only providers who have on-site arrangements with the org specified
     * @param videoEnabled only providers who have enabled video therapy sessions
     * @param onlineSchedulingEnabled only providers who have enabled online scheduling
     * @param gender 
     * @param zipCode radius for searching
     * @param radius radius for searching
     * @param radiusUnit unit for the search radius. defaults to km
     * @param ageRange age range for matching
     * @param stateLicensed state licensed in
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findProvider(specialty?: string, onSiteOrgName?: string, videoEnabled?: boolean, onlineSchedulingEnabled?: boolean, gender?: string, zipCode?: string, radius?: number, radiusUnit?: string, ageRange?: string, stateLicensed?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Provider>>;
    public findProvider(specialty?: string, onSiteOrgName?: string, videoEnabled?: boolean, onlineSchedulingEnabled?: boolean, gender?: string, zipCode?: string, radius?: number, radiusUnit?: string, ageRange?: string, stateLicensed?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Provider>>>;
    public findProvider(specialty?: string, onSiteOrgName?: string, videoEnabled?: boolean, onlineSchedulingEnabled?: boolean, gender?: string, zipCode?: string, radius?: number, radiusUnit?: string, ageRange?: string, stateLicensed?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Provider>>>;
    public findProvider(specialty?: string, onSiteOrgName?: string, videoEnabled?: boolean, onlineSchedulingEnabled?: boolean, gender?: string, zipCode?: string, radius?: number, radiusUnit?: string, ageRange?: string, stateLicensed?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (specialty !== undefined) {
            queryParameters = queryParameters.set('specialty', <any>specialty);
        }
        if (onSiteOrgName !== undefined) {
            queryParameters = queryParameters.set('onSiteOrgName', <any>onSiteOrgName);
        }
        if (videoEnabled !== undefined) {
            queryParameters = queryParameters.set('videoEnabled', <any>videoEnabled);
        }
        if (onlineSchedulingEnabled !== undefined) {
            queryParameters = queryParameters.set('onlineSchedulingEnabled', <any>onlineSchedulingEnabled);
        }
        if (gender !== undefined) {
            queryParameters = queryParameters.set('gender', <any>gender);
        }
        if (zipCode !== undefined) {
            queryParameters = queryParameters.set('zipCode', <any>zipCode);
        }
        if (radius !== undefined) {
            queryParameters = queryParameters.set('radius', <any>radius);
        }
        if (radiusUnit !== undefined) {
            queryParameters = queryParameters.set('radiusUnit', <any>radiusUnit);
        }
        if (ageRange !== undefined) {
            queryParameters = queryParameters.set('ageRange', <any>ageRange);
        }
        if (stateLicensed !== undefined) {
            queryParameters = queryParameters.set('stateLicensed', <any>stateLicensed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<Provider>>(`${this.basePath}/run/findProvider`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Provider by Id
     * Get Provider by Id
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProviderById(id: string, observe?: 'body', reportProgress?: boolean): Observable<Provider>;
    public getProviderById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Provider>>;
    public getProviderById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Provider>>;
    public getProviderById(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getProviderById.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Provider>(`${this.basePath}/run/getProvider`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
