import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

const DOB_PATTERN = /^(19\d{2}|[2-9]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/

@Directive({
    selector: '[ngxValidateDOB]',
    providers: [{provide: NG_VALIDATORS, useExisting: ValidateDobDirective, multi: true}],
  })
  export class ValidateDobDirective implements Validator {

    validate(control: AbstractControl): ValidationErrors | null {
        return DOB_PATTERN.test(control.value) ? null : { dobInvalid: true };
    }
  }
