import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { NpsSurveyService } from '../../../@core/utils/nps-survey.service';
import { Router, ActivatedRoute } from '@angular/router';
const ROUTE_NAME = 'concerned-welfare';

@Component({
  selector: 'ngx-concerned-welfare',
  templateUrl: './concerned-welfare.component.html',
  styleUrls: ['./concerned-welfare.component.scss'],
})
export class ConcernedWelfareComponent implements OnInit {


  previousValue: number;
  submitted: boolean = false;
  value: number = -1;
  selectedText: string;
  selectedValue: number;
  disableButton: boolean = true;
  isShowAnswer: boolean;
  constructor(private npsSurveyService: NpsSurveyService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.npsSurveyService.setCarePathProgress(ROUTE_NAME);
  }

  ngOnInit() {
    if (this.npsSurveyService.concernedData) {
      this.selectedText = this.npsSurveyService.concernedData;
      this.getValue();
      this.value = this.selectedValue;

      this.previousValue = this.value;
      this.isShowAnswer = true;
    }
    this.isValidState();
    this.getTextValue();

  }
  options: Options = {

    showTicks: true,
    stepsArray: [{
      value: 1,
      legend: 'Strongly disagree',
    }, {
      value: 2,
    }, {
      value: 3,
    }, {
      value: 4,
    }, {
      value: 5,
      legend: 'Strongly agree',
    }],
  };



  isValidState() {
    this.disableButton = this.value === -1 ? true : false;

  }
  changeValueEvent() {
    if (this.value === this.previousValue) {
      this.previousValue = -1;
      this.value = -1;
      this.selectedValue = null;
      this.selectedText = '';
      this.isShowAnswer = false;
    } else {
      this.previousValue = this.value;
      this.getTextValue();
      this.isShowAnswer = true;
    }

    this.isValidState();
  }

  getValue() {
    for (let i = 0; i < this.npsSurveyService.selectedArr.length; i++) {
      if (this.npsSurveyService.selectedArr[i].text === this.selectedText) {
        this.selectedValue = this.npsSurveyService.selectedArr[i].value;
        this.selectedText = this.npsSurveyService.selectedArr[i].text;

      }

    }
  }

  getTextValue() {
    for (let i = 0; i < this.npsSurveyService.selectedArr.length; i++) {
      if (this.npsSurveyService.selectedArr[i].value === this.value) {
        this.selectedText = this.npsSurveyService.selectedArr[i].text;
        this.selectedValue = this.npsSurveyService.selectedArr[i].value;
      }
    }
    this.isValidState();
  }

  goNext(): void {
    this.submitted = true;
    this.value = this.value;
    this.npsSurveyService.concernedData = this.selectedText;
    const nextStep = this.npsSurveyService.getNextStep(ROUTE_NAME);
    this.router.navigate([`../${nextStep}`], { relativeTo: this.activatedRoute });

  }
}
