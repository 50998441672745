import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent, ConfirmationModal, ModalOptions, SizeModal } from '../../@theme/components/reusables';
import { AddressSouModalComponent } from '../../@theme/components/reusables/modal/address-sou-modal/address-sou-modal.component';
import { AddressFormModalComponent } from '../../@theme/components/reusables/modal/address-form-modal/address-form-modal.component';
import { ProviderSelectionModalComponent } from '../../@theme/components/reusables/modal/provider-selection-modal/provider-selection-modal.component';
import { SwitchModalComponent } from '../../@theme/components/reusables/modal/switch-modal/switch-modal.component';
import { OnlineSwitchComponent } from '../../@theme/components/reusables/modal/online-switch/online-switch.component';
import { CounselorDetailsModalComponent } from '../../@theme/components/reusables/modal/CounelorDetailsModalComponent/counselor-details-modal.component';
import { BetterHelpTransitionModalComponent } from '../../@theme/components/reusables/modal/betterhelp-transition-modal/betterhelp-transition-modal.component';
import { SwitchToOnlineModalComponent } from '../../@theme/components/reusables/modal/switch-to-online-modal/switch-to-online-modal.component';
import { WorkLifeRequestFormModalComponent } from '../../@theme/components/reusables/modal/work-life-request-form-modal/work-life-request-form-modal.component';
import { WLConfirmationModalComponent } from '../../@theme/components/reusables/modal/work-life-request-confirmation/work-life-confirmation-modal.component';
import { ConfirmBabyKitModalComponent } from '../../@theme/components/reusables/modal/confirm-babykit-modal/confirm-babykit-modal.component';
import { EmindfulModalComponent } from '../../@theme/components/reusables/modal/edmindful/emindful-modal.component';
import { CoachingTransitionModalComponent } from '../../@theme/components/reusables/modal/coaching-transition-modal/coaching-transition-modal.component';

@Injectable()
export class ModalService {
  private activeModal;
  private modeOfHelp: string;
  private useCase: string;

  constructor(private modalService: NgbModal) { }

  public setModeOfHelp(mode: string) {
    this.modeOfHelp = mode;
  }

  public setUseCase(useCase: string) {
    this.useCase = useCase
  }

  showModal(size: SizeModal, modal: ModalOptions, callback?, acceptNo?): any {
    return this.coreOpenModal(ModalComponent, size, modal, callback, acceptNo);
  }

  showAddressSouModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(AddressSouModalComponent, size, modal, callback, acceptNo);
  }


  showAddressFormModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(AddressFormModalComponent, size, modal, callback, acceptNo);
  }

  showBabyKitConfirmation(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(ConfirmBabyKitModalComponent, size, modal, callback, acceptNo);
  }

  showProviderSelectionModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(ProviderSelectionModalComponent, size, modal, callback, acceptNo);
  }

  showSwitchModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(SwitchModalComponent, size, modal, callback, acceptNo);
  }

  showOnlineSwitchModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(OnlineSwitchComponent, size, modal, callback, acceptNo);
  }

  showCounselorDetailsModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(CounselorDetailsModalComponent, size, modal, callback, acceptNo);
  }

  showBetterHelpTransitionModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(BetterHelpTransitionModalComponent, size, modal, callback, acceptNo);
  }

  showCoachingTransitionModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(CoachingTransitionModalComponent, size, modal, callback, acceptNo);
  }

  switchToOnlineModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(SwitchToOnlineModalComponent, size, modal, callback, acceptNo);
  }

  showWorkLifeRequestModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(WorkLifeRequestFormModalComponent, size, modal, callback, acceptNo);
  }

  showWorkLifeConfirmationModal(size: SizeModal, modal: any, callback?, acceptNo?, callbackOnOutsideClick = false): any {
    return this.coreOpenModal(WLConfirmationModalComponent, size, modal, callback, acceptNo, callbackOnOutsideClick);
  }

  showEmindfulModal(size: SizeModal, modal: any, callback?, acceptNo?): any {
    return this.coreOpenModal(EmindfulModalComponent, size, modal, callback, acceptNo);
  }

  private coreOpenModal(content: any, size: SizeModal, modal: any, callback: any, acceptNo: any, callbackOnOutsideClick = false): any {
    this.activeModal = this.modalService.open(content, { size: size, backdrop: true, centered: true});
    this.activeModal.componentInstance.modal = modal;
    if (this.modeOfHelp) {
      this.activeModal.componentInstance.modeOfHelp = this.modeOfHelp;
    }
    this.activeModal.componentInstance.useCase = this.useCase;
    this.useCase = '';

    this.activeModal.result.then(result => {
      if ((result === ConfirmationModal.Yes && callback) || acceptNo) {
        if (acceptNo) {
          callback(result);
        } else {
          callback();
        }
      }
    }).catch(err => {
      if ( err === 0 && callbackOnOutsideClick) {
        callback();
      }
      return;
    });

    return this.activeModal.componentInstance;
  }
}
