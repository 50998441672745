import { HistoryService } from './api/history.service';
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './configuration';

import { CaseService } from './api/case.service';
import { ConditionService } from './api/condition.service';
import { ConfigService } from './api/config.service';
import { ContractService } from './api/contract.service';
import { OrgService } from './api/org.service';
import { PatientService } from './api/patient.service';
import { ProviderService } from './api/provider.service';
import { CounselorService } from './counselor.service';

@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [],
  exports:      [],
  providers: [
    CaseService,
    ConditionService,
    ConfigService,
    ContractService,
    OrgService,
    PatientService,
    ProviderService,
    CounselorService,
    HistoryService,
    ],
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ],
        }
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import your base AppModule only.');
        }
    }
}
