/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instructions-tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "./instructions-tooltip.component";
var styles_InstructionsTooltipComponent = [i0.styles];
var RenderType_InstructionsTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstructionsTooltipComponent, data: {} });
export { RenderType_InstructionsTooltipComponent as RenderType_InstructionsTooltipComponent };
function View_InstructionsTooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "])), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "i", [["class", "fa fa-info-circle icircle"], ["placement", "left"], ["popoverClass", "instruction-popover"]], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i2.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbPopoverConfig, i1.NgZone, i3.DOCUMENT], { ngbPopover: [0, "ngbPopover"], placement: [1, "placement"], popoverClass: [2, "popoverClass"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.popOverMessage, ""); var currVal_2 = "left"; var currVal_3 = "instruction-popover"; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 0, 0, currVal_0); }); }
function View_InstructionsTooltipComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InstructionsTooltipComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstructionsTooltipComponent_3)), i1.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_InstructionsTooltipComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InstructionsTooltipComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstructionsTooltipComponent_5)), i1.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InstructionsTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["icon", 2]], null, 0, null, View_InstructionsTooltipComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstructionsTooltipComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstructionsTooltipComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachToCorner; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.attachToCorner; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_InstructionsTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-instructions-tooltip", [], null, null, null, View_InstructionsTooltipComponent_0, RenderType_InstructionsTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i4.InstructionsTooltipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstructionsTooltipComponentNgFactory = i1.ɵccf("ngx-instructions-tooltip", i4.InstructionsTooltipComponent, View_InstructionsTooltipComponent_Host_0, { message: "message", popOverMessage: "popOverMessage", attachToCorner: "attachToCorner" }, {}, []);
export { InstructionsTooltipComponentNgFactory as InstructionsTooltipComponentNgFactory };
