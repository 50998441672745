/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counselor-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./counselor-image.component";
import * as i4 from "../../../../@core/data/api/content.service";
var styles_CounselorImageComponent = [i0.styles];
var RenderType_CounselorImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounselorImageComponent, data: {} });
export { RenderType_CounselorImageComponent as RenderType_CounselorImageComponent };
function View_CounselorImageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "media-object img-responsive"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "img-d": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "media-object img-responsive"; var currVal_2 = _ck(_v, 2, 0, !_co.isFacetedSearch); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 0, 0, currVal_0); }); }
function View_CounselorImageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "user-picture background"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "user-picture-d": 0 }), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "user-picture background"; var currVal_1 = _ck(_v, 2, 0, !_co.isFacetedSearch); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.initials; _ck(_v, 3, 0, currVal_2); }); }
export function View_CounselorImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "media-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CounselorImageComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["initialsUi", 2]], null, 0, null, View_CounselorImageComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.imageUrl)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CounselorImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-counselor-image", [], null, null, null, View_CounselorImageComponent_0, RenderType_CounselorImageComponent)), i1.ɵdid(1, 573440, null, 0, i3.CounselorImageComponent, [i4.ContentService], null, null)], null, null); }
var CounselorImageComponentNgFactory = i1.ɵccf("ngx-counselor-image", i3.CounselorImageComponent, View_CounselorImageComponent_Host_0, { counselor: "counselor", isFacetedSearch: "isFacetedSearch" }, {}, []);
export { CounselorImageComponentNgFactory as CounselorImageComponentNgFactory };
