import { Injectable } from '@angular/core';
import { UserService } from './users.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class BabyKitService {
  constructor(private userService: UserService, private cookieService: CookieService) { }

  async updateUserBabyKit(user: any, contract: any, org: any, isHomeAddressSame: boolean) {
    // if not same as home, extract address from user so it is does not override user profile
    const { addresses, ...userObj } = user;
    const [ primaryAddress ] = addresses;

    const filteredUser = isHomeAddressSame ? user : userObj;

    const updateUser = {
      ...filteredUser,
      babyKitRequest: {
        contractId: contract.id,
        submitted: new Date().toISOString(),
        shipTo: user.fullName,
        shippingAddress: {
          ...primaryAddress,
        },
      },
    };

    // update user object
    const { data } = await this.userService.updateUser(updateUser).toPromise();
    const requestData = this.gatherRequestData(data, primaryAddress, contract, org);
    this.userService.requestWorkLifeServices(requestData).subscribe();

    return data;
  }

  private gatherRequestData(user, address, contract, org) {
    return {
      userId: user.id,
      internalId: user.internalId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      dateOfBirth: user.dateOfBirth,
      gender: user.gender,
      allowedHomePhoneMessage: user.allowedHomePhoneMessage ? 'Yes' : 'No',
      allowEmailMessage: user.allowEmailMessage ? 'Yes' : 'No',
      phoneNumber: this.userService.getpreferredPhoneNumber(user),
      preferredPhone: user.preferredPhone,
      address,
      preferredComunicationMethod: user.preferredCommunicationMethod || '',
      employerInformation: {
        companyName: this.cookieService.get('companyName') || '',
        companySiteLocation: this.cookieService.get('companySiteLocation') || '',
        companyAffiliation: this.cookieService.get('companyAffiliation') || '',
      },
      organization: { id: org.id, name: org.name },
      currentContract: { id: contract.id, orgId: org.id, onSiteEnabled: contract.onSiteEnabled || false },
      chosenSiteLocation: { name: this.cookieService.get('companySiteLocation') },
      companyName: org.name,
      workLifeCategory: 'parenting-advice',
      workLifeSubCategory: 'Concern\'s New Baby Kit',
      workLifeCategoryOption: 'parenting-advice',
      submittedDate: new Date().toISOString(),
      description: '',
      modeOfHelp: '',
      carePath: 'work-life',
    };
  }

}
