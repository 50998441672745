import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NpsSurveyComponent } from './nps-survey.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NpsSurveyRoutingModule } from './nps-survey-routing.module';
import { ThemeModule } from '../../@theme/theme.module';
import { RecommendServicesComponent } from './recommend-services/recommend-services.component';
import { MyCounselorComponent } from './my-counselor/my-counselor.component';
import { NpsSurveyService } from '../../@core/utils/nps-survey.service';
import { ScheduledAppointmentsComponent } from './scheduled-appointments/scheduled-appointments.component';
import { ConcernedWelfareComponent } from './concerned-welfare/concerned-welfare.component';
import { FeedbackTextboxComponent } from './feedback-textbox/feedback-textbox.component';
import { ThankyouSurveyComponent } from './thankyou-survey/thankyou-survey.component';
import { NpsProgressBarComponent } from '../../@theme/components/reusables/progress-bar/progress-bar.component-nps';

@NgModule({
  imports: [
    CommonModule,
    NgxSliderModule,
    ThemeModule,
    NpsSurveyRoutingModule,

  ],
  declarations: [NpsSurveyComponent, RecommendServicesComponent, MyCounselorComponent,
    ScheduledAppointmentsComponent, ConcernedWelfareComponent, FeedbackTextboxComponent, ThankyouSurveyComponent],
  exports: [ThankyouSurveyComponent, FeedbackTextboxComponent],
  providers: [NpsProgressBarComponent, NpsSurveyService],
})
export class NpsSurveyModule { }
