import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Progress } from '../../../../@core/utils/nps-survey.service';

@Component({
  selector: 'ngx-progress-bar-nps',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class NpsProgressBarComponent implements OnInit, OnChanges {

  @Input() progress: Progress;
  @Input() visible: boolean = false;
  public range = [];

  constructor() { }

  ngOnInit() {
    this.initRange();
  }

  public initRange() {
    this.range = [];
    for (let i = 0; this.progress && i < this.progress.numberOfBlocks; i++) {
      this.range.push(i);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const progressChange: SimpleChange = changes.progress;
    this.progress = progressChange.currentValue;
    this.initRange();
  }
}
