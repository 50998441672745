import { NgModule } from '@angular/core';
import { MagicLinkComponent } from './magic-link.component';
import {CookieService } from 'ngx-cookie-service';
import { MagicLinkService } from './magic-link.service';

@NgModule({
    declarations: [
      MagicLinkComponent,
    ],
    providers: [CookieService, MagicLinkService],
  })
  export class MagicLinkModule { }
