import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { userRoutes } from './constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../variables";
import * as i3 from "../configuration";
var SURVEY_EMAIL_URL = '/run/sendEmailSurvey';
var SendEmailService = /** @class */ (function () {
    function SendEmailService(httpClient, basePath, configuration) {
        this.httpClient = httpClient;
        this.basePath = 'https://localhost:4200/v1';
        this.configuration = new Configuration();
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    SendEmailService.prototype.sendSurveyEmail = function (userData, companyName) {
        return this.httpClient.post("" + this.basePath + SURVEY_EMAIL_URL, { user: userData, company: companyName })
            .pipe(catchError(this.handleError));
    };
    SendEmailService.prototype.resendVerification = function (email) {
        return this.httpClient.post("" + this.basePath + userRoutes.postResendVerification, { email: email });
    };
    SendEmailService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    ;
    SendEmailService.ngInjectableDef = i0.defineInjectable({ factory: function SendEmailService_Factory() { return new SendEmailService(i0.inject(i1.HttpClient), i0.inject(i2.BASE_PATH, 8), i0.inject(i3.Configuration, 8)); }, token: SendEmailService, providedIn: "root" });
    return SendEmailService;
}());
export { SendEmailService };
