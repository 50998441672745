<div class="animate__animated animate__slideInRight text-center animate__faster feelings_content">

  <div class="row justify-content-center no-gutters">
    <h4 class="col-12 top font-size-36 font-weight-500 mb32 text-center font-black">Thank you for completing this
      <span class="break-line">survey! </span>

    </h4>
  </div>


  <div *ngIf='isRiskLow'>
    <div class="score_block mx-auto risk_low">
      <p class="font-size-16 font-weight-500">Your score indicates you are doing well with little
        emotional
        distress.</p>
    </div>
    <p class="font-black font-weight-400 font-size-16 mb16 line-height-22 w-60 mx-auto" *ngIf="isShowLogin">Go to my
      care to
      see the trend
      report.</p>

  </div>
  <div *ngIf='isRiskMedium'>
    <div class="score_block mx-auto risk_medium">
      <p class="font-size-16 font-weight-500">Your score indicates you are experiencing a moderate
        level of emotional distress.</p>
    </div>
    <p class="font-black font-weight-400 font-size-16 mb16 line-height-22 w-60 mx-auto" *ngIf="isShowLogin">Go to my
      care to
      see
      the trend report
      and learn
      more about Concern services.</p>
  </div>
  <div *ngIf='isRiskHigh'>
    <div class="score_block mx-auto risk_high">
      <p class="font-size-16 font-weight-500">Your score indicates you are experiencing a high
        level of emotional distress. </p>
    </div>
    <p class="font-black font-weight-400 font-size-16 mb16 line-height-22 w-60 mx-auto" *ngIf="isShowLogin">Go to my
      care to
      see
      the trend report
      and learn
      more about how Concern can help.</p>
  </div>



  <div class="row justify-content-center no-gutters" *ngIf="!isShowLogin">
    <button class="btn btn-primary col-12 col-md-5 mb24" (click)="goNext()">LOGIN
      <i class="fas fa-arrow-circle-right"></i></button>
    <p class="font-black font-weight-400 font-size-16 mb16 line-height-22 w-60 mx-auto" *ngIf="!isShowLogin">Login to
      Go to My Care Page to see the trend report.</p>
  </div>
  <div class="row justify-content-center no-gutters" *ngIf="isShowLogin">
    <button class="btn btn-primary col-12 col-md-5 mb24 max-width-none" (click)="navigate()">LOGIN
      <i class="fas fa-arrow-circle-right"></i></button>

  </div>

</div>