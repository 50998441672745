import { NbMenuService } from '@nebular/theme';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@nebular/theme/components/menu/menu.service";
var LumaMenuService = /** @class */ (function () {
    function LumaMenuService(replayMenuService) {
        var _this = this;
        this._onItemClick = new Subject();
        this._onItemSelect = new Subject();
        replayMenuService.onItemClick().subscribe(function (item) { return _this._onItemClick.next(item); });
        replayMenuService.onItemSelect().subscribe(function (item) { return _this._onItemSelect.next(item); });
    }
    LumaMenuService.prototype.onItemClick = function () { return this._onItemClick; };
    LumaMenuService.prototype.onItemSelect = function () { return this._onItemSelect; };
    LumaMenuService.ngInjectableDef = i0.defineInjectable({ factory: function LumaMenuService_Factory() { return new LumaMenuService(i0.inject(i1.NbMenuService)); }, token: LumaMenuService, providedIn: "root" });
    return LumaMenuService;
}());
export { LumaMenuService };
