import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { STATES } from '../../../../../@core/utils/intake-flow.service';
import { ConfirmationModal } from '../modal.component';

import { ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import {
  IN_PERSON_MODE_OF_HELP,
  ONLINE_MODE_OF_HELP,
  PHYSICIAN_MODE_OF_HELP
} from '../../../../../@core/utils/constants';

@Component({
  selector: 'ngx-switch-to-online-modal',
  templateUrl: './switch-to-online-modal.component.html',
  styleUrls: ['./switch-to-online-modal.component.scss'],
})
export class SwitchToOnlineModalComponent implements OnInit {
  @Input() modeOfHelp: string;
  @Input() useCase: string;
  heading: string = 'How BetterHelp matches you with a Counselor';
  public states = STATES;
  stateList: string[];
  counselingFor: string = '';
  selectState: string[] = [];
  whySwitch: string = '';

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  value: any;
  reasons: any;
  switchToBHModesOfHelp = [PHYSICIAN_MODE_OF_HELP, IN_PERSON_MODE_OF_HELP];

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.getUSAStateCodes();
    this.stateList = Object.values(this.states)
    this.stateList.shift();
    this.reasons = environment.reasons;
  }

  getUSAStateCodes(): Array<string> {
    return Object.keys(this.states);
  }
  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }
  getStates(): any {
    return this.stateList
  }

  accept(): void {
    this.activeModal.close({
      answer: ConfirmationModal.Yes,
      selectState: this.selectState,
      counselingFor: this.counselingFor,
      test: ONLINE_MODE_OF_HELP,
      reasonForSwitch: this.whySwitch,
    });
  }
  completeRequest() {
    for (const eachCode in this.states) {
      if (this.states[eachCode] === this.value) {
        this.selectState.push(eachCode)
        this.selectState.push(this.states[eachCode])
      }
    }
    this.counselingFor = 'Individual';
    if (this.useCase === 'faceted') {
      this.whySwitch = 'Wanted a different counseling mode';
    }
    this.accept();
  }

  enableSwitchToBHCounselorBtn() {
    return this.switchToBHModesOfHelp.includes(this.modeOfHelp);
  }

}
