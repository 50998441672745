import { Injectable } from '@angular/core';
import { CounselingFor } from '../../pages/intake/counseling-for/counseling-for.component';
import { WORKLIFE_OPTIONS } from '../../pages/intake/work-life/work-life-options';
import { EmployerInformation } from '../../pages/intake/employer-information/models/employee-information';
import { Org, Contract, CaseCounselingLocation, WorkLifeRequestForm } from '../data/model/models';
import { ProgressBarService } from './progress-bar.service';
import { ContentRadioItem } from '../../@theme/components/reusables';

export interface Progress {
    progress: number,
    numberOfBlocks: number,
    width?: number
};

export const STEPS = {
    // let the string value match the router path for convenient routing
    employerInformation2: 'employer-information-2',
    ssoEmail: 'sso-email',
    carePath: 'care-path',
    emotions: 'emotions',
    // emotionIntensity: 'emotion-intensity',
    emotionalState: 'emotional-state',
    moodFrequency: 'mood-frequency',
    speedBump: 'speed-bump',

    lifeSatisfaction: 'life-satisfaction',
    stressIntakeSurvey: 'stress-survey',

    transitionToCounselor: 'transition-to-counselor',
    additionalInsurance: 'additional-insurance',
    counselingFor: 'counseling-for',
    workWithCounselor: 'work-with-counselor',
    dontKnow: 'dont-know-path',
    riskGauge: 'risk-gauge',
    modeOfHelp: 'mode-of-help',
    workLife: 'work-life',
    legalIssue: 'legal-issues',
    parentingAdvice: 'parenting-advice',
    careAdvice: 'care-advice',
    conciergeService: 'concierge-service',
    emergencyService: 'emergency-service',
    financialIssue: 'financial-issues',
    counselingLocation: 'counseling-location',
    workLifeSubCategory: 'work-life-category',
    workLifeRequestForm: 'work-life-request-form',
    counselorLocation: 'counselor-location',
    employeeNumber: 'employee-number',
}

export const STATES = {
    '': '',
    'AL': 'Alabama',
    'AK': 'Alaska',
    // 'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District Of Columbia',
    // 'FM': 'Federated States Of Micronesia',
    'FL': 'Florida',
    'GA': 'Georgia',
    // 'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    // 'MH': 'Marshall Islands',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    // 'MP': 'Northern Mariana Islands',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    // 'PW': 'Palau',
    'PA': 'Pennsylvania',
    // 'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Islands',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',
};

export class IntakeFlowFormData {
    carePath: string = '';
    emotions: string[] = [];
    riskOptions: string = '';
    // emotionIntensity: string = '';
    emotionalStates: string[] = [];
    moodFrequencies: string[] = [];
    speedBump: boolean = false;
    selectState: string[] = [];
    totalScore: number;

    lifeSatisfaction: string = '';
    stressIntakeSurvey: string = '';
    transitionToCounselor: boolean = false;
    additionalInsurance: { isPartImportnant: string, provider: string };
    counselingFor: CounselingFor = { participants: '', age_ranges: [] };
    modeOfHelp: string = '';
    workWithCounselorOption: string = '';
    workLifeCategory: string = '';
    workLifeSubCategory: string = '';
    workLifeRequestForm: WorkLifeRequestForm = { preferredCommunication: '', description: '' };
    workLifeCategoryOption: string = '';
    currentContract: Contract = {};
    organization: Org = { parentOrg: '', phone: '', id: '', name: '', email: '', timeZone: '', locations: [] };
    companyCode: string = '';
    employeeNumber: string = '';
    emotionalCareTypeRequiresCounselingLocationStep: boolean = false;
    counselingLocation: CaseCounselingLocation = { selection: '', value: '' };
    employerInformation: EmployerInformation = { companyName: '', companySiteLocation: '', companyAffiliation: '', chosenSiteLocation: {} };
    user: any = {};
    requiresEmployeeNumber: boolean = false;
    riskAtIntake: string = '';
    depressionAtIntake: string = '';
    pathListOptions: ContentRadioItem[];


    reset(): void {
        this.carePath = '';
        this.emotions = [];
        //    this.emotionIntensity = '';
        this.emotionalStates = [];
        this.moodFrequencies = [];
        this.speedBump = false;
        this.totalScore = 0;
        this.lifeSatisfaction = '';
        this.selectState = [];
        this.stressIntakeSurvey = '';
        this.transitionToCounselor = false;
        this.additionalInsurance = { isPartImportnant: '', provider: '' };
        this.counselingFor = { participants: '', age_ranges: [] };
        this.modeOfHelp = '';
        this.workWithCounselorOption = '';
        this.riskOptions = '';
        this.workLifeCategoryOption = '';
        this.counselingLocation = { selection: '', value: '' };
        this.employerInformation = { companyName: '', companySiteLocation: '', companyAffiliation: '', chosenSiteLocation: {} };
        // this.organization = { parentOrg: '', phone: '', id: '', name: '', email: '', timeZone: '', locations: [] };
        // this.currentContract = {};
        this.workLifeCategory = '';
        this.workLifeSubCategory = '';
        this.workLifeRequestForm = { preferredCommunication: '', description: '' };
        this.workWithCounselorOption = '';
        this.user = {};
        this.companyCode = '';
        this.requiresEmployeeNumber = false;
        this.riskAtIntake = '';
        this.depressionAtIntake = '';
        // this.pathListOptions = [];
    }

    clearCarePath(): void {
        this.carePath = '';
        this.emotions = [];
        //    this.emotionIntensity = '';
        this.selectState = [];
        this.emotionalStates = [];
        this.moodFrequencies = [];
        this.speedBump = false;
        this.totalScore = 0;
        this.lifeSatisfaction = '';
        this.stressIntakeSurvey = '';
        this.transitionToCounselor = false;
        this.additionalInsurance = { isPartImportnant: '', provider: '' };
        this.counselingFor = { participants: '', age_ranges: [] };
        this.modeOfHelp = '';
        this.workWithCounselorOption = '';
        this.riskOptions = '';
        this.workLifeCategoryOption = '';
        this.counselingLocation = { selection: '', value: '' };
        // this.currentContract = {};
        this.workLifeCategory = '';
        this.workLifeSubCategory = '';
        this.workLifeRequestForm = { preferredCommunication: '', description: '' };
        this.workWithCounselorOption = '';
        this.requiresEmployeeNumber = false;
        this.riskAtIntake = '';
        this.depressionAtIntake = '';
        // this.pathListOptions = [];
    }
}
@Injectable()
export class IntakeFlowService {
    private formData: IntakeFlowFormData = new IntakeFlowFormData();
    constructor(private progressBarService: ProgressBarService) {
    }
    typeOfCounseling: string = '';

    get counselingLocationDetail(): CaseCounselingLocation {
        return this.formData.counselingLocation;
    }
    public get organization(): Org {
        return this.formData.organization;
    }
    public set organization(value: Org) {
        this.formData.organization = value;
    }
    set counselingLocationDetail(value: CaseCounselingLocation) {
        this.formData.counselingLocation = value;
        this.validateStep(STEPS.counselingLocation);
    }
    get employerInformation(): EmployerInformation {
        return this.formData.employerInformation;
    }
    set employerInformation(employerInformation: EmployerInformation) {
        this.formData.employerInformation = employerInformation;
        this.validateStep(STEPS.employerInformation2);
    }

    get carePath(): string {
        return this.formData.carePath;
    }
    set carePath(path: string) {
        // update value and set step to valid.
        // we trust that the relevant screen validated this data.
        this.formData.carePath = path;
        this.validateStep(STEPS.carePath);
    }

    get riskOptions(): string {
        return this.formData.riskOptions;
    }
    set riskOptions(riskOptions: string) {
        this.formData.riskOptions = riskOptions;
        this.validateStep(STEPS.riskGauge);
    }

    get pathListOptions(): ContentRadioItem[] {
        return this.formData.pathListOptions;
    }
    set pathListOptions(pathOptions: ContentRadioItem[]) {
        this.formData.pathListOptions = pathOptions;
    }

    get employeeNumber(): string {
        return this.formData.employeeNumber;
    }
    set employeeNumber(employeeNumber: string) {
        this.formData.employeeNumber = employeeNumber;
        this.validateEmployeeNumberStep()
    }

    validateEmployeeNumberStep() {
        this.validateStep(STEPS.employeeNumber);
    }

    // work life
    get workLifeCategoryOption(): string {
        return this.formData.workLifeCategoryOption;
    }
    set workLifeCategoryOption(type: string) {
        this.formData.workLifeCategoryOption = type;
        this.workLifeCategory = this.getCategoryFromWorkLifeOptions(type);
    }
    private getCategoryFromWorkLifeOptions(category: string) {
        if (category === WORKLIFE_OPTIONS.LEGAL_ISSUE) {
            category = STEPS.legalIssue;
        } else if (category === WORKLIFE_OPTIONS.FINANCIAL_ISSUE) {
            category = STEPS.financialIssue;
        } else if (category === WORKLIFE_OPTIONS.PARENTING_ADVICE) {
            category = STEPS.parentingAdvice;
        } else {
            category = STEPS.careAdvice;
        }
        return category;
    }
    get workLifeCategory(): string {
        return this.formData.workLifeCategory;
    }
    set workLifeCategory(type: string) {
        this.formData.workLifeCategory = type;
    }
    get workLifeSubCategory(): string {
        return this.formData.workLifeSubCategory;
    }
    set workLifeSubCategory(type: string) {
        this.formData.workLifeSubCategory = type;
    }

    get workLifeRequestForm(): WorkLifeRequestForm {
        return this.formData.workLifeRequestForm;
    }
    set workLifeRequestForm(type: WorkLifeRequestForm) {
        this.formData.workLifeRequestForm = type;
    }

    // Life-satisfaction
    get lifeSatisfaction(): string {
        return this.formData.lifeSatisfaction
    }
    set lifeSatisfaction(lifeSatisfaction: string) {
        this.formData.lifeSatisfaction = lifeSatisfaction;
        this.validateStep(STEPS.lifeSatisfaction);
    }
    get stressIntakeSurvey(): string {
        return this.formData.stressIntakeSurvey
    }

    set stressIntakeSurvey(stressIntakeSurvey: string) {
        this.formData.stressIntakeSurvey = stressIntakeSurvey;
        this.validateStep(STEPS.stressIntakeSurvey)
    }

    get emotionalStates(): string[] {
        return this.formData.emotionalStates;
    }
    set emotionalStates(states: string[]) {
        this.formData.emotionalStates = states;
        this.validateStep(STEPS.emotionalState);
    }

    get selectState(): string[] {
        return this.formData.selectState;
    }
    set selectState(zipState: string[]) {
        this.formData.selectState = zipState
    }

    set totalScore(score: any) {
        this.formData.totalScore = score;
    }

    get speedBump(): boolean {
        return this.formData.speedBump;
    }
    set speedBump(seen: boolean) {
        this.formData.speedBump = seen;
        this.validateStep(STEPS.speedBump);
    }

    get moodFrequencies(): any {
        return this.formData.moodFrequencies;
    }

    set moodFrequencies(frequencies: any) {
        this.formData.moodFrequencies = frequencies;
        this.validateStep(STEPS.moodFrequency);
    }

    get transitionToCounselor(): boolean {
        return this.formData.transitionToCounselor;
    }
    set transitionToCounselor(transitioned: boolean) {
        this.formData.transitionToCounselor = transitioned;
        this.validateStep(STEPS.transitionToCounselor);
    }

    get additionalInsurance(): { isPartImportnant: string, provider: string } {
        return this.formData.additionalInsurance;
    }

    set additionalInsurance(selections: { isPartImportnant: string, provider: string }) {
        this.formData.additionalInsurance = selections;
        this.validateStep(STEPS.additionalInsurance);
    }

    get counselingFor(): CounselingFor {
        return this.formData.counselingFor;
    }

    set counselingFor(selections: CounselingFor) {
        this.formData.counselingFor = selections;
        this.validateStep(STEPS.counselingFor);
    }

    set modeOfHelp(mode: string) {
        this.formData.modeOfHelp = mode;
        this.validateStep(STEPS.modeOfHelp);
    }

    get modeOfHelp(): string {
        return this.formData.modeOfHelp;
    }

    get workWithCounselorOption(): string {
        return this.formData.workWithCounselorOption;
    }

    set workWithCounselorOption(option: string) {
        this.formData.workWithCounselorOption = option;
        this.validateStep(STEPS.workWithCounselor);
    }

    set user(value) {
        this.formData.user = value;
    }

    get user(): any {
        return this.formData.user;
    }

    set companyCode(value) {
        this.formData.companyCode = value;
    }

    get companyCode() {
        return this.formData.companyCode;
    }

    set emotionalCareTypeRequiresCounselingLocationStep(value) {
        this.formData.emotionalCareTypeRequiresCounselingLocationStep = value;
    }

    get emotionalCareTypeRequiresCounselingLocationStep() {
        return this.formData.emotionalCareTypeRequiresCounselingLocationStep;
    }

    public get riskAtIntake(): string {
        return this.formData.riskAtIntake;
    }
    public set riskAtIntake(value: string) {
        this.formData.riskAtIntake = value;
    }

    public get depressionAtIntake(): string {
        return this.formData.depressionAtIntake;
    }
    public set depressionAtIntake(value: string) {
        this.formData.depressionAtIntake = value;
    }

    getFormData(): IntakeFlowFormData {
        return this.formData;
    }

    resetFormData(): IntakeFlowFormData {
        this.resetSteps();
        this.formData.reset();
        return this.formData;
    }

    clearCarePath() {
        this.formData.clearCarePath();
    }

    isFormValid(): boolean {
        // check that all formData fields were populated.
        return !this.workflowSteps.some(elem => {
            return elem.valid === false;
        })
    }

    private workflowSteps = [
        // the order matters
        // { step: STEPS.employerInformation2, valid: false },
        // { step: STEPS.ssoEmail, valid: false },
        { step: STEPS.carePath, valid: false },
        { step: STEPS.emotionalState, valid: false },
        { step: STEPS.moodFrequency, valid: false },
        { step: STEPS.speedBump, valid: false },
        { step: STEPS.riskGauge, valid: false },
        { step: STEPS.stressIntakeSurvey, valid: false },
        { step: STEPS.lifeSatisfaction, valid: false },
        { step: STEPS.modeOfHelp, valid: false },
        // { step: STEPS.employeeNumber, valid: false },
        // { step: STEPS.transitionToCounselor, valid: false },
        // { step: STEPS.workWithCounselor, valid: false },
        { step: STEPS.counselingLocation, valid: false },
        // { step: STEPS.additionalInsurance, valid: false },
        { step: STEPS.counselingFor, valid: false },

    ];

    private workLifeSteps = [
        // the order matters
        { step: STEPS.workLife, valid: false },
        { step: STEPS.workLifeSubCategory, valid: false },
    ];

    private getWorkFlowStepIndex(step: string): number {
        return this.workflowSteps.findIndex(elem => elem.step === step);
    }

    validateStep(step: string): void {
        const index = this.getWorkFlowStepIndex(step);
        if (index >= 0) { this.workflowSteps[index].valid = true };
    }

    invalidateStep(step: string): void {
        const index = this.getWorkFlowStepIndex(step);
        if (index >= 0) { this.workflowSteps[index].valid = false };
    }

    getNextStep(step: string): string {
        const stepIndex = this.getWorkFlowStepIndex(step);
        if (stepIndex < 0 || (stepIndex + 1) >= this.workflowSteps.length) return '';
        return this.workflowSteps[stepIndex + 1].step;
    }

    getPreviousStep(step: string): string {
        let stepIndex = this.getWorkFlowStepIndex(step);
        let workFlow = this.workflowSteps;
        if (stepIndex <= 0) {
            stepIndex = this.workLifeSteps.findIndex(elem => elem.step === step);
            workFlow = this.workLifeSteps;
        }
        if (stepIndex <= 0) return 'care-path';
        return workFlow[stepIndex - 1].step;
    }

    disableStep(step: string): boolean {
        const stepIndex = this.getWorkFlowStepIndex(step);
        if (stepIndex < 0) return false;
        this.workflowSteps.splice(stepIndex, 1);
        return true;
    }

    reEnableStep(step: string, turnOffIfEnabled = false): boolean {
        const result = this.workflowSteps.find(element => element.step === step);
        if (result && turnOffIfEnabled) {
            const stepIndex = this.getWorkFlowStepIndex(step);
            if (stepIndex < 0) return false;
            this.workflowSteps.splice(stepIndex, 1);
            return false;
        } else if (result || turnOffIfEnabled) {
            return;
        } else {
            this.workflowSteps.splice(0, 0, { step: step, valid: false });
            return true;
        }
    }

    resetSteps(): void {
        // reset all the steps to invalid for start over.
        this.workflowSteps.forEach(elem => { elem.valid = false });
    }

    getFirstInvalidStep(step: string): string {
        // if all previous steps are valid, return blank, else return the first invalid step
        // used by the route guard.
        let inValidStep: string = '';
        this.workflowSteps.some(elem => {
            if (elem.step === step) {
                return true;
            } else {
                if (!elem.valid) {
                    inValidStep = elem.step;
                    return true;
                }
            }
        });
        return inValidStep;
    }

    setCarePathProgress(step: string) {
        const blockNumber = this.workflowSteps.map(s => s.step).indexOf(step);
        const numOfSteps = this.workflowSteps.length + 1; // for the employee information screen

        this.progressBarService.progress = { progress: blockNumber + 1, numberOfBlocks: numOfSteps, width: 10 };
    }



}
