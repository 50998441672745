<div class="animate__animated animate__slideInRight animate__faster">
  <div class="row justify-content-center text-center">
    <h4 class="col-12 top header-text font-size-36 font-500 font-montserrat color-black">
      I believe my counselor or coach is concerned about my welfare. </h4>
    <h5 class="small_title mt32 mb45">Please indicate how you feel about this statement.</h5>

  </div>
  <ngx-slider [(value)]="value" [options]="options" (click)="changeValueEvent()" class="mb75"
    [ngClass]="{'remove_pointer': !isShowAnswer}">
  </ngx-slider>
  <p class="survey_answer">Your answer : <span class="font-500" *ngIf=isShowAnswer>{{selectedValue}} -
      {{selectedText}}</span> <span *ngIf=!isShowAnswer>--</span>
  </p>
  <div class="row justify-content-center no-gutters mb24">
    <button class="btn btn-primary col-12 col-md-5" (click)="goNext()" [disabled]="submitted || disableButton">CONTINUE
      <i class="fas fa-arrow-circle-right"></i></button>
  </div>
</div>