export const mapAllowedMessageProps = (allowedParam: string | boolean) => {
  switch (typeof allowedParam) {
    case 'string':
      return allowedParam === 'Okay to leave message';
    case 'boolean':
      return allowedParam;
    default:
      return false;
  }
 }
