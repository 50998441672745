<ngx-navbar></ngx-navbar>
<router-outlet></router-outlet>
<script type = "text/javascript" src = 'https://home-c62.nice-incontact.com/inContact/ChatClient/js/embed.min.js'></script>

<script type ="text/javascript">

icPatronChat.init({serverHost:'https://home-c62.nice-incontact.com',bus_no:4605795,poc:'d22041e1-7f46-49f2-9aee-37aa5db75304',params:['FirstName','Last Name','first.last@company.com',555-555-5555]});

</script>
<ngx-moment-support></ngx-moment-support>
