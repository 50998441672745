import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-instructions-tooltip',
  templateUrl: './instructions-tooltip.component.html',
  styleUrls: ['./instructions-tooltip.component.scss'],
})
export class InstructionsTooltipComponent implements OnInit {
  @Input()
  message: String;

  @Input()
  popOverMessage: String;

  @Input()
  attachToCorner: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
