import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Address } from '../../../@core/data/model/address';
import { GeocodedAddressResult } from './models/GeocodedAddress';

const DEFAULT_COUNTRY = 'USA';

@Injectable({ providedIn: 'root' })
export class GeocodeingService implements OnInit {
  GEOCODE_ENDPOINT = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
  API_KEY = 'AIzaSyCr2FFHgGQ3gZMoLi3G6ucKIKd2g9YJ3fM';

  constructor(private http: HttpClient) { }

  ngOnInit(): void { }

  getGeocodeFromAddress(address: Address): Observable<GeocodedAddressResult>  {
    return this.http.get<GeocodedAddressResult>(this.GEOCODE_ENDPOINT +
      address.street + ' ' + address.city + ' ' + address.state + ' ' +
      DEFAULT_COUNTRY + '&key=' + this.API_KEY)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
