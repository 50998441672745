import { OnInit } from '@angular/core';
import { ConfirmationModal } from '../modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../environments/environment';
import { LOCAL_SERVICE } from '../../../../../@core/utils/constants';
var CounselorDetailsModalComponent = /** @class */ (function () {
    function CounselorDetailsModalComponent(activeModal) {
        this.activeModal = activeModal;
        this._modal = { context: {}, modalHeader: '', options: false };
        this.user = {};
        this.souAgreed = false;
        this.modality = '';
        this.message = '';
        this.submitted = false;
        this.zipCode = '';
        this.radius = '';
        this.counselingFor = '';
        this.reasonForSwitch = '';
    }
    Object.defineProperty(CounselorDetailsModalComponent.prototype, "modal", {
        set: function (value) {
            if (Object.keys(value).length > 0) {
                this.message = "";
                this.heading = "Switching to " + LOCAL_SERVICE + " Counseling?";
            }
        },
        enumerable: true,
        configurable: true
    });
    CounselorDetailsModalComponent.prototype.ngOnInit = function () {
        this.reasons = environment.reasons;
    };
    CounselorDetailsModalComponent.prototype.closeModal = function () {
        this.activeModal.close({ answer: ConfirmationModal.No, user: this.user });
    };
    CounselorDetailsModalComponent.prototype.accept = function () {
        this.activeModal.close({ answer: ConfirmationModal.Yes,
            user: this.user,
            radius: this.radius,
            zipCode: this.zipCode,
            counselingFor: this.counselingFor,
            reasonForSwitch: this.reasonForSwitch,
        });
    };
    CounselorDetailsModalComponent.prototype.completeRequest = function () {
        if (this.submitted)
            return;
        this.submitted = true;
        this.accept();
    };
    return CounselorDetailsModalComponent;
}());
export { CounselorDetailsModalComponent };
