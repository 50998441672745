<ng-container *ngIf="enableMaintenanceMode; then maintenace; else normal">
</ng-container>
<ng-template #maintenace>
  <!-- <div class="topnav" [class.responsive]="responsive">
    <a class="icon" (click)="makeResponsive()">
      <i class="fa fa-bars"></i>
    </a>
  </div> -->
  <div class="row masthead justify-content-center">
    <div class="container ie-landing-home">
      <div class="row justify-content-center">
        <div class="col-12 col-xl-2 col-md-8 col-sm-12">
          <h1>Welcome to Concern</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-xl-8 col-md-10 col-sm-8">
          <h1 class="landing-text header-text" style="margin-top: 100px;">Under Maintenance</h1>
          <!-- <h4 class="landing-text subheader-text mx-3">{{subHeaderText}}</h4> -->
        </div>
      </div>
      <div class=" container row justify-content-center">
        <div class="col-12 text-center ">
          <!-- <a class="btn button-text btn-outline-primary mr-md-4 mr-lg-4 mr-xl-4 mr-sm-3 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0 mb-3 learnMore"
            (click)="emitLearnMoreEvent()">{{buttonTextOutline}}</a>
          <a class="btn button-text btn-primary" routerLink="{{getHelpLink}}">{{buttonText}}</a> -->
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <!-- <p class="landing-text account-text">Already have an account?
            <a class="sing-in" routerLink="../auth/login">Sign in</a>
            now
          </p> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #normal>
  <!-- <div class="topnav" [class.responsive]="responsive">
    <a class="icon" (click)="makeResponsive()">
      <i class="fa fa-bars"></i>
    </a>
  </div> -->
  <div class="row masthead justify-content-center">
    <div class="container ie-landing-home">
      <div class="row justify-content-center">
        <div class="col-12 welcome">
          Welcome to Concern
        </div>
      </div>
      <div class="row justify-content-center header-text">
        <div class="col-12">
          <h1 class="landing-text">Personalized Counseling, Life-Balance,</h1>
        </div>
        <div class="col-12">
          <h1 class="landing-text">and Emotional Wellbeing Resources</h1>
        </div>
      </div>
      <div class="row justify-content-center">
          <ng-template #homepageInstructionsStepOne>
              <div class="heading">
                Getting Started
              </div>
              <p>
                  Click on EXPLORE SERVICES to use Concern’s Digital Access, then answer a few questions to get help with counseling, self-help, or life balance services, and get personalized recommendations tailored to fit your needs.
              </p>
            </ng-template>
        <div class="col-12 text-center stepContainer">
          <a class="btn button-text btn-outline-secondary" #exploreServicesBtn routerLink="{{getHelpLink}}">{{buttonText}}</a>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <p class="landing-text account-text">Already have an account?
            <a class="sing-in" routerLink="../auth/login">Sign in</a>
            now
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>