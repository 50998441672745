import { Component, OnInit } from '@angular/core';
import { PillSelectorItem } from '../../../@theme/components/reusables';
import { Router, ActivatedRoute } from '@angular/router';
import { EmotionalWellbeingSurveyService } from '../../../@core/utils/emotional-wellbeing-survey.service';
import { SATISFIED_RESPONSES, RISK } from '../../../@core/utils/enums';
import { SurveyService } from '../../../@core/data/api/survey.service';
import { SurveyCalcService } from '../../../@core/utils/survey-calc.service';
import { take } from 'rxjs/operators';

const RESPONSES = [
  SATISFIED_RESPONSES.VERY_SATISFIED,
  SATISFIED_RESPONSES.SLIGHTLY_SATISFIED,
  SATISFIED_RESPONSES.SLIGHTLY_DISSATISFIED,
  SATISFIED_RESPONSES.VERY_DISSATISFIED,
];
const ROUTE_NAME = 'satisfied-life';

@Component({
  selector: 'ngx-satisfied-life',
  templateUrl: './satisfied-life.component.html',
  styleUrls: ['./satisfied-life.component.scss'],
})
export class SatisfiedLifeComponent implements OnInit {


  title: string;
  subHeader: string;
  isShowValid: boolean;
  pillItems: PillSelectorItem[];
  submitted: boolean = false;
  selectedFrequencyIndices: [number, number, number, number] = [-1, -1, -1, -1];
  tooltip = `This question helps us understand your satisfaction with your daily life so that we can guide you to the right level of support.`;
  feelingsData: string[];
  stressData: string[];
  satisfiedData: string[];
  riskLevel: RISK;
  feelingsNervous: string;
  controlWorryingData: string;
  feelingDepressedData: string;
  littleInterestData: string;

  constructor(private router: Router,
    private emotionalWellBeingService: EmotionalWellbeingSurveyService,
    private activatedRoute: ActivatedRoute, private surveyService: SurveyService, private surveyCalcService: SurveyCalcService) {
    this.emotionalWellBeingService.setCarePathProgress(ROUTE_NAME);

    this.pillItems = [
      {
        caption: '',
        items: RESPONSES,
      },

    ];
  }


  ngOnInit() {
    this.emotionalWellBeingService.setCarePathProgress(ROUTE_NAME);
    if (this.emotionalWellBeingService.satisfiedLifeData) {
      this.selectedFrequencyIndices[0] = this.pillItems[0].items.findIndex(e => e === this.emotionalWellBeingService.satisfiedLifeData[0]);
      this.isValidState();
    }

  }


  isValidState() {
    this.isShowValid = this.selectedFrequencyIndices[0] < 0;
  }
  private gatherData() {
    this.feelingsData = this.emotionalWellBeingService.feelingsData;
    // this.feelingsNervous = this.feelingsData[0];
    // this.controlWorryingData = this.feelingsData[1];
    // this.feelingDepressedData = this.feelingsData[2];
    // this.littleInterestData = this.feelingsData[3];
    this.stressData = this.emotionalWellBeingService.stressLifeData;
    this.satisfiedData = this.emotionalWellBeingService.satisfiedLifeData;
    this.riskLevel = this.surveyCalcService.calculateRisk();
  }

  goNext(): void {
    this.submitted = true;
    try {
      const satisfiedLifeData = [
        this.pillItems[0].items[this.selectedFrequencyIndices[0]],
      ];
      this.emotionalWellBeingService.satisfiedLifeData = satisfiedLifeData;
      this.gatherData();
      const selectedData = [this.feelingsData, this.stressData, this.satisfiedData, this.riskLevel]
      const data = this.convertSelectionsToAnswer(selectedData);
      const model = {
        data, config: {
          name: 'emotional wellbeing survey',
          lastTaken: new Date().toISOString(), showSurvey: false,
        },
      };

      this.surveyService.updateEmotionalSurvey(model).subscribe((response: any) => {
      }, err => {
        // error saving data
      });


      this.surveyService.createEmotionalSurvey(data).pipe(take(1)).subscribe((response: any) => {
      }, err => {

      });

      const nextStep = this.emotionalWellBeingService.getNextStep(ROUTE_NAME);
      this.router.navigate([`../${nextStep}`], { relativeTo: this.activatedRoute });
    } catch (err) { this.submitted = false }
  }
  private convertSelectionsToAnswer([feelingsData, stressData, satisfiedData, riskLevel]: any) {

    return { feelingsData, stressData, satisfiedData, riskLevel };
  }

}
