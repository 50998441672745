<div class="stress_content animate__animated animate__slideInRight animate__faster feelings_content">

  <div class="row justify-content-center no-gutters">
    <h4 class="col-12 top font-size-36 font-weight-500 mb32 text-center font-black">Tell us about your level of stress.
      <ng-container *ngIf="tooltip">
        <ngx-instructions-tooltip [popOverMessage]="tooltip" [attachToCorner]="false"></ngx-instructions-tooltip>
      </ng-container>
    </h4>
  </div>
  <div class="pill_title font-size-24 font-weight-400 text-center font-black">Stress is a situation where you feel
    tense, restless, nervous, anxious, or unable to sleep at night because your mind is troubled.</div>
  <div class="pill_title font-size-24 font-weight-400 text-center mb16 font-black">Over the <b>past 30 days</b> how
    often have you felt this kind of stress?</div>
  <ngx-emotional-pill-selector [caption]="pillItems[0].caption" [items]="pillItems[0].items"
    (selectedItemIndex)="selectedFrequencyIndices[0] = $event" [(selectedIndex)]="selectedFrequencyIndices[0]"
    (isDisable)="isValidState()">
  </ngx-emotional-pill-selector>

  <div class="row justify-content-center no-gutters mt8">
    <button class="btn btn-primary col-12 col-md-5 mb24" (click)="goNext()"
      [disabled]="submitted || isShowValid">CONTINUE
      <i class="fas fa-arrow-circle-right"></i></button>
  </div>
</div>