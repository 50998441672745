<div class="animate__animated animate__slideInRight animate__faster thankyou">
  <div class="row justify-content-center text-center">
    <h4 class="col-12 top header-text font-size-36 font-500 font-montserrat color-black mb64">
      Thank you for completing this <span class="break-line">survey! </span></h4>
  </div>

  <div class="row justify-content-center no-gutters mb24" *ngIf="isShowLogin">
    <button class="btn btn-primary col-12 col-md-5" (click)="login()">LOGIN
      <i class=" fas fa-arrow-circle-right"></i></button>
    <p class="login_text d-block col-12 mt10 text-center">Login to access your full services</p> 
  </div>
</div>
