var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasicAuthScreen } from '../auth.component';
import { NbAuthService, NbTokenStorage } from '@nebular/auth';
import { AnalyticsService } from '../../../../@core/utils/analytics.service';
import { VaraAuthService } from '../services/VaraAuthService';
import { CookieService } from '../../../../../../node_modules/ngx-cookie-service';
import { IntakeFlowService } from '../../../../@core/utils/intake-flow.service';
var DxChangePasswordComponent = /** @class */ (function (_super) {
    __extends(DxChangePasswordComponent, _super);
    function DxChangePasswordComponent(service, options, router, route, tokenStorage, analytics, varaAuthService, intakeService, cookieService) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, service, options, router, route, analytics, intakeService, cookieService) || this;
        _this.service = service;
        _this.options = options;
        _this.router = router;
        _this.route = route;
        _this.tokenStorage = tokenStorage;
        _this.varaAuthService = varaAuthService;
        _this.intakeService = intakeService;
        _this.cookieService = cookieService;
        _this.prefix = 'forms.login'; // change later to forms.passwordChange
        _this.analyticsTrack = 'User changing password.';
        _this.user = {};
        return _this;
    }
    DxChangePasswordComponent.prototype.ngOnInit = function () { };
    DxChangePasswordComponent.prototype.changePassword = function () {
        var _this = this;
        this.submitted = true;
        this.varaAuthService
            .changeUserPassword(this.user.current_password, this.user.new_password)
            .subscribe(function (data) {
            if (data) {
                _this.showMessages.success = true;
                _this.showMessages.error = false;
                _this.messages = [
                    'Your password was updated successfuly!, you will now be redirected to the login screen.',
                ];
                // display message and logout then navigate to login
                setTimeout(function () {
                    _this.router.navigate(['/auth/logout']);
                    _this.submitted = false;
                }, 5000);
            }
        }, function (errors) {
            _this.showMessages.error = true;
            _this.submitted = false;
            _this.errors = ['The password you specified is not correct, please specify another'];
        });
    };
    return DxChangePasswordComponent;
}(BasicAuthScreen));
export { DxChangePasswordComponent };
