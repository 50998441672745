import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-selector-xl',
  templateUrl: './selector-xl.component.html',
  styleUrls: ['./selector-xl.component.scss'],
})
export class SelectorXlComponent {
  @Input() items: string[];
  @Input() description: any;
  @Input() selectedIndex: number;
  @Output() selectedIndexChange = new EventEmitter<number>();

  onClick(index: number) {
      this.selectedIndex = index;
      this.selectedIndexChange.emit(index);
  }
}
