import { ModuleWithProviders, NgModule, Optional, SkipSelf, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { DataModule } from './data/data.module';
import { AnalyticsService } from './utils/analytics.service';
import { CookieService } from 'ngx-cookie-service';
import { NbAuthModule, NbPasswordAuthStrategy, NbPasswordAuthStrategyOptions, NbAuthStrategyClass, NbAuthJWTToken } from '@nebular/auth';
import { TokenInterceptor } from '../token-interceptor';
import { HeadersInterceptor } from '../headers-interceptor';
import { HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { IntakeFlowService } from './utils/intake-flow.service';
import { SurveyDataService } from './utils/survey-data.service';
import { RiskCalcService } from './utils/risk-calc.service';
import { OrgService, CaseService } from './data';
import { EmployerInformationService } from '../pages/intake/employer-information/employer-information.service';
import { EmployerInformationResolver } from '../pages/intake/employer-information/employer-information.resolver';
import { environment } from '../../environments/environment';
import { ModalService } from './utils/modal.service';
import { SsoAuthGuardService, OnlySsoAuthGuardService } from './utils/sso-auth-guard.service';
import { userRoutes } from './data/api/constants';
export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export function tokenGetter(module: string, res: HttpResponse<Object>, options: NbPasswordAuthStrategyOptions) {
  const tokenData: any = { ...res.body, retrievalDate: new Date().toISOString() };
  window.localStorage.setItem('token_data', JSON.stringify(tokenData));
  return tokenData.accessToken;
}

/**
 * This is a workaround for nebular which can not handle multiple instances of the same auth strategy
 */
@Injectable({ providedIn: 'root' })
export class NbCredentialAuthStrategy extends NbPasswordAuthStrategy {
  static setup(options: NbPasswordAuthStrategyOptions): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
    return [NbCredentialAuthStrategy, options]; // HERE we make sure our strategy return correct class reference
  }
}
@Injectable({ providedIn: 'root' })
export class NbSsoAuthStrategy extends NbPasswordAuthStrategy {
  static setup(options: NbPasswordAuthStrategyOptions): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
    return [NbSsoAuthStrategy, options]; // HERE we make sure our strategy return correct class reference
  }
}
// Demo Salesforce provider
@Injectable({ providedIn: 'root' })
export class NbSso1AuthStrategy extends NbPasswordAuthStrategy {
  static setup(options: NbPasswordAuthStrategyOptions): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
    return [NbSso1AuthStrategy, options]; // HERE we make sure our strategy return correct class reference
  }
}

export const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  ...NbAuthModule.forRoot({
    strategies: [
      NbSso1AuthStrategy.setup({
        name: 'sso1',
        baseEndpoint: environment.basePath,
        login: {
          endpoint: '/authenticate/salesforce',
          redirect: {
            success: '/pages/dashboard',
            failure: null,
          },
          defaultErrors: ['The SSO provider failed to authenticate successfully.'],
        },
        logout: {
          endpoint: '/users/logout',
          method: 'post',
          redirect: {
            success: '/auth/login',
            failure: null,
          },
        },
        errors: {
          key: 'errors',
        },
        messages: {
          key: 'errors',
        },
        token: {
          key: 'id',
        },
      }),
      NbCredentialAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.basePath,
        login: {
          endpoint: userRoutes.postLogin,
          redirect: {
            success: '/pages/dashboard',
            failure: null,
          },
          defaultErrors: ['The email or password that you entered is incorrect. Please contact 800-344-4222 for assistance'],
        },
        register: {
          endpoint: userRoutes.user,
          redirect: {
            success: '/auth/verify-email',
            failure: null,
          },
        },
        logout: {
          endpoint: userRoutes.getUserLogout,
          method: 'get',
          redirect: {
            success: '/auth/login',
            failure: null,
          },
        },
        requestPass: {
          endpoint: userRoutes.postUserForgotPassword,
          redirect: {
            success: '/',
            failure: null,
          },
          defaultMessages: [`Reset Password instructions sent. If you haven\'t received this
          email within 5 minutes, please double-check the email entered or contact Concern at concern-support@medullan.com`],
        },
        resetPass: {
          endpoint: userRoutes.postUserResetPassword,
          method: 'post',
          redirect: {
            success: null,
            failure: null,
          },
        },
        refreshToken: {
          endpoint: userRoutes.postRefreshToken,
          method: 'post',
          requireValidToken: true,
          redirect: {
            success: null,
            failure: '/auth/login'
          },
          defaultErrors: ['Something went wrong, please try again.'],
          defaultMessages: ['Your token has been successfully refreshed.'],
        },
        errors: {
          key: 'errors',
        },
        messages: {
          key: 'errors',
        },
        token: {
          class: NbAuthJWTToken,
          key: 'accessToken',
          getter: tokenGetter,
        },
      }),
    ],
    forms: {
      login: {
        redirectDelay: 0, // delay before redirect after a successful login, while success message is shown to the user
        rememberMe: false,   // whether to show or not the `rememberMe` checkbox
        showMessages: {     // show/not show success/error messages
          success: true,
          error: true,
        },
      },
      validation: {
        password: {
          required: true,
          minLength: 8,
          maxLength: 50,
        },
        email: {
          required: true,
        },
        firstName: {
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        lastName: {
          required: true,
          minLength: 2,
          maxLength: 50,
        },
      },
    },
  }).providers,
  ...NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  NbCredentialAuthStrategy,
  NbSsoAuthStrategy,
  AnalyticsService,
  IntakeFlowService,
  CookieService,
  OrgService,
  CaseService,
  EmployerInformationService,
  EmployerInformationResolver,
  RiskCalcService,
  SurveyDataService,
  ModalService,
  SsoAuthGuardService,
  OnlySsoAuthGuardService,
  SurveyDataService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HeadersInterceptor,
    multi: true,
  },

];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS],
    };
  }
}
