import { Component } from '@angular/core';

@Component({
  selector: 'ngx-landing',
  styleUrls: ['./landing.compoment.scss'],
  templateUrl: './landing.component.html',
})
export class LandingComponent {
  learnMoreNavEvent: Event;
  public learnMoreNavFn(event: Event) {
    this.learnMoreNavEvent = event;
  }
}
