import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { BasicAuthScreen } from '../auth.component';
import { NbAuthService, NbTokenStorage, NB_AUTH_OPTIONS } from '@nebular/auth';
import { AnalyticsService } from '../../../../@core/utils/analytics.service';
import { VaraAuthService } from '../services/VaraAuthService';
import { CookieService } from '../../../../../../node_modules/ngx-cookie-service';
import { IntakeFlowService } from '../../../../@core/utils/intake-flow.service';

@Component({
  selector: 'nb-change-password',
  styleUrls: ['./change-password.component.scss'],
  templateUrl: './change-password.component.html',
  providers: [VaraAuthService],
})
export class DxChangePasswordComponent extends BasicAuthScreen
  implements OnInit {
  ngOnInit(): void {}
  prefix = 'forms.login'; // change later to forms.passwordChange
  analyticsTrack = 'User changing password.';
  user: any = {};

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    public router: Router,
    protected route: ActivatedRoute,
    public tokenStorage: NbTokenStorage,
    analytics: AnalyticsService,
    private varaAuthService: VaraAuthService,
    protected intakeService: IntakeFlowService,
    protected cookieService: CookieService,
  ) {
    super(service, options, router, route, analytics, intakeService, cookieService);
  }

  changePassword(): void {
    this.submitted = true;
    this.varaAuthService
      .changeUserPassword(this.user.current_password, this.user.new_password)
      .subscribe(
        data => {
          if (data) {
            this.showMessages.success = true;
            this.showMessages.error = false;
            this.messages = [
              'Your password was updated successfuly!, you will now be redirected to the login screen.',
            ];
            // display message and logout then navigate to login
            setTimeout(() => {
              this.router.navigate(['/auth/logout']);
              this.submitted = false;

            }, 5000);
          }
        },
        errors => {
          this.showMessages.error = true;
          this.submitted = false;
          this.errors = ['The password you specified is not correct, please specify another'];
        },
      );
  }
}
