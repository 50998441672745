
<ng-template #icon>
    {{message}}
    <i class="fa fa-info-circle icircle"
    placement="left"
    popoverClass="instruction-popover"
    ngbPopover="{{popOverMessage}}">
    </i>
</ng-template>

<ng-container>
    <div *ngIf="attachToCorner">
      <ng-container *ngTemplateOutlet="icon"></ng-container>
    </div>
    <span *ngIf="!attachToCorner">
      <ng-container *ngTemplateOutlet="icon"></ng-container>
    </span>
</ng-container>