<div class="w-100 footer p-0 py-lg-5 m-0">
    <div class="row text-center w-100">
        <div class="col-12 col-lg-3 mb-3 mb-lg-0 logo-panel">
            <a class="" routerLink="/"><img class="footer-img-responsive"
                    src="/assets/images/logo/concern-one-color-logo.png"></a>
        </div>

        <div class="col-6 offset-1 col-md-3 offset-md-3 col-lg-3 offset-lg-0 links-panel">
            <ul class="p-0">
                <li>
                    <a href="{{env.concernHealth}}/about-counseling" class="footer-link" (click)="GaEvent('About Counseling');">About Counseling</a>
                </li>
                <li>
                    <a href="{{env.concernHealth}}/resources" class="footer-link" (click)="GaEvent('Browse Library');">Browse Library</a>
                </li>
                <li>
                    <a href="/?tab=my-care" class="footer-link" (click)="GaEvent('My Care');">My Care</a>
                </li>
                <li>
                    <a routerLink="/pages/contact-us" class="footer-link" (click)="GaEvent('Contact Us');">Contact Us</a>
                </li>
            </ul>
        </div>

        <div class="col-4 offset-1 col-lg-3 offset-lg-0 links-panel">
            <ul class="p-0">
                <li>
                    <a href="{{env.concernHealth}}/hipaa-privacy" class="footer-link" (click)="GaEvent('HIPAA Privacy');">HIPAA Privacy</a>
                </li>
                <li>
                    <a routerLink="/auth/privacy" class="footer-link" (click)="GaEvent('Online Privacy');">Online Privacy</a>
                </li>
                <li>
                    <a routerLink="/auth/terms" class="footer-link" (click)="GaEvent('Terms of Use');">Terms of Use</a>
                </li>
                <li>
                    <a href="{{ env.concernHealth }}/language-assistance-program" class="footer-link" (click)="GaEvent('Language Services');">Language Services</a>
                </li>
            </ul>
        </div>

        <div class="col-12 col-lg-3 text-center">
            <span class="For-immediate-suppor">
                FOR IMMEDIATE SUPPORT CALL
            </span><br>
            <a href="tel:800-344-4222" class="contact-number">
                800.344.4222
            </a>
        </div>

    </div>
</div>