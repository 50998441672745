import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmotionalWellbeingSurveyService, STEPS } from '../../../../@core/utils/emotional-wellbeing-survey.service';

@Component({
  selector: 'ngx-emotional-navigation-buttons',
  templateUrl: './emotional-navigation-buttons.component.html',
  styleUrls: ['./emotional-navigation-buttons.component.scss'],
})
export class EmotionalNavigationButtonsComponent implements OnInit {

  @Input() backOnly: boolean;


  constructor(private router: Router, private emotionalSurveyService: EmotionalWellbeingSurveyService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() { }

  goBack(): void {
    try {
      const currentUrl = this.router.url;
      const arrPath = currentUrl.split('/');
      const currentStep = arrPath[arrPath.length - 1];
      const previousStep = this.emotionalSurveyService.getPreviousStep(currentStep);
      this.router.navigate([`${previousStep}`], { relativeTo: this.activatedRoute });
    } catch (exception) {
      console.error(exception);
    }
  }

  startOver(): void {
    try {
      this.emotionalSurveyService.resetFormData();
      const firstStep = STEPS.feelingsPath;
      this.router.navigate([`${firstStep}`], { relativeTo: this.activatedRoute });
    } catch (exception) {
      console.error(exception);
    }

  }

}
