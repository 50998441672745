import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { contentRoutes, providerRoutes } from './constants';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../variables";
import * as i4 from "ngx-cookie-service/cookie-service/cookie.service";
var ContentService = /** @class */ (function () {
    function ContentService(_httpClient, domSanitizer, basePath, cookieService) {
        this._httpClient = _httpClient;
        this.domSanitizer = domSanitizer;
        this.cookieService = cookieService;
        this.basePath = environment.basePath;
        this.cache = {};
        this.ring = [];
        this.max = 20;
        if (basePath) {
            this.basePath = basePath;
        }
    }
    ContentService.prototype.saveContentRating = function (_a) {
        var contentId = _a.contentId, isHelpful = _a.isHelpful, isSaved = _a.isSaved;
        return this._httpClient.post("" + this.basePath + contentRoutes.postContentRating, { contentId: contentId, isHelpful: isHelpful, isSaved: isSaved });
    };
    /*
       get all the self help recommended contents by case ids
    */
    ContentService.prototype.getContentByCaseIds = function (caseIds, benefits) {
        if (benefits === void 0) { benefits = []; }
        var org = this.cookieService.check('companyName') ? this.cookieService.get('companyName') : '';
        return this._httpClient.post("" + this.basePath + contentRoutes.getRecommendedContents, { caseIds: caseIds, org: org, benefits: benefits });
    };
    ContentService.prototype.getContentById = function (id) {
        return this._httpClient.get("" + this.basePath + contentRoutes.getContentById + "?id=" + id);
    };
    /** Get General Content */
    ContentService.prototype.getGeneralContent = function () {
        return this._httpClient.get("" + this.basePath + contentRoutes.getGeneralContent);
    };
    ContentService.prototype.getProviderImage = function (providerId) {
        var _this = this;
        var item = this.cache[providerId];
        if (item) {
            return item;
        }
        ;
        var added = this.cache[providerId] = this._httpClient.get("" + this.basePath + providerRoutes.getProviderImage + "/" + providerId, { responseType: 'blob' })
            .pipe(map(function (blob) { return _this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)); }), catchError(function (err) { return of(null); }));
        this.ring.push(providerId);
        if (this.ring.length > this.max) {
            var removeId = this.ring.shift();
            delete this.cache[removeId];
        }
        return added;
    };
    ContentService.prototype.getServiceContent = function (services) {
        var organization = this.cookieService.check('companyName') ? this.cookieService.get('companyName') : '';
        return this._httpClient.post("" + environment.basePath + contentRoutes.getServiceContent, { services: services, organization: organization });
    };
    ContentService.prototype.getFAQ = function () {
        return this._httpClient.get("" + this.basePath + contentRoutes.getFAQ);
    };
    ContentService.ngInjectableDef = i0.defineInjectable({ factory: function ContentService_Factory() { return new ContentService(i0.inject(i1.HttpClient), i0.inject(i2.DomSanitizer), i0.inject(i3.BASE_PATH, 8), i0.inject(i4.CookieService)); }, token: ContentService, providedIn: "root" });
    return ContentService;
}());
export { ContentService };
