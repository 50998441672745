/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./progress-bar.component-nps";
var styles_NpsProgressBarComponent = [i0.styles];
var RenderType_NpsProgressBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NpsProgressBarComponent, data: {} });
export { RenderType_NpsProgressBarComponent as RenderType_NpsProgressBarComponent };
function View_NpsProgressBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "block"], ["style", "height: 8px;"]], [[4, "width", "%"]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "current": 0, "activated": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "block"; var currVal_2 = _ck(_v, 2, 0, (_v.context.$implicit == _co.progress.progress), (_v.context.$implicit < _co.progress.progress)); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.progress.width; _ck(_v, 0, 0, currVal_0); }); }
function View_NpsProgressBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"], ["style", "margin: 0; display: block;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "progress-block w-100 col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NpsProgressBarComponent_2)), i1.ɵdid(3, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.range; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_NpsProgressBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NpsProgressBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NpsProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-progress-bar-nps", [], null, null, null, View_NpsProgressBarComponent_0, RenderType_NpsProgressBarComponent)), i1.ɵdid(1, 638976, null, 0, i3.NpsProgressBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NpsProgressBarComponentNgFactory = i1.ɵccf("ngx-progress-bar-nps", i3.NpsProgressBarComponent, View_NpsProgressBarComponent_Host_0, { progress: "progress", visible: "visible" }, {}, []);
export { NpsProgressBarComponentNgFactory as NpsProgressBarComponentNgFactory };
