import { Injectable } from '@angular/core';
import { ProgressBarService } from './progress-bar.service';
import { BehaviorSubject } from 'rxjs';


export interface Progress {
  progress: number,
  numberOfBlocks: number,
  width?: number
};
export const STEPS = {
  // let the string value match the router path for convenient routing
  feelingsPath: 'feelings',
  stressPath: 'stress-life',
  satisfiedPath: 'satisfied-life',
  emotionThankyou: 'thankyou-survey',
}

export class EmotionalFlowFormData {
  feelingsPath: string = '';
  stressPath: string = '';
  satisfiedPath: string = '';
  emotionThankyou: string = '';
  feelingsData: string[] = [];
  stressData: string[] = [];
  satisfiedData: string[] = [];
  // suggestionData: string;
  // recommendPath: string = '';
  // mycounselerPath: string = '';
  // scheduledPath: string = '';
  // concernedPath: string = '';
  // suggestionPath: string = '';
  // loginPath: string = '';
  reset(): void {
    this.feelingsData = [];
    this.satisfiedData = [];
    this.stressData = [];
    // this.emotionThankyou = null;
  }
  clearCarePath(): void {
    this.feelingsPath = '';
    this.stressPath = '';
    this.satisfiedPath = '';
    this.emotionThankyou = '';

  }
}
@Injectable({
  providedIn: 'root',
})
export class EmotionalWellbeingSurveyService {
  private formData: EmotionalFlowFormData = new EmotionalFlowFormData();
  private approvalStageMessage = new BehaviorSubject('message from service');
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();

  constructor(private progressBarService: ProgressBarService) { }
  private workflowSteps = [

    { step: STEPS.feelingsPath, valid: false },
    { step: STEPS.stressPath, valid: false },
    { step: STEPS.satisfiedPath, valid: false },
    { step: STEPS.emotionThankyou, valid: false },

  ]

  validateStep(step: string): void {
    const index = this.getWorkFlowStepIndex(step);
    if (index >= 0) { this.workflowSteps[index].valid = true };
  }

  setCarePathProgress(step: string) {
    const blockNumber = this.workflowSteps.map(s => s.step).indexOf(step);
    const numOfSteps = this.workflowSteps.length; // for the employee information screen

    this.progressBarService.progress = { progress: blockNumber + 1, numberOfBlocks: numOfSteps, width: 50 };
  }
  private getWorkFlowStepIndex(step: string): number {
    return this.workflowSteps.findIndex(elem => elem.step === step);
  }

  resetSteps(): void {
    // reset all the steps to invalid for start over.
    this.workflowSteps.forEach(elem => { elem.valid = false });
  }
  resetFormData(): EmotionalFlowFormData {
    this.resetSteps();
    this.formData.reset();
    return this.formData;
  }

  getNextStep(step: string): string {
    const stepIndex = this.getWorkFlowStepIndex(step);
    if (stepIndex < 0 || (stepIndex + 1) >= this.workflowSteps.length) return '';
    return this.workflowSteps[stepIndex + 1].step;
  }
  updateApprovalMessage(message: string) {
    this.approvalStageMessage.next(message)
  }

  getPreviousStep(step: string): string {
    const stepIndex = this.getWorkFlowStepIndex(step);
    const workFlow = this.workflowSteps;

    if (stepIndex <= 0) return 'feelings';
    return workFlow[stepIndex - 1].step;
  }

  clearCarePath() {
    this.formData.clearCarePath();
  }

  getFirstInvalidStep(step: string): string {
    // if all previous steps are valid, return blank, else return the first invalid step
    // used by the route guard.
    let inValidStep: string = '';
    this.workflowSteps.some(elem => {
      if (elem.step === step) {
        return true;
      } else {
        if (!elem.valid) {
          inValidStep = elem.step;
          return true;
        }
      }
    });
    return inValidStep;
  }


  get feelingsData(): any {
    return this.formData.feelingsData;
  }

  set feelingsData(feelings: any) {
    this.formData.feelingsData = feelings;
    this.validateStep(STEPS.feelingsPath);
  }

  get satisfiedLifeData(): string[] {
    return this.formData.satisfiedData;
  }

  set satisfiedLifeData(satisfiedData: string[]) {
    this.formData.satisfiedData = satisfiedData;
    this.validateStep(STEPS.satisfiedPath);
  }


  get stressLifeData(): string[] {
    return this.formData.stressData;
  }

  set stressLifeData(stressData: string[]) {
    this.formData.stressData = stressData;
    this.validateStep(STEPS.stressPath);
  }


}
