import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor() {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('googleapis')) {
     request = request.clone({
      headers: request.headers.set('app-id', environment.appId).set('client-key', environment.clientKey)
     })
    }
     return next.handle(request);
  }
}
