<ng-container *ngIf="isValidating; then validateAddress; else enterAddress"></ng-container>

<ng-template #enterAddress>
  <form class="col-md-12 col-lg-12 col-sm-12 address-form" #form="ngForm" (ngSubmit)="validateUserAddress(form)">
    <div class="modal-content">
      <span class="close-icon" (click)="closeModal()">&#10005;</span>
      <span class="lead text-center">
        <p>{{ header }}</p>
      </span>
      <div class="text-center message-container">
        <div [hidden]="primaryAddressExist && !alwaysPromptForAnAddress">
          <div class="form-group" *ngIf="isShipping">
            <label for="fullName" class="sr-label">Ship to (NAME)</label>
            <input name="fullName" [(ngModel)]="tempUser.fullName" id="fullName" #fullName="ngModel" class="form-control"
              [class.form-control-danger]="fullName.invalid && fullName.touched" required>
            <small class="form-text error" *ngIf="fullName.invalid && fullName.touched && fullName.errors?.required">
              Name is required!
            </small>
          </div>

          <div class="form-group">
            <label for="input-addressLineOne" class="sr-label">Street Address - Line 1</label>
            <input name="addressLineOne" [(ngModel)]="tempUser.addresses[0].street" id="input-addressLineOne"
              #addressLineOne="ngModel" class="form-control"
              [class.form-control-danger]="addressLineOne.invalid && addressLineOne.touched" required>
            <small class="form-text error"
              *ngIf="addressLineOne.invalid && addressLineOne.touched && addressLineOne.errors?.required">
              Street Address is required!
            </small>
          </div>

          <div class="form-group">
            <label for="input-addressLineTwo" class="sr-label">Street Address - Line 2 (Optional)</label>
            <input name="addressLineTwo" [(ngModel)]="tempUser.addresses[0].suite" id="input-addressLineTwo"
              #addressLineTwo="ngModel" class="form-control">
          </div>

          <div class="form-group row">
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="input-state" class="sr-label">State</label>
                <select name="state" [(ngModel)]="tempUser.addresses[0].state" id="input-state" #state="ngModel" class="form-control"
                  [class.form-control-danger]="state.invalid && state.touched"
                  (change)="handleStateChange(tempUser.addresses[0].state, false)" required>
                  <option *ngFor="let state of getStates()" [value]="state">
                    {{state}}
                  </option>
                </select>
                <small class="form-text error" *ngIf="state.invalid && state.touched && state.errors?.required">
                  State is required!
                </small>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="input-city" class="sr-label">City</label>
                <div class="dropdown-wrapper">
                  <input type="text" name="city" [(ngModel)]="tempUser.addresses[0].city" id="input-city" #city="ngModel"
                    class="form-control dropdown-input city-field" [class.form-control-danger]="city.invalid && city.touched"
                    (input)="onCityInput(city.value)" (click)="showCityDropdown = true" (blur)="hideCityDropdownIfValid()" required
                    autocomplete="off" [ngClass]="{'is-invalid': !cityValid && city.touched}">
                  <i class="fa fa-caret-down dropdown-icon" (click)="toggleCityDropdown()"></i>
                  <ul
                    *ngIf="showCityDropdown && filteredCities.length > 0 && !cityValid"
                    class="dropdown-menu show">
                    <li *ngFor="let city of filteredCities" (mousedown)="selectCity(city)">
                      {{city}}
                    </li>
                  </ul>
                </div>
                <small *ngIf="loadingCities">Fetching cities...</small>
                <small class="form-text error" *ngIf="city.invalid && city.touched && city.errors?.required">
                  City is required!
                </small>
                <small class="form-text error" *ngIf="!cityValid && city.touched">
                  Invalid city!
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-12 col-sm-6">
              <label for="input-zip" class="sr-label">Zip Code</label>
              <input name="input-zip" [(ngModel)]="tempUser.addresses[0].postal" id="input-zip" #zip="ngModel"
                class="form-control" [class.form-control-danger]="zip.invalid && zip.touched" required
                ngxValidateZipCode minlength="5">
              <ng-container *ngIf="zip.invalid && zip.touched">
                <small class="form-text error" *ngIf="zip.errors?.required">
                  Zip Code is required!
                </small>
                <small class="form-text error" *ngIf="zip.errors?.minlength">
                  Zip Code should be at least 5 digits
                </small>
                <small class="form-text error" *ngIf="zip.errors?.zip">
                  Zip Code is invalid
                </small>
              </ng-container>
              <small *ngIf="zip.pending">Validating Zip Code...</small>
            </div>

            <div *ngIf="selected && selected.modeOfHelp==='online-therapy'" class="col-12 col-sm-6">
              <div class="form-group">
                <label for="input-county" class="sr-label">COUNTY</label>
                <input name="county" [(ngModel)]="tempUser.county" id="input-county" #county="ngModel" class="form-control"
                  [class.form-control-danger]="county.invalid && county.touched">
                <small class="form-text error" *ngIf="county.invalid && county.touched && county.errors?.required">
                  county is required!
                </small>
              </div>
            </div>
          </div>

          <div class="form-group d-flex justify-content-center" *ngIf="isShipping">
            <input class="input-same-home" type="checkbox" name="isHomeAddressSame" [(ngModel)]="isHomeAddressSame">
            <label class="form-check-label" for="input-same-home">
              This is the same as my home address
            </label>
          </div>
        </div>
        <div *ngIf="showEmergencyContact">
          <div>
            <div class="contactinfo">
              <p class="emergecny-contact">Emergency Contact Information</p>
              <div class="form-group">
                <label for="input-emergencyContactName" class="sr-label">Emergency Contact Name</label>
                <input name="emergencyContactName" [(ngModel)]="tempUser?.emergencyContact.name"
                  id="input-emergencyContactName" #emergencyContactName="ngModel" class="form-control"
                  [class.form-control-danger]="emergencyContactName.invalid && emergencyContactName.touched" required>
                <small class="form-text error"
                  *ngIf="emergencyContactName.invalid && emergencyContactName.touched && emergencyContactName.errors?.required">
                  Emergency Contact Name is required!
                </small>
              </div>
              <div class="form-group">
                <label for="input-phoneNumber" class="sr-label">Phone Number</label>
                <input name="phoneNumber" [(ngModel)]="tempUser?.emergencyContact.phoneNumber" id="input-phoneNumber"
                  #phoneNumber="ngModel" class="form-control" mask='(000) 000-0000'
                  [class.form-control-danger]="phoneNumber.invalid && phoneNumber.touched" required type="tel"
                  pattern="[0-9]{10,15}" minlength="10" maxlength="15">
                <small class="form-text error"
                  *ngIf="phoneNumber.invalid && phoneNumber.touched && phoneNumber.errors?.required">
                  Emergency Contact Phone Number is required!
                </small>
                <small class="form-text error"
                  *ngIf="phoneNumber.invalid && phoneNumber.touched && phoneNumber.errors?.minlength">
                  Emergency Contact Phone Number should be at minimum 10 digits!
                </small>
                <small class="form-text error"
                    *ngIf="phoneNumber.invalid && phoneNumber.touched && phoneNumber.errors?.minlength && !phoneNumber.errors?.minlength">
                    Emergency Contact Phone Number must be all digits (0-9)!
                  </small>
              </div>

              <div class="form-group">
                <label for="input-relationship" class="sr-label">Relationship to You</label>
                <input name="relationship" [(ngModel)]="tempUser?.emergencyContact.relationship" id="input-relationship"
                  #relationship="ngModel" class="form-control"
                  [class.form-control-danger]="relationship.invalid && relationship.touched" required>
                <small class="form-text error"
                  *ngIf="relationship.invalid && relationship.touched && relationship.errors?.required">
                  Relationship is required!
                </small>
              </div>

            </div>
            <!-- PCP info -->
            <!-- PCP info -->
          </div>
        </div>

        <div *ngIf="selected && selected.modeOfHelp==='online-therapy' && showPCPInfo && !pcpInfoExist" class="col-12 col-sm-12 mt-3">
          <div class="form-group">
            <p class="emergecny-contact">Primary Care Provider (PCP) Consent</p>
            <input type="checkbox" name="pcpAgreed" id="" class="pcpAgreed" [(ngModel)]="pcpAgreed" (change)="updateCityValidity(true)">
            Please uncheck the box if you <span class="pcpinfo">DO NOT</span> want your information sent to your PCP.
          </div>
          <div *ngIf="selected.modeOfHelp==='online-therapy' && pcpAgreed" class="pcpdetail">
            <p class="pcp-consent">Primary Care Provider Information</p>
            <div class="form-group">
              <label for="input-pcpname" class="sr-label"><span class="required">*</span>PCP Name</label>
              <input name="pcpname" [(ngModel)]="tempUser.UserDefinedTextField1" id="input-pcpname" #pcpname="ngModel"
                class="form-control" [class.form-control-danger]="pcpname.invalid && pcpname.touched" [required]="pcpAgreed" [disabled]="!pcpAgreed">
              <small class="form-text error" *ngIf="pcpname.invalid && pcpname.touched && pcpname.errors?.required">
                PCP name is required!
              </small>
            </div>
            <div class="form-group">
              <label for="input-pcp-addressLineOne" class="sr-label"><span class="required">*</span> Street Address - Line 1</label>
              <input name="pcpaddressLineOne" [(ngModel)]="tempUser.addresses[1].street" id="input-pcp-addressLineOne"
                #pcpaddressLineOne="ngModel" class="form-control" [class.form-control-danger]="pcpaddressLineOne.invalid && pcpaddressLineOne.touched" 
                [required]="pcpAgreed" [disabled]="!pcpAgreed">
              <small class="form-text error" *ngIf="pcpaddressLineOne.invalid && pcpaddressLineOne.touched && pcpaddressLineOne.errors?.required">
                Street Address is required!
              </small>
            </div>
            <div class="form-group">
              <label for="input-pcp-addressLineTwo" class="sr-label">Street Address - Line 2 (Optional)</label>
              <input name="pcpaddressLineTwo" [(ngModel)]="tempUser.addresses[1].suite" id="input-pcp-addressLineTwo"
                #pcpaddressLineTwo="ngModel" class="form-control" [disabled]="!pcpAgreed">
            </div>
            <div class="form-group row">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="select-pcp-state" class="sr-label"><span class="required">*</span> State</label>
                  <select name="pcp-state" [(ngModel)]="tempUser.addresses[1].state" id="input-pcp-state" #pcpstate="ngModel"
                    class="form-control" [class.form-control-danger]="pcpstate.invalid && pcpstate.touched" (change)="handleStateChange(tempUser.addresses[1].state, true)"
                    [required]="pcpAgreed" [disabled]="!pcpAgreed">
                    <option *ngFor="let state of getStates()" [value]="state">
                      {{state}}
                    </option>
                  </select>
                  <small class="form-text error" *ngIf="pcpstate.invalid && pcpstate.touched && pcpstate.errors?.required">
                    State is required!
                  </small>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="input-pcp-city" class="sr-label">PCP City</label>
                  <div class="dropdown-wrapper">
                    <input type="text" name="pcp-city" [(ngModel)]="tempUser.addresses[1].city" id="input-pcp-city" #pcpcity="ngModel"
                      class="form-control dropdown-input pcp-city-field" [class.form-control-danger]="pcpcity.invalid && pcpcity.touched"
                      (input)="onPcpCityInput(pcpcity.value)" (click)="showPcpCityDropdown = true" (blur)="hidePcpCityDropdownIfValid()"
                      [required]="pcpAgreed" autocomplete="off"
                      [ngClass]="{'is-invalid': !pcpCityValid && pcpcity.touched}" [disabled]="!pcpAgreed">
                    <i class="fa fa-caret-down dropdown-icon" (click)="togglePcpCityDropdown()"></i>
                    <ul
                      *ngIf="showPcpCityDropdown && filteredPcpCities.length > 0 && !pcpCityValid"
                      class="dropdown-menu show">
                      <li *ngFor="let city of filteredPcpCities" (mousedown)="selectPcpCity(city)">
                        {{city}}
                      </li>
                    </ul>
                  </div>
                  <small *ngIf="loadingPcpCities">Fetching cities...</small>
                  <small class="form-text error" *ngIf="pcpcity.invalid && pcpcity.touched && pcpcity.errors?.required">
                    City is required!
                  </small>
                  <small class="form-text error" *ngIf="!pcpCityValid && pcpcity.touched">
                    Invalid city!
                  </small>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="input-pcp-zip" class="sr-label"><span class="required">*</span> zip</label>
                  <input name="pcp-zip" [(ngModel)]="tempUser.addresses[1].postal" id="input-pcp-zip" #pcpzip="ngModel"
                    class="form-control" [class.form-control-danger]="pcpzip.invalid && pcpzip.touched" [required]="pcpAgreed" [disabled]="!pcpAgreed"
                    ngxValidateZipCode minlength="5">
                  <ng-container *ngIf="pcpzip.invalid && pcpzip.touched">
                    <small class="form-text error" *ngIf="pcpzip.errors?.required">
                      Zip Code is required!
                    </small>
                    <small class="form-text error" *ngIf="pcpzip.errors?.minlength">
                      Zip Code should be at least 5 digits
                    </small>
                    <small class="form-text error" *ngIf="pcpzip.errors?.zip">
                      Zip Code is invalid
                    </small>
                  </ng-container>
                  <small *ngIf="pcpzip.pending">Validating Zip Code...</small>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="input-pcpphone" class="sr-label"><span class="required">*</span> PCP Phone</label>
                  <input name="pcpphone" [(ngModel)]="tempUser.TTYPhone" id="input-pcpphone" #pcpphone="ngModel"
                    class="form-control" [required]="pcpAgreed" mask='(000) 000-0000'
                    type="tel" pattern="[0-9]{10,15}" minlength="10" maxlength="15" [disabled]="!pcpAgreed">
                  <small class="form-text error" *ngIf="pcpphone.invalid && pcpphone.touched && pcpphone.errors?.required">
                    PCP Number is required!
                  </small>
                  <small class="form-text error" *ngIf="pcpphone.invalid && pcpphone.touched && pcpphone.errors?.minlength">
                    PCP Number should be at minimum 10 digits!
                  </small>
                  <small class="form-text error" *ngIf="pcpphone.invalid && pcpphone.touched && pcpphone.errors?.minlength && !pcpphone.errors?.minlength">
                    PCP Number must be all digits (0-9)!
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        

      </div>

    </div>
    <div class="modal-footer text-center">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-12">
            <button
              [disabled]="!form.valid || !isCityValid(tempUser.addresses[0].city, filteredCities, false) || (pcpAgreed && !pcpCityValid)"
              [class.btn-pulse]="submitted" class="confirm-btn btn btn-lg btn-primary" (click)="onSubmitClicked(form)">
              {{ isShipping ? 'Confirm & Continue': 'Continue'}}
            </button>
          </div>
          <div class="col-12">
            <button class="cancel-btn btn btn-lg btn-outline-primary" (click)="closeModal()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #validateAddress>
  <div class="modal-header text-center">
    <span class="lead text-center">
      <p>Review your Address</p>
    </span>
  </div>

  <form class="col-md-8 col-lg-8 col-sm-12 mx-auto" #form="ngForm">
    <div class="modal-content">
      <div class="text-center message-container">
        <ngx-address-validation (addressChange)="chosenAdress = $event" [original]="formattedOriginalAddress"
          [suggested]="suggestedAddresses"></ngx-address-validation>
      </div>
    </div>
    <div class="modal-footer text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-6">
            <button class="btn btn-lg btn-primary" (click)="closeModal()">Cancel</button>
          </div>
          <div class="col-6">
            <button
              [disabled]="!form.valid || (!isShipping && !pcpCityValid) || !cityValid"
              [class.btn-pulse]="submitted" class="btn btn-lg btn-primary" (click)="submitForm(form)">
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
