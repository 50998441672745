<ng-container *ngIf="enableMaintenanceMode; then maintenace; else normal">
</ng-container>
<ng-template #maintenace>
  <div class="row main my-3 mx-2" #nav>
    <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12">
      <!-- <div class="heading">MAINTENANCE</div> -->
      <div class="mb-3">
        <p></p>
      </div>
      <div class="mb-3">
        <!-- <p>We are undergoing a bit of scheduled maintenace.</p> -->
      </div>
      <div class="mb-3 mt-3">
        <h3 style="text-align: center; margin-top: 50px;">Sorry, we’re down for maintenance.</h3>
        <h3 style="text-align: center;">We’ll be back up shortly. If you require
          further assistance,
          please call <a href="tel:8003444222">(800) 344-4222</a>
        </h3>
      </div>
    </div>
    <!-- <div class="col-sm-12 col-xs-12 col-lg-5 col-md-5 d-flex align-items-center justify-content-center">
      <img src="../../../assets/images/landing/maintenance.png" alt="maintenace" class="img-fluid">
    </div> -->
  </div>
</ng-template>
<ng-template #normal>
  <div class="row main my-3 mx-2" #nav>
    <div class="col-sm-12 col-xs-12 col-lg-6 col-md-6">
      <div class="heading">{{title}}</div>
      <div class="mb-3 content">
        <p>{{contentTop}}</p>
      </div>
      <div class="mb-3 content">
        <p>{{contentBottom}}</p>
      </div>
      <div class="mb-3 content">
        <p>{{contentShort}}</p>
      </div>
      <div>
        <p>
          <a class="link" routerLink="{{getHelpLink}}">Sign Up</a>
          <span class="mx-1">or</span>
          <a class="link" routerLink="../auth/login">Sign in</a>
          now to get started. A happier you awaits.
        </p>
      </div>
    </div>
    <div class="col-sm-12 col-xs-12 col-md-6 d-flex align-items-center justify-content-center">
      <img src="../../../assets/images/landing/detail.png" alt="detail" class="img-fluid detail-img">
    </div>
  </div>
</ng-template>