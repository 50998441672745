/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./@theme/components/navbar/navbar.component.ngfactory";
import * as i2 from "./@theme/components/navbar/navbar.component";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/router";
import * as i5 from "./@core/data/caseManagement.service";
import * as i6 from "./@theme/components/reusables/moment-support/moment-support.component.ngfactory";
import * as i7 from "./@theme/components/reusables/moment-support/moment-support.component";
import * as i8 from "./@core/utils/auth-guard.service";
import * as i9 from "@nebular/auth/services/token/token-storage";
import * as i10 from "./app.component";
import * as i11 from "./@core/utils/analytics.service";
import * as i12 from "./@core/data/users.service";
import * as i13 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i14 from "./@core/data/api/send-email.service";
import * as i15 from "ngx-cookieconsent";
import * as i16 from "angular-user-idle";
import * as i17 from "./@core/utils/previous-url.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-navbar", [], null, null, null, i1.View_NavbarComponent_0, i1.RenderType_NavbarComponent)), i0.ɵdid(1, 114688, null, 0, i2.NavbarComponent, [i3.HttpClient, i4.Router, i5.CaseManagementService], null, null), (_l()(), i0.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "ngx-moment-support", [], null, null, null, i6.View_MomentSupportComponent_0, i6.RenderType_MomentSupportComponent)), i0.ɵdid(5, 114688, null, 0, i7.MomentSupportComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ngx-app", [], null, [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseleave" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).mouseLeave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i0.ɵprd(4608, null, i8.AuthGuardService, i8.AuthGuardService, [i4.Router, i9.NbTokenStorage]), i0.ɵdid(2, 4440064, null, 0, i10.AppComponent, [i11.AnalyticsService, i4.Router, i9.NbTokenStorage, i12.UserService, i13.CookieService, i14.SendEmailService, i15.NgcCookieConsentService, i5.CaseManagementService, i16.UserIdleService, i17.PreviousUrlService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("ngx-app", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
