<div class="animate__animated animate__slideInRight animate__faster slider_10">
  <div class="row justify-content-center text-center">
    <h4 class="col-12 top header-text font-size-36 font-500 font-montserrat mb45 color-black">
      How likely are you to recommend Concern services to family, friends, co-workers?
    </h4>
  </div>
  <ngx-slider [(value)]="value" [options]="options" (click)="changeValueEvent(value)" class="mb125"
    [ngClass]="{'remove_pointer': !isShowAnswer}">
  </ngx-slider>
  <div class="row justify-content-center no-gutters nps_continue">
    <button class="btn btn-primary col-12 col-md-5 mb64" (click)="goNext()"
      [disabled]="submitted || disableButton">CONTINUE
      <i class="fas fa-arrow-circle-right"></i></button>


  </div>
</div>