/**
 * Luma API
 * Luma API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { ProviderLicense } from './providerLicense';
import { User } from './user';


/**
 * read-only access
 */
export interface Provider extends User {
    betterHelpId: any;
    /**
     * The id of the provider on the external telehealth vendor platform
     */
    externalId?: string;
    /**
     * The external id for the provider on telehealth vendor platform
     */
    providerId?: string;
    /**
     * The id for the provider on case management platform
     */
    internalId?: string;
    /**
     * The image timestamp for the provider on case management platform
     */
    imageModifiedOn?: string;
    ethnicity?: string;
    role?: Provider.RoleEnum;
    /**
     * provider will be found in video enabled providers search
     */
    videoEnabled?: boolean;
    /**
     * provider is on-boarded and has an up-to-date list of available times
     */
    onlineSchedulingEnabled?: boolean;
    /**
     * provider is associated to a list of org locations where on-site counseling is offered
     */
    onSiteEnabled?: boolean;
    /**
     * list of location ids (or sub-org) at which on-site counseling is offered
     */
    onSiteOrgIds?: Array<string>;
    thumbnail?: string;
    photo?: string;
    /**
     * the start date of service with Concern:EAP (provider since)
     */
    startDate?: string;
    specializations?: Array<string>;
    /**
     * matches to credentialsSelected on EAPX. equivalent to age groups
     */
    credentials?: Array<string>;
    biography?: string;
    /**
     * matches associationsSelected on EAPX
     */
    associations?: string;
    insurancePanels?: Array<string>;
    clinicalPanels?: Array<string>;
    authorized?: number;
    /**
     * Gets or Sets OfficeLocations
     */
    officeLocations?: Array<Address>;
    /**
     * Gets or Sets ClinicalLicenses
     */
    providerLicenses?: Array<ProviderLicense>;
}
export namespace Provider {
    export type RoleEnum = 'counselor';
    export const RoleEnum = {
        Counselor: 'counselor' as RoleEnum,
    };
}
