import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NbTokenStorage } from '@nebular/auth';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, public tokenStorage: NbTokenStorage) {}
  canActivate(): boolean {
    const token = this.tokenStorage.get().getValue();
    if (!token || token === '') {
      this.router.navigate(['auth/login']);
      return false;
    }
    return true;
  }
}
