/**
 * Luma API
 * Luma API
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/*  tslint:disable */

export interface Address {
    id?: number;
    name?: string;
    type?: Address.TypeEnum;
    street?: string;
    city?: string;
    state?: string;
    postal?: string;
    country?: string;
}
export namespace Address {
    export type TypeEnum = 'room' | 'office' | 'home' | 'mailing' | 'billing' | 'default';
    export const TypeEnum = {
        Room: 'room' as TypeEnum,
        Office: 'office' as TypeEnum,
        Home: 'home' as TypeEnum,
        Mailing: 'mailing' as TypeEnum,
        Billing: 'billing' as TypeEnum,
        Default: 'default' as TypeEnum
    }
}
