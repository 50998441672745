<div class="justify-content-center {{selectorRowClass}}">
  <div class="pill-selector {{selectorInnerClass}}">
    <div class="row justify-content-center">
      <div class="pill_title font-size-24 font-weight-400 text-center mb16 font-black">
        {{ caption }}
      </div>
    </div>
    <ul class="nav nav-pills {{'flex-column'}}" (window:resize)="onResize()">
      <li *ngFor="let item of items; let i = index" class="nav-item {{i === selectedIndex ? 'activeok' : ''}}"
        (click)="onClick(i)">
        <span class="checkered-left"></span>
        <a class="nav-link pill-link item-text" data-toggle="pill">{{ item }} </a>
      </li>
    </ul>
  </div>
</div>
