import { Component, OnInit } from '@angular/core';
import { IntakeFlowService } from '../../@core/utils/intake-flow.service';

@Component({
  selector: 'ngx-landing-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  title: String;
  description: String;
  imgPath: String;
  btnText: String;
  getHelpLink: String;
  items: String[] = ['No Stress', 'No Judgement', 'No cost'];
  constructor(private intakeFlowService: IntakeFlowService) {
    this.title = 'Concern Is Here to Help';
    this.btnText = 'ACCESS SERVICES';
    this.imgPath = '/assets/images/landing/footer-img.jpg'
    this.description = `We provide a suite of services to help you manage work and life.
     Our confidential digital experience personalizes our wellbeing solutions to fit your needs.`;
  }

  ngOnInit() {
    // Check if company context is set
    if (this.intakeFlowService.getFormData().currentContract) {
      this.getHelpLink = '/pages/intake/care-path';
    } else {
      this.getHelpLink = '/pages/intake/care-path';
    }
  }

}
