import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../modal.component';

@Component({
  selector: 'ngx-coaching-transition-modal',
  templateUrl: './coaching-transition-modal.component.html',
  styleUrls: ['./coaching-transition-modal.component.scss'],
})
export class CoachingTransitionModalComponent implements OnInit {
  heading: string = 'How BetterHelp matches you with a Counselor';


  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }

  accept(): void {
    this.activeModal.close(ConfirmationModal.Yes);
  }
}
